import { useEffect, useState } from "react";

import { ReactComponent as IconCommentPlus } from "../icons/IconCommentPlus.svg";

import { PopupWindowWithHeader } from "../components/PopupWindow";
import CurrentSelection from "../components/CurrentSelection";
import SelectQuestionType, {
  QuestionAbility,
} from "../components/QuestionType";
import WordCount from "../components/WordCount";
import Button from "../components/Button";
import cx from "classnames";

import { QuestionType } from "../components/QuestionType";
interface SelectQuestionPopupProps {
  article: String;
  selectedText: string;
  onClose: () => void;
  onSave: (
    type: QuestionType | null,
    ability: QuestionAbility | null,
    numberOfParagraph: string
  ) => void;
  mode: string;
  selectAllPressed?: boolean;
}

function SelectQuestionPopup({
  article,
  onClose,
  selectedText,
  onSave,
  mode,
  selectAllPressed = false,
}: SelectQuestionPopupProps) {
  // const selectedText = [
  //   "Audio player software is used to play back sound recordings in one of the many formats available for computers today. It can also play back music CDs. There is audio player software that is native to the computer’s operating system (Windows, Macintosh, and Linux) and there are web-based audio players. This article discusses the local computer audio players. Advantages of computer audio player software",
  //   "The main advantage of a computer audio player is that you can play your audio CDs and there is no longer any need to have a separate CD player. However the capabilities of the computer audio player go beyond just playing traditional music CDs.",
  //   "Software is used to play back sound recordings in one of the many formats available for computers today. It can also play back music CDs. There is audio player software that is native to the computer’s operating system (Windows, Macintosh, and Linux) and there are web-based audio players. This article discusses the local computer audio players.",
  //   "Software is used to play back sound recordings in one of the many formats available for computers today. It can also play back music CDs. There is audio player software that is native to the computer’s operating system (Windows, Macintosh, and Linux) and there are web-based audio players. This article discusses the local computer audio players.",
  // ];
  const trimmed: string[] = article.split(/\n+/).map((para) => para.trim());

  const [selectedType, setSelectedType] = useState<QuestionType | null>(null);
  const [selectedAbility, setSelectedAbility] =
    useState<QuestionAbility | null>(null);
  const [numberOfParagraph, setNumberOfParagraph] = useState<number>(0);

  useEffect(() => {
    const selectedTexts: string[] = selectedText
      .split(/\n+/)
      .filter((str) => str);
    for (let i = 0; i < trimmed.length; i++) {
      const paragraph: string = trimmed[i].trim();

      if (paragraph.includes(selectedTexts[0].trim())) {
        setNumberOfParagraph(i + 1);
      }
    }
  }, [selectedText]);

  const paragraphsNumber = "The paragraph number is " + numberOfParagraph;

  return (
    <PopupWindowWithHeader
      title={`Create Question ${mode}`}
      onClose={onClose}
      className="min-w-[800px] inset-y-[5%] w-4/5 flex flex-col"
    >
      <main className="flex flex-col flex-grow flex-shrink basis-0">
        <div className="grid grid-cols-2 gap-x-6 py-4 px-[30px] overflow-y-auto flex-grow flex-shrink basis-0">
          <CurrentSelection
            selections={
              selectAllPressed
                ? "Whole Article"
                : selectedText
                    .split(/\n+/)
                    .filter((str) => str)
                    .map((para) => para.trim())
            }
            NumberOfParagraph={numberOfParagraph}
          />
          <SelectQuestionType
            onSelectType={(type, ability) => {
              setSelectedType(type);
              setSelectedAbility(ability ?? null);
            }}
          />
        </div>
      </main>
      <footer className="px-[30px] pb-5 flex justify-between">
        <WordCount text={selectedText} className="text-sm" />

        <div className="flex gap-3">
          <Button
            text="Discard"
            className="bg-white text-blue1 border border-border2 hover:bg-grey4"
            onClick={onClose}
          />
          <Button
            text="Create Question"
            icon={IconCommentPlus}
            className={cx(
              "bg-white text-blue1 border border-border2 cursor-not-allowed",
              {
                "!text-white bg-blue1 hover:bg-blue2 hover:border-grey !cursor-pointer":
                  selectedType,
              }
            )}
            onClick={() =>
              onSave(selectedType, selectedAbility, paragraphsNumber)
            }
            disable={!selectedType}
          />
        </div>
      </footer>
    </PopupWindowWithHeader>
  );
}

export default SelectQuestionPopup;

//One day, while going about their daily routines, the six characters stumbled upon a mysterious map that showed the location of a hidden treasure. They all looked at each other in amazement, wondering what the treasure could be and how they would find it.
//One day, while going about their daily routines, the six characters stumbled upon a mysterious map that showed the location of a hidden treasure. They all looked at each other in amazement, wondering what the treasure could be and how they would find it.
