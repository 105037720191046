import React, { useMemo, useState } from "react";
import cx from "classnames";
import { ReactComponent as IconChevronDown } from "../../icons/IconChevronDown.svg";

export type Option<T> = {
  id: string;
  name?: string;
  value: T;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  disabled?: boolean;
};
export type Options<T> = Option<T>[];

interface DropdownItemProps<T>
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "onClick"> {
  option: Option<T>;
  onClick: () => void;
}

interface DropdownProps<T>
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "onSelect"> {
  options: Options<T>;
  defaultOption?: Option<T>;
  placeholder?: string;
  onSelect: (option: Option<T> | null) => void;
  disabled?: boolean;
}

function DropdownItem<T>({ option, onClick }: DropdownItemProps<T>) {
  const { name, icon: Icon, disabled } = option;
  return (
    <div
      tabIndex={disabled ? undefined : 0}
      aria-disabled={disabled}
      onClick={disabled ? undefined : onClick}
      className="cursor-pointer py-[10px] px-4 text-black font-semibold flex items-center gap-4 rounded-[8px] hover:text-blue1 hover:bg-blue1 hover:bg-opacity-10"
    >
      {/* show icon if exists */}
      {Icon && <Icon className="w-[20px] h-[20px]" />}
      {name}
    </div>
  );
}

function Dropdown<T>({
  options,
  defaultOption,
  placeholder,
  onSelect,
  disabled = false,
}: DropdownProps<T>) {
  const [isOpen, setIsOpen] = useState(false);

  const items = useMemo(() => {
    return options.reduce((currArr, option) => {
      currArr.push({ ...option, name: option.name ?? String(option.value) });
      return currArr;
    }, [] as Options<T>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selected, setSelected] = useState<Option<T> | null>(() => {
    if (!defaultOption) return null;
    return defaultOption.name
      ? defaultOption
      : { ...defaultOption, name: String(defaultOption.value) };
  });

  const handleItemClick = (item: Option<T>) => {
    setSelected(item);
    setIsOpen(false);
    onSelect && onSelect(item);
  };

  return (
    <div className="relative mr-[24px] self-start">
      <button
        className="block font-semibold rounded-11xl py-3 px-6 flex gap-2.5 items-center justify-center bg-blue1 hover:bg-blue2 text-sm text-white disabled:text-grey8 disabled:bg-grey7"
        onClick={() => setIsOpen(!isOpen)}
        disabled={disabled}
      >
        {placeholder || (selected?.name ?? "")}
        <IconChevronDown className="w-[1.2em] h-[1.2em]" />
      </button>

      <div
        className={cx(
          "absolute top-[120%] w-max right-0 p-2 bg-white1 rounded-[10px] shadow-md",
          { hidden: !isOpen }
        )}
      >
        {/* populate options */}
        {items.map((item) => (
          <DropdownItem
            key={item.id}
            option={item}
            onClick={() => handleItemClick(item)}
          />
        ))}
      </div>
    </div>
  );
}

export default Dropdown;
