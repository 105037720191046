import {
  Exercise,
  Question,
  QuestionAbility,
  QuestionType,
  TrialUser,
} from "./codegen/API";
import { Observable } from "zen-observable-ts/lib/zenObservable";
import { API } from "aws-amplify";
import * as mutations from "./codegen/graphql/mutations";
import * as query from "./codegen/graphql/queries";
import * as subscription from "./codegen/graphql/subscriptions";
import { GraphQLResult } from "@aws-amplify/api-graphql";

// export type subscriptType = {
//   value: Exercise;
// };
// export type value = {
//   data: data;
// };
export type data = {
  allExercisesByUser: ExerciseList | null;
  getParticularExercise: ExerciseList | null;
  getParticularExerciseConsistent: ExerciseList | null;
  deleteExercise: ExerciseList | null;
  createExercise: Exercise | null;
  subscribeExercise: Exercise | null;
  generateQuestion: string | null;
  generatePaper: string | null;
  getCheckOutSession: string | null;
  getPublishedExercises: ExerciseList | null;
  getParticularPublishedExercise: ExerciseList | null;
  getMyTrialUser: TrialUser | null;
};

export type ExerciseList = {
  exercises: Array<Exercise> | null;
  nextToken: string | null;
};

export const queryGetCheckOutSession = async (successUrl: string) => {
  return await (API.graphql({
    query: query.getCheckOutSession,
    variables: { successUrl: successUrl },
  }) as GraphQLResult<data>);
};

export const queryAllExerciseForUser = async () => {
  return await (API.graphql({
    query: query.allExercisesByUser,
  }) as GraphQLResult<data>);
};

export const queryParticularExercise = async (exerciseIdInput: string) => {
  return await (API.graphql({
    query: query.getParticularExercise,
    variables: { exerciseID: exerciseIdInput },
  }) as GraphQLResult<data>);
};

// export const queryAllExerciseForUser = async () => {
//   return await API.graphql({
//     query: query.allExercisesByUser,
//   });
//   //   console.log(queryAllExerciseForUser);
//   //   console.log("called API");
// };
// Create exercise

export const queryPublishedExercises = async () => {
  return await (API.graphql({
    query: query.getPublishedExercises,
  }) as GraphQLResult<data>);
};

export const queryParticularPublishedExercise = async (
  exerciseIdInput: string
) => {
  return await (API.graphql({
    query: query.getParticularPublishedExercise,
    variables: { exerciseID: exerciseIdInput },
  }) as GraphQLResult<data>);
};

export const queryGetMyTrialUser = async () => {
  return await (API.graphql({
    query: query.getMyTrialUser,
  }) as GraphQLResult<data>);
};

//handle duplicate as a copy of paper: set published true
export const mutationCreateExerciseUser = async (
  exerciseName: string,
  article: string,
  articleName?: string,
  questions?: Array<Question>,
  published?: string
) => {
  return await (API.graphql({
    query: mutations.createExercise,
    variables: {
      exerciseName: exerciseName,
      article: article,
      articleName: articleName,
      questions: questions,
      published: published,
    },
  }) as GraphQLResult<data>);
};

// Delete exercise

export const mutationDeleteExerciseUser = async (exerciseIdInput: string) => {
  try {
    await API.graphql({
      query: mutations.deleteExercise,
      variables: { exerciseID: exerciseIdInput },
    }) as GraphQLResult<data>;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
//  Update Exercise

export const mutationUpdateExerciseUser = async (
  exerciseIdInput: string,
  articleInput: string,
  exerciseNameInput: string,
  questionsInput: Array<Question>,
  articleNameInput?: string
) => {
  return await API.graphql({
    query: mutations.updateExercise,
    variables: {
      exerciseID: exerciseIdInput,
      article: articleInput,
      exerciseName: exerciseNameInput,
      questions: questionsInput,
      articleName: articleNameInput ? articleNameInput : "",
    },
  });
};

export const mutationUpdateExerciseAccessDateUser = async (
  exerciseIdInput: string
) => {
  return await API.graphql({
    query: mutations.updateExerciseLastAccess,
    variables: {
      exerciseID: exerciseIdInput,
    },
  });
};

export const mutationGenerateExerciseUser = async (
  exerciseIdInput: string,
  questionTypeInput: QuestionType,
  questionAbilityInput: QuestionAbility | null,
  articleContextInput: string,
  highlightStartInput: string,
  highlightEndInput: string
) => {
  if (
    exerciseIdInput == null ||
    articleContextInput == null ||
    highlightStartInput == null ||
    highlightEndInput == null
  ) {
    return;
  }

  return await API.graphql({
    query: mutations.generateQuestion,
    variables: {
      exerciseID: exerciseIdInput,
      questionType: questionTypeInput,
      questionAbility: questionAbilityInput,
      articleContext: articleContextInput,
      highlightStart: highlightStartInput,
      highlightEnd: highlightEndInput,
    },
  });
};

export const mutationGeneratePaper = async (
  exerciseIdInput: string,
  articleContextInput: string,
  highlightStartInput: string,
  highlightEndInput: string,
  levelOfDifficulty: string,
) => {
  if (
    exerciseIdInput == null ||
    articleContextInput == null ||
    highlightStartInput == null ||
    highlightEndInput == null ||
    levelOfDifficulty == null
  ) {
    return;
  }

  return await API.graphql({
    query: mutations.generatePaper,
    variables: {
      exerciseID: exerciseIdInput,
      articleContext: articleContextInput,
      highlightStart: highlightStartInput,
      highlightEnd: highlightEndInput,
      levelOfDifficulty: levelOfDifficulty
    },
  });
};

export const mutationSwapQuestion = async (
  exerciseID: string,
  questionIdx1: number,
  questionIdx2: number
) => {
  return (await API.graphql({
    query: mutations.swapQuestion,
    variables: {
      exerciseID,
      questionIdx1,
      questionIdx2,
    },
  })) as Promise<{ data: { swapQuestion: Exercise } }>;
};

export const mutationUpdateQuestion = async (
  exerciseID: string,
  questionIdx: number,
  question: Question
) => {
  return (await API.graphql({
    query: mutations.updateQuestion,
    variables: {
      exerciseID,
      questionIdx,
      question,
    },
  })) as Promise<{ data: { updateQuestion: Exercise } }>;
};

export const mutationUpdateArticle = async (
  exerciseID: string,
  article: string,
  articleName: string
) => {
  return (await API.graphql({
    query: mutations.updateArticle,
    variables: {
      exerciseID,
      article,
      articleName,
    },
  })) as Promise<{ data: { updateArticle: Exercise } }>;
};

export const mutationUpdateExerciseName = async (
  exerciseID: string,
  exerciseName: string
) => {
  return (await API.graphql({
    query: mutations.updateExerciseName,
    variables: {
      exerciseID,
      exerciseName,
    },
  })) as Promise<{ data: { updateExerciseName: Exercise } }>;
};

export const mutationDeleteQuestion = async (
  exerciseID: string,
  questionIdx: number
) => {
  return (await API.graphql({
    query: mutations.deleteQuestion,
    variables: {
      exerciseID,
      questionIdx,
    },
  })) as Promise<{ data: { deleteQuestion: Exercise } }>;
};

export const mutationUpsertPublish = async (
  exerciseID: string,
  published?: string
) => {
  return (await API.graphql({
    query: mutations.upsertPublish,
    variables: {
      exerciseID,
      published,
    },
  })) as Promise<{ data: { upsertPublish: Exercise } }>;
};

// Subscripition
export const subscriptionUser = (
  exerciseIdInput: string,
  userIdInput: string
) => {
  return API.graphql({
    query: subscription.subscribeExercise,
    variables: { id: exerciseIdInput, userId: userIdInput },
  }) as Observable<Exercise>;
};

export const subscriptionQuestion = (exerciseId: string, userId: string) => {
  return API.graphql({
    query: subscription.subscribeQuestion,
    variables: { id: exerciseId, userId: userId },
  }) as Observable<Exercise>;
};
