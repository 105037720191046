import { AnswerWithId } from "./QuestionTypes";
import { ReactComponent as IconTrashCan } from "../../icons/IconTrashCan.svg";
import cx from "classnames";
import { useState } from "react";

interface SCAnswerProps {
  answers: AnswerWithId;
  setModelAnswer: (value: AnswerWithId) => void;
  isEditing: boolean;
}

export default function SCContent({
  answers,
  setModelAnswer,
  isEditing,
}: SCAnswerProps) {
  const [onChange, setOnChange] = useState<boolean>(false);
  const handleAnswerChange = (id: number | string, newValue: string) => {
    const newArr = [...answers];
    // find choice by id
    const idxToChange = newArr.findIndex((item) => item.id === id);
    if (idxToChange === -1) return;

    newArr[idxToChange].answer = newValue;
    setModelAnswer(newArr);
  };

  const handleDelete = (id: number | string) => {
    if (answers.length <= 1) return;

    const newArr = answers.filter((item) => item.id !== id);
    setModelAnswer(newArr);
  };
  return (
    <table className="table-auto border-separate border-spacing-2 overflow-x-auto w-full px-2 block">
      <thead>
        <tr>
          <th></th>
          {!isEditing ? <th></th> : <></>}
          {!isEditing ? <th></th> : <></>}
          <th className="w-[99%] text-start text-grey2 font-normal text-sm leading-6">
            Answer
          </th>
          <th></th>
          {isEditing && <th></th>}
          {isEditing && <th></th>}
        </tr>
      </thead>
      <tbody>
        {answers.map(({ id, answer }, index) => {
          if (isEditing) {
            return (
              <tr key={id} className="w-full">
                <td className="">
                  <div className="h-[22px] w-[22px] rounded-full bg-purple-50 text-[8.5px] grid place-content-center font-semibold">
                    {index + 1}
                  </div>
                </td>
                <td className="flex">
                  <input
                    className="border-2 py-[8px] px-[12px] border-Gray focus:outline-none focus:border-sky-400 rounded-md text-[14px] font-normal w-full"
                    placeholder={`Answer ${index + 1}`}
                    value={answer}
                    onChange={(e) => {
                      handleAnswerChange(id, e.target.value);
                      setOnChange(!onChange);
                    }}
                  />
                  {/* <div className="border border-gray-400 border-title text-gray-700">
                    <h2 className="text-lg font-bold mb-2">
                      Title
                    </h2>
                    <input
                      className="
                      border-2 px-[12px] border-Gray focus:outline-none focus:border-sky-400 rounded-md text-[14px] font-normal w-full"
                      placeholder={`Answer ${index + 1}`}
                      value={answer}
                      onChange={(e) => {
                        handleAnswerChange(id, e.target.value)
                        setOnChange(!onChange)
                      }}
                    /> */}
                  {/* </div> */}
                </td>
                <td>
                  <IconTrashCan
                    className={cx(
                      "cursor-pointer w-[1.2em] h-[1.2em] text-[#FF6628] hover:text-[#DE6628]",
                      { "!text-grey cursor-not-allowed": answers.length <= 1 }
                    )}
                    title={
                      answers.length <= 1
                        ? "At least 2 choices are required"
                        : "Delete"
                    }
                    onClick={() => {
                      handleDelete(id);
                    }}
                  />
                </td>
                <td></td>
              </tr>
            );
          } else {
            return (
              <tr key={index}>
                <td></td>
                <td></td>
                <td></td>
                <td className="flex gap-3 text-CTA/Success font-semibold text-[14px]">
                  <div>{index + 1}.</div>
                  <div>{answer}</div>
                </td>
                <td></td>
              </tr>
            );
          }
        })}
      </tbody>
    </table>
  );
}
