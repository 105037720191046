import React from "react";
import cx from "classnames";

function SelectionAbsence({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cx("relative m-auto", className)} {...rest}>
      <img
        alt=""
        src="/book-and-sheet.svg"
        className="w-[160.05px] h-[135.44px] mx-auto"
      />
      <p className=" text-center text-lg font-medium text-grey5">
        Select article first
      </p>
      <p className=" text-center text-sm font-light text-grey6">
        Please select text to generate question{" "}
      </p>
      <p className=" text-center text-sm font-light text-grey6">
        or add question manually
      </p>
    </div>
  );
}

export default SelectionAbsence;
