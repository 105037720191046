interface FTBContentProps {
  answerStatementPlaceHolder: string;
  answerStatement: string;
  setAnswerStatement: (value: string) => void;
  answer: string;
  answerPlaceHolder: string;
  setAnswer: (value: string) => void;
  isEditing: boolean;
}

export default function FTBContent({
  answerStatementPlaceHolder,
  answerStatement,
  setAnswerStatement,
  answer,
  answerPlaceHolder,
  setAnswer,
  isEditing,
}: FTBContentProps) {
  return (
    <>
      {isEditing ? (
        <div className="flex flex-col gap-2 px-4">
          <h1 className="font-normal text-[14px] text-grey2">
            Answer Statement
          </h1>
          <textarea
            className="w-full h-fit py-[12px] px-4 border-2 border-Gray focus:outline-none focus:border-sky-400 rounded-md text-sm leading-6"
            placeholder={answerStatementPlaceHolder}
            value={answerStatement ?? ""}
            onChange={(e) => setAnswerStatement(e.target.value)}
          />
          <h1 className="font-normal text-[14px] text-grey2">Answer</h1>
          <textarea
            className="w-full h-fit py-[12px] px-4 border-2 border-Gray focus:outline-none focus:border-sky-400 rounded-md text-sm leading-6"
            placeholder={answerPlaceHolder}
            value={answer ?? ""}
            onChange={(e) => setAnswer(e.target.value)}
          />
        </div>
      ) : (
        <div className="flex flex-col pl-11 text-sm leading-6">
          <div className="text-[14px] pr-7 text-black font-semibold break-words">
            {answerStatement + " "}
            <span className="break-all">{"_".repeat(80).concat(" .")}</span>
          </div>
          <h1 className="font-normal  text-grey2 mt-[20px] mb-[5px]">Answer</h1>
          <div className="text-[14px] pr-11 text-CTA/Success font-semibold">
            {answer}
          </div>
        </div>
      )}
    </>
  );
}
