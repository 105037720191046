import { Menu, Transition } from "@headlessui/react";
import { Question, QuestionAbility, QuestionType } from "../api/codegen/API";
import { Fragment } from "react";
import QuestionTypeSummary from "./QuestionTypeSummary";
import { questionTypeNameMap } from "./QuestionType";

interface QuestionAbilitySummaryBoxProps {
  title: string;
  abilityGroup?: QuestionAbility[];
  questions: Question[];
  bgColor: string;
  textColor: string;
}

const questionTypes: QuestionType[] = [
  QuestionType.LONG_QUESTION,
  QuestionType.SHORT_QUESTION,
  QuestionType.TRUE_FALSE,
  QuestionType.MULTIPLE_CHOICE,
  QuestionType.SUMMARY_CLOZE,
  QuestionType.FILL_THE_BLANK,
  QuestionType.MATCHING,
  QuestionType.TABLE,
];

const QuestionAbilitySummaryBox = ({
  abilityGroup,
  questions,
  title,
  bgColor,
  textColor,
}: QuestionAbilitySummaryBoxProps) => {
  if (questions.length === 0) return null;

  const questionTypeList = questions.reduce((result, q) => {
    if (!result.includes(q.questionType)) {
      result.push(q.questionType);
    }
    return result;
  }, [] as Array<QuestionType>);

  const completeMatchingTypeAndAbilityArray = abilityGroup?.reduce(
    (result, ability) => {
      questionTypeList.forEach((type) => {
        result.push({ ability: ability, type: type });
      });
      return result;
    },
    [] as { ability: QuestionAbility; type: QuestionType }[]
  );

  return (
    <Menu as="div" className="relative inline-block text-left justify-center">
      <Menu.Button>
        <div
          className="flex flex-row items-center w-[352px] h-[61px] rounded-16xl"
          style={{ backgroundColor: bgColor }}
        >
          <h1
            className="font-semibold text-[16px] ml-[22px] mr-auto"
            style={{ color: textColor }}
          >
            {title}
          </h1>
          <div className="w-[34px] h-[34px] font-semibold text-[14px] rounded-full bg-white mr-[15px] ml-auto flex justify-center items-center">
            {questions.length}
          </div>
        </div>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items
          className="absolute group flex w-[80%] rounded-md mt-1 px-2 py-2 z-10 text-sm"
          style={{ backgroundColor: bgColor, color: textColor }}
        >
          <Menu.Item disabled>
            <div className="flex flex-col gap-1">
              {completeMatchingTypeAndAbilityArray?.map((group) => {
                {
                  return (
                    <QuestionTypeSummary
                      key={group.ability}
                      type={group.type}
                      ability={group.ability}
                      // we cannot use this one now since there are multiple questionTypes per each questionAbility.
                      questions={questions.filter(
                        ({ questionAbility, questionType }) => {
                          return (
                            questionAbility === group.ability &&
                            questionType === group.type
                          );
                        }
                      )}
                    />
                  );
                }
              }) ??
                questionTypes.map((type) => {
                  return (
                    <QuestionTypeSummary
                      key={type}
                      type={type}
                      questions={questions.filter(({ questionType }) => {
                        return questionType === type;
                      })}
                    />
                  );
                })}
            </div>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default QuestionAbilitySummaryBox;
