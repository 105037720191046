import DocumentsPage from "../components/DocumentPageLayout";

export default function DashboardPage() {
  return (
    <DocumentsPage
      addNewExButtonShown={true}
      sortingButtonShown={true}
      selectionButtonShown={true}
      title="Recent Exercises"
      pageContent="dashboard"
    />
  );
}
