import {
  AlignmentType,
  BorderStyle,
  HeadingLevel,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TabStopType,
  TextRun,
  VerticalAlign,
  WidthType,
} from "docx";

// constants
export const exportSettings = {
  TABLE_WIDTH: 9500,
  TF_STATEMENT_WIDTH: 5800,
  TF_CHOICE_WIDTH: 1150,
  TF_STATEMENT_TAB: 2795,
  LEFT_MARGIN: 455,
  RIGHT_MARGIN: 160,
  BORDER_SPACE: 200,
  TEXT_SIZE: 20,
  CIRCLE_SIZE: 36,
};

// constructors for convenience and abstraction
export const newLine = (): Paragraph => {
  return new Paragraph({});
};

export const trailingLines: Paragraph[] = [newLine(), newLine()];

export const answerLine = (): Paragraph => {
  return new Paragraph({
    children: [
      new TextRun(
        "\t________________________________________________________________________________________"
      ),
    ],
    style: "question-text",
    tabStops: [
      { type: TabStopType.LEFT, position: exportSettings.LEFT_MARGIN },
    ],
  });
};

export const circleInCell = (): TableCell => {
  return new TableCell({
    children: [
      new Paragraph({
        text: " ◯  ",
        style: "circle",
      }),
    ],
    borders: noTableBorder.borders,
  });
};

export const mcChoiceLabelInCell = (letter: string): TableCell => {
  return new TableCell({
    children: [newLine(), new Paragraph({ text: `    ${letter}` })],
    borders: noTableBorder.borders,
  });
};

export const tfChoiceLabelInCell = (letter: string): TableCell => {
  return new TableCell({
    children: [new Paragraph({ text: letter, style: "bold" })],
    borders: noTableBorder.borders,
    width: {
      size: exportSettings.TF_CHOICE_WIDTH,
      type: WidthType.DXA,
    },
  });
};

let passageNewLines: Paragraph[] = new Array(24).fill(newLine());

export const generalInstrcutions = (levelOfDiff: string): Paragraph[] => {
  return [
    customParagraph({
      text: "GENERAL INSTRUCTIONS",
      bold: true,
    }),
    newLine(),
    customParagraph({
      text: "(1)  Refer to the General Instructions on Page 1 of the Reading Passages booklet for Part A.",
    }),
    newLine(),
    newLine(),
    customParagraph({
      text: `INSTRUCTIONS FOR PART ${levelOfDiff}S`,
      bold: true,
    }),
    newLine(),
    customParagraph({
      text: `(1)  The Question-Answer Book for Part ${levelOfDiff} is inserted in this Reading Passages booklet.`,
    }),
    newLine(),
    customParagraph({
      text: `(2)  Candidates who choose Part ${levelOfDiff} should attempt all questions in this part. Each question carries ONE mark unless otherwise stated.`,
    }),
    newLine(),
    customParagraph({
      text: `(3)  Hand in only ONE Question-Answer Book for Part B, either B1 or B2, and fasten it with the Question-Answer Book for Part A using the green tag provided.`,
    }),
    ...passageNewLines,
  ];
};

export const readingPassagesRow = (setNumber: string): TableRow => {
  return new TableRow({
    children: [
      new TableCell({
        borders: noTableBorder.borders,
        width: {
          size: "20%",
        },
        children: [],
      }),
      new TableCell({
        borders: noTableBorder.borders,
        width: {
          size: "60%",
        },
        children: [
          customParagraph({
            alignment: AlignmentType.CENTER,
            text: "Reading Passages",
            after: 200,
            size: 34,
            bold: true,
          }),
          customParagraph({
            alignment: AlignmentType.CENTER,
            text: "8:30 am – 10:00 am (1½ hours)",
            after: 100,
          }),
          customParagraph({
            alignment: AlignmentType.CENTER,
            text: "(for both Parts A and B)",
          }),
        ],
      }),
      new TableCell({
        borders: noTableBorder.borders,
        children: [setTable(setNumber, 0, 0)],
      }),
    ],
  });
};

export const lineNumbers = (article: string): Paragraph[] => {
  const trimmed: string[] = article.split(/\n+/).map((para) => para.trim());
  let result: Paragraph[] = [];
  let lineNum = 1;

  // Loop through each paragraph and count the number of lines.
  trimmed
    .filter((str) => str)
    .forEach((text, index) => {
      const numLines = Math.ceil(text.length / 115);

      // Insert numbered or empty paragraphs based on line count and interval.
      for (let i = 1; i <= numLines; i++) {
        if (lineNum === 1 || lineNum % 5 === 0) {
          const lineNumberText = lineNum.toString();
          result.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: lineNumberText,
                }),
              ],
            })
          );
        } else {
          result.push(new Paragraph({}));
        }

        // Add current part of the original paragraph to our new array.
        lineNum++;
      }
      result.push(new Paragraph({}));
    });

  return result;
};

export const notTakenAwayField = (): TableCell => {
  return new TableCell({
    margins: {
      left: 30,
      right: 30,
      top: 80,
      bottom: 80,
    },
    children: [
      customParagraph({
        alignment: AlignmentType.CENTER,
        text: "Not to be taken away before the end of the examination session",
      }),
    ],
  });
};
type paragraphOptions = {
  // marginLeft?: number,
  // marginRight?: number,
  // marginTop?: number,
  // marginBottom?: number,
  alignment?: AlignmentType;
  text?: string;
  size?: number;
  font?: string;
  before?: number;
  after?: number;
  line?: number;
  bold?: boolean;
  style?: string;
};
export const customParagraph = (options: paragraphOptions = {}): Paragraph => {
  const {
    alignment = AlignmentType.LEFT,
    text = "",
    size = 20,
    font = "Arial",
    before = 0,
    after = 0,

    bold = false,
    style = "question-text",
  } = options;
  return new Paragraph({
    alignment: alignment,
    style: style,
    spacing: {
      before: before,
      after: after,
      line: options.line,
    },
    children: [
      new TextRun({
        text: text,
        size: size,
        font: font,
        bold: bold,
      }),
    ],
  });
};

export const cmToDocDXA = (cm: number): number => {
  return (cm * 1133.9) / 2;
};

export const leftTopContent = (
  levelOfDiff: string,
  levelOfDiffStatement: string
): Table => {
  return new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            borders: noTableBorder.borders,
            verticalAlign: VerticalAlign.BOTTOM,

            children: [
              customParagraph({
                text: "READily-DSE",
                size: 22,
                bold: true,
              }),
              customParagraph({
                text: "ENG LANG",
                size: 22,
                bold: true,
              }),
              customParagraph({
                before: 100,
                text: "PAPER 1",
              }),
              customParagraph({
                before: 100,
                text: `PART ${levelOfDiff}`,
              }),
            ],
          }),
          new TableCell({
            borders: noTableBorder.borders,
            width: {
              size: cmToDocDXA(13),
              type: WidthType.DXA,
            },
            children: [new Paragraph(" ")],
          }),
          new TableCell({
            borders: noTableBorder.borders,
            verticalAlign: VerticalAlign.TOP,
            children: [
              new Table({
                borders: noTableBorder.borders,
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        borders: {
                          top: {
                            size: 15,
                            style: BorderStyle.OUTSET,
                          },
                          bottom: {
                            size: 15,
                            style: BorderStyle.OUTSET,
                          },
                          right: {
                            size: 15,
                            style: BorderStyle.OUTSET,
                          },
                          left: {
                            size: 15,
                            style: BorderStyle.OUTSET,
                          },
                        },
                        margins: {
                          top: 100,
                          left: 100,
                          bottom: 100,
                          right: 100,
                        },
                        children: [
                          sectionTitle(
                            60,
                            levelOfDiff === "A" ? 11 : 14,
                            levelOfDiff,
                            levelOfDiffStatement
                          ),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
            ],
            // [sectionTitle(60, 14)],
          }),
        ],
      }),
    ],
  });
};
let testbookInformation: string[] = [
  "HKDSE (READily MOCK)",
  "ENGLISH LANGUAGE",
  "Part 1 Part B2 ",
  "QUESTION-ANSWER BOOK",
];

export const headerDescription = (levelOfDiff: string): TableCell => {
  return new TableCell({
    margins: {
      right: 1400,
    },
    verticalAlign: VerticalAlign.BOTTOM,
    children: testbookInformation.map((value, index) =>
      customParagraph({
        before: 0,
        after: 0,
        text: index != 2 ? value : `Part 1 Part ${levelOfDiff} `,
        bold: true,
      })
    ),
  });
};
export const numberToRomanNumeral = (number: number): string => {
  const romanNumerals = [
    { value: 1000, numeral: "m" },
    { value: 900, numeral: "cm" },
    { value: 500, numeral: "d" },
    { value: 400, numeral: "cd" },
    { value: 100, numeral: "c" },
    { value: 90, numeral: "xc" },
    { value: 50, numeral: "l" },
    { value: 40, numeral: "xl" },
    { value: 10, numeral: "x" },
    { value: 9, numeral: "ix" },
    { value: 5, numeral: "v" },
    { value: 4, numeral: "iv" },
    { value: 1, numeral: "i" },
  ];
  number++;
  let result = "";
  for (const roman of romanNumerals) {
    while (number >= roman.value) {
      result += roman.numeral;
      number -= roman.value;
    }
  }

  return result;
};

// document settings
export const noTableBorder = {
  borders: {
    top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
    bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
    left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
    right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
  },
};

export const pageBorderSetting = {
  style: BorderStyle.SINGLE,
  size: 3,
  color: "auto",
  space: exportSettings.BORDER_SPACE,
};

export const questionTextStyle = {
  id: "question-text",
  name: "question-text",
  basedOn: "Normal",
  // next: "Normal",
  quickFormat: true,
  run: {
    size: exportSettings.TEXT_SIZE,
  },
};

export const circleStyle = {
  id: "circle",
  name: "circle",
  basedOn: "Normal",
  next: "Normal",
  quickFormat: true,
  run: {
    size: exportSettings.CIRCLE_SIZE,
  },
};

export const boldStyle = {
  id: "bold",
  name: "bold",
  basedOn: "Normal",
  next: "Normal",
  quickFormat: true,
  run: {
    size: exportSettings.TEXT_SIZE,
    bold: true,
  },
};
export const setTable = (
  setNumber: string,
  marginLeft?: number,
  marginRight?: number
): Table => {
  return new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            borders: noTableBorder.borders,
            children: [new Paragraph("")],
            margins: {
              right: marginRight ?? 900,
              left: marginLeft ?? 900,
            },
          }),
          new TableCell({
            borders: {
              top: {
                size: 18,
                style: BorderStyle.OUTSET,
              },
              bottom: {
                size: 18,
                style: BorderStyle.OUTSET,
              },
              right: {
                size: 18,
                style: BorderStyle.OUTSET,
              },
              left: {
                size: 18,
                style: BorderStyle.OUTSET,
              },
            },
            verticalAlign: VerticalAlign.CENTER,
            children: [
              customParagraph({
                text: `Set ${setNumber}`,
                bold: true,
                size: 30,
              }),
            ],
            margins: {
              top: 200,
              bottom: 200,
              right: 400,
              left: 400,
            },
          }),
        ],
      }),
    ],
  });
};

export const barcodeTable = (): Table => {
  return new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            margins: {
              bottom: 900,
              right: 600,
              top: 100,
              left: 100,
            },
            children: [
              customParagraph({
                text: "Please stick the barcode label here.",
                size: 16,
              }),
            ],
          }),
        ],
      }),
    ],
  });
};

export const sectionTitle = (
  hSize: number,
  subSize: number,
  levelOfDiff: string,
  levelOfDiffStatement: string
): Table => {
  return new Table({
    borders: noTableBorder.borders,
    rows: [
      new TableRow({
        children: [
          new TableCell({
            borders: noTableBorder.borders,
            children: [
              customParagraph({
                style: HeadingLevel.TITLE,
                alignment: AlignmentType.CENTER,
                text: levelOfDiff != "" ? levelOfDiff : "B2",
                size: hSize,
                bold: true,
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            margins: {
              right: 30,
              left: 30,
            },
            borders: noTableBorder.borders,
            children: [
              customParagraph({
                alignment: AlignmentType.CENTER,
                before: 0,
                after: 30,
                text: `${levelOfDiffStatement} SECTION`,
                bold: true,
                size: subSize,
              }),
            ],
          }),
        ],
      }),
    ],
  });
};

let emptySquer: TableCell[] = new Array(9).fill(
  new TableCell({
    children: [
      new Paragraph({
        text: " ",
        spacing: {
          after: 100,
          before: 100,
        },
      }),
    ],
    margins: {
      right: 200,
      left: 150,
    },
  })
);

export const candidateNumber = (): Table => {
  return new Table({
    // Candidate Number table
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: "Candidate Number",
                style: "question-text",
                spacing: {
                  after: 100,
                  before: 100,
                },
              }),
            ],
            margins: {
              left: 80,
              right: 80,
            },
          }),
          ...emptySquer,
        ],
      }),
    ],
  });
};
