import Button from "../components/Button";
import { PopupWindowWithHeader } from "../components/PopupWindow";

interface DiscardChangesPopupProps {
  onClose: () => void;
  onDiscard: () => void;
}

const DiscardChangesPopup = ({
  onClose,
  onDiscard,
}: DiscardChangesPopupProps) => {
  return (
    <PopupWindowWithHeader
      title="Discard Changes"
      onClose={onClose}
      className="inset-y-[10%] w-max h-[452px]"
    >
      <main className="flex flex-col items-center py-10">
        <img className="h-[160px]" src="/deleteWarning.svg" alt="" />
        <h1 className="text-[28px] font-semibold">Abort and discard changes</h1>
        <p className="text-sm">
          This will reset the question to the original state
        </p>
      </main>
      <footer className="flex justify-center gap-4 px-[35px] pb-[32px]">
        <Button
          onClick={onClose}
          text="Continue Editing"
          className="border border-grey px-[50px] py-[10px] hover:border-transparent hover:bg-grey4 shadow-sm"
        ></Button>
        <Button
          onClick={() => {
            onDiscard();
            onClose();
          }}
          text="Discard"
          className="text-white bg-CTA/Warning px-[85px] py-[10px] hover:bg-[#ff9223]"
        ></Button>
      </footer>
    </PopupWindowWithHeader>
  );
};

export default DiscardChangesPopup;
