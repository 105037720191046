import cx from "classnames";

interface RadioButtonProps {
  isSelected: boolean;
  onSelect: () => void;
  disabled?: boolean;
}

function RadioButton({ isSelected, disabled, onSelect }: RadioButtonProps) {
  console.log("testing")
  return (
    <div
      className={cx(
        "h-[1.5em] w-[1.5em] cursor-pointer rounded-full bg-transparent m-0 border-2 border-purple2 p-1",
        {
          "!border-blue1 bg-blue1 ring-[20px] ring-inset ring-blue1 ring-offset-4":
            isSelected,
        }
      )}
      onClick={onSelect}
    ></div>
  );
}

export default RadioButton;
