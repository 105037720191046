import { useState } from "react";

import { ChoicesWithId } from "./QuestionTypes";
import { RadioButton } from "../Button";
import { ReactComponent as IconMCOrder } from "../../icons/IconMCOrder.svg";
import { ReactComponent as IconTrashCan } from "../../icons/IconTrashCan.svg";
import { ReactComponent as IconCross } from "../../icons/IconCross.svg";
import { ReactSortable } from "react-sortablejs";
import cx from "classnames";

interface MCQAnswerProps {
  choices: ChoicesWithId;
  setChoices: (value: ChoicesWithId) => void;
  answerId: number | string;
  setAnswerId: (value: number | string) => void;
  isEditing: boolean;
}

function MCQContent({
  choices,
  setChoices,
  answerId,
  setAnswerId,
  isEditing,
}: MCQAnswerProps) {
  const handleChoiceChange = (id: number | string, newValue: string) => {
    const newArr = [...choices];
    // find choice by id
    const idxToChange = newArr.findIndex((item) => item.id === id);
    if (idxToChange === -1) return;

    newArr[idxToChange].choice = newValue;
    setChoices(newArr);
  };

  const handleDelete = (id: number | string) => {
    // won't delete if too few choices
    if (choices.length <= 2) return;

    const newArr = choices.filter((item) => item.id !== id);
    setChoices(newArr);
  };

  const [deleteOptionWarning, setDeleteOptionWarning] = useState(false);

  return (
    <ReactSortable
      handle=".handle"
      animation={150}
      list={choices}
      setList={setChoices}
      className="flex flex-col gap-4 px-[20px]"
    >
      {choices.map(({ id, choice }, index) => {
        if (isEditing) {
          return (
            <div key={index + choices.length}>
              {deleteOptionWarning && (
                <div className="justify-center items-center flex fixed inset-0 bg-black bg-opacity-20 z-50 outline-none focus:outline-none">
                  <div className="rounded-20xl shadow-lg relative w-[33.75rem] h-[16.375rem] bg-white outline-none focus:outline-none">
                    <IconCross
                      className="cursor-pointer w-[24px] h-[24px] text-grey hover:text-black absolute right-5 top-5"
                      onClick={() => {
                        setDeleteOptionWarning(false);
                      }}
                    />
                    <div className="flex flex-row rounded-t p-5 mb-2">
                      <img
                        src="/CorrectAnswer.svg"
                        alt=""
                        className="mt-12 mr-9"
                      />
                      <h1 className="mt-14 text-[24px] font-bold mb-3.5">
                        This answer is marked as correct, delete it anyways?
                      </h1>
                    </div>
                    <div className="flex flex-row">
                      <button
                        onClick={() => {
                          setDeleteOptionWarning(false);
                        }}
                        className="h-11 w-[14.375rem] border border-innerBorder text-black rounded-11xl font-medium mr-4 ml-10 hover:bg-grey4"
                      >
                        No keep this
                      </button>
                      <button
                        onClick={() => {
                          setDeleteOptionWarning(false);
                          handleDelete(id);
                        }}
                        className="h-11 w-[14.375rem] bg-CTA/Error hover:bg-[#ff5728] text-white rounded-11xl font-semibold"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div className="flex justify-evenly items-center gap-2">
                <IconMCOrder className="handle cursor-ns-resize" />
                <div className="h-[22px] w-[22px] rounded-full bg-purple-50 text-[8.5px] grid place-content-center font-semibold">
                  {String.fromCharCode(index + 65)}
                </div>
                <input
                  className="px-[12px] py-[8px] flex-1 border-2 border-Gray focus:outline-none focus:border-sky-400 rounded-md text-[14px]"
                  placeholder={`Option ${index + 1}`}
                  value={choice}
                  onChange={(e) => handleChoiceChange(id, e.target.value)}
                />

                <RadioButton
                  isSelected={answerId === id}
                  onSelect={() => setAnswerId(id)}
                />
                <IconTrashCan
                  className={cx(
                    "cursor-pointer w-[1.2em] h-[1.2em] text-[#FF6628] hover:text-[#DE6628]",
                    { "!text-grey cursor-not-allowed": choices.length <= 2 }
                  )}
                  title={
                    choices.length <= 2
                      ? "At least 2 choices are required"
                      : "Delete"
                  }
                  onClick={() => {
                    if (id === answerId) {
                      setDeleteOptionWarning(true);
                    } else {
                      handleDelete(id);
                    }
                  }}
                />
              </div>
            </div>
          );
        } else {
          return (
            <div key={id} className="flex items-center px-[21px]">
              <div
                className={cx(
                  "h-[22px] w-[22px] text-[14px] font-normal self-start",
                  {
                    "text-CTA/Success font-semibold": id === answerId,
                  }
                )}
              >
                {String.fromCharCode(index + 65)}.
              </div>
              {id === answerId}
              <div
                className={cx("text-[14px] font-normal", {
                  "text-CTA/Success font-semibold": id === answerId,
                })}
              >
                {choice}
              </div>
            </div>
          );
        }
      })}
    </ReactSortable>
  );
}

export default MCQContent;
