/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type Exercise = {
  __typename: "Exercise",
  id: string,
  userId: string,
  exerciseName: string,
  questions:  Array<Question >,
  article: string,
  articleName?: string | null,
  createdDate: string,
  lastAccessedDate: string,
  version: number,
  published?: string | null,
};

export type Question = {
  __typename: "Question",
  questionID: string,
  questionType: QuestionType,
  questionState?: QuestionState | null,
  questionAbility?: QuestionAbility | null,
  questionStatement: string,
  mcQuestionContent?: McQuestion | null,
  tfQuestionContent?: TfQuestion | null,
  sqQuestionContent?: SqQuestion | null,
  lqQuestionContent?: LqQuestion | null,
  scQuestionContent?: ScQuestion | null,
  ftbQuestionContent?: FtbQuestion | null,
  matchQuestionContent?: MatchQuestion | null,
  tableQuestionContent?: TableQuestion | null,
  weirdQuestionContent?: WeirdQuestion | null,
  articleContext: string,
  highlightStart: string,
  highlightEnd: string,
  startGenerationTime?: string | null,
};

export enum QuestionType {
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  TRUE_FALSE = "TRUE_FALSE",
  SHORT_QUESTION = "SHORT_QUESTION",
  LONG_QUESTION = "LONG_QUESTION",
  SUMMARY_CLOZE = "SUMMARY_CLOZE",
  FILL_THE_BLANK = "FILL_THE_BLANK",
  MATCHING = "MATCHING",
  TABLE = "TABLE",
}


export enum QuestionState {
  GENERATING = "GENERATING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}


export enum QuestionAbility {
  THEMATIC = "THEMATIC",
  THEMATIC_PURPOSE = "THEMATIC_PURPOSE",
  THEMATIC_TEXT_TAKEN_FROM = "THEMATIC_TEXT_TAKEN_FROM",
  MAIN_IDEA = "MAIN_IDEA",
  MAIN_IDEA_ONE_WORD_PHRASE = "MAIN_IDEA_ONE_WORD_PHRASE",
  FACTUAL = "FACTUAL",
  FACTUAL_INCORRECT_STATEMENT = "FACTUAL_INCORRECT_STATEMENT",
  FACTUAL_RECOMMENDATION = "FACTUAL_RECOMMENDATION",
  FACTUAL_SPECIFIC_INFORMATION = "FACTUAL_SPECIFIC_INFORMATION",
  FACTUAL_WRITER_THOUGHTS = "FACTUAL_WRITER_THOUGHTS",
  FACTUAL_TOPIC = "FACTUAL_TOPIC",
  FACTUAL_NUMBER = "FACTUAL_NUMBER",
  FACTUAL_EVIDENCE = "FACTUAL_EVIDENCE",
  FACTUAL_TERM_IDENFITICATION = "FACTUAL_TERM_IDENFITICATION",
  VOCABULARY = "VOCABULARY",
  VOCABULARY_MEAN = "VOCABULARY_MEAN",
  VOCABULARY_PURPOSE = "VOCABULARY_PURPOSE",
  VOCABULARY_SYNONYM = "VOCABULARY_SYNONYM",
  VOCABULARY_PHRASE_SYNONYM = "VOCABULARY_PHRASE_SYNONYM",
  VOCABULARY_SUPPORT_STATEMENT = "VOCABULARY_SUPPORT_STATEMENT",
  VOCABULARY_PHRASE_MEANING = "VOCABULARY_PHRASE_MEANING",
  REFERENCE = "REFERENCE",
  REFERENCE_MC = "REFERENCE_MC",
  REFERENCE_SQ = "REFERENCE_SQ",
  REFERENCE_FILLING = "REFERENCE_FILLING",
  INFERENCE = "INFERENCE",
  INFERENCE_SQ = "INFERENCE_SQ",
  INFERENCE_TF = "INFERENCE_TF",
  INFERENCE_PHRASE_SUGGESTION = "INFERENCE_PHRASE_SUGGESTION",
  INFERENCE_ACCORDING_TO = "INFERENCE_ACCORDING_TO",
  INFERENCE_PHRASE_RELATIONS = "INFERENCE_PHRASE_RELATIONS",
  INFERENCE_IMPLY = "INFERENCE_IMPLY",
  INFERENCE_EVIDENCE = "INFERENCE_EVIDENCE",
  INFERENCE_PHRASE_MEANING = "INFERENCE_PHRASE_MEANING",
  HIGHER_ORDER = "HIGHER_ORDER",
  HIGHER_ORDER_OPINION_WITH_EVIDENCE = "HIGHER_ORDER_OPINION_WITH_EVIDENCE",
  HIGHER_ORDER_INFER_THOUGHT = "HIGHER_ORDER_INFER_THOUGHT",
  HIGHER_ORDER_COMPARISONS = "HIGHER_ORDER_COMPARISONS",
  FIGURATIVE_LANG = "FIGURATIVE_LANG",
  FIGURATIVE_LANG_PHRASE_MEANING = "FIGURATIVE_LANG_PHRASE_MEANING",
  FIGURATIVE_LANG_METAPHOR = "FIGURATIVE_LANG_METAPHOR",
  FIGURATIVE_LANG_INFER_LINES = "FIGURATIVE_LANG_INFER_LINES",
  VIEW_ATTITUDE = "VIEW_ATTITUDE",
  VIEW_ATTITUDE_TONE = "VIEW_ATTITUDE_TONE",
  VIEW_ATTITUDE_MEAN = "VIEW_ATTITUDE_MEAN",
  VIEW_ATTITUDE_PERSON_INVOLVED = "VIEW_ATTITUDE_PERSON_INVOLVED",
  VIEW_ATTITUDE_VIEW = "VIEW_ATTITUDE_VIEW",
  VIEW_ATTITUDE_ATTITUDE = "VIEW_ATTITUDE_ATTITUDE",
  VIEW_ATTITUDE_IMPLY = "VIEW_ATTITUDE_IMPLY",
  VIEW_ATTITUDE_LETTER_ONCE = "VIEW_ATTITUDE_LETTER_ONCE",
  RELATIONSHIP = "RELATIONSHIP",
  RELATIONSHIP_PROBLEM_AND_SOLUTION = "RELATIONSHIP_PROBLEM_AND_SOLUTION",
  RELATIONSHIP_CAUSES = "RELATIONSHIP_CAUSES",
  RELATIONSHIP_REASONING = "RELATIONSHIP_REASONING",
}


export type McQuestion = {
  __typename: "McQuestion",
  choices: Array< string | null >,
  answer: number,
};

export type TfQuestion = {
  __typename: "TfQuestion",
  tfQuestionAnswerList:  Array<TfQuestionAnswerList >,
};

export type TfQuestionAnswerList = {
  __typename: "TfQuestionAnswerList",
  tfQuestion: string,
  tfAnswer: TfAnswer,
};

export enum TfAnswer {
  TRUE = "TRUE",
  FALSE = "FALSE",
  NOT_GIVEN = "NOT_GIVEN",
}


export type SqQuestion = {
  __typename: "SqQuestion",
  answer: string,
};

export type LqQuestion = {
  __typename: "LqQuestion",
  answer: string,
};

export type ScQuestion = {
  __typename: "ScQuestion",
  summary: string,
  numBlanks: number,
  answer: Array< string >,
};

export type FtbQuestion = {
  __typename: "FtbQuestion",
  answerStatement: string,
  answer: string,
};

export type MatchQuestion = {
  __typename: "MatchQuestion",
  questionItemType: string,
  questionItem: Array< string >,
  numItems: number,
  statement: Array< string >,
  answers: Array< number >,
};

export type TableQuestion = {
  __typename: "TableQuestion",
  questionTitle: Array< string >,
  numItems: number,
  answerStatement: Array< string >,
  answers: Array< string >,
};

export type WeirdQuestion = {
  __typename: "WeirdQuestion",
  weirdQuestionAnswerList:  Array<WeirdQuestionAnswerList >,
};

export type WeirdQuestionAnswerList = {
  __typename: "WeirdQuestionAnswerList",
  weirdQuestion: string,
  weirdAnswer: string,
};

export type QuestionInput = {
  questionID: string,
  questionType: QuestionType,
  questionState?: QuestionState | null,
  questionAbility?: QuestionAbility | null,
  questionStatement: string,
  mcQuestionContent?: McQuestionInput | null,
  tfQuestionContent?: TfQuestionInput | null,
  sqQuestionContent?: SqQuestionInput | null,
  lqQuestionContent?: LqQuestionInput | null,
  scQuestionContent?: ScQuestionInput | null,
  ftbQuestionContent?: FtbQuestionInput | null,
  matchQuestionContent?: MatchQuestionInput | null,
  tableQuestionContent?: TableQuestionInput | null,
  weirdQuestionContent?: WeirdQuestionInput | null,
  articleContext: string,
  highlightStart: string,
  highlightEnd: string,
  startGenerationTime?: string | null,
};

export type McQuestionInput = {
  choices: Array< string | null >,
  answer: number,
};

export type TfQuestionInput = {
  tfQuestionAnswerList: Array< TfQuestionAnswerListInput >,
};

export type TfQuestionAnswerListInput = {
  tfQuestion: string,
  tfAnswer: TfAnswer,
};

export type SqQuestionInput = {
  answer: string,
};

export type LqQuestionInput = {
  answer: string,
};

export type ScQuestionInput = {
  summary: string,
  numBlanks: number,
  answer: Array< string >,
};

export type FtbQuestionInput = {
  answerStatement: string,
  answer: string,
};

export type MatchQuestionInput = {
  questionItemType: string,
  questionItem: Array< string >,
  numItems: number,
  statement: Array< string >,
  answers: Array< number >,
};

export type TableQuestionInput = {
  questionTitle: Array< string >,
  numItems: number,
  answerStatement: Array< string >,
  answers: Array< string >,
};

export type WeirdQuestionInput = {
  weirdQuestionAnswerList: Array< WeirdQuestionAnswerListInput >,
};

export type WeirdQuestionAnswerListInput = {
  weirdQuestion: string,
  weirdAnswer: string,
};

export type BatchQuestionsInput = {
  questionID: string,
  questionType: QuestionType,
  questionAbility?: QuestionAbility | null,
  questionState?: QuestionState | null,
  questionStatement: string,
  articleContext: string,
  mcQuestionContent?: McQuestionInput | null,
  tfQuestionContent?: TfQuestionInput | null,
  sqQuestionContent?: SqQuestionInput | null,
  lqQuestionContent?: LqQuestionInput | null,
  scQuestionContent?: ScQuestionInput | null,
  ftbQuestionContent?: FtbQuestionInput | null,
  matchQuestionContent?: MatchQuestionInput | null,
  tableQuestionContent?: TableQuestionInput | null,
  weirdQuestionContent?: WeirdQuestionInput | null,
  highlightStart: string,
  highlightEnd: string,
  startGenerationTime?: string | null,
};

export type Exercises = {
  __typename: "Exercises",
  exercises:  Array<Exercise >,
  nextToken?: string | null,
};

export type TrialUser = {
  __typename: "TrialUser",
  userId: string,
  isIntern: boolean,
  email: string,
  name: string,
};

export type TrialUsers = {
  __typename: "TrialUsers",
  trialUsers:  Array<TrialUser >,
  nextToken?: string | null,
};

export type SwapQuestionMutationVariables = {
  exerciseID: string,
  questionIdx1: number,
  questionIdx2: number,
};

export type SwapQuestionMutation = {
  swapQuestion:  {
    __typename: "Exercise",
    id: string,
    userId: string,
    exerciseName: string,
    questions:  Array< {
      __typename: "Question",
      questionID: string,
      questionType: QuestionType,
      questionState?: QuestionState | null,
      questionAbility?: QuestionAbility | null,
      questionStatement: string,
      mcQuestionContent?:  {
        __typename: "McQuestion",
        choices: Array< string | null >,
        answer: number,
      } | null,
      tfQuestionContent?:  {
        __typename: "TfQuestion",
        tfQuestionAnswerList:  Array< {
          __typename: "TfQuestionAnswerList",
          tfQuestion: string,
          tfAnswer: TfAnswer,
        } >,
      } | null,
      sqQuestionContent?:  {
        __typename: "SqQuestion",
        answer: string,
      } | null,
      lqQuestionContent?:  {
        __typename: "LqQuestion",
        answer: string,
      } | null,
      scQuestionContent?:  {
        __typename: "ScQuestion",
        summary: string,
        numBlanks: number,
        answer: Array< string >,
      } | null,
      ftbQuestionContent?:  {
        __typename: "FtbQuestion",
        answerStatement: string,
        answer: string,
      } | null,
      matchQuestionContent?:  {
        __typename: "MatchQuestion",
        questionItemType: string,
        questionItem: Array< string >,
        numItems: number,
        statement: Array< string >,
        answers: Array< number >,
      } | null,
      tableQuestionContent?:  {
        __typename: "TableQuestion",
        questionTitle: Array< string >,
        numItems: number,
        answerStatement: Array< string >,
        answers: Array< string >,
      } | null,
      weirdQuestionContent?:  {
        __typename: "WeirdQuestion",
        weirdQuestionAnswerList:  Array< {
          __typename: "WeirdQuestionAnswerList",
          weirdQuestion: string,
          weirdAnswer: string,
        } >,
      } | null,
      articleContext: string,
      highlightStart: string,
      highlightEnd: string,
      startGenerationTime?: string | null,
    } >,
    article: string,
    articleName?: string | null,
    createdDate: string,
    lastAccessedDate: string,
    version: number,
    published?: string | null,
  },
};

export type CreateExerciseMutationVariables = {
  exerciseName: string,
  article: string,
  articleName?: string | null,
  questions?: Array< QuestionInput > | null,
  published?: string | null,
};

export type CreateExerciseMutation = {
  createExercise:  {
    __typename: "Exercise",
    id: string,
    userId: string,
    exerciseName: string,
    questions:  Array< {
      __typename: "Question",
      questionID: string,
      questionType: QuestionType,
      questionState?: QuestionState | null,
      questionAbility?: QuestionAbility | null,
      questionStatement: string,
      mcQuestionContent?:  {
        __typename: "McQuestion",
        choices: Array< string | null >,
        answer: number,
      } | null,
      tfQuestionContent?:  {
        __typename: "TfQuestion",
        tfQuestionAnswerList:  Array< {
          __typename: "TfQuestionAnswerList",
          tfQuestion: string,
          tfAnswer: TfAnswer,
        } >,
      } | null,
      sqQuestionContent?:  {
        __typename: "SqQuestion",
        answer: string,
      } | null,
      lqQuestionContent?:  {
        __typename: "LqQuestion",
        answer: string,
      } | null,
      scQuestionContent?:  {
        __typename: "ScQuestion",
        summary: string,
        numBlanks: number,
        answer: Array< string >,
      } | null,
      ftbQuestionContent?:  {
        __typename: "FtbQuestion",
        answerStatement: string,
        answer: string,
      } | null,
      matchQuestionContent?:  {
        __typename: "MatchQuestion",
        questionItemType: string,
        questionItem: Array< string >,
        numItems: number,
        statement: Array< string >,
        answers: Array< number >,
      } | null,
      tableQuestionContent?:  {
        __typename: "TableQuestion",
        questionTitle: Array< string >,
        numItems: number,
        answerStatement: Array< string >,
        answers: Array< string >,
      } | null,
      weirdQuestionContent?:  {
        __typename: "WeirdQuestion",
        weirdQuestionAnswerList:  Array< {
          __typename: "WeirdQuestionAnswerList",
          weirdQuestion: string,
          weirdAnswer: string,
        } >,
      } | null,
      articleContext: string,
      highlightStart: string,
      highlightEnd: string,
      startGenerationTime?: string | null,
    } >,
    article: string,
    articleName?: string | null,
    createdDate: string,
    lastAccessedDate: string,
    version: number,
    published?: string | null,
  },
};

export type GenerateQuestionMutationVariables = {
  exerciseID: string,
  questionType: QuestionType,
  questionAbility?: QuestionAbility | null,
  articleContext: string,
  highlightStart: string,
  highlightEnd: string,
};

export type GenerateQuestionMutation = {
  generateQuestion: string,
};

export type GeneratePaperMutationVariables = {
  exerciseID: string,
  articleContext: string,
  highlightStart: string,
  highlightEnd: string,
  levelOfDifficulty: string,
};

export type GeneratePaperMutation = {
  generatePaper: string,
};

export type UpdateExerciseMutationVariables = {
  exerciseID: string,
  article?: string | null,
  questions?: Array< QuestionInput > | null,
  articleName?: string | null,
  exerciseName?: string | null,
};

export type UpdateExerciseMutation = {
  updateExercise:  {
    __typename: "Exercise",
    id: string,
    userId: string,
    exerciseName: string,
    questions:  Array< {
      __typename: "Question",
      questionID: string,
      questionType: QuestionType,
      questionState?: QuestionState | null,
      questionAbility?: QuestionAbility | null,
      questionStatement: string,
      mcQuestionContent?:  {
        __typename: "McQuestion",
        choices: Array< string | null >,
        answer: number,
      } | null,
      tfQuestionContent?:  {
        __typename: "TfQuestion",
        tfQuestionAnswerList:  Array< {
          __typename: "TfQuestionAnswerList",
          tfQuestion: string,
          tfAnswer: TfAnswer,
        } >,
      } | null,
      sqQuestionContent?:  {
        __typename: "SqQuestion",
        answer: string,
      } | null,
      lqQuestionContent?:  {
        __typename: "LqQuestion",
        answer: string,
      } | null,
      scQuestionContent?:  {
        __typename: "ScQuestion",
        summary: string,
        numBlanks: number,
        answer: Array< string >,
      } | null,
      ftbQuestionContent?:  {
        __typename: "FtbQuestion",
        answerStatement: string,
        answer: string,
      } | null,
      matchQuestionContent?:  {
        __typename: "MatchQuestion",
        questionItemType: string,
        questionItem: Array< string >,
        numItems: number,
        statement: Array< string >,
        answers: Array< number >,
      } | null,
      tableQuestionContent?:  {
        __typename: "TableQuestion",
        questionTitle: Array< string >,
        numItems: number,
        answerStatement: Array< string >,
        answers: Array< string >,
      } | null,
      weirdQuestionContent?:  {
        __typename: "WeirdQuestion",
        weirdQuestionAnswerList:  Array< {
          __typename: "WeirdQuestionAnswerList",
          weirdQuestion: string,
          weirdAnswer: string,
        } >,
      } | null,
      articleContext: string,
      highlightStart: string,
      highlightEnd: string,
      startGenerationTime?: string | null,
    } >,
    article: string,
    articleName?: string | null,
    createdDate: string,
    lastAccessedDate: string,
    version: number,
    published?: string | null,
  },
};

export type UpdateExerciseLastAccessMutationVariables = {
  exerciseID: string,
};

export type UpdateExerciseLastAccessMutation = {
  updateExerciseLastAccess:  {
    __typename: "Exercise",
    id: string,
    userId: string,
    exerciseName: string,
    questions:  Array< {
      __typename: "Question",
      questionID: string,
      questionType: QuestionType,
      questionState?: QuestionState | null,
      questionAbility?: QuestionAbility | null,
      questionStatement: string,
      mcQuestionContent?:  {
        __typename: "McQuestion",
        choices: Array< string | null >,
        answer: number,
      } | null,
      tfQuestionContent?:  {
        __typename: "TfQuestion",
        tfQuestionAnswerList:  Array< {
          __typename: "TfQuestionAnswerList",
          tfQuestion: string,
          tfAnswer: TfAnswer,
        } >,
      } | null,
      sqQuestionContent?:  {
        __typename: "SqQuestion",
        answer: string,
      } | null,
      lqQuestionContent?:  {
        __typename: "LqQuestion",
        answer: string,
      } | null,
      scQuestionContent?:  {
        __typename: "ScQuestion",
        summary: string,
        numBlanks: number,
        answer: Array< string >,
      } | null,
      ftbQuestionContent?:  {
        __typename: "FtbQuestion",
        answerStatement: string,
        answer: string,
      } | null,
      matchQuestionContent?:  {
        __typename: "MatchQuestion",
        questionItemType: string,
        questionItem: Array< string >,
        numItems: number,
        statement: Array< string >,
        answers: Array< number >,
      } | null,
      tableQuestionContent?:  {
        __typename: "TableQuestion",
        questionTitle: Array< string >,
        numItems: number,
        answerStatement: Array< string >,
        answers: Array< string >,
      } | null,
      weirdQuestionContent?:  {
        __typename: "WeirdQuestion",
        weirdQuestionAnswerList:  Array< {
          __typename: "WeirdQuestionAnswerList",
          weirdQuestion: string,
          weirdAnswer: string,
        } >,
      } | null,
      articleContext: string,
      highlightStart: string,
      highlightEnd: string,
      startGenerationTime?: string | null,
    } >,
    article: string,
    articleName?: string | null,
    createdDate: string,
    lastAccessedDate: string,
    version: number,
    published?: string | null,
  },
};

export type UpdateExerciseLambdaMutationVariables = {
  userID: string,
  exerciseID: string,
  fetchedQuestions?: Array< QuestionInput > | null,
  uploadQuestions?: Array< QuestionInput > | null,
  version: number,
};

export type UpdateExerciseLambdaMutation = {
  updateExerciseLambda:  {
    __typename: "Exercise",
    id: string,
    userId: string,
    exerciseName: string,
    questions:  Array< {
      __typename: "Question",
      questionID: string,
      questionType: QuestionType,
      questionState?: QuestionState | null,
      questionAbility?: QuestionAbility | null,
      questionStatement: string,
      mcQuestionContent?:  {
        __typename: "McQuestion",
        choices: Array< string | null >,
        answer: number,
      } | null,
      tfQuestionContent?:  {
        __typename: "TfQuestion",
        tfQuestionAnswerList:  Array< {
          __typename: "TfQuestionAnswerList",
          tfQuestion: string,
          tfAnswer: TfAnswer,
        } >,
      } | null,
      sqQuestionContent?:  {
        __typename: "SqQuestion",
        answer: string,
      } | null,
      lqQuestionContent?:  {
        __typename: "LqQuestion",
        answer: string,
      } | null,
      scQuestionContent?:  {
        __typename: "ScQuestion",
        summary: string,
        numBlanks: number,
        answer: Array< string >,
      } | null,
      ftbQuestionContent?:  {
        __typename: "FtbQuestion",
        answerStatement: string,
        answer: string,
      } | null,
      matchQuestionContent?:  {
        __typename: "MatchQuestion",
        questionItemType: string,
        questionItem: Array< string >,
        numItems: number,
        statement: Array< string >,
        answers: Array< number >,
      } | null,
      tableQuestionContent?:  {
        __typename: "TableQuestion",
        questionTitle: Array< string >,
        numItems: number,
        answerStatement: Array< string >,
        answers: Array< string >,
      } | null,
      weirdQuestionContent?:  {
        __typename: "WeirdQuestion",
        weirdQuestionAnswerList:  Array< {
          __typename: "WeirdQuestionAnswerList",
          weirdQuestion: string,
          weirdAnswer: string,
        } >,
      } | null,
      articleContext: string,
      highlightStart: string,
      highlightEnd: string,
      startGenerationTime?: string | null,
    } >,
    article: string,
    articleName?: string | null,
    createdDate: string,
    lastAccessedDate: string,
    version: number,
    published?: string | null,
  },
};

export type UpdateQuestionMutationVariables = {
  exerciseID: string,
  questionIdx: number,
  question: QuestionInput,
};

export type UpdateQuestionMutation = {
  updateQuestion:  {
    __typename: "Exercise",
    id: string,
    userId: string,
    exerciseName: string,
    questions:  Array< {
      __typename: "Question",
      questionID: string,
      questionType: QuestionType,
      questionState?: QuestionState | null,
      questionAbility?: QuestionAbility | null,
      questionStatement: string,
      mcQuestionContent?:  {
        __typename: "McQuestion",
        choices: Array< string | null >,
        answer: number,
      } | null,
      tfQuestionContent?:  {
        __typename: "TfQuestion",
        tfQuestionAnswerList:  Array< {
          __typename: "TfQuestionAnswerList",
          tfQuestion: string,
          tfAnswer: TfAnswer,
        } >,
      } | null,
      sqQuestionContent?:  {
        __typename: "SqQuestion",
        answer: string,
      } | null,
      lqQuestionContent?:  {
        __typename: "LqQuestion",
        answer: string,
      } | null,
      scQuestionContent?:  {
        __typename: "ScQuestion",
        summary: string,
        numBlanks: number,
        answer: Array< string >,
      } | null,
      ftbQuestionContent?:  {
        __typename: "FtbQuestion",
        answerStatement: string,
        answer: string,
      } | null,
      matchQuestionContent?:  {
        __typename: "MatchQuestion",
        questionItemType: string,
        questionItem: Array< string >,
        numItems: number,
        statement: Array< string >,
        answers: Array< number >,
      } | null,
      tableQuestionContent?:  {
        __typename: "TableQuestion",
        questionTitle: Array< string >,
        numItems: number,
        answerStatement: Array< string >,
        answers: Array< string >,
      } | null,
      weirdQuestionContent?:  {
        __typename: "WeirdQuestion",
        weirdQuestionAnswerList:  Array< {
          __typename: "WeirdQuestionAnswerList",
          weirdQuestion: string,
          weirdAnswer: string,
        } >,
      } | null,
      articleContext: string,
      highlightStart: string,
      highlightEnd: string,
      startGenerationTime?: string | null,
    } >,
    article: string,
    articleName?: string | null,
    createdDate: string,
    lastAccessedDate: string,
    version: number,
    published?: string | null,
  },
};

export type AddQuestionLambdaMutationVariables = {
  userID: string,
  exerciseID: string,
  questionID: string,
  questionType: QuestionType,
  questionAbility?: QuestionAbility | null,
  questionState?: QuestionState | null,
  questionStatement: string,
  articleContext: string,
  mcQuestionContent?: McQuestionInput | null,
  tfQuestionContent?: TfQuestionInput | null,
  sqQuestionContent?: SqQuestionInput | null,
  lqQuestionContent?: LqQuestionInput | null,
  scQuestionContent?: ScQuestionInput | null,
  ftbQuestionContent?: FtbQuestionInput | null,
  matchQuestionContent?: MatchQuestionInput | null,
  tableQuestionContent?: TableQuestionInput | null,
  weirdQuestionContent?: WeirdQuestionInput | null,
  highlightStart: string,
  highlightEnd: string,
  startGenerationTime?: string | null,
};

export type AddQuestionLambdaMutation = {
  addQuestionLambda:  {
    __typename: "Exercise",
    id: string,
    userId: string,
    exerciseName: string,
    questions:  Array< {
      __typename: "Question",
      questionID: string,
      questionType: QuestionType,
      questionState?: QuestionState | null,
      questionAbility?: QuestionAbility | null,
      questionStatement: string,
      mcQuestionContent?:  {
        __typename: "McQuestion",
        choices: Array< string | null >,
        answer: number,
      } | null,
      tfQuestionContent?:  {
        __typename: "TfQuestion",
        tfQuestionAnswerList:  Array< {
          __typename: "TfQuestionAnswerList",
          tfQuestion: string,
          tfAnswer: TfAnswer,
        } >,
      } | null,
      sqQuestionContent?:  {
        __typename: "SqQuestion",
        answer: string,
      } | null,
      lqQuestionContent?:  {
        __typename: "LqQuestion",
        answer: string,
      } | null,
      scQuestionContent?:  {
        __typename: "ScQuestion",
        summary: string,
        numBlanks: number,
        answer: Array< string >,
      } | null,
      ftbQuestionContent?:  {
        __typename: "FtbQuestion",
        answerStatement: string,
        answer: string,
      } | null,
      matchQuestionContent?:  {
        __typename: "MatchQuestion",
        questionItemType: string,
        questionItem: Array< string >,
        numItems: number,
        statement: Array< string >,
        answers: Array< number >,
      } | null,
      tableQuestionContent?:  {
        __typename: "TableQuestion",
        questionTitle: Array< string >,
        numItems: number,
        answerStatement: Array< string >,
        answers: Array< string >,
      } | null,
      weirdQuestionContent?:  {
        __typename: "WeirdQuestion",
        weirdQuestionAnswerList:  Array< {
          __typename: "WeirdQuestionAnswerList",
          weirdQuestion: string,
          weirdAnswer: string,
        } >,
      } | null,
      articleContext: string,
      highlightStart: string,
      highlightEnd: string,
      startGenerationTime?: string | null,
    } >,
    article: string,
    articleName?: string | null,
    createdDate: string,
    lastAccessedDate: string,
    version: number,
    published?: string | null,
  },
};

export type AddBatchQuestionsMutationVariables = {
  userID: string,
  exerciseID: string,
  questions: Array< BatchQuestionsInput >,
};

export type AddBatchQuestionsMutation = {
  addBatchQuestions:  {
    __typename: "Exercise",
    id: string,
    userId: string,
    exerciseName: string,
    questions:  Array< {
      __typename: "Question",
      questionID: string,
      questionType: QuestionType,
      questionState?: QuestionState | null,
      questionAbility?: QuestionAbility | null,
      questionStatement: string,
      mcQuestionContent?:  {
        __typename: "McQuestion",
        choices: Array< string | null >,
        answer: number,
      } | null,
      tfQuestionContent?:  {
        __typename: "TfQuestion",
        tfQuestionAnswerList:  Array< {
          __typename: "TfQuestionAnswerList",
          tfQuestion: string,
          tfAnswer: TfAnswer,
        } >,
      } | null,
      sqQuestionContent?:  {
        __typename: "SqQuestion",
        answer: string,
      } | null,
      lqQuestionContent?:  {
        __typename: "LqQuestion",
        answer: string,
      } | null,
      scQuestionContent?:  {
        __typename: "ScQuestion",
        summary: string,
        numBlanks: number,
        answer: Array< string >,
      } | null,
      ftbQuestionContent?:  {
        __typename: "FtbQuestion",
        answerStatement: string,
        answer: string,
      } | null,
      matchQuestionContent?:  {
        __typename: "MatchQuestion",
        questionItemType: string,
        questionItem: Array< string >,
        numItems: number,
        statement: Array< string >,
        answers: Array< number >,
      } | null,
      tableQuestionContent?:  {
        __typename: "TableQuestion",
        questionTitle: Array< string >,
        numItems: number,
        answerStatement: Array< string >,
        answers: Array< string >,
      } | null,
      weirdQuestionContent?:  {
        __typename: "WeirdQuestion",
        weirdQuestionAnswerList:  Array< {
          __typename: "WeirdQuestionAnswerList",
          weirdQuestion: string,
          weirdAnswer: string,
        } >,
      } | null,
      articleContext: string,
      highlightStart: string,
      highlightEnd: string,
      startGenerationTime?: string | null,
    } >,
    article: string,
    articleName?: string | null,
    createdDate: string,
    lastAccessedDate: string,
    version: number,
    published?: string | null,
  },
};

export type UpdateArticleMutationVariables = {
  exerciseID: string,
  article: string,
  articleName: string,
};

export type UpdateArticleMutation = {
  updateArticle:  {
    __typename: "Exercise",
    id: string,
    userId: string,
    exerciseName: string,
    questions:  Array< {
      __typename: "Question",
      questionID: string,
      questionType: QuestionType,
      questionState?: QuestionState | null,
      questionAbility?: QuestionAbility | null,
      questionStatement: string,
      mcQuestionContent?:  {
        __typename: "McQuestion",
        choices: Array< string | null >,
        answer: number,
      } | null,
      tfQuestionContent?:  {
        __typename: "TfQuestion",
        tfQuestionAnswerList:  Array< {
          __typename: "TfQuestionAnswerList",
          tfQuestion: string,
          tfAnswer: TfAnswer,
        } >,
      } | null,
      sqQuestionContent?:  {
        __typename: "SqQuestion",
        answer: string,
      } | null,
      lqQuestionContent?:  {
        __typename: "LqQuestion",
        answer: string,
      } | null,
      scQuestionContent?:  {
        __typename: "ScQuestion",
        summary: string,
        numBlanks: number,
        answer: Array< string >,
      } | null,
      ftbQuestionContent?:  {
        __typename: "FtbQuestion",
        answerStatement: string,
        answer: string,
      } | null,
      matchQuestionContent?:  {
        __typename: "MatchQuestion",
        questionItemType: string,
        questionItem: Array< string >,
        numItems: number,
        statement: Array< string >,
        answers: Array< number >,
      } | null,
      tableQuestionContent?:  {
        __typename: "TableQuestion",
        questionTitle: Array< string >,
        numItems: number,
        answerStatement: Array< string >,
        answers: Array< string >,
      } | null,
      weirdQuestionContent?:  {
        __typename: "WeirdQuestion",
        weirdQuestionAnswerList:  Array< {
          __typename: "WeirdQuestionAnswerList",
          weirdQuestion: string,
          weirdAnswer: string,
        } >,
      } | null,
      articleContext: string,
      highlightStart: string,
      highlightEnd: string,
      startGenerationTime?: string | null,
    } >,
    article: string,
    articleName?: string | null,
    createdDate: string,
    lastAccessedDate: string,
    version: number,
    published?: string | null,
  },
};

export type UpdateExerciseNameMutationVariables = {
  exerciseID: string,
  exerciseName: string,
};

export type UpdateExerciseNameMutation = {
  updateExerciseName:  {
    __typename: "Exercise",
    id: string,
    userId: string,
    exerciseName: string,
    questions:  Array< {
      __typename: "Question",
      questionID: string,
      questionType: QuestionType,
      questionState?: QuestionState | null,
      questionAbility?: QuestionAbility | null,
      questionStatement: string,
      mcQuestionContent?:  {
        __typename: "McQuestion",
        choices: Array< string | null >,
        answer: number,
      } | null,
      tfQuestionContent?:  {
        __typename: "TfQuestion",
        tfQuestionAnswerList:  Array< {
          __typename: "TfQuestionAnswerList",
          tfQuestion: string,
          tfAnswer: TfAnswer,
        } >,
      } | null,
      sqQuestionContent?:  {
        __typename: "SqQuestion",
        answer: string,
      } | null,
      lqQuestionContent?:  {
        __typename: "LqQuestion",
        answer: string,
      } | null,
      scQuestionContent?:  {
        __typename: "ScQuestion",
        summary: string,
        numBlanks: number,
        answer: Array< string >,
      } | null,
      ftbQuestionContent?:  {
        __typename: "FtbQuestion",
        answerStatement: string,
        answer: string,
      } | null,
      matchQuestionContent?:  {
        __typename: "MatchQuestion",
        questionItemType: string,
        questionItem: Array< string >,
        numItems: number,
        statement: Array< string >,
        answers: Array< number >,
      } | null,
      tableQuestionContent?:  {
        __typename: "TableQuestion",
        questionTitle: Array< string >,
        numItems: number,
        answerStatement: Array< string >,
        answers: Array< string >,
      } | null,
      weirdQuestionContent?:  {
        __typename: "WeirdQuestion",
        weirdQuestionAnswerList:  Array< {
          __typename: "WeirdQuestionAnswerList",
          weirdQuestion: string,
          weirdAnswer: string,
        } >,
      } | null,
      articleContext: string,
      highlightStart: string,
      highlightEnd: string,
      startGenerationTime?: string | null,
    } >,
    article: string,
    articleName?: string | null,
    createdDate: string,
    lastAccessedDate: string,
    version: number,
    published?: string | null,
  },
};

export type DeleteQuestionMutationVariables = {
  exerciseID: string,
  questionIdx: number,
};

export type DeleteQuestionMutation = {
  deleteQuestion:  {
    __typename: "Exercise",
    id: string,
    userId: string,
    exerciseName: string,
    questions:  Array< {
      __typename: "Question",
      questionID: string,
      questionType: QuestionType,
      questionState?: QuestionState | null,
      questionAbility?: QuestionAbility | null,
      questionStatement: string,
      mcQuestionContent?:  {
        __typename: "McQuestion",
        choices: Array< string | null >,
        answer: number,
      } | null,
      tfQuestionContent?:  {
        __typename: "TfQuestion",
        tfQuestionAnswerList:  Array< {
          __typename: "TfQuestionAnswerList",
          tfQuestion: string,
          tfAnswer: TfAnswer,
        } >,
      } | null,
      sqQuestionContent?:  {
        __typename: "SqQuestion",
        answer: string,
      } | null,
      lqQuestionContent?:  {
        __typename: "LqQuestion",
        answer: string,
      } | null,
      scQuestionContent?:  {
        __typename: "ScQuestion",
        summary: string,
        numBlanks: number,
        answer: Array< string >,
      } | null,
      ftbQuestionContent?:  {
        __typename: "FtbQuestion",
        answerStatement: string,
        answer: string,
      } | null,
      matchQuestionContent?:  {
        __typename: "MatchQuestion",
        questionItemType: string,
        questionItem: Array< string >,
        numItems: number,
        statement: Array< string >,
        answers: Array< number >,
      } | null,
      tableQuestionContent?:  {
        __typename: "TableQuestion",
        questionTitle: Array< string >,
        numItems: number,
        answerStatement: Array< string >,
        answers: Array< string >,
      } | null,
      weirdQuestionContent?:  {
        __typename: "WeirdQuestion",
        weirdQuestionAnswerList:  Array< {
          __typename: "WeirdQuestionAnswerList",
          weirdQuestion: string,
          weirdAnswer: string,
        } >,
      } | null,
      articleContext: string,
      highlightStart: string,
      highlightEnd: string,
      startGenerationTime?: string | null,
    } >,
    article: string,
    articleName?: string | null,
    createdDate: string,
    lastAccessedDate: string,
    version: number,
    published?: string | null,
  },
};

export type DeleteExerciseMutationVariables = {
  exerciseID: string,
};

export type DeleteExerciseMutation = {
  deleteExercise?:  {
    __typename: "Exercises",
    exercises:  Array< {
      __typename: "Exercise",
      id: string,
      userId: string,
      exerciseName: string,
      questions:  Array< {
        __typename: "Question",
        questionID: string,
        questionType: QuestionType,
        questionState?: QuestionState | null,
        questionAbility?: QuestionAbility | null,
        questionStatement: string,
        mcQuestionContent?:  {
          __typename: "McQuestion",
          choices: Array< string | null >,
          answer: number,
        } | null,
        tfQuestionContent?:  {
          __typename: "TfQuestion",
          tfQuestionAnswerList:  Array< {
            __typename: "TfQuestionAnswerList",
            tfQuestion: string,
            tfAnswer: TfAnswer,
          } >,
        } | null,
        sqQuestionContent?:  {
          __typename: "SqQuestion",
          answer: string,
        } | null,
        lqQuestionContent?:  {
          __typename: "LqQuestion",
          answer: string,
        } | null,
        scQuestionContent?:  {
          __typename: "ScQuestion",
          summary: string,
          numBlanks: number,
          answer: Array< string >,
        } | null,
        ftbQuestionContent?:  {
          __typename: "FtbQuestion",
          answerStatement: string,
          answer: string,
        } | null,
        matchQuestionContent?:  {
          __typename: "MatchQuestion",
          questionItemType: string,
          questionItem: Array< string >,
          numItems: number,
          statement: Array< string >,
          answers: Array< number >,
        } | null,
        tableQuestionContent?:  {
          __typename: "TableQuestion",
          questionTitle: Array< string >,
          numItems: number,
          answerStatement: Array< string >,
          answers: Array< string >,
        } | null,
        weirdQuestionContent?:  {
          __typename: "WeirdQuestion",
          weirdQuestionAnswerList:  Array< {
            __typename: "WeirdQuestionAnswerList",
            weirdQuestion: string,
            weirdAnswer: string,
          } >,
        } | null,
        articleContext: string,
        highlightStart: string,
        highlightEnd: string,
        startGenerationTime?: string | null,
      } >,
      article: string,
      articleName?: string | null,
      createdDate: string,
      lastAccessedDate: string,
      version: number,
      published?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type UpsertPublishMutationVariables = {
  exerciseID: string,
  published?: string | null,
};

export type UpsertPublishMutation = {
  upsertPublish:  {
    __typename: "Exercise",
    id: string,
    userId: string,
    exerciseName: string,
    questions:  Array< {
      __typename: "Question",
      questionID: string,
      questionType: QuestionType,
      questionState?: QuestionState | null,
      questionAbility?: QuestionAbility | null,
      questionStatement: string,
      mcQuestionContent?:  {
        __typename: "McQuestion",
        choices: Array< string | null >,
        answer: number,
      } | null,
      tfQuestionContent?:  {
        __typename: "TfQuestion",
        tfQuestionAnswerList:  Array< {
          __typename: "TfQuestionAnswerList",
          tfQuestion: string,
          tfAnswer: TfAnswer,
        } >,
      } | null,
      sqQuestionContent?:  {
        __typename: "SqQuestion",
        answer: string,
      } | null,
      lqQuestionContent?:  {
        __typename: "LqQuestion",
        answer: string,
      } | null,
      scQuestionContent?:  {
        __typename: "ScQuestion",
        summary: string,
        numBlanks: number,
        answer: Array< string >,
      } | null,
      ftbQuestionContent?:  {
        __typename: "FtbQuestion",
        answerStatement: string,
        answer: string,
      } | null,
      matchQuestionContent?:  {
        __typename: "MatchQuestion",
        questionItemType: string,
        questionItem: Array< string >,
        numItems: number,
        statement: Array< string >,
        answers: Array< number >,
      } | null,
      tableQuestionContent?:  {
        __typename: "TableQuestion",
        questionTitle: Array< string >,
        numItems: number,
        answerStatement: Array< string >,
        answers: Array< string >,
      } | null,
      weirdQuestionContent?:  {
        __typename: "WeirdQuestion",
        weirdQuestionAnswerList:  Array< {
          __typename: "WeirdQuestionAnswerList",
          weirdQuestion: string,
          weirdAnswer: string,
        } >,
      } | null,
      articleContext: string,
      highlightStart: string,
      highlightEnd: string,
      startGenerationTime?: string | null,
    } >,
    article: string,
    articleName?: string | null,
    createdDate: string,
    lastAccessedDate: string,
    version: number,
    published?: string | null,
  },
};

export type AllExercisesByUserQueryVariables = {
  nextToken?: string | null,
  count?: number | null,
};

export type AllExercisesByUserQuery = {
  allExercisesByUser:  {
    __typename: "Exercises",
    exercises:  Array< {
      __typename: "Exercise",
      id: string,
      userId: string,
      exerciseName: string,
      questions:  Array< {
        __typename: "Question",
        questionID: string,
        questionType: QuestionType,
        questionState?: QuestionState | null,
        questionAbility?: QuestionAbility | null,
        questionStatement: string,
        mcQuestionContent?:  {
          __typename: "McQuestion",
          choices: Array< string | null >,
          answer: number,
        } | null,
        tfQuestionContent?:  {
          __typename: "TfQuestion",
          tfQuestionAnswerList:  Array< {
            __typename: "TfQuestionAnswerList",
            tfQuestion: string,
            tfAnswer: TfAnswer,
          } >,
        } | null,
        sqQuestionContent?:  {
          __typename: "SqQuestion",
          answer: string,
        } | null,
        lqQuestionContent?:  {
          __typename: "LqQuestion",
          answer: string,
        } | null,
        scQuestionContent?:  {
          __typename: "ScQuestion",
          summary: string,
          numBlanks: number,
          answer: Array< string >,
        } | null,
        ftbQuestionContent?:  {
          __typename: "FtbQuestion",
          answerStatement: string,
          answer: string,
        } | null,
        matchQuestionContent?:  {
          __typename: "MatchQuestion",
          questionItemType: string,
          questionItem: Array< string >,
          numItems: number,
          statement: Array< string >,
          answers: Array< number >,
        } | null,
        tableQuestionContent?:  {
          __typename: "TableQuestion",
          questionTitle: Array< string >,
          numItems: number,
          answerStatement: Array< string >,
          answers: Array< string >,
        } | null,
        weirdQuestionContent?:  {
          __typename: "WeirdQuestion",
          weirdQuestionAnswerList:  Array< {
            __typename: "WeirdQuestionAnswerList",
            weirdQuestion: string,
            weirdAnswer: string,
          } >,
        } | null,
        articleContext: string,
        highlightStart: string,
        highlightEnd: string,
        startGenerationTime?: string | null,
      } >,
      article: string,
      articleName?: string | null,
      createdDate: string,
      lastAccessedDate: string,
      version: number,
      published?: string | null,
    } >,
    nextToken?: string | null,
  },
};

export type GetParticularExerciseQueryVariables = {
  exerciseID?: string | null,
};

export type GetParticularExerciseQuery = {
  getParticularExercise:  {
    __typename: "Exercises",
    exercises:  Array< {
      __typename: "Exercise",
      id: string,
      userId: string,
      exerciseName: string,
      questions:  Array< {
        __typename: "Question",
        questionID: string,
        questionType: QuestionType,
        questionState?: QuestionState | null,
        questionAbility?: QuestionAbility | null,
        questionStatement: string,
        mcQuestionContent?:  {
          __typename: "McQuestion",
          choices: Array< string | null >,
          answer: number,
        } | null,
        tfQuestionContent?:  {
          __typename: "TfQuestion",
          tfQuestionAnswerList:  Array< {
            __typename: "TfQuestionAnswerList",
            tfQuestion: string,
            tfAnswer: TfAnswer,
          } >,
        } | null,
        sqQuestionContent?:  {
          __typename: "SqQuestion",
          answer: string,
        } | null,
        lqQuestionContent?:  {
          __typename: "LqQuestion",
          answer: string,
        } | null,
        scQuestionContent?:  {
          __typename: "ScQuestion",
          summary: string,
          numBlanks: number,
          answer: Array< string >,
        } | null,
        ftbQuestionContent?:  {
          __typename: "FtbQuestion",
          answerStatement: string,
          answer: string,
        } | null,
        matchQuestionContent?:  {
          __typename: "MatchQuestion",
          questionItemType: string,
          questionItem: Array< string >,
          numItems: number,
          statement: Array< string >,
          answers: Array< number >,
        } | null,
        tableQuestionContent?:  {
          __typename: "TableQuestion",
          questionTitle: Array< string >,
          numItems: number,
          answerStatement: Array< string >,
          answers: Array< string >,
        } | null,
        weirdQuestionContent?:  {
          __typename: "WeirdQuestion",
          weirdQuestionAnswerList:  Array< {
            __typename: "WeirdQuestionAnswerList",
            weirdQuestion: string,
            weirdAnswer: string,
          } >,
        } | null,
        articleContext: string,
        highlightStart: string,
        highlightEnd: string,
        startGenerationTime?: string | null,
      } >,
      article: string,
      articleName?: string | null,
      createdDate: string,
      lastAccessedDate: string,
      version: number,
      published?: string | null,
    } >,
    nextToken?: string | null,
  },
};

export type GetParticularExerciseConsistentQueryVariables = {
  exerciseID?: string | null,
  userID?: string | null,
};

export type GetParticularExerciseConsistentQuery = {
  getParticularExerciseConsistent:  {
    __typename: "Exercises",
    exercises:  Array< {
      __typename: "Exercise",
      id: string,
      userId: string,
      exerciseName: string,
      questions:  Array< {
        __typename: "Question",
        questionID: string,
        questionType: QuestionType,
        questionState?: QuestionState | null,
        questionAbility?: QuestionAbility | null,
        questionStatement: string,
        mcQuestionContent?:  {
          __typename: "McQuestion",
          choices: Array< string | null >,
          answer: number,
        } | null,
        tfQuestionContent?:  {
          __typename: "TfQuestion",
          tfQuestionAnswerList:  Array< {
            __typename: "TfQuestionAnswerList",
            tfQuestion: string,
            tfAnswer: TfAnswer,
          } >,
        } | null,
        sqQuestionContent?:  {
          __typename: "SqQuestion",
          answer: string,
        } | null,
        lqQuestionContent?:  {
          __typename: "LqQuestion",
          answer: string,
        } | null,
        scQuestionContent?:  {
          __typename: "ScQuestion",
          summary: string,
          numBlanks: number,
          answer: Array< string >,
        } | null,
        ftbQuestionContent?:  {
          __typename: "FtbQuestion",
          answerStatement: string,
          answer: string,
        } | null,
        matchQuestionContent?:  {
          __typename: "MatchQuestion",
          questionItemType: string,
          questionItem: Array< string >,
          numItems: number,
          statement: Array< string >,
          answers: Array< number >,
        } | null,
        tableQuestionContent?:  {
          __typename: "TableQuestion",
          questionTitle: Array< string >,
          numItems: number,
          answerStatement: Array< string >,
          answers: Array< string >,
        } | null,
        weirdQuestionContent?:  {
          __typename: "WeirdQuestion",
          weirdQuestionAnswerList:  Array< {
            __typename: "WeirdQuestionAnswerList",
            weirdQuestion: string,
            weirdAnswer: string,
          } >,
        } | null,
        articleContext: string,
        highlightStart: string,
        highlightEnd: string,
        startGenerationTime?: string | null,
      } >,
      article: string,
      articleName?: string | null,
      createdDate: string,
      lastAccessedDate: string,
      version: number,
      published?: string | null,
    } >,
    nextToken?: string | null,
  },
};

export type GetCheckOutSessionQueryVariables = {
  successUrl: string,
};

export type GetCheckOutSessionQuery = {
  getCheckOutSession: string,
};

export type GetPublishedExercisesQueryVariables = {
  nextToken?: string | null,
  count?: number | null,
};

export type GetPublishedExercisesQuery = {
  getPublishedExercises:  {
    __typename: "Exercises",
    exercises:  Array< {
      __typename: "Exercise",
      id: string,
      userId: string,
      exerciseName: string,
      questions:  Array< {
        __typename: "Question",
        questionID: string,
        questionType: QuestionType,
        questionState?: QuestionState | null,
        questionAbility?: QuestionAbility | null,
        questionStatement: string,
        mcQuestionContent?:  {
          __typename: "McQuestion",
          choices: Array< string | null >,
          answer: number,
        } | null,
        tfQuestionContent?:  {
          __typename: "TfQuestion",
          tfQuestionAnswerList:  Array< {
            __typename: "TfQuestionAnswerList",
            tfQuestion: string,
            tfAnswer: TfAnswer,
          } >,
        } | null,
        sqQuestionContent?:  {
          __typename: "SqQuestion",
          answer: string,
        } | null,
        lqQuestionContent?:  {
          __typename: "LqQuestion",
          answer: string,
        } | null,
        scQuestionContent?:  {
          __typename: "ScQuestion",
          summary: string,
          numBlanks: number,
          answer: Array< string >,
        } | null,
        ftbQuestionContent?:  {
          __typename: "FtbQuestion",
          answerStatement: string,
          answer: string,
        } | null,
        matchQuestionContent?:  {
          __typename: "MatchQuestion",
          questionItemType: string,
          questionItem: Array< string >,
          numItems: number,
          statement: Array< string >,
          answers: Array< number >,
        } | null,
        tableQuestionContent?:  {
          __typename: "TableQuestion",
          questionTitle: Array< string >,
          numItems: number,
          answerStatement: Array< string >,
          answers: Array< string >,
        } | null,
        weirdQuestionContent?:  {
          __typename: "WeirdQuestion",
          weirdQuestionAnswerList:  Array< {
            __typename: "WeirdQuestionAnswerList",
            weirdQuestion: string,
            weirdAnswer: string,
          } >,
        } | null,
        articleContext: string,
        highlightStart: string,
        highlightEnd: string,
        startGenerationTime?: string | null,
      } >,
      article: string,
      articleName?: string | null,
      createdDate: string,
      lastAccessedDate: string,
      version: number,
      published?: string | null,
    } >,
    nextToken?: string | null,
  },
};

export type GetParticularPublishedExerciseQueryVariables = {
  exerciseID?: string | null,
};

export type GetParticularPublishedExerciseQuery = {
  getParticularPublishedExercise:  {
    __typename: "Exercises",
    exercises:  Array< {
      __typename: "Exercise",
      id: string,
      userId: string,
      exerciseName: string,
      questions:  Array< {
        __typename: "Question",
        questionID: string,
        questionType: QuestionType,
        questionState?: QuestionState | null,
        questionAbility?: QuestionAbility | null,
        questionStatement: string,
        mcQuestionContent?:  {
          __typename: "McQuestion",
          choices: Array< string | null >,
          answer: number,
        } | null,
        tfQuestionContent?:  {
          __typename: "TfQuestion",
          tfQuestionAnswerList:  Array< {
            __typename: "TfQuestionAnswerList",
            tfQuestion: string,
            tfAnswer: TfAnswer,
          } >,
        } | null,
        sqQuestionContent?:  {
          __typename: "SqQuestion",
          answer: string,
        } | null,
        lqQuestionContent?:  {
          __typename: "LqQuestion",
          answer: string,
        } | null,
        scQuestionContent?:  {
          __typename: "ScQuestion",
          summary: string,
          numBlanks: number,
          answer: Array< string >,
        } | null,
        ftbQuestionContent?:  {
          __typename: "FtbQuestion",
          answerStatement: string,
          answer: string,
        } | null,
        matchQuestionContent?:  {
          __typename: "MatchQuestion",
          questionItemType: string,
          questionItem: Array< string >,
          numItems: number,
          statement: Array< string >,
          answers: Array< number >,
        } | null,
        tableQuestionContent?:  {
          __typename: "TableQuestion",
          questionTitle: Array< string >,
          numItems: number,
          answerStatement: Array< string >,
          answers: Array< string >,
        } | null,
        weirdQuestionContent?:  {
          __typename: "WeirdQuestion",
          weirdQuestionAnswerList:  Array< {
            __typename: "WeirdQuestionAnswerList",
            weirdQuestion: string,
            weirdAnswer: string,
          } >,
        } | null,
        articleContext: string,
        highlightStart: string,
        highlightEnd: string,
        startGenerationTime?: string | null,
      } >,
      article: string,
      articleName?: string | null,
      createdDate: string,
      lastAccessedDate: string,
      version: number,
      published?: string | null,
    } >,
    nextToken?: string | null,
  },
};

export type GetMyTrialUserQuery = {
  getMyTrialUser?:  {
    __typename: "TrialUser",
    userId: string,
    isIntern: boolean,
    email: string,
    name: string,
  } | null,
};

export type GetAllTrialUsersQueryVariables = {
  nextToken?: string | null,
  count?: number | null,
};

export type GetAllTrialUsersQuery = {
  getAllTrialUsers:  {
    __typename: "TrialUsers",
    trialUsers:  Array< {
      __typename: "TrialUser",
      userId: string,
      isIntern: boolean,
      email: string,
      name: string,
    } >,
    nextToken?: string | null,
  },
};

export type SubscribeExerciseSubscriptionVariables = {
  id: string,
  userId: string,
};

export type SubscribeExerciseSubscription = {
  subscribeExercise?:  {
    __typename: "Exercise",
    id: string,
    userId: string,
    exerciseName: string,
    questions:  Array< {
      __typename: "Question",
      questionID: string,
      questionType: QuestionType,
      questionState?: QuestionState | null,
      questionAbility?: QuestionAbility | null,
      questionStatement: string,
      mcQuestionContent?:  {
        __typename: "McQuestion",
        choices: Array< string | null >,
        answer: number,
      } | null,
      tfQuestionContent?:  {
        __typename: "TfQuestion",
        tfQuestionAnswerList:  Array< {
          __typename: "TfQuestionAnswerList",
          tfQuestion: string,
          tfAnswer: TfAnswer,
        } >,
      } | null,
      sqQuestionContent?:  {
        __typename: "SqQuestion",
        answer: string,
      } | null,
      lqQuestionContent?:  {
        __typename: "LqQuestion",
        answer: string,
      } | null,
      scQuestionContent?:  {
        __typename: "ScQuestion",
        summary: string,
        numBlanks: number,
        answer: Array< string >,
      } | null,
      ftbQuestionContent?:  {
        __typename: "FtbQuestion",
        answerStatement: string,
        answer: string,
      } | null,
      matchQuestionContent?:  {
        __typename: "MatchQuestion",
        questionItemType: string,
        questionItem: Array< string >,
        numItems: number,
        statement: Array< string >,
        answers: Array< number >,
      } | null,
      tableQuestionContent?:  {
        __typename: "TableQuestion",
        questionTitle: Array< string >,
        numItems: number,
        answerStatement: Array< string >,
        answers: Array< string >,
      } | null,
      weirdQuestionContent?:  {
        __typename: "WeirdQuestion",
        weirdQuestionAnswerList:  Array< {
          __typename: "WeirdQuestionAnswerList",
          weirdQuestion: string,
          weirdAnswer: string,
        } >,
      } | null,
      articleContext: string,
      highlightStart: string,
      highlightEnd: string,
      startGenerationTime?: string | null,
    } >,
    article: string,
    articleName?: string | null,
    createdDate: string,
    lastAccessedDate: string,
    version: number,
    published?: string | null,
  } | null,
};

export type SubscribeQuestionSubscriptionVariables = {
  id: string,
  userId: string,
};

export type SubscribeQuestionSubscription = {
  subscribeQuestion?:  {
    __typename: "Exercise",
    id: string,
    userId: string,
    exerciseName: string,
    questions:  Array< {
      __typename: "Question",
      questionID: string,
      questionType: QuestionType,
      questionState?: QuestionState | null,
      questionAbility?: QuestionAbility | null,
      questionStatement: string,
      mcQuestionContent?:  {
        __typename: "McQuestion",
        choices: Array< string | null >,
        answer: number,
      } | null,
      tfQuestionContent?:  {
        __typename: "TfQuestion",
        tfQuestionAnswerList:  Array< {
          __typename: "TfQuestionAnswerList",
          tfQuestion: string,
          tfAnswer: TfAnswer,
        } >,
      } | null,
      sqQuestionContent?:  {
        __typename: "SqQuestion",
        answer: string,
      } | null,
      lqQuestionContent?:  {
        __typename: "LqQuestion",
        answer: string,
      } | null,
      scQuestionContent?:  {
        __typename: "ScQuestion",
        summary: string,
        numBlanks: number,
        answer: Array< string >,
      } | null,
      ftbQuestionContent?:  {
        __typename: "FtbQuestion",
        answerStatement: string,
        answer: string,
      } | null,
      matchQuestionContent?:  {
        __typename: "MatchQuestion",
        questionItemType: string,
        questionItem: Array< string >,
        numItems: number,
        statement: Array< string >,
        answers: Array< number >,
      } | null,
      tableQuestionContent?:  {
        __typename: "TableQuestion",
        questionTitle: Array< string >,
        numItems: number,
        answerStatement: Array< string >,
        answers: Array< string >,
      } | null,
      weirdQuestionContent?:  {
        __typename: "WeirdQuestion",
        weirdQuestionAnswerList:  Array< {
          __typename: "WeirdQuestionAnswerList",
          weirdQuestion: string,
          weirdAnswer: string,
        } >,
      } | null,
      articleContext: string,
      highlightStart: string,
      highlightEnd: string,
      startGenerationTime?: string | null,
    } >,
    article: string,
    articleName?: string | null,
    createdDate: string,
    lastAccessedDate: string,
    version: number,
    published?: string | null,
  } | null,
};
