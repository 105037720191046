/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const subscribeExercise = /* GraphQL */ `
  subscription SubscribeExercise($id: String!, $userId: String!) {
    subscribeExercise(id: $id, userId: $userId) {
      id
      userId
      exerciseName
      questions {
        questionID
        questionType
        questionState
        questionAbility
        questionStatement
        mcQuestionContent {
          choices
          answer
        }
        tfQuestionContent {
          tfQuestionAnswerList {
            tfQuestion
            tfAnswer
          }
        }
        sqQuestionContent {
          answer
        }
        lqQuestionContent {
          answer
        }
        scQuestionContent {
          summary
          numBlanks
          answer
        }
        ftbQuestionContent {
          answerStatement
          answer
        }
        matchQuestionContent {
          questionItemType
          questionItem
          numItems
          statement
          answers
        }
        tableQuestionContent {
          questionTitle
          numItems
          answerStatement
          answers
        }
        weirdQuestionContent {
          weirdQuestionAnswerList {
            weirdQuestion
            weirdAnswer
          }
        }
        articleContext
        highlightStart
        highlightEnd
        startGenerationTime
      }
      article
      articleName
      createdDate
      lastAccessedDate
      version
      published
    }
  }
`;
export const subscribeQuestion = /* GraphQL */ `
  subscription SubscribeQuestion($id: String!, $userId: String!) {
    subscribeQuestion(id: $id, userId: $userId) {
      id
      userId
      exerciseName
      questions {
        questionID
        questionType
        questionState
        questionAbility
        questionStatement
        mcQuestionContent {
          choices
          answer
        }
        tfQuestionContent {
          tfQuestionAnswerList {
            tfQuestion
            tfAnswer
          }
        }
        sqQuestionContent {
          answer
        }
        lqQuestionContent {
          answer
        }
        scQuestionContent {
          summary
          numBlanks
          answer
        }
        ftbQuestionContent {
          answerStatement
          answer
        }
        matchQuestionContent {
          questionItemType
          questionItem
          numItems
          statement
          answers
        }
        tableQuestionContent {
          questionTitle
          numItems
          answerStatement
          answers
        }
        weirdQuestionContent {
          weirdQuestionAnswerList {
            weirdQuestion
            weirdAnswer
          }
        }
        articleContext
        highlightStart
        highlightEnd
        startGenerationTime
      }
      article
      articleName
      createdDate
      lastAccessedDate
      version
      published
    }
  }
`;
