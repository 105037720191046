import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button, EmailTextInputComponent } from "./LoginPage";
export default function ForgotPasswordPage() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmCode, setConfirmCode] = useState("");
  const [confirmEmailPage, setConfirmEmailPage] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [pwError, setPwError] = useState("");
  const [codeError, setCodeError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [emailError, setEmailError] = useState<string | null>(null);
  const nav = useNavigate();
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const toggleResetPassword = () => {
    setConfirmEmailPage(!confirmEmailPage);
  };

  async function handleEmailSubmit(event: any) {
    event.preventDefault();
    setIsLoading(true);
    if (email) {
    } else {
      setEmailError("Please make sure all fields are filled");
      setIsLoading(false);
      return;
    }

    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email.match(validRegex)) {
      setEmailError("Please enter valid Email");
      setIsLoading(false);
    } else {
      setEmailError(null);
      setIsLoading(true);
      try {
        await Auth.forgotPassword(email);
        toggleResetPassword();
      } catch (e: any) {
        setEmailError(e.message);
      }
      setIsLoading(false);
    }
  }

  async function handleChangePassword(event: any) {
    setIsLoading(true);
    event.preventDefault();
    if (!(confirmPassword === password)) {
      setConfirmPasswordError("Passwords do not match");
      setIsLoading(false);
      return;
    }
    if (email) {
    } else {
      setEmailError("Please make sure all fields are filled");
      setIsLoading(false);

      return;
    }
    if (password) {
    } else {
      setPwError("Password cannot be empty.");
      setIsLoading(false);
      return;
    }
    try {
      await Auth.forgotPasswordSubmit(email, confirmCode, password);
      nav("/login");
      alert("Your password has been successfully reset.");
    } catch (e: any) {
      if (JSON.stringify(e).includes("CodeMismatchException")) {
        const msgAlert =
          "The code does not match with the code provided. Please try again with the same code.";
        alert(msgAlert);
        setEmailError(msgAlert);
        setIsLoading(false);
        return;
      }

      if (
        e.message.includes("Password does not conform to policy:") ||
        e.message.includes("validation error detected:")
      ) {
        const msgAlert =
          "Password must contain: Minimum length of 8 characters, including upper and lower case, special, and numeric characters.";
        alert(msgAlert);
        setPwError(msgAlert);
        setIsLoading(false);
        return;
      }
      alert(e.message);
      setIsLoading(false);
    }
  }

  return (
    <div className="flex flex-row">
      <div className="relative lg:w-4/5">
        <img
          src="/READilyLogoBlack.svg"
          alt=""
          className="w-40 h-12 absolute left-16 top-10"
        />
        <img src="/Section.png" alt="" className=" h-screen w-0 lg:w-screen" />
      </div>
      <div className="h-screen w-fill container mx-auto flex justify-center">
        <div className="my-auto w-full mx-10 lg:max-w-md">
          <div className="text-MainColor/Black">
            {!confirmEmailPage && (
              <form>
                <h1 className="text-4xl text-Fill/Dark/MainDark font-Manrope font-semibold py-2 pb-8">
                  Password Reset
                </h1>
                <EmailTextInputComponent
                  onChange={(e: any) => {
                    setEmail(e.target.value);
                    const validRegex =
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

                    if (e.target.value.match(validRegex)) {
                      setEmailError(null);
                    } else {
                      setEmailError("Please enter a valid Email.");
                    }
                  }}
                  value={email ?? ""}
                  error={emailError}
                  label="Email"
                  placeholder="Enter your email"
                />

                <div className="pt-4">
                  <Button
                    isLoading={isLoading}
                    onClick={handleEmailSubmit}
                    text="Send me verification code"
                  />
                </div>
              </form>
            )}
            <form onSubmit={handleChangePassword}>
              {confirmEmailPage && (
                <div>
                  <h1 className="text-4xl text-Fill/Dark/MainDark font-Manrope font-semibold py-2">
                    Set a new password
                  </h1>
                  <div className="pb-4">
                    <EmailTextInputComponent
                      onChange={(e) => {
                        setConfirmCode(e.target.value);
                        const validRegex = /^.{6}$/;
                        if (e.target.value.match(validRegex)) {
                          setCodeError("");
                        } else {
                          setCodeError(
                            "Verification code must be exactly 6 characters"
                          );
                        }
                      }}
                      error={codeError}
                      label={"Verification Code"}
                      placeholder="Enter code"
                      value={confirmCode ?? ""}
                    />
                  </div>
                  <div className="relative">
                    <div className="pb-4">
                      <PasswordInputComponent
                        onChange={(e) => {
                          setPassword(e.target.value);
                          const validRegex =
                            /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@\\^_`{|}~\]\[]).{8,}/;
                          if (e.target.value.match(validRegex)) {
                            setPwError("");
                          } else {
                            setPwError("Password does not meet requirement.");
                          }

                          //to constantly check if the two pw entries match
                          if (confirmPassword === e.target.value) {
                            setConfirmPasswordError("");
                          } else {
                            setConfirmPasswordError("Password does not match");
                          }
                        }}
                        error={pwError}
                        label={"New password"}
                        placeholder="Enter new password"
                        value={password ?? ""}
                        showPassword={passwordShown}
                        onClick={togglePassword}
                      />
                    </div>
                    <div className="pb-4">
                      <PasswordInputComponent
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);

                          if (e.target.value === password) {
                            setConfirmPasswordError("");
                          } else {
                            setConfirmPasswordError("Password does not match.");
                          }
                        }}
                        error={confirmPasswordError}
                        label={"Confirm new password"}
                        placeholder="Confirm new password"
                        value={
                          confirmPassword === null ? undefined : confirmPassword
                        }
                        showPassword={passwordShown}
                        onClick={togglePassword}
                      />
                    </div>

                    <p className="pt-1.5 text-xs text-dark">
                      Minimum length of 8 characters, including upper and lower
                      case, special, and numeric characters.
                    </p>
                    <div className="pt-7">
                      <Button
                        isLoading={isLoading}
                        onClick={handleChangePassword}
                        text="Submit"
                      />
                    </div>
                  </div>
                </div>
              )}
            </form>
            <div className="justify-center pt-7">
              <p className="text-center">
                <Link
                  to="/login"
                  className="text-sm text-blue1 hover:underline font-semibold"
                >
                  Back to sign-in
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const PasswordInputComponent = ({
  onChange,
  error,
  label,
  placeholder = "example@email.com",
  value,
  showPassword,
  onClick,
}: {
  onClick: React.MouseEventHandler<SVGSVGElement>;
  onChange: (e: any) => void;
  error: string | null;
  label: string;
  placeholder?: string;
  value?: string;
  showPassword: boolean;
}) => {
  return (
    <>
      <p className="pb-2 text-sm font-normal">{label}</p>
      <div className="relative">
        <input
          className={
            "peer outline-none border  rounded  w-full h-12 pl-4 py-3 " +
            (error
              ? " border-CTA/Error text-CTA/Error focus:border-CTA/Error focus:ring-CTA/Error"
              : "border-Purple focus:border-blue1")
          }
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          autoFocus
          type={showPassword ? "text" : "password"}
        ></input>
        {error && (
          <p
            className={
              error != null
                ? "pt-1.5 text-xs text-CTA/Error"
                : "pt-1.5 invisible"
            }
          >
            {error}
          </p>
        )}
        <svg
          onClick={onClick}
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-6 fill-Fill/Dark/Dark2 absolute inset-y-3.5 right-3.5"
          viewBox="0 0 20 20"
        >
          <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
          <path
            fillRule="evenodd"
            d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </>
  );
};
