import React, { useState } from "react";
//import Form from "react-bootstrap/Form";
//import Button from "react-bootstrap/Button";
import { useAppContext } from "../context/context";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TermsOfUse from "../components/TermsOfUse";
import PrivacyPolicy from "../components/PrivacyPolicy";
// import { ReactComponent as LoadingIcon } from "./loading.svg";

import { EmailTextInputComponent } from "./LoginPage";
import { PasswordInputComponent } from "./ForgotPasswordPage";
import { LoadingIcon } from "../components/LoadingIcon";

export default function RegisterPage() {
  const { userHasAuthenticated, email, setEmail, setName, name, setUserId } =
    useAppContext();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [policyChecked, setPolicyChecked] = useState(false); //if box is checked, it will be true

  const [showTermsOfUse, setShowTermsOfUse] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  const [pwError, setPwError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [emailCode, setEmailCode] = useState("");
  const [nameError, setNameError] = useState<string>("");
  const [policyError, setPolicyError] = useState("");
  const [showEmailVerify, setShowEmailVerify] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);

  const nav = useNavigate();
  const handlepolicycheck = (e: any) => {
    setPolicyChecked(e.target.checked);
  };
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const toggleEmailVerify = () => {
    setShowEmailVerify(!showEmailVerify);
  };

  const [emailError, setEmailError] = useState<string | null>(null);
  async function handleSubmit(event: any) {
    setIsLoading(true);
    event.preventDefault();

    if (!(confirmPassword === password)) {
      setConfirmPasswordError("Passwords do not match");
      setIsLoading(false);
      return;
    }
    if (!name || name.length === 0) {
      setNameError("Name could not be empty.");
      setIsLoading(false);
      return;
    }
    if (email && password) {
    } else {
      setEmailError("Please make sure all fields are filled");
      setIsLoading(false);
      return;
    }

    if (!policyChecked) {
      const msgAlert =
        "The terms and condition need to be acknowledged in order to use the app";
      setIsLoading(false);
      setPolicyError(msgAlert);

      return;
    }

    try {
      await Auth.signUp({
        username: email,
        password: password,
        attributes: { email: email, preferred_username: name },
        //email: email,
      });

      setIsLoading(false);
      toggleEmailVerify();
    } catch (e: any) {
      if (e.message.includes("validation error detected:")) {
        const msgAlert =
          "Password must contain: Minimum length of 8 characters, including upper and lower case, special, and numeric characters.";
        setEmailError(msgAlert);
        setIsLoading(false);
        return;
      }

      setEmailError(e.message);
      setIsLoading(false);
    }
  }

  async function handleEmailCode(event: any) {
    event.preventDefault();
    setIsLoading(true);

    if (emailCode == null) {
      setEmailError("Please enter the verification code sent to your email.");
      setIsLoading(false);
      return;
    }
    try {
      await Auth.confirmSignUp(email!, emailCode);

      const user = await Auth.signIn(email!, password!);
      setUserId(user.attributes.sub);
      setName(user.attributes.preferred_username);
      userHasAuthenticated(true);
      nav("/");
    } catch (e: any) {
      const ERROR_TYPES = [
        "InvalidParameterException",
        "CodeMismatchException",
      ];
      const errorString = JSON.stringify(e);
      if (ERROR_TYPES.some((eType) => errorString.includes(eType))) {
        setInvalidCode(true);
        // alert(
        //   "The code does not match with the code provided. Please try again with the new code. "
        // );
        await Auth.resendSignUp(email!);
        return;
      }

      setEmailError(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleResendCode(event: any) {
    event.preventDefault();
    setIsLoading(true);

    try {
      await Auth.resendSignUp(email!);
      setIsLoading(false);
    } catch (e: any) {
      setEmailError(e.message);
      setIsLoading(false);
    }
  }

  return (
    <div className="flex flex-row">
      <div className="relative lg:w-4/5">
        <img
          src="/READilyLogoBlack.svg"
          alt=""
          className="w-40 h-12 absolute left-16 top-10"
        />
        <img src="/Section.png" alt="" className=" h-screen w-0 lg:w-screen" />
      </div>
      {!showEmailVerify && (
        <div className="h-screen w-fill container mx-auto flex justify-center">
          <div className="my-auto w-full mx-10 lg:max-w-md">
            <h1 className="text-4xl text-Fill/Dark/MainDark font-Manrope font-semibold py-2">
              Welcome to Readily
            </h1>
            <p className="text-sm text-Fill/Dark/Dark2 font-normal pb-8">
              Register an account to get started now!
            </p>
            <div className="text-MainColor/Black">
              <form onSubmit={handleSubmit}>
                <div className="pb-4">
                  <EmailTextInputComponent
                    onChange={(e) => {
                      setName(e.target.value);
                      const validRegex = /^[a-z ,.'-]+$/i;
                      if (e.target.value.match(validRegex)) {
                        setNameError("");
                      } else {
                        setNameError("invalid name");
                      }
                    }}
                    error={nameError}
                    label={"Name"}
                    placeholder="Full name"
                    value={name ?? ""}
                  />
                </div>

                <div className="pb-4">
                  <EmailTextInputComponent
                    onChange={(e) => {
                      setEmail(e.target.value);
                      const validRegex =
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

                      if (e.target.value.match(validRegex)) {
                        setEmailError(null);
                      } else {
                        setEmailError("Please enter a valid Email.");
                      }
                    }}
                    label="Email"
                    error={emailError}
                    value={email ?? ""}
                  />
                </div>
                <div className="pb-4">
                  <PasswordInputComponent
                    onChange={(e) => {
                      setPassword(e.target.value);
                      const validRegex =
                        /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@\\^_`{|}~\]\[]).{8,}/;
                      if (e.target.value.match(validRegex)) {
                        setPwError("");
                      } else {
                        setPwError("Password does not meet requirement.");
                      }

                      //to constantly check if the two pw entries match
                      if (confirmPassword === e.target.value) {
                        setConfirmPasswordError("");
                      } else {
                        setConfirmPasswordError("Password does not match");
                      }
                    }}
                    error={pwError}
                    label={"Create Password"}
                    placeholder="Password"
                    value={password ?? ""}
                    showPassword={passwordShown}
                    onClick={togglePassword}
                  />
                  <p className="pt-1.5 text-xs text-dark">
                    Minimum length of 8 characters, including upper and lower
                    case, special, and numeric characters.
                  </p>
                </div>
                <div className="pb-4">
                  <PasswordInputComponent
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);

                      if (e.target.value === password) {
                        setConfirmPasswordError("");
                      } else {
                        setConfirmPasswordError("Password does not match.");
                      }
                    }}
                    error={confirmPasswordError}
                    label={"Confirm password"}
                    placeholder="Confirm password"
                    value={confirmPassword ?? ""}
                    showPassword={passwordShown}
                    onClick={togglePassword}
                  />
                </div>
                <div className="pt-7">
                  <div className="justify-self-start">
                    <div className="m-1 flex flex-row">
                      <input
                        className={
                          "form-checkbox mr-3.5 border rounded text-blue1 w-5 h-5 " +
                          (policyError ? " border-CTA/Error" : "")
                        }
                        type="checkbox"
                        // value={policyChecked}
                        onChange={handlepolicycheck}
                      />
                      <span className="text-xs font-normal text-Secondary/Black">
                        By clicking Register, I agree that I have read and
                        accepted the{" "}
                        <span
                          onClick={() => setShowTermsOfUse(true)}
                          className="text-blue1 font-medium hover:underline"
                        >
                          Terms of Use
                        </span>{" "}
                        and{" "}
                        <span
                          onClick={() => setShowPrivacyPolicy(true)}
                          className="text-blue1 font-medium hover:underline"
                        >
                          Privacy Policy
                        </span>
                        .{" "}
                        {policyError && (
                          <p
                            className={
                              policyError != null
                                ? "pt-1.5 text-xs text-CTA/Error"
                                : "pt-1.5 invisible"
                            }
                          >
                            {policyError}
                          </p>
                        )}
                      </span>
                      {showTermsOfUse && (
                        <>
                          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-auto mx-auto max-w-3xl">
                              {/*content*/}
                              <div className="border-0 rounded-20xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t mx-10">
                                  <h3 className="text-xl font-semibold">
                                    Terms of Use
                                  </h3>
                                  <button
                                    onClick={() => setShowTermsOfUse(false)}
                                  >
                                    <img src="/close.svg" alt="" />
                                  </button>
                                </div>
                                <div className="pr-2">
                                  <TermsOfUse />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
                        </>
                      )}
                      {showPrivacyPolicy && (
                        <>
                          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-auto mx-auto max-w-3xl">
                              {/*content*/}
                              <div className="border-0 rounded-20xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t mx-10">
                                  <h3 className="text-xl font-semibold">
                                    Privacy Policy
                                  </h3>
                                  <button
                                    onClick={() => setShowPrivacyPolicy(false)}
                                  >
                                    <img src="/close.svg" alt="" />
                                  </button>
                                </div>
                                <PrivacyPolicy />
                              </div>
                            </div>
                          </div>
                          <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="pt-7">
                  {isLoading ? (
                    <button
                      className="cursor-pointer flex flex-row items-center justify-center text-white bg-blue1 border rounded w-full h-12 font-medium hover:bg-blue2 cursor-not-allowed"
                      disabled
                    >
                      <LoadingIcon />
                      Register
                    </button>
                  ) : (
                    <input
                      className="text-white bg-blue1 border rounded w-full h-12 font-medium hover:bg-blue2"
                      type="submit"
                      value="Register"
                    />
                  )}
                </div>
              </form>
              <div className="justify-center py-8">
                <p className="text-center">
                  Already have an account? &nbsp;
                  <Link
                    to="/login"
                    className="text-blue1 hover:underline font-semibold"
                  >
                    Login
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {showEmailVerify && (
        <div className="h-screen w-fill container mx-auto flex justify-center">
          <div className="my-auto w-full mx-10 lg:max-w-md">
            <h1 className="text-4xl text-Fill/Dark/MainDark font-Manrope font-semibold py-2 flex justify-center">
              Email Verification
            </h1>
            <div className="text-MainColor/Black">
              <form onSubmit={handleEmailCode}>
                <div>
                  <h1 className="pt-8 pb-4 text-sm font-medium flex justify-center">
                    Enter verification code sent to
                    <span className="font-semibold">&nbsp; {email}</span>
                  </h1>
                </div>

                <div className="relative">
                  <input
                    className="peer outline-none border-b border-Purple rounded focus:border-blue1 w-full h-12 pl-4 py-3"
                    onChange={(e) => {
                      setEmailCode(e.target.value);
                    }}
                    placeholder="Enter the 6-character code"
                    type="text"
                    id="code"
                    name="code"
                  ></input>
                  {invalidCode && (
                    <div className="mt-[4px] text-center text-CTA/Error text-[14px] font-semibold">
                      The code does not match with the code provided. <br></br>
                      Please try again with the new code.
                    </div>
                  )}
                </div>

                <div className="pt-7">
                  {isLoading ? (
                    <button
                      className="cursor-pointer flex flex-row items-center justify-center text-white bg-blue1 border rounded w-full h-12 font-medium hover:bg-blue2 cursor-not-allowed"
                      disabled
                    >
                      <LoadingIcon />
                      Submit
                    </button>
                  ) : (
                    <input
                      className="cursor-pointer text-white bg-blue1 border rounded w-full h-12 font-medium hover:bg-blue2"
                      type="submit"
                      value="Submit"
                    />
                  )}
                </div>
              </form>
              <div className="justify-center pt-4">
                <p className="text-center">
                  Don't see the verification code? &nbsp;
                  <button
                    onClick={handleResendCode}
                    className="cursor-pointer text-blue1 hover:underline font-semibold"
                  >
                    Resend
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
