import React, { useState } from "react";
import { Exercise } from "../../api/codegen/API";
import { ReactComponent as IconDocx } from "../../icons/IconDocx.svg";
import { ReactComponent as IconPdf } from "../../icons/IconPdf.svg";
import { ReactComponent as IconCross } from "../../icons/IconCross.svg";
import { exportExerciseHandler } from "../exportExerciseHandler";

import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";

export const ExportandGeneratePopUp: React.FC<{
  onClose: () => void;
  exercise: Exercise;
  isExport: boolean;
  onConfirm?: (part: string) => void;
}> = (props) => {
  const gaEventTracker: any = useAnalyticsEventTracker("Passage");
  const [showPart, setShowPart] = useState(false);
  const [showFileTypes, setShowFileTypes] = useState(false);
  const [isPdfType, setIsPdfType] = useState(false);
  const [part, setPart] = useState("");
  const [setNumber, setSetNumber] = useState("1");

  return (
    <div>
      <div
        className={`fixed z-[50] w-full h-full outline-none`}
        style={{ top: `${props.isExport ? 25 : 35}%` }}
      >
        <div className={`mx-auto w-[36%] h-[${props.isExport ? 41 : 22}%]`}>
          <div className="rounded-20xl border-none shadow-2xl relative flex flex-col w-full h-full bg-white outline-none focus:outline-none">
            <div className="rounded-20xl bg-white border-b-2 shadow-2xl">
              <div className="">
                <div className="flex justify-between items-center py-6 px-6">
                  <span className="font-semibold">{`${
                    props.isExport ? "Export" : "Generate Paper"
                  }`}</span>
                  <IconCross
                    onClick={() => props.onClose()}
                    className="cursor-pointer w-[24px] h-[24px] text-grey hover:text-black"
                  />
                </div>

                {showPart && (
                  <div className="absolute top-[167px] left-[25px] z-20 grid grid-cols-1 items-center w-[90%] h-[150px] border-2 border-Purple rounded-[8px] bg-white drop-shadow-lg">
                    <ul className="flex text-sm font-medium">
                      <button
                        className="inline-flex items-center w-full mx-[8px] pl-[16px] py-[11px] h-[44px] hover:bg-sky-100 hover:rounded-md"
                        onClick={() => {
                          setPart("A");
                          setShowPart(false);
                        }}
                      >
                        A (Compulsory)
                      </button>
                    </ul>
                    <ul className="flex text-sm font-medium">
                      <button
                        className="inline-flex items-center w-full mx-[8px] pl-[16px] py-[11px] h-[44px] hover:bg-sky-100 hover:rounded-md"
                        onClick={() => {
                          setPart("B1");
                          setShowPart(false);
                        }}
                      >
                        B1 (Easy)
                      </button>
                    </ul>
                    <ul className="flex text-sm font-medium">
                      <button
                        className="inline-flex items-center w-full mx-[8px] pl-[16px] py-[11px] h-[44px] hover:bg-sky-100 hover:rounded-md"
                        onClick={() => {
                          setPart("B2");
                          setShowPart(false);
                        }}
                      >
                        B2 (Difficult)
                      </button>
                    </ul>
                  </div>
                )}
                <div>
                  <div>
                    <h1 className="text-sm font-medium pt-[19px] pl-[29px]">
                      Part
                    </h1>
                    <div className="flex w-full relative">
                      <div
                        className="flex w-[90%] h-[48px] border-2 border-gray-400 mt-[8px] ml-[25px] pl-[16px] py-[12px] rounded-[8px] justify-left items-center"
                        onClick={() => {
                          setShowPart(!showPart);
                        }}
                      >
                        {part}
                        <svg
                          className="ml-[147px] absolute right-[70px]"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.33301 5.66602L7.99967 10.3327L12.6663 5.66602"
                            stroke="#9EA3AE"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>

                    {props.isExport && (
                      <div className="flex flex-col justify-start items-left">
                        <h1 className="text-sm font-medium pt-[19px] pl-[29px]">
                          Set Number
                        </h1>

                        <input
                          type="number"
                          id="setNumber"
                          onChange={(e) => {
                            setSetNumber(e.target.value);
                          }}
                          className="flex w-[90%] h-[48px] border-2 border-gray-400 mt-[8px] ml-[25px] pl-[16px] py-[12px] rounded-[8px] justify-left items-center"
                        />
                      </div>
                    )}

                    {props.isExport && (
                      <div className="flex flex-col justify-start items-left">
                        <h1 className="text-sm font-medium pt-[19px] pl-[29px]">
                          Select File Type
                        </h1>
                        <div
                          className="flex w-[90%] h-[48px] border-2 border-gray-400 mt-[8px] ml-[25px] pl-[16px] py-[12px] rounded-[8px] justify-left items-center"
                          onClick={() => {
                            setShowFileTypes(!showFileTypes);
                          }}
                        >
                          {!isPdfType ? (
                            <div className="inline-flex">
                              <IconDocx />
                              <div className="inline-flex items-center">
                                <h1 className="pl-[10px]">
                                  Microsoft Word document (*.DOCX)
                                </h1>
                                <svg
                                  className="ml-[147px] absolute right-[70px]"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M3.33301 5.66602L7.99967 10.3327L12.6663 5.66602"
                                    stroke="#9EA3AE"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          ) : (
                            <div className="inline-flex">
                              <IconPdf />
                              <h1 className="pl-[10px]">
                                Portable Document Format (*.PDF)
                              </h1>
                            </div>
                          )}
                        </div>

                        {showFileTypes && (
                          <div className="absolute top-[357px] left-[25px] z-20 flex items-center w-[90%] h-[62px] border-2 border-Purple rounded-[8px] bg-white drop-shadow-lg">
                            <ul className="text-sm font-medium w-full">
                              <button
                                className="inline-flex items-center w-full px-[8px] py-[8px] h-[44px]  hover:rounded-md"
                                onClick={() => {
                                  setShowFileTypes(false);
                                  setIsPdfType(false);
                                }}
                              >
                                <button
                                  className="inline-flex items-center w-full  pl-[16px] py-[11px] h-[44px] hover:bg-sky-100 hover:rounded-md"
                                  onClick={() => {
                                    setShowPart(false);
                                  }}
                                >
                                  <IconDocx />
                                  <h1 className="inline-flex items-center w-full mx-[8px] pl-[16px] py-[11px] h-[44px]  hover:rounded-md">
                                    Microsoft Word document (*.DOCX)
                                  </h1>
                                </button>
                              </button>
                              <div
                                style={{
                                  position: "absolute",
                                  // top: `${yPosition >= 500 ? -height - 25 : 25}px`,
                                  top: "50px",
                                  zIndex: "30",
                                  width: "244px",
                                  height: "max",
                                  left: "-3em",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              ></div>
                            </ul>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex gap-1 mt-[31px] mb-[47px] justify-evenly">
                  <button
                    className="font-semibold w-[41%] h-[44px] border-2 border-Gray rounded-md hover:bg-grey4 text-[16px]"
                    onClick={() => {
                      props.onClose();
                    }}
                  >
                    Cancel
                  </button>

                  {props.isExport ? (
                    <button
                      className="text-white bg-blue1 w-[41%] h-[44px] rounded-md hover:bg-blue2 text-[16px] font-semibold"
                      onClick={async () => {
                        gaEventTracker(
                          "export exercise",
                          isPdfType ? "PDF" : "DOC"
                        );
                        await exportExerciseHandler(
                          props.exercise,
                          isPdfType,
                          part,
                          setNumber
                        );
                        props.onClose();
                      }}
                    >
                      Export
                    </button>
                  ) : (
                    <button
                      className="text-white bg-blue1 w-[41%] h-[44px] rounded-md hover:bg-blue2 text-[16px] font-semibold"
                      onClick={() => {
                        if (props.onConfirm) {
                          props.onConfirm(part);
                        }
                        props.onClose();
                      }}
                    >
                      Confirm
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div />
          </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-[10] bg-black"></div>
    </div>
  );
};
