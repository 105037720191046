import { Navigate, useLocation } from "react-router-dom";
import { useAppContext } from "../context/context";
import { Auth } from "aws-amplify";
import { LoadingPopup } from "./PopupWindow";

export default function AuthenticatedRoute({ children }: { children: any }) {
  const { pathname, search } = useLocation();
  const { isAuthenticated, setName, setUserId, userId } = useAppContext();
  const getUserNameAndId = async () => {
    const user = await Auth.currentAuthenticatedUser();
    setName(user.attributes.preferred_username);
    setUserId(user.attributes.sub);
  };

  if (!isAuthenticated) {
    return <Navigate to={`/login?redirect=${pathname}${search}`} />;
  } else {
    getUserNameAndId();
  }
  if (!userId) {
    return <LoadingPopup action="Loading" />;
  } else {
    return children;
  }
}
