import React, { useRef } from "react";
// @ts-ignore
import { fromRange } from "xpath-range";
import { v4 as uuidv4 } from "uuid";

type Duration = {
  indexInWords: {
    startIndex: number;
    endIndex: number;
  };
};
interface HighlightProps {
  wordStartPos: number;
  wordEndPos: number;
  setWordStartPos: React.Dispatch<React.SetStateAction<number>>;
  setWordEndPos: React.Dispatch<React.SetStateAction<number>>;
  setHighlightedArticle: React.Dispatch<React.SetStateAction<string>>;
  splitedWords: Array<string>;
  editArticlePressed: boolean;
  highlightcolour?: string;
  // setHighlighted: React.Dispatch<React.SetStateAction<string>>;
  // Todo: convert start and end position to text for showing
}
interface UserAnnotationProps {
  text: string;
  colorName: string;
  decisionId: string;
  highlightId: string;
  startOffset: string;
  endOffset: string;
  startContainer: string;
  endContainer: string;
  typename: string;
}
interface OnHighlightActionProps {
  colorName: string;
  decision: { id: string };
  setShowToolTip: React.Dispatch<React.SetStateAction<boolean>>;
  userAnnotations: { saveUserAnnotation: (res: UserAnnotationProps) => void };
  el: HTMLDivElement | null;
}

export const getDuration = (result: any): Duration => {
  if (result?.startContainer && result?.endContainer) {
    let regex = /\/span\[(\d+)\]/;

    let startWordIndex = result.startContainer.match(regex)[1];
    let endWordIndex = result.endContainer.match(regex)[1];

    return {
      indexInWords: {
        startIndex: startWordIndex - 1,
        endIndex: endWordIndex - 1,
      },
    };
  }
  return {
    indexInWords: {
      startIndex: 0,
      endIndex: 0,
    },
  };
};

export function onHighlightAction({
  colorName,
  decision,
  setShowToolTip,
  userAnnotations,
  el,
}: OnHighlightActionProps) {
  let text = getSelection()?.toString() || "";

  if (!text || text === " ") {
    setShowToolTip(false);
    return;
  }

  const selection = getSelection();

  if (selection && selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    // const content = document.getElementById("decision-reader-body-root");
    const content = el;
    let xpath = null;

    let validRange = rangeCompareNode(range, content) === 2;

    if (content && validRange) {
      xpath = fromRange(range, content);
    }

    if (xpath) {
      try {
        let { startOffset, endOffset, startContainer, endContainer } =
          getXpathParameters(xpath);
        const highlightId = uuidv4();
        const typename = "highlight";
        userAnnotations.saveUserAnnotation({
          text,
          colorName,
          decisionId: decision.id,
          highlightId,
          startOffset,
          endOffset,
          startContainer,
          endContainer,
          typename,
        });
      } catch (e) {
        console.error("user highlight failed: ", e);
      }
    }
  }
  setShowToolTip(false);
}

function rangeCompareNode(range: any, node: any) {
  var nodeRange = node.ownerDocument.createRange();
  try {
    nodeRange.selectNode(node);
  } catch (e) {
    nodeRange.selectNodeContents(node);
  }
  var nodeIsBefore =
    range.compareBoundaryPoints(Range.START_TO_START, nodeRange) === 1;
  var nodeIsAfter =
    range.compareBoundaryPoints(Range.END_TO_END, nodeRange) === -1;

  if (nodeIsBefore && !nodeIsAfter) return 0;
  if (!nodeIsBefore && nodeIsAfter) return 1;
  if (nodeIsBefore && nodeIsAfter) return 2;

  return 3;
}
const getXpathParameters = (xpath: any) => {
  const startOffset = xpath.startOffset;
  const endOffset = xpath.endOffset;
  let startContainer = xpath.start;
  // /div[2]/p[7]/text()[1] -> /div[2]/p[7]/text[1]
  startContainer = startContainer.replace(/(|)/g, "");

  let endContainer = xpath.end;

  endContainer = endContainer.replace(/(|)/g, "");

  return { startOffset, endOffset, startContainer, endContainer };
};

export default function Highlight({
  wordStartPos,
  wordEndPos,
  highlightcolour = "yellow",
  setWordStartPos,
  setWordEndPos,
  setHighlightedArticle,
  splitedWords,
  editArticlePressed,
}: HighlightProps) {
  const ref = useRef<HTMLDivElement>(null);

  const saveUserAnnotation = (res: UserAnnotationProps) => {
    let d = getDuration(res);

    d && setWordStartPos(d.indexInWords.startIndex);
    d && setWordEndPos(d.indexInWords.endIndex);
    let highlightdummy = "";
    for (
      var i = d.indexInWords.startIndex;
      i < d.indexInWords.endIndex + 1;
      i++
    ) {
      highlightdummy = highlightdummy + splitedWords[i] + " ";
    }
    if (
      d.indexInWords.startIndex === 0 &&
      d.indexInWords.endIndex === splitedWords.length - 1
    ) {
      setHighlightedArticle(highlightdummy);
    } else {
      setHighlightedArticle(highlightdummy);
    }
  };
  //---------------------------------------------------------------------------------------------------
  const onMouseUp = () => {
    let el = ref.current;

    onHighlightAction({
      colorName: "Green",
      decision: { id: "" }, // ?
      setShowToolTip: () => {},
      userAnnotations: { saveUserAnnotation },
      el, // ref.current
    });
  };

  return (
    <div
      ref={ref}
      onMouseUp={onMouseUp}
      onMouseDown={() => {
        setWordEndPos(-1);
        setWordStartPos(-1);
      }}
      className={
        " relative row-span-5 h-[90%] shrink  font-normal text-sm break-words ml-[41px] mt-[20px] mb-[30px] pb-[50px] overflow-y-auto paragraph" +
        (editArticlePressed
          ? "  border rounded-lg border-blue1"
          : " border rounded-lg border-white1")
      }
    >
      {splitedWords.map((word: string, index: number) =>
        wordStartPos === -1 && wordEndPos === -1 ? (
          <span key={index}>
            {word}
            {(index !== word.length - 1 || word.length > 1) && " "}
          </span>
        ) : index >= wordStartPos && index <= wordEndPos ? (
          <mark key={index} className={" bg-bluetext"}>
            {word}
            {(index !== word.length - 1 || word.length > 1) && " "}
          </mark>
        ) : (
          <span key={index}>
            {word}
            {(index !== word.length - 1 || word.length > 1) && " "}
          </span>
        )
      )}
    </div>
  );
}
