import { useContext, createContext } from "react";
interface appContextType {
  isAuthenticated: boolean;
  userHasAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  emailError: string | null;
  setEmailError: React.Dispatch<React.SetStateAction<string | null>>;
  pwError: string | null;
  setPwError: React.Dispatch<React.SetStateAction<string | null>>;
  email: string | null;
  setEmail: React.Dispatch<React.SetStateAction<string | null>>;
  name: string | null;
  setName: React.Dispatch<React.SetStateAction<string | null>>;
  exerciseArticleInput: string | null;
  setExerciseArticleInput: React.Dispatch<React.SetStateAction<string | null>>;
  exerciseNameInput: string | null;
  setExerciseNameInput: React.Dispatch<React.SetStateAction<string | null>>;
  exerciseIdInput: string | null;
  setExerciseIdInput: React.Dispatch<React.SetStateAction<string | null>>;
  userId: string | null;
  setUserId: React.Dispatch<React.SetStateAction<string | null>>;
}
export const appContextDefaultValues: appContextType = {
  isAuthenticated: false,
  userHasAuthenticated: () => {},
  emailError: null,
  setEmailError: () => {},
  pwError: null,
  setPwError: () => {},
  email: null,
  setEmail: () => {},
  name: null,
  setName: () => {},
  exerciseArticleInput: null,
  setExerciseArticleInput: () => {},
  exerciseNameInput: null,
  setExerciseNameInput: () => {},
  exerciseIdInput: null,
  setExerciseIdInput: () => {},
  userId: null,
  setUserId: () => {},
};
export const AppContext = createContext<appContextType>(
  appContextDefaultValues
);
export function useAppContext() {
  return useContext(AppContext);
}
