import React from "react";
import cx from "classnames";

export interface NavButtonProps {
  text: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  className?: string;
  selectedClassName?: string;
  disabledClassName?: string;
  selected?: boolean;
  disabled?: boolean;
  onClick: () => void;
}

function NavButton({
  icon: Icon,
  text,
  className,
  selectedClassName = "",
  disabledClassName = "",
  selected = false,
  disabled = false,
  onClick,
}: NavButtonProps) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={cx(
        "flex justify-center items-center px-4 py-[10px] rounded-[32px] ",
        className,
        { [selectedClassName]: selected },
        { [disabledClassName]: disabled }
      )}
    >
      <Icon className="w-4 h-4 mr-1" />
      <h1 className="text-sm font-semibold">{text}</h1>
    </button>
  );
}

export default NavButton;
