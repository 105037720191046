import { useMemo } from "react";
import { countWord } from "../../utils";
import cx from "classnames";

interface WordCountProps {
  text: string | string[];
  className?: string;
}

function WordCount({ text, className = "" }: WordCountProps) {
  const count = useMemo(() => {
    if (Array.isArray(text))
      return text.reduce((accum, text) => accum + countWord(text), 0);

    return countWord(text);
  }, [text]);

  return (
    <div className="flex items-center gap-2 text-Fill/Dark/Dark2">
      Word count :
      <span
        className={cx(
          "flex justify-center py-[3px] px-3 rounded-full bg-blue1/[.1] text-blue1",
          className
        )}
      >
        {count}
      </span>
    </div>
  );
}

export default WordCount;
