import React from "react";
import DocumentsPage from "../components/DocumentPageLayout";

function PapersPage() {
  return (
    <DocumentsPage
      addNewExButtonShown={false}
      sortingButtonShown={false}
      selectionButtonShown={false}
      title="Paper Bank"
      pageContent="paperbank"
    />
  );
}

export default PapersPage;
