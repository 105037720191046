import React from "react";
import { ReactComponent as IconCross } from "../../icons/IconCross.svg";

export const UnSubscriptionPopUp: React.FC<{
  // text: UnSubscriptionPopUpProps;
  onClose: () => void;
  proceedAction: React.MouseEventHandler<HTMLButtonElement>;
}> = ({ onClose, proceedAction }) => {
  const handleCancel: React.MouseEventHandler<HTMLButtonElement> = (_) => {
    onClose();
  };
  return (
    <>
      <div className="justify-center items-center flex fixed inset-0 bg-black bg-opacity-50 z-10 outline-none focus:outline-none">
        <div className="rounded-20xl shadow-lg relative w-[33.75rem] bg-white outline-none focus:outline-none pb-7">
          <IconCross
            className="cursor-pointer w-[24px] h-[24px] text-grey hover:text-black absolute right-5 top-5"
            onClick={() => {
              onClose();
            }}
          />
          <div className="p-9">
            <div className="mb-3">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Try our one month trial and unlock full features!
              </h3>
            </div>
            <div className="text-sm leading-5 text-gray-500">
              <p>
                Thank you for using our app. To continue using this function and
                unlock all of our features, please subscribe to our service.
                With a subscription, you'll have access to generation of all of
                the questions. Don't miss out on the full experience.
              </p>
            </div>
          </div>
          <div className="flex flex-row">
            <button
              onClick={handleCancel}
              className="h-11 w-[14.375rem] border border-innerBorder text-black rounded-11xl font-medium mr-4 ml-10 hover:bg-grey4"
            >
              Cancel
            </button>
            <button
              onClick={proceedAction}
              className="h-11 w-[14.375rem] bg-CTA/Error hover:bg-[#ff5728] text-white rounded-11xl font-semibold"
            >
              Subscribe Now
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
