import {
  TBAnswerWithId,
  TBQuestionTitleAndStatementWithId,
} from "./QuestionTypes";

import { ReactComponent as IconTrashCan } from "../../icons/IconTrashCan.svg";
import cx from "classnames";

interface TableQuestionProps {
  TBQuestionTitleAndStatement: TBQuestionTitleAndStatementWithId;
  setTBQuestionTitleAndStatement: (
    value: TBQuestionTitleAndStatementWithId
  ) => void;
  TBAnswer: TBAnswerWithId;
  setTBAnswer: (value: TBAnswerWithId) => void;
  isEditing: boolean;
}
export default function TBContent({
  TBQuestionTitleAndStatement,
  setTBQuestionTitleAndStatement,
  TBAnswer,
  setTBAnswer,
  isEditing,
}: TableQuestionProps) {
  // handle answer statement change
  const handleAnswerStatementChange = (
    id: number | string,
    newValue: string
  ) => {
    const newArr = [...TBQuestionTitleAndStatement];
    const idxToChange = newArr.findIndex((item) => {
      return item.id === id;
    });
    if (idxToChange === -1) {
      return;
    }
    newArr[idxToChange].statement = newValue;
    setTBQuestionTitleAndStatement(newArr);
  };
  const handleAnswerDelete = (id: string | number) => {
    if (TBAnswer.length < 1) {
      return;
    }
    const newArr = TBAnswer.filter((item) => {
      return item.id !== id;
    });
    setTBAnswer(newArr);
  };
  const handleAnswerChange = (id: string | number, newValue: string) => {
    const newArr = [...TBAnswer];
    const idxToChange = newArr.findIndex((item) => {
      return item.id === id;
    });
    if (idxToChange === -1) {
      return;
    }
    newArr[idxToChange].answers = newValue;
    setTBAnswer(newArr);
  };
  const handleQuestionTitleAndStatementDelete = (id: string | number) => {
    if (TBQuestionTitleAndStatement.length < 1) {
      return;
    }
    const newArr = TBQuestionTitleAndStatement.filter((item) => {
      return item.id !== id;
    });
    setTBQuestionTitleAndStatement(newArr);
  };

  const handleQuestionTitleChange = (id: string | number, newValue: string) => {
    const newArr = [...TBQuestionTitleAndStatement];
    const idxToChange = newArr.findIndex((item) => {
      return item.id === id;
    });
    if (idxToChange === -1) {
      return;
    }
    newArr[idxToChange].title = newValue;
    setTBQuestionTitleAndStatement(newArr);
  };

  return (
    <table className="table-auto border-separate border-spacing-2 block overflow-x-auto w-full">
      <table className="table-layout: auto w-full">
        <div className="flex flex-col gap-2 px-4">
          <div className="w-full">
            {/* <tr> */}
            {TBQuestionTitleAndStatement.map(
              ({ id, title, statement }, index) => {
                // mtAnswer.map(answer=>{
                if (isEditing) {
                  return (
                    <div
                      key={id}
                      className="flex flex-row gap-x-1 w-full h-[60px] py-[12px] px-4 text-[14px]"
                    >
                      <span className="w-[40px] flex self-center justify-self-center ">
                        <div className="h-[22px] w-[22px] rounded-full bg-purple-50 text-[8.5px] grid place-content-center font-semibold">
                          {index + 1}
                        </div>
                      </span>

                      <input
                        pattern="[A-Z]"
                        className="pl-[10px] w-[30%] h-[3rem] border-2 border-Gray focus:outline-none focus:border-sky-400 rounded-md "
                        placeholder={` Title ${index + 1}`}
                        value={title}
                        onChange={(e) => {
                          return handleQuestionTitleChange(id, e.target.value);
                        }}
                      />

                      <input
                        className="pl-[10px] w-full h-[3rem] border-2 border-Gray focus:outline-none focus:border-sky-400 rounded-md "
                        placeholder={`  Question Statement ${index + 1}`}
                        value={statement}
                        onChange={(e) =>
                          handleAnswerStatementChange(id, e.target.value)
                        }
                      />

                      <div className="flex justify-evenly items-center gap-2">
                        <IconTrashCan
                          className={cx(
                            "cursor-pointer w-[1.2em] h-[1.2em] text-[#FF6628] hover:text-[#DE6628]",
                            {
                              "!text-grey cursor-not-allowed":
                                TBQuestionTitleAndStatement.length <= 1,
                            }
                          )}
                          title={
                            TBQuestionTitleAndStatement.length <= 1
                              ? "At least 2 choices are required"
                              : "Delete"
                          }
                          onClick={() => {
                            handleQuestionTitleAndStatementDelete(id);
                          }}
                        />
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <tr
                      className={`w-full flex flex-row border-solid border-black ${
                        index === TBQuestionTitleAndStatement.length - 1
                          ? "border-[1px]"
                          : "border-t-[1px] border-x-[1px]"
                      } gap-y-0`}
                      key={`tr-${id}`}
                    >
                      <td className="flex align-middle w-[35%] py-4 border-solid border-r-[1px] border-black">
                        {!title ? (
                          <div></div>
                        ) : (
                          <div
                            key={`title-${id}`}
                            className="flex flex-row w-full gap-x-1 text-[14px] font-bold pl-4 pr-2"
                          >
                            {title}
                          </div>
                        )}
                      </td>
                      <td className="flex align-middle w-[65%]  py-4 gap-y-0">
                        <div
                          key={`statement-${id}`}
                          className="flex flex-row w-full gap-x-1 text-[14px] pl-4 pr-4 "
                        >
                          {statement}
                        </div>
                      </td>
                    </tr>
                  );
                }
              }
            )}

            <h1 className="font-medium text-sm my-2">
              <br /> Answers
            </h1>
            {TBAnswer.map(({ id, answers }, index) => {
              if (isEditing) {
                return (
                  <div
                    key={id}
                    className="flex flex-row gap-x-1 w-full h-[4.5rem] py-[12px] px-4 text-[14px]"
                  >
                    <span className="w-[40px] flex self-center justify-self-center ">
                      <div className="h-[22px] w-[22px] rounded-full bg-purple-50 text-[8.5px] grid place-content-center font-semibold">
                        {index + 1}
                      </div>
                    </span>

                    <input
                      className="pl-[10px] w-full border-2 border-Gray focus:outline-none focus:border-sky-400 rounded-md "
                      placeholder={`  Answer ${index + 1}`}
                      value={answers}
                      onChange={(e) => handleAnswerChange(id, e.target.value)}
                    />

                    <div className="flex justify-evenly items-center gap-2">
                      <IconTrashCan
                        className={cx(
                          "cursor-pointer w-[1.2em] h-[1.2em] text-[#FF6628] hover:text-[#DE6628]",
                          {
                            "!text-grey cursor-not-allowed":
                              TBAnswer.length <= 1,
                          }
                        )}
                        title={
                          TBAnswer.length <= 1
                            ? "At least 2 choices are required"
                            : "Delete"
                        }
                        onClick={() => {
                          handleAnswerDelete(id);
                        }}
                      />
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={`div-${id}`}>
                    <tr className="w-full flex flex-row">
                      <td className="flex w-full align-middle">
                        {answers === "" ? (
                          <div></div>
                        ) : (
                          <div
                            key={id}
                            className="flex flex-row w-[100%] gap-x-1 text-[14px] pl-1 pr-2"
                          >
                            <div className="w-[6.5%] text-left">{`( ${
                              index + 1
                            } ) `}</div>
                            <div className="w-[93.5%]">{answers}</div>
                          </div>
                        )}
                      </td>
                    </tr>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </table>
    </table>
  );
}
