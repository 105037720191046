import React, { useEffect, useState } from "react";
import { ReactComponent as IconList } from "../../icons/toolbar/IconList.svg";
import { ReactComponent as IconEdit } from "../../icons/toolbar/IconEdit.svg";
import { ReactComponent as IconDuplicate } from "../../icons/toolbar/IconDuplicate-toolbar.svg";
import { ReactComponent as IconTrash } from "../../icons/toolbar/IconTrash.svg";
import { ReactComponent as IconUpArrow } from "../../icons/toolbar/IconUpArrow.svg";
import { ReactComponent as IconDownArrow } from "../../icons/toolbar/IconDownArrow.svg";
import { ReactComponent as IconShow } from "../../icons/IconShow.svg";
import { ReactComponent as IconCollapse } from "../../icons/IconCollapse.svg";

import SLQContent from "./SLQContent";
import MCQContent from "./MCQContent";
import TFContent from "./TFContent";
import SCContent from "./SCContent";
import Button from "../Button";
import MatchContent from "./MatchContent";
import FTBContent from "./FTBContent";
import cx from "classnames";

import {
  transformMCWithId,
  transformTFWithId,
  transformSCWithId,
  transformMTQuestionType,
  transFormMTQNAWithId,
  transFormMTQNAAWithId,
  transFormMTstatementWithId,
  newMCChoice,
  newTFAnswer,
  newScAnswer,
  createQuestion,
  newMTStatement,
  newMTQuestion,
  transformTBAnswerWithId,
  transformTBQuestionTitleAndStatementWithId,
  transformFTBAnswerStatement,
  newTBQuestionTitleAndStatement,
  newTBAnswer,
} from "./helper";

import {
  QuestionType,
  Question,
  questionTypeNameMap,
  ChoicesWithId,
  StatementWithId,
  AnswerWithId,
  MTAnswerWithId,
  MatchQNAwithId,
  questionCategoryMap,
  TBQuestionTitleAndStatementWithId,
  TBAnswerWithId,
} from "./QuestionTypes";
import DiscardChangesPopup from "../../pages/DiscardChangesPopup";
import { WarningPopUp } from "../PopupWindow";
import TBContent from "./TBContent";
import { FtbQuestion } from "../../api/codegen/API";

export enum ReorderDirection {
  UP,
  DOWN,
}

interface EditingBarProps {
  actions: {
    icon: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & { title?: string }
    >;
    disabled?: boolean;
    handler: () => void;
    className?: string;
    title?: string;
  }[];
  isEditing: boolean;
}

function EditingBar({ actions, isEditing }: EditingBarProps) {
  return (
    <div className="grid grid-cols-6 rounded-md overflow-hidden">
      {actions.map(
        ({ icon: Icon, disabled, handler, className, title }, index) => (
          <button
            key={index}
            title={title}
            className={cx(
              "border-r-2 border-gray-50 bg-white hover:bg-grey4",
              {
                "!bg-blue7": isEditing && className === "edit",
              },
              { "cursor-not-allowed text-grey6 hover:bg-white": disabled }
            )}
            onClick={handler}
          >
            <Icon className="w-[25px] h-[25px]" />
          </button>
        )
      )}
    </div>
  );
}

const questionTypeColorMap = {
  [QuestionType.LONG_QUESTION]: {
    bgColor: "bg-rose-100",
    textContainerColor: "bg-rose-200",
    textColor: "text-rose-500",
  },
  [QuestionType.SHORT_QUESTION]: {
    bgColor: "bg-pink-100",
    textContainerColor: "bg-pink-200",
    textColor: "text-pink-500",
  },
  [QuestionType.TRUE_FALSE]: {
    bgColor: "bg-orange-100",
    textContainerColor: "bg-orange-200",
    textColor: "text-orange-500",
  },
  [QuestionType.MULTIPLE_CHOICE]: {
    bgColor: "bg-yellow-100",
    textContainerColor: "bg-yellow-200",
    textColor: "text-yellow-500",
  },
  [QuestionType.SUMMARY_CLOZE]: {
    bgColor: "bg-lime-100",
    textContainerColor: "bg-lime-200",
    textColor: "text-lime-500",
  },
  [QuestionType.FILL_THE_BLANK]: {
    bgColor: "bg-green-100",
    textContainerColor: "bg-green-200",
    textColor: "text-green-500",
  },
  [QuestionType.TABLE]: {
    bgColor: "bg-blue-100",
    textContainerColor: "bg-blue-200",
    textColor: "text-blue-500",
  },
  [QuestionType.MATCHING]: {
    bgColor: "bg-purple-100",
    textContainerColor: "bg-pueple-200",
    textColor: "text-purple-500",
  },
};

export interface QuestionCardProps {
  question: Question;
  pos: { index: number; total: number };
  selectArticle: () => void;
  onUpdateQuestion: (question: Question) => void;
  onDiscardQuestion: (questionID: string) => void;
  onDuplicateQuestion: (question: Question) => void;
  onReorderQuestion: (direction: ReorderDirection) => void;
  isPaperBank: boolean;
}

// Start reading from this
function QuestionCard({
  question,
  pos,
  selectArticle,
  onUpdateQuestion,
  onDiscardQuestion,
  onDuplicateQuestion,
  onReorderQuestion,
  isPaperBank,
}: QuestionCardProps) {
  const [qStatement, setQStatement] = useState(question.questionStatement);
  const [isEditing, setIsEditing] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [mcChoices, setMcChoices] = useState([] as ChoicesWithId);
  // remember to convert back to real index
  const [mcAnswerId, setMcAnswerId] = useState<string | number>("");
  const [mtAnswerId] = useState<string | number>("testing");
  // remember to convert back to real index
  const [deleteQuestionWarning, setDeleteQuestionWarning] = useState(false);
  const [showConfirmReset, setShowConfirmReset] = useState(false);
  const [scAnswer, setScAnswer] = useState<AnswerWithId>([] as AnswerWithId);
  const [summary, setSummary] = useState<string | undefined>(
    question.scQuestionContent?.summary
  );
  const [questionItemType, setQuestionItemType] = useState<string | undefined>(
    question.matchQuestionContent?.questionItemType
  );
  const [mtAnswer, setMtAnswer] = useState<MTAnswerWithId>(
    [] as MTAnswerWithId
  );

  const [mtStatement, setMtStatement] = useState<StatementWithId>(
    [] as StatementWithId
  );
  const [MTQuestion, setMTQuestion] = useState<MatchQNAwithId>(
    [] as MatchQNAwithId
  );
  const [TBQuestionTitleAndStatement, setTBQuestionTitleAndStatement] =
    useState<TBQuestionTitleAndStatementWithId>(
      [] as TBQuestionTitleAndStatementWithId
    );
  const [TBAnswer, setTBAnswer] = useState<TBAnswerWithId>(
    [] as TBAnswerWithId
  );

  const [FTBAnswerStatement, setFTBAnswerStatement] = useState(
    question.ftbQuestionContent?.answerStatement ?? ""
  );

  const [FTBAnswer, setFTBAnswer] = useState(
    question.ftbQuestionContent?.answer ?? ""
  );
  const setMatchingQuestionForTransformation = () => {
    // Matching Question
    if (question.matchQuestionContent?.questionItemType) {
      const transformedMTType = transformMTQuestionType(
        question.matchQuestionContent.questionItemType
      );

      setQuestionItemType(transformedMTType);
    } else {
      setQuestionItemType("");
    }
    // Matching Question
    if (question.matchQuestionContent?.statement) {
      const transformedMTState = transFormMTstatementWithId(
        question.matchQuestionContent.statement
      );

      //above ok
      setMtStatement(transformedMTState);
    }
    // Matching Question
    if (question.matchQuestionContent?.questionItem) {
      const transformedMTQuestion = transFormMTQNAWithId(
        question.matchQuestionContent.questionItem,
        question.matchQuestionContent.answers
      );

      setMTQuestion(transformedMTQuestion);
    }
    // Matching Question
    if (question.matchQuestionContent?.answers) {
      const transformedMTAnswer = transFormMTQNAAWithId(
        question.matchQuestionContent.questionItem,
        question.matchQuestionContent.answers
      );

      setMtAnswer(transformedMTAnswer);
    }
  };
  const setFTBQuestionForTransformation = () => {
    if (question.ftbQuestionContent?.answerStatement) {
      const transformedFTBAnswerStatement = transformFTBAnswerStatement(
        question.ftbQuestionContent.answerStatement
      );
      setFTBAnswerStatement(transformedFTBAnswerStatement);
    }
  };
  const setTableQuestionForTransformation = () => {
    if (question.tableQuestionContent?.answers) {
      const transformedTBAnswer = transformTBAnswerWithId(
        question.tableQuestionContent.answers
      );

      setTBAnswer(transformedTBAnswer);
    }
    // Table
    if (question.tableQuestionContent?.questionTitle) {
      const newArr: { statement: string; title: string }[] = [];
      for (
        let i = 0;
        i < question.tableQuestionContent.answerStatement.length;
        i++
      ) {
        newArr.push({
          title: question.tableQuestionContent.questionTitle[i],
          statement: question.tableQuestionContent.answerStatement[i],
        });
      }
      const transformedTBQuestionTitleAndStatement =
        transformTBQuestionTitleAndStatementWithId(newArr);

      setTBQuestionTitleAndStatement(transformedTBQuestionTitleAndStatement);
    }
  };
  // Setup
  useEffect(() => {
    if (question.mcQuestionContent?.choices) {
      const transformedMC = transformMCWithId(
        question.mcQuestionContent.choices
      );
      setMcChoices(transformedMC);

      const answerIndex = question.mcQuestionContent.answer;
      setMcAnswerId(transformedMC[answerIndex].id as string);
    }
    // Table

    if (question.scQuestionContent?.answer) {
      const transformedSC = transformSCWithId(
        question.scQuestionContent.answer
      );
      setScAnswer(transformedSC);
    }
    setMatchingQuestionForTransformation();
    setTableQuestionForTransformation();
    setFTBQuestionForTransformation();
  }, []);

  const [tfAnsList, setTfAnsList] = useState(() => {
    if (!question.tfQuestionContent?.tfQuestionAnswerList) return [];

    return transformTFWithId(question.tfQuestionContent.tfQuestionAnswerList);
  });

  const [slqAnswer, setSlqAnswer] = useState(() => {
    if (question.questionType === QuestionType.SHORT_QUESTION)
      return question.sqQuestionContent?.answer ?? "";
    else if (question.questionType === QuestionType.LONG_QUESTION)
      return question.lqQuestionContent?.answer ?? "";
    else return null; // null for MC and TF
  });

  const colors = questionTypeColorMap[question.questionType];

  const getQuestionContent = (type: QuestionType) => {
    switch (type) {
      case QuestionType.LONG_QUESTION:
        return (
          <SLQContent
            placeholder="Write answer here"
            modelAnswer={slqAnswer}
            setModelAnswer={setSlqAnswer}
            isEditing={isEditing}
          />
        );
      case QuestionType.SHORT_QUESTION:
        return (
          <SLQContent
            placeholder="Write answer here"
            modelAnswer={slqAnswer}
            setModelAnswer={setSlqAnswer}
            isEditing={isEditing}
          />
        );
      case QuestionType.MATCHING:
        return (
          <MatchContent
            mtType={questionItemType}
            setMtType={setQuestionItemType}
            mtStatement={mtStatement}
            setMtStatement={setMtStatement}
            mtAnswer={mtAnswer}
            setMtAnswer={setMtAnswer}
            mtQuestion={MTQuestion}
            setMtQuestion={setMTQuestion}
            isEditing={isEditing}
          />
        );
      case QuestionType.FILL_THE_BLANK: {
        return (
          <FTBContent
            answer={FTBAnswer}
            answerStatement={FTBAnswerStatement}
            setAnswer={setFTBAnswer}
            setAnswerStatement={setFTBAnswerStatement}
            isEditing={isEditing}
            answerPlaceHolder="Write answer here"
            answerStatementPlaceHolder="Write the answer statement here"
          />
        );
      }

      case QuestionType.MULTIPLE_CHOICE:
        return (
          <MCQContent
            choices={mcChoices}
            setChoices={setMcChoices}
            answerId={mcAnswerId}
            setAnswerId={setMcAnswerId}
            isEditing={isEditing}
          />
        );
      case QuestionType.TRUE_FALSE:
        return (
          <TFContent
            qaList={tfAnsList}
            setQaList={setTfAnsList}
            isEditing={isEditing}
          />
        );
      case QuestionType.SUMMARY_CLOZE:
        return (
          <SCContent
            answers={scAnswer}
            setModelAnswer={setScAnswer}
            isEditing={isEditing}
          />
        );
      case QuestionType.TABLE:
        return (
          <TBContent
            TBAnswer={TBAnswer}
            setTBAnswer={setTBAnswer}
            TBQuestionTitleAndStatement={TBQuestionTitleAndStatement}
            setTBQuestionTitleAndStatement={setTBQuestionTitleAndStatement}
            isEditing={isEditing}
          />
        );
    }
  };

  const addMCAnswer = () => {
    const newArr = mcChoices.concat(newMCChoice());
    setMcChoices(newArr);
  };

  const addTFAnswer = () => {
    const newArr = tfAnsList.concat(newTFAnswer());
    setTfAnsList(newArr);
  };

  const addSCAnswer = () => {
    const newArr = scAnswer.concat(newScAnswer());
    setScAnswer(newArr);
  };

  const addMTStatement = () => {
    const newArr = mtStatement.concat(newMTStatement());
    setMtStatement(newArr);
  };

  const addMTQuestion = () => {
    const newArr = MTQuestion.concat(newMTQuestion());
    setMTQuestion(newArr);
  };
  const addTBQuestionTitleAndStatement = () => {
    const newArr = TBQuestionTitleAndStatement.concat(
      newTBQuestionTitleAndStatement()
    );

    setTBQuestionTitleAndStatement(newArr);
  };
  const addTBAnswer = () => {
    const newArr = TBAnswer.concat(newTBAnswer());

    setTBAnswer(newArr);
  };

  const addAnswer = (type: QuestionType) => {
    if (type === QuestionType.MULTIPLE_CHOICE) addMCAnswer();
    else if (type === QuestionType.TRUE_FALSE) addTFAnswer();
    else if (type === QuestionType.SUMMARY_CLOZE) addSCAnswer();
    else if (type === QuestionType.TABLE) addTBAnswer();
  };

  const addQuestion = (type: QuestionType) => {
    if (type === QuestionType.MATCHING) {
      addMTQuestion();
    } else if (type === QuestionType.TABLE) {
      addTBQuestionTitleAndStatement();
    }
  };

  const handleDone = () => {
    setIsEditing(false);

    onUpdateQuestion(
      createQuestion(
        question,
        qStatement,
        mcChoices,
        mcAnswerId,
        mtAnswerId,
        slqAnswer,
        tfAnsList,
        scAnswer,
        summary ?? "",
        mtAnswer,
        mtStatement,
        MTQuestion,
        TBQuestionTitleAndStatement,
        TBAnswer,
        FTBAnswerStatement,
        FTBAnswer,
        questionItemType ?? ""
      )
    );
  };
  // }

  const handleDuplicate = () => {
    onDuplicateQuestion(
      createQuestion(
        question,
        qStatement,
        mcChoices,
        mcAnswerId,
        mtAnswerId,
        slqAnswer,
        tfAnsList,
        scAnswer,
        summary,
        mtAnswer,
        mtStatement,
        MTQuestion,
        TBQuestionTitleAndStatement,
        TBAnswer,

        FTBAnswerStatement,
        FTBAnswer,
        questionItemType ?? "",
        true
      )
    );
  };

  const handleReset = () => {
    setQStatement(question.questionStatement);

    setTfAnsList(() => {
      if (!question.tfQuestionContent?.tfQuestionAnswerList) return [];

      return transformTFWithId(question.tfQuestionContent.tfQuestionAnswerList);
    });
    setSlqAnswer(() => {
      if (question.questionType === QuestionType.SHORT_QUESTION)
        return question.sqQuestionContent?.answer ?? "";
      else if (question.questionType === QuestionType.LONG_QUESTION)
        return question.lqQuestionContent?.answer ?? "";
      else return null; // null for MC and TF
    });
    if (question.ftbQuestionContent?.answerStatement) {
      setFTBAnswerStatement(
        transformFTBAnswerStatement(question.ftbQuestionContent.answerStatement)
      );
    } else {
      {
        question.ftbQuestionContent && setFTBAnswerStatement("");
      }
    }
    if (question.ftbQuestionContent?.answer) {
      setFTBAnswer(question.ftbQuestionContent.answer);
    } else {
      {
        question.ftbQuestionContent && setFTBAnswer("");
      }
    }
    if (question.mcQuestionContent?.choices) {
      const transformedMC = transformMCWithId(
        question.mcQuestionContent.choices
      );
      setMcChoices(transformedMC);

      const answerIndex = question.mcQuestionContent.answer;
      setMcAnswerId(transformedMC[answerIndex].id);
    }

    if (question.scQuestionContent?.answer) {
      const transformedSC = transformSCWithId(
        question.scQuestionContent.answer
      );
      setScAnswer(transformedSC);
    }
    setMatchingQuestionForTransformation();
    setTableQuestionForTransformation();
    setSummary(question.scQuestionContent?.summary);
  };

  const editActions = [
    {
      icon: IconUpArrow,
      handler: () => onReorderQuestion(ReorderDirection.UP),
      title: "Move up",
      disabled: pos.index === 0,
    },
    {
      icon: IconDownArrow,
      handler: () => onReorderQuestion(ReorderDirection.DOWN),
      title: "Move down",
      disabled: pos.index === pos.total - 1,
    },
    {
      icon: IconList,
      handler: () => selectArticle(),
      title: "Show highlighted text",
    },
    {
      icon: IconEdit,
      handler: () => {
        setIsEditing(true);
      },
      className: "edit",
      title: "Edit this question",
    },
    {
      icon: IconDuplicate,
      handler: () => {
        handleDuplicate();
      },
      title: "Duplicate this question",
    },
    {
      icon: IconTrash,
      handler: () => {
        setDeleteQuestionWarning(true);
      },
      title: "Delete this question",
    },
  ];

  const canAddAnswer =
    question.questionType === QuestionType.MULTIPLE_CHOICE ||
    question.questionType === QuestionType.TRUE_FALSE ||
    question.questionType === QuestionType.SUMMARY_CLOZE ||
    question.questionType === QuestionType.TABLE;

  const canAddStatement = question.questionType === QuestionType.MATCHING;

  const canAddQuestion = question.questionType === QuestionType.MATCHING;

  const canAddTableStatement = question.questionType === QuestionType.TABLE;
  return (
    <>
      {showConfirmReset && (
        <DiscardChangesPopup
          onClose={() => setShowConfirmReset(false)}
          onDiscard={handleReset}
        />
      )}
      {deleteQuestionWarning && (
        <>
          <WarningPopUp
            text={{
              title: "Are you sure you want to delete this question?",
              proceedText: "Delete",
            }}
            onClose={() => {
              setDeleteQuestionWarning(false);
            }}
            proceedAction={() => {
              onDiscardQuestion(question.questionID);
            }}
          />
        </>
      )}
      <div
        className={cx(
          "flex flex-col gap-[20px] mx-auto w-[90%] max-w-[700px] bg-white1 rounded-18xl shadow-lg overflow-hidden",
          { "pb-[25px]": !isEditing }
        )}
      >
        <div
          className={`${colors.bgColor} cardbar rounded-md px-[20px] py-[12px]`}
        >
          <div className="flex flex-row justify-between gap-2 items-center">
            <div
              className={`px-[8px] py-[3px] max-w-[300px] ${colors.textContainerColor} rounded-md`}
            >
              <h1 className={`${colors.textColor} font-semibold`}>
                {(question.questionAbility === undefined ||
                  question.questionAbility === null
                  ? ""
                  : questionCategoryMap[question.questionAbility].Ability +
                  " ") +
                  questionTypeNameMap[question.questionType] +
                  (question.questionAbility === undefined ||
                    question.questionAbility === null
                    ? ""
                    : questionCategoryMap[question.questionAbility].Category)}
              </h1>
            </div>
            {!isPaperBank && (
              <EditingBar actions={editActions} isEditing={isEditing} />
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2 px-4">
          {isEditing ? (
            <>
              <div className="flex flex-row items-center justify-center">
                <h1 className="font-medium text-sm mr-auto">
                  Question {pos.index + 1}
                </h1>
                <button
                  onClick={() => {
                    setCollapse(!collapse);
                  }}
                  className="w-[24px] h-[24px] ml-auto mr-[23px]"
                >
                  {collapse ? <IconCollapse /> : <IconShow />}
                </button>
              </div>
              <input
                className="w-full pl-4 py-4 border-2 border-Gray focus:outline-none focus:border-sky-400 rounded-md font-medium text-base"
                placeholder="Question Statement"
                value={qStatement}
                onChange={(e) => setQStatement(e.target.value)}
              />
              {(question.questionType === QuestionType.SUMMARY_CLOZE && !collapse) ? (
                <input
                  className="w-full pl-4 py-4 border-2 border-Gray focus:outline-none focus:border-sky-400 rounded-md font-medium text-sm leading-6"
                  placeholder="Question Statement"
                  value={summary}
                  onChange={(e) => setSummary(e.target.value)}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <div>
              <div className="flex flex-row items-start">
                <div className="flex flex-row text-[18px] text-black font-bold w-[90%]">
                  <h1 className="ml-[1px]">
                    {pos.index + 1}.&nbsp;&nbsp;&nbsp;
                  </h1>
                  <div className="flex flex-col gap-y-2 w-full h-fit">
                    <h1>{qStatement}</h1>
                    {(question.questionType === QuestionType.SUMMARY_CLOZE && !collapse) ? (
                      <>
                        <div className="w-[95%] h-fit bg-white rounded-md p-3.4 font-medium leading-6 mt-2">
                          <h1 className="w-full text-justify font-medium text-sm leading-6">
                            {summary}
                          </h1>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <button
                  onClick={() => {
                    setCollapse(!collapse);
                  }}
                  className="w-[24px] h-[24px] ml-auto mr-[23px]"
                >
                  {collapse ? <IconCollapse /> : <IconShow />}
                </button>
              </div>
            </div>
          )}
        </div>
        {!collapse && getQuestionContent(question.questionType)}

        {isEditing && (
          <div className="flex justify-between gap-3 items-center bg-gray-50 py-4 px-[10px] text-sm">
            <>
              {canAddAnswer ? (
                <button
                  className="text-blue1 hover:text-blue2 font-semibold"
                  onClick={() => addAnswer(question.questionType)}
                >
                  + &nbsp; Add Answer
                </button>
              ) : (
                // placeholder for justify between
                <div></div>
              )}

              {canAddStatement ? (
                <button
                  className="text-blue1 hover:text-blue2 font-semibold w-[60%] h-[60%]"
                  onClick={() => addQuestion(question.questionType)}
                >
                  + &nbsp; Add Question
                </button>
              ) : (
                // placeholder for justify between
                <div></div>
              )}
              {canAddTableStatement ? (
                <button
                  className="text-blue1 hover:text-blue2 font-semibold w-[60%] h-[60%]"
                  onClick={() => addTBQuestionTitleAndStatement()}
                >
                  + &nbsp; Add Statement
                </button>
              ) : (
                <div></div>
              )}
              {canAddQuestion ? (
                <button
                  className="text-blue1 hover:text-blue2 font-semibold w-[60%] h-[60%]"
                  onClick={() => addMTStatement()}
                >
                  + &nbsp; Add Statement
                </button>
              ) : (
                // placeholder for justify between
                <div></div>
              )}
            </>
            <div className="flex gap-3 items-center">
              <Button
                onClick={() => setShowConfirmReset(true)}
                text="Discard Changes"
                className="px-[24px] py-[6px] text-grey border-2 border-grey hover:text-white hover:bg-red hover:bg-opacity-70"
              />
              <Button
                onClick={handleDone}
                text="Done"
                className={cx(
                  "px-[16px] py-[6px] bg-blue1 text-white border border-transparent hover:bg-blue2 hover:border-grey",
                  {
                    "!text-grey cursor-not-allowed ":
                      mtStatement.length < MTQuestion.length,
                  }
                )}
                disable={mtStatement.length < MTQuestion.length}
              />
              { }
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default QuestionCard;
