/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const allExercisesByUser = /* GraphQL */ `
  query AllExercisesByUser($nextToken: String, $count: Int) {
    allExercisesByUser(nextToken: $nextToken, count: $count) {
      exercises {
        id
        userId
        exerciseName
        questions {
          questionID
          questionType
          questionState
          questionAbility
          questionStatement
          mcQuestionContent {
            choices
            answer
          }
          tfQuestionContent {
            tfQuestionAnswerList {
              tfQuestion
              tfAnswer
            }
          }
          sqQuestionContent {
            answer
          }
          lqQuestionContent {
            answer
          }
          scQuestionContent {
            summary
            numBlanks
            answer
          }
          ftbQuestionContent {
            answerStatement
            answer
          }
          matchQuestionContent {
            questionItemType
            questionItem
            numItems
            statement
            answers
          }
          tableQuestionContent {
            questionTitle
            numItems
            answerStatement
            answers
          }
          weirdQuestionContent {
            weirdQuestionAnswerList {
              weirdQuestion
              weirdAnswer
            }
          }
          articleContext
          highlightStart
          highlightEnd
          startGenerationTime
        }
        article
        articleName
        createdDate
        lastAccessedDate
        version
        published
      }
      nextToken
    }
  }
`;
export const getParticularExercise = /* GraphQL */ `
  query GetParticularExercise($exerciseID: String) {
    getParticularExercise(exerciseID: $exerciseID) {
      exercises {
        id
        userId
        exerciseName
        questions {
          questionID
          questionType
          questionState
          questionAbility
          questionStatement
          mcQuestionContent {
            choices
            answer
          }
          tfQuestionContent {
            tfQuestionAnswerList {
              tfQuestion
              tfAnswer
            }
          }
          sqQuestionContent {
            answer
          }
          lqQuestionContent {
            answer
          }
          scQuestionContent {
            summary
            numBlanks
            answer
          }
          ftbQuestionContent {
            answerStatement
            answer
          }
          matchQuestionContent {
            questionItemType
            questionItem
            numItems
            statement
            answers
          }
          tableQuestionContent {
            questionTitle
            numItems
            answerStatement
            answers
          }
          weirdQuestionContent {
            weirdQuestionAnswerList {
              weirdQuestion
              weirdAnswer
            }
          }
          articleContext
          highlightStart
          highlightEnd
          startGenerationTime
        }
        article
        articleName
        createdDate
        lastAccessedDate
        version
        published
      }
      nextToken
    }
  }
`;
export const getParticularExerciseConsistent = /* GraphQL */ `
  query GetParticularExerciseConsistent($exerciseID: String, $userID: String) {
    getParticularExerciseConsistent(exerciseID: $exerciseID, userID: $userID) {
      exercises {
        id
        userId
        exerciseName
        questions {
          questionID
          questionType
          questionState
          questionAbility
          questionStatement
          mcQuestionContent {
            choices
            answer
          }
          tfQuestionContent {
            tfQuestionAnswerList {
              tfQuestion
              tfAnswer
            }
          }
          sqQuestionContent {
            answer
          }
          lqQuestionContent {
            answer
          }
          scQuestionContent {
            summary
            numBlanks
            answer
          }
          ftbQuestionContent {
            answerStatement
            answer
          }
          matchQuestionContent {
            questionItemType
            questionItem
            numItems
            statement
            answers
          }
          tableQuestionContent {
            questionTitle
            numItems
            answerStatement
            answers
          }
          weirdQuestionContent {
            weirdQuestionAnswerList {
              weirdQuestion
              weirdAnswer
            }
          }
          articleContext
          highlightStart
          highlightEnd
          startGenerationTime
        }
        article
        articleName
        createdDate
        lastAccessedDate
        version
        published
      }
      nextToken
    }
  }
`;
export const getCheckOutSession = /* GraphQL */ `
  query GetCheckOutSession($successUrl: String!) {
    getCheckOutSession(successUrl: $successUrl)
  }
`;
export const getPublishedExercises = /* GraphQL */ `
  query GetPublishedExercises($nextToken: String, $count: Int) {
    getPublishedExercises(nextToken: $nextToken, count: $count) {
      exercises {
        id
        userId
        exerciseName
        questions {
          questionID
          questionType
          questionState
          questionAbility
          questionStatement
          mcQuestionContent {
            choices
            answer
          }
          tfQuestionContent {
            tfQuestionAnswerList {
              tfQuestion
              tfAnswer
            }
          }
          sqQuestionContent {
            answer
          }
          lqQuestionContent {
            answer
          }
          scQuestionContent {
            summary
            numBlanks
            answer
          }
          ftbQuestionContent {
            answerStatement
            answer
          }
          matchQuestionContent {
            questionItemType
            questionItem
            numItems
            statement
            answers
          }
          tableQuestionContent {
            questionTitle
            numItems
            answerStatement
            answers
          }
          weirdQuestionContent {
            weirdQuestionAnswerList {
              weirdQuestion
              weirdAnswer
            }
          }
          articleContext
          highlightStart
          highlightEnd
          startGenerationTime
        }
        article
        articleName
        createdDate
        lastAccessedDate
        version
        published
      }
      nextToken
    }
  }
`;
export const getParticularPublishedExercise = /* GraphQL */ `
  query GetParticularPublishedExercise($exerciseID: String) {
    getParticularPublishedExercise(exerciseID: $exerciseID) {
      exercises {
        id
        userId
        exerciseName
        questions {
          questionID
          questionType
          questionState
          questionAbility
          questionStatement
          mcQuestionContent {
            choices
            answer
          }
          tfQuestionContent {
            tfQuestionAnswerList {
              tfQuestion
              tfAnswer
            }
          }
          sqQuestionContent {
            answer
          }
          lqQuestionContent {
            answer
          }
          scQuestionContent {
            summary
            numBlanks
            answer
          }
          ftbQuestionContent {
            answerStatement
            answer
          }
          matchQuestionContent {
            questionItemType
            questionItem
            numItems
            statement
            answers
          }
          tableQuestionContent {
            questionTitle
            numItems
            answerStatement
            answers
          }
          weirdQuestionContent {
            weirdQuestionAnswerList {
              weirdQuestion
              weirdAnswer
            }
          }
          articleContext
          highlightStart
          highlightEnd
          startGenerationTime
        }
        article
        articleName
        createdDate
        lastAccessedDate
        version
        published
      }
      nextToken
    }
  }
`;
export const getMyTrialUser = /* GraphQL */ `
  query GetMyTrialUser {
    getMyTrialUser {
      userId
      isIntern
      email
      name
    }
  }
`;
export const getAllTrialUsers = /* GraphQL */ `
  query GetAllTrialUsers($nextToken: String, $count: Int) {
    getAllTrialUsers(nextToken: $nextToken, count: $count) {
      trialUsers {
        userId
        isIntern
        email
        name
      }
      nextToken
    }
  }
`;
