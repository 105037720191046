import React from 'react'
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import NavButton, { NavButtonProps } from "./NavButton";
import { ReactComponent as IconDashboard } from "../../icons/IconDashboard.svg";
import { ReactComponent as IconExercise } from "../../icons/IconExercise.svg";
import { ReactComponent as IconPapers } from "../../icons/IconPapers.svg";


function HeaderNavButtonGrp() {
    const nav = useNavigate();
    const location = useLocation();
    async function navDashboard() {
        nav("/dashboard");
      }
      async function navPapers() {
        nav("/paperbank");
      }
    let buttons = [
        {
          text: "Dashboard",
          icon: IconDashboard,
          onClick: navDashboard,
          selected:
            location.pathname.startsWith("/dashboard") ||
            location.pathname === "/",
        },
        {
          text: "Exercise",
          icon: IconExercise,
          onClick: () => {},
          selected: location.pathname.startsWith("/passage"),
        },
        {
          text: "Paper Bank",
          icon: IconPapers,
          onClick: navPapers,
          selected: location.pathname.startsWith("/paperbank"),
        }
      ];
  return (
    <div className="flex items-center">
        {buttons.map((nav, index) => (
          <NavButton
            key={index}
            {...nav}
            className="text-grey"
            selectedClassName="!text-blue1 bg-blue1 bg-opacity-[15%]"
          />
        ))}
        
    </div>
  )
}

export default HeaderNavButtonGrp