import { useState, useRef, Fragment } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { useAppContext } from "../../context/context";
import NavButton, { NavButtonProps } from "./NavButton";
import { ReactComponent as IconUser } from "../../icons/IconUser.svg";
import { ReactComponent as IconLogout } from "../../icons/IconLogout.svg";
import { ReactComponent as IconDropdown } from "../../icons/IconDropdown.svg";
import { Transition, Menu } from "@headlessui/react";
import classNames from "classnames";
import HeaderNavButtonGrp from "./HeaderNavButtonGrp";

interface HeaderProps {
  logo?: string;
  // navs: NavButtonProps[];
  onLogout: () => void;
}

function Header({
  logo = "/READilyLogoWhite.svg",
  // navs,
  onLogout,
}: HeaderProps) {
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const { name } = useAppContext();

  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => {
    setIsShowDropdown(false);
  });

  return (
    <div className="h-28 w-full bg-Dark flex px-16 lg:px-32 items-center justify-center">
      <img src={logo} alt="READily logo" className="w-40 h-12 mr-auto" />
      <div className="flex items-center">
        <HeaderNavButtonGrp></HeaderNavButtonGrp>
      </div>

      <Menu as="div" className="ml-auto">
        <div>
          <Menu.Button
            as="div"
            className="flex flex-row rounded-full w-fit h-11"
          >
            <IconUser className="mt-1.5 ml-1.5 text-blue1" />
            <div className="cursor-pointer pl-2.5 flex flex-col">
              <p className="text-white text-sm w-fit h-5 mt-1.5 font-semibold">
                {name}
              </p>
              <p className="text-darkblue text-xs w-fit h-5">Free Tier</p>
            </div>
            <div className="relative">
              <IconDropdown className="w-8 h-6 mt-2 text-[#8F92A1]" />
            </div>
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                    <div
                      className={classNames(
                        active ? "cursor-pointer bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                      onClick={onLogout}
                    >
                      {/* <IconLogout className="text-grey w-5 h-5" /> */}
                      Logout
                    </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    href={process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL!}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    My Subscription
                  </a>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      {/* <button
        ref={dropdownRef}
        type="button"
        className="ml-auto"
        onClick={() => {
          setIsShowDropdown(!isShowDropdown);
        }}
      >
        <div className="flex flex-row rounded-full w-fit h-11">
          <IconUser className="mt-1.5 ml-1.5 text-blue1" />
          <div className="pl-2.5 flex flex-col">
            <p className="text-white text-sm w-fit h-5 mt-1.5 font-semibold">
              {name}
            </p>
            <p className="text-darkblue text-xs w-fit h-5">Free Tier</p>
          </div>
          <div className="relative">
            <IconDropdown className="w-8 h-6 mt-2 text-[#8F92A1]" />
            {isShowDropdown && (
              <div className="cursor-pointer absolute z-10 top-[110%] right-0 bg-white hover:bg-grey4 border-solid border-Purple w-40 h-12 rounded-16xl border-2 flex items-center">
                <ul className="text-sm font-medium" onClick={onLogout}>
                  <div className="flex gap-2 pl-5">
                    <IconLogout className="text-grey w-5 h-5" />
                    Logout
                  </div>
                </ul>
                <ul className="text-sm font-medium">
                  <a href={process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL}>
                    <div className="flex gap-2 pl-5">My Subscription</div>
                  </a>
                </ul>
              </div>
            )}
          </div>
        </div>
      </button> */}
    </div>
  );
}

export default Header;
