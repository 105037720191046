import * as apiType from "../api/codegen/API";
import { QuestionAbility } from "../api/codegen/API";

import { ReactComponent as Comments } from "../assets/comments.svg";
import { ReactComponent as IconCross } from "../icons/IconCross.svg";

import QuestionAbilitySummaryBox from "./QuestionAbilitySummaryBox";
const THEMATIC_GROUP: apiType.QuestionAbility[] = [
  QuestionAbility.THEMATIC,
  QuestionAbility.THEMATIC_PURPOSE,
  QuestionAbility.THEMATIC_TEXT_TAKEN_FROM,
];
const MAIN_IDEA_GROUP: apiType.QuestionAbility[] = [
  QuestionAbility.MAIN_IDEA,
  QuestionAbility.MAIN_IDEA_ONE_WORD_PHRASE,
];
const FACTUAL_GROUP: apiType.QuestionAbility[] = [
  QuestionAbility.FACTUAL,
  QuestionAbility.FACTUAL_INCORRECT_STATEMENT,
  QuestionAbility.FACTUAL_RECOMMENDATION,
  QuestionAbility.FACTUAL_SPECIFIC_INFORMATION,
  QuestionAbility.FACTUAL_WRITER_THOUGHTS,
  QuestionAbility.FACTUAL_TOPIC,
  QuestionAbility.FACTUAL_NUMBER,
  QuestionAbility.FACTUAL_EVIDENCE,
  QuestionAbility.FACTUAL_TERM_IDENFITICATION,
];
const VOCABULARY_GROUP: apiType.QuestionAbility[] = [
  QuestionAbility.VOCABULARY,
  QuestionAbility.VOCABULARY_MEAN,
  QuestionAbility.VOCABULARY_PURPOSE,
  QuestionAbility.VOCABULARY_SYNONYM,
  QuestionAbility.VOCABULARY_PHRASE_SYNONYM,
  QuestionAbility.VOCABULARY_SUPPORT_STATEMENT,
  QuestionAbility.VOCABULARY_PHRASE_MEANING,
];
const REFERENCE_GROUP: apiType.QuestionAbility[] = [
  QuestionAbility.REFERENCE_MC,
  QuestionAbility.REFERENCE_SQ,
  QuestionAbility.REFERENCE_FILLING,
];
const INFERENCE_GROUP: apiType.QuestionAbility[] = [
  QuestionAbility.INFERENCE_SQ,
  QuestionAbility.INFERENCE_TF,
  QuestionAbility.INFERENCE_PHRASE_SUGGESTION,
  QuestionAbility.INFERENCE_ACCORDING_TO,
  QuestionAbility.INFERENCE_PHRASE_RELATIONS,
  QuestionAbility.INFERENCE_IMPLY,
  QuestionAbility.INFERENCE_EVIDENCE,
  QuestionAbility.INFERENCE_PHRASE_MEANING,
];
const HIGHER_ORDER_GROUP: apiType.QuestionAbility[] = [
  QuestionAbility.HIGHER_ORDER,
  QuestionAbility.HIGHER_ORDER_OPINION_WITH_EVIDENCE,
  QuestionAbility.HIGHER_ORDER_INFER_THOUGHT,
  QuestionAbility.HIGHER_ORDER_COMPARISONS,
];
const FIGURATIVE_LANG_GROUP: apiType.QuestionAbility[] = [
  QuestionAbility.FIGURATIVE_LANG,
  QuestionAbility.FIGURATIVE_LANG_PHRASE_MEANING,
  QuestionAbility.FIGURATIVE_LANG_METAPHOR,
  QuestionAbility.FIGURATIVE_LANG_INFER_LINES,
];
const VIEW_ATTITUDE_GROUP: apiType.QuestionAbility[] = [
  QuestionAbility.VIEW_ATTITUDE,
  QuestionAbility.VIEW_ATTITUDE_TONE,
  QuestionAbility.VIEW_ATTITUDE_MEAN,
  QuestionAbility.VIEW_ATTITUDE_PERSON_INVOLVED,
  QuestionAbility.VIEW_ATTITUDE_VIEW,
  QuestionAbility.VIEW_ATTITUDE_ATTITUDE,
  QuestionAbility.VIEW_ATTITUDE_IMPLY,
  QuestionAbility.VIEW_ATTITUDE_LETTER_ONCE,
];
const RELATIONSHIP_GROUP: apiType.QuestionAbility[] = [
  QuestionAbility.RELATIONSHIP,
  QuestionAbility.RELATIONSHIP_PROBLEM_AND_SOLUTION,
  QuestionAbility.RELATIONSHIP_CAUSES,
  QuestionAbility.RELATIONSHIP_REASONING,
];

export interface SelectedQuestion {
  type: string;
  ability: string | null | undefined;
  abilityGroup: string | null | undefined;
}

export const QuestionSummary: React.FC<{
  onClose: () => void;
  questions: Array<{ id: string } & apiType.Question> | null;
}> = ({ onClose, questions }) => {
  if (!questions) return <></>;
  console.log("These are all the questions");
  console.log(questions);
  return (
    <div className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto mx-auto max-w-3xl">
        <div className="rounded-20xl shadow-lg relative w-[775px] h-[800px] divide-y divide-innerBorder bg-white outline-none focus:outline-none">
          <div className="rounded-20xl shadow-lg relative w-[775px] h-[800px] divide-y divide-innerBorder bg-white outline-none focus:outline-none">
            <div className="flex flex-row h-[68px] justify-center items-center">
              <h1 className="font-bold text-sm ml-[42px] mr-auto">Summary</h1>
              <IconCross
                className="ml-auto mr-[21px] cursor-pointer w-[24px] h-[24px] text-grey hover:text-black"
                onClick={() => onClose()}
              />
            </div>
            <div className="h-[159px] flex flex-row items-center">
              <Comments className="w-[140px] h-[105px] ml-[47px]" />
              <div className="ml-[39px]">
                <h1 className="font-semibold text-[28px] mb-[6px]">
                  Question Summary
                </h1>
                <p className="font-medium text-[12px] text-Fill/Dark/Dark2">
                  Organize your exercise and make easily accessible by saving in
                  a folder.<br></br>
                  Exercise may contain mcq, long question, short question etc{" "}
                  <br></br>
                  Click on question type to show ability
                </p>
              </div>
            </div>
            <div className="ml-[29px] h-[473px]">
              <div className="grid grid-cols-2 gap-y-4">
                <QuestionAbilitySummaryBox
                  title="General"
                  questions={questions.filter(
                    ({ questionAbility }) => !questionAbility
                  )}
                  bgColor="#EBADB4"
                  textColor="#573A3D"
                />
                <QuestionAbilitySummaryBox
                  title="Factual"
                  abilityGroup={FACTUAL_GROUP}
                  bgColor="#FACCCC"
                  textColor="#7A4040"
                  questions={questions.filter(
                    ({ questionAbility }) =>
                      questionAbility && FACTUAL_GROUP.includes(questionAbility)
                  )}
                />
                <QuestionAbilitySummaryBox
                  title="Vocabulary"
                  abilityGroup={VOCABULARY_GROUP}
                  bgColor="#F5DCC8"
                  textColor="#75563D"
                  questions={questions.filter(
                    ({ questionAbility }) =>
                      questionAbility &&
                      VOCABULARY_GROUP.includes(questionAbility)
                  )}
                />
                <QuestionAbilitySummaryBox
                  title="Inference"
                  abilityGroup={INFERENCE_GROUP}
                  bgColor="#F3EDC7"
                  textColor="#736B3C"
                  questions={questions.filter(
                    ({ questionAbility }) =>
                      questionAbility &&
                      INFERENCE_GROUP.includes(questionAbility)
                  )}
                />
                <QuestionAbilitySummaryBox
                  title="View attitude"
                  abilityGroup={VIEW_ATTITUDE_GROUP}
                  bgColor="#D7F0C0"
                  textColor="#537038"
                  questions={questions.filter(
                    ({ questionAbility }) =>
                      questionAbility &&
                      VIEW_ATTITUDE_GROUP.includes(questionAbility)
                  )}
                />
                <QuestionAbilitySummaryBox
                  title="Thematic"
                  abilityGroup={THEMATIC_GROUP}
                  questions={questions.filter(
                    ({ questionAbility }) =>
                      questionAbility &&
                      THEMATIC_GROUP.includes(questionAbility)
                  )}
                  bgColor="#B8EDC6"
                  textColor="#356E44"
                />
                <QuestionAbilitySummaryBox
                  title="Reference"
                  abilityGroup={REFERENCE_GROUP}
                  bgColor="#BDEBE8 "
                  textColor="#366B68"
                  questions={questions.filter(
                    ({ questionAbility }) =>
                      questionAbility &&
                      REFERENCE_GROUP.includes(questionAbility)
                  )}
                />
                <QuestionAbilitySummaryBox
                  title="High order"
                  abilityGroup={HIGHER_ORDER_GROUP}
                  bgColor="#BCD4E8"
                  textColor="#355169"
                  questions={questions.filter(
                    ({ questionAbility }) =>
                      questionAbility &&
                      HIGHER_ORDER_GROUP.includes(questionAbility)
                  )}
                />
                <QuestionAbilitySummaryBox
                  title="Figurative Language"
                  abilityGroup={FIGURATIVE_LANG_GROUP}
                  bgColor="#CED8F3"
                  textColor="#435075"
                  questions={questions.filter(
                    ({ questionAbility }) =>
                      questionAbility &&
                      FIGURATIVE_LANG_GROUP.includes(questionAbility)
                  )}
                />
                <QuestionAbilitySummaryBox
                  title="Relationship"
                  abilityGroup={RELATIONSHIP_GROUP}
                  bgColor="#B3B7E5"
                  textColor="#313566"
                  questions={questions.filter(
                    ({ questionAbility }) =>
                      questionAbility &&
                      RELATIONSHIP_GROUP.includes(questionAbility)
                  )}
                />
                <QuestionAbilitySummaryBox
                  title="Main idea"
                  abilityGroup={MAIN_IDEA_GROUP}
                  bgColor="#C3AFE3"
                  textColor="#432F63"
                  questions={questions.filter(
                    ({ questionAbility }) =>
                      questionAbility &&
                      MAIN_IDEA_GROUP.includes(questionAbility)
                  )}
                />
              </div>

              <button
                onClick={onClose}
                className="flex justify-center items-center w-[164px] h-11 bg-blue1 text-white font-semibold text-[16px] absolute bottom-[25px] right-[32px] rounded-11xl hover:bg-blue2"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="opacity-60 fixed inset-0 z-40 bg-black"></div> */}
    </div>
  );
};
