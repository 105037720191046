import { TfAnswer, TFQAListWithId } from "./QuestionTypes";
import { RadioButton } from "../Button";
import { ReactComponent as IconMCOrder } from "../../icons/IconMCOrder.svg";
import { ReactComponent as IconTrashCan } from "../../icons/IconTrashCan.svg";
import { ReactSortable } from "react-sortablejs";
import cx from "classnames";

interface TFAnswerProps {
  qaList: TFQAListWithId;
  setQaList: (value: TFQAListWithId) => void;
  isEditing: boolean;
}

function TFContent({ qaList, setQaList, isEditing }: TFAnswerProps) {
  const setRadio = (id: number | string, value: TfAnswer) => {
    const newArr = [...qaList];
    // find qaList item by id
    const idxToChange = newArr.findIndex((item) => item.id === id);

    if (idxToChange === -1) return;
    newArr[idxToChange].tfAnswer = value;
    setQaList(newArr);
  };

  const setQuestion = (id: number | string, value: string) => {
    const newArr = [...qaList];
    // find qaList item by id
    const idxToChange = newArr.findIndex((item) => item.id === id);
    if (idxToChange === -1) return;
    newArr[idxToChange].tfQuestion = value;
    setQaList(newArr);
  };

  const handleDelete = (id: number | string) => {
    // won't delete if too few items
    if (qaList.length <= 1) return;
    const newArr = qaList.filter((item) => item.id !== id);
    setQaList(newArr);
  };

  return (
    <table className="table-auto border-separate border-spacing-2 px-2 block overflow-x-auto w-full">
      <thead>
        <tr className="text-sm leading-6 text-grey2">
          <th></th>
          <th></th>
          <th
            className={cx("w-[99%] text-start font-normal", {
              "pl-5": !isEditing,
            })}
          >
            Statement
          </th>
          <th className="font-normal">True</th>
          <th className="font-normal">False</th>
          <th className="font-normal">NG</th>
          <th></th>
        </tr>
      </thead>

      <ReactSortable
        tag="tbody"
        animation={150}
        handle=".handle"
        list={qaList}
        setList={setQaList}
      >
        {qaList.map(({ id, tfQuestion, tfAnswer }, index) => {
          if (isEditing) {
            return (
              <tr key={index}>
                <td>
                  <IconMCOrder className="handle cursor-ns-resize" />
                </td>
                <td>
                  <div className="h-[22px] w-[22px] rounded-full bg-purple-50 text-[8.5px] grid place-content-center font-semibold">
                    {String.fromCharCode(index + 97)}
                  </div>
                </td>
                <td className="flex">
                  <input
                    className="flex-1 border-2 py-[8px] px-[12px] border-Gray focus:outline-none focus:border-sky-400 rounded-md text-[14px] font-normal"
                    placeholder="Write statement"
                    value={tfQuestion}
                    onChange={(e) => setQuestion(id, e.target.value)}
                  />
                </td>
                <td>
                  <RadioButton
                    isSelected={tfAnswer === TfAnswer.TRUE}
                    onSelect={() => setRadio(id, TfAnswer.TRUE)}
                  />
                </td>
                <td>
                  <RadioButton
                    isSelected={tfAnswer === TfAnswer.FALSE}
                    onSelect={() => setRadio(id, TfAnswer.FALSE)}
                  />
                </td>
                <td>
                  <RadioButton
                    isSelected={tfAnswer === TfAnswer.NOT_GIVEN}
                    onSelect={() => setRadio(id, TfAnswer.NOT_GIVEN)}
                  />
                </td>
                <td>
                  <IconTrashCan
                    className={cx(
                      "cursor-pointer w-[1.2em] h-[1.2em] text-[#FF6628] hover:text-[#DE6628]",
                      { "!text-grey cursor-not-allowed": qaList.length <= 1 }
                    )}
                    title={
                      qaList.length <= 1
                        ? "At least 1 question is required"
                        : "Delete"
                    }
                    onClick={() => handleDelete(id)}
                  />
                </td>
              </tr>
            );
          } else {
            return (
              <tr key={index}>
                <td></td>
                <td></td>
                <td className="flex gap-3 text-sm leading-6">
                  <div>{String.fromCharCode(index + 97)}.</div>
                  <div>{tfQuestion}</div>
                </td>
                <td>
                  {tfAnswer === TfAnswer.TRUE ? (
                    <img src="/TFdone.svg" alt="" />
                  ) : (
                    <div className="h-[1.25em] w-[1.25em] rounded-full bg-transparent ml-[2px] border-2 border-grey10"></div>
                  )}
                </td>
                <td>
                  {tfAnswer === TfAnswer.FALSE ? (
                    <img src="/TFdone.svg" alt="" />
                  ) : (
                    <div className="h-[1.25em] w-[1.25em] rounded-full bg-transparent ml-[2px] border-2 border-grey10"></div>
                  )}
                </td>
                <td>
                  {tfAnswer === TfAnswer.NOT_GIVEN ? (
                    <img src="/TFdone.svg" alt="" />
                  ) : (
                    <div className="h-[1.25em] w-[1.25em] rounded-full bg-transparent ml-[2px] border-2 border-grey10"></div>
                  )}
                </td>
                <td></td>
              </tr>
            );
          }
        })}
      </ReactSortable>
    </table>
  );
}

export default TFContent;
