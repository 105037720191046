import { SelectedQuestion } from "./QuestionSummary";
import {
  questionCategoryMap,
  QuestionType,
  QuestionAbility,
  Question,
} from "./QuestionType/QuestionTypes";
interface QuestionSummaryProp {
  selected: SelectedQuestion[];
}

const questionTypeNameMap: Record<QuestionType, string> = {
  [QuestionType.LONG_QUESTION]: "LQ",
  [QuestionType.SHORT_QUESTION]: "SQ",
  [QuestionType.TRUE_FALSE]: "TF",
  [QuestionType.MULTIPLE_CHOICE]: "MCQ",
  [QuestionType.SUMMARY_CLOZE]: "SC",
  [QuestionType.FILL_THE_BLANK]: "FTB",
  [QuestionType.MATCHING]: "MT",
  [QuestionType.TABLE]: "TB",
};

interface QuestionTypeSummaryItemProps {
  type?: QuestionType;
  ability?: QuestionAbility;
  questions: Question[];
}

const QuestionTypeSummary = ({
  type,
  ability,
  questions,
}: QuestionTypeSummaryItemProps) => {
  if (questions.length === 0) return null;
  const typeDisplay = type
    ? questionTypeNameMap[type]
    : questionTypeNameMap[questions[0].questionType];
  const abilityDisplay = ability
    ? "- " +
      questionCategoryMap[ability].Ability +
      questionCategoryMap[ability].Category
    : "";
  // console.log("This is QuestionTypeSummary");
  // console.log("This is question");
  // console.log(questions);
  return (
    <div className="flex w-[282px] rounded-[0.5rem] h-[40px] items-center">
      <h1 className="flex-[18] font-semibold ml-4 mr-[6px] items-center">{`${typeDisplay} ${abilityDisplay}`}</h1>
      <div className="flex-[2] w-6 h-6 font-semibold text-[7px] rounded-full bg-white mr-[15px] ml-auto flex justify-center items-center">
        {questions.length}
      </div>
    </div>
  );
};
export default QuestionTypeSummary;
