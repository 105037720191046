import { Auth } from "aws-amplify";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useOnClickOutside } from "usehooks-ts";
import { useAppContext } from "../context/context";
import { ReactComponent as IconDelete } from "../icons/IconDelete.svg";
import { ReactComponent as IconDuplicate } from "../icons/IconDuplicate.svg";
import { ReactComponent as IconExport } from "../icons/IconExport.svg";
import { ReactComponent as IconShare } from "../icons/IconShare.svg";
import { ReactComponent as IconShareOff } from "../icons/IconShareOff.svg";
import "./DocumentPageLayout.css";
import { ExportandGeneratePopUp } from "./PopupWindow/ExportandGeneratePopup";

import { ToastContainer } from "react-toastify";
import {
  mutationCreateExerciseUser,
  mutationDeleteExerciseUser,
  mutationUpsertPublish,
  queryAllExerciseForUser,
  queryGetMyTrialUser,
  queryPublishedExercises,
} from "../api/api";
import { Exercise } from "../api/codegen/API";
import useAnalyticsEventTracker from "../useAnalyticsEventTracker";
import { countWord } from "../utils";

import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import { LoadingPopup } from "./PopupWindow";

interface DashboardExercise extends Exercise {
  checked: boolean;
}

interface PageComponents {
  addNewExButtonShown: boolean;
  sortingButtonShown: boolean;
  selectionButtonShown: boolean;
  title: string;
  pageContent: string;
}

function DocumentsPage({
  addNewExButtonShown,
  sortingButtonShown,
  selectionButtonShown,
  title,
  pageContent,
}: PageComponents) {
  const nav = useNavigate();
  const location = useLocation();
  const gaEventTracker = useAnalyticsEventTracker("Dashboard");

  const { userHasAuthenticated, setEmailError, setPwError, name } =
    useAppContext();

  const [sortByShown, setSortByShown] = useState(false);
  const [linkToExercise, setlinkToExercise] = useState("");
  const [exerciseName, setExerciseName] = useState("");
  const [selectShown, setSelectShown] = useState(false);
  const [sortBy, setSortBy] = useState("Last Modified");
  const [selectModeShown, setSelectModeShown] = useState(false);
  const [warningShown, setWarningShown] = useState(false);
  const [newExerciseShown, setNewExerciseShown] = useState(false);
  const [textAreaField, setTextAreaField] = useState("");
  const [listOfExercise, setListOfExercise] =
    useState<Array<DashboardExercise> | null>(null);
  const [discardWarningShown, setDiscardWarningShown] = useState(false);
  const [articleName, setArticleName] = useState("");
  const [actionChange, setActionChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [numOfSelected, setNumOfSelected] = useState(0);
  const [exportExercise, setExportExercise] = useState<Exercise | null>(null);
  const [exportPopUpShown, setExportPopUpShown] = useState(false);

  const toggleSelect = () => {
    setSelectShown(!selectShown);
  };
  const toggleSelectAll = () => {
    const updatedList: DashboardExercise[] | null = listOfExercise
      ? listOfExercise.map((exercise) => {
          return { ...exercise, checked: true };
        })
      : null;
    setListOfExercise(updatedList);
  };
  const handleResetSelect = () => {
    listOfExercise?.forEach((exercise) => {
      exercise.checked = false;
    });
  };
  const handlePasteFromClipboard = async () => {
    gaEventTracker("Paste from clipboard");
    const pasteData = await navigator.clipboard.readText();
    setTextAreaField(pasteData);
  };

  const handleTextAreaFieldChange = (event: any) => {
    const textData = event.target.value;
    setTextAreaField(textData);
  };
  const [isIntern, setIsIntern] = useState(false);

  async function fetch() {
    const data = await queryGetMyTrialUser();
    setIsIntern(data.data?.getMyTrialUser?.isIntern ?? false);
  }
  useEffect(() => {
    fetch();
  }, []);

  const SortByButton = ({ sortType }: { sortType: string }) => {
    return (
      <button
        onClick={() => {
          setSortBy(sortType);
          handleSortBy(sortType);
          setSortByShown(false);
        }}
        className="pl-2 h-11 w-[13.5rem] mx-2 hover:bg-Purple hover:text-blue1 hover:rounded-16xl align-middle inline-flex items-center"
      >
        {sortType}
      </button>
    );
  };

  const ListOfExerciseSetup = (exercises: Exercise[] | null) => {
    const list: DashboardExercise[] | null = exercises
      ? exercises.map((exercise) => {
          return { ...exercise, checked: false };
        })
      : null;
    if (list) {
      const sortedData = [...list].sort((a, b) => {
        return a.lastAccessedDate.localeCompare(b.lastAccessedDate);
      });
      setListOfExercise(sortedData.reverse());
    }
  };

  async function handleCreateExercise(event: any) {
    gaEventTracker("create exercise");
    event.preventDefault();
    let idForPassagePage = "";
    try {
      await mutationCreateExerciseUser(
        exerciseName,
        textAreaField,
        articleName
      ).then((res) => {
        if (res.data?.createExercise?.id !== undefined) {
          idForPassagePage = res.data?.createExercise?.id;
        }
      });
      nav("/passage/" + idForPassagePage);
      setActionChange(!actionChange);
    } catch (e: any) {
      console.error(e);
    }

    setTextAreaField("done");
  }

  async function handleBulkDelete(event: any) {
    gaEventTracker("bulk delete exercise", "delete");
    event.preventDefault();
    setIsLoading(true);
    try {
      const checkedExercises = listOfExercise?.filter(
        (exercise) => exercise.checked === true
      );
      const deletePromises: Promise<void>[] =
        checkedExercises?.map((exercise) =>
          mutationDeleteExerciseUser(exercise.id)
        ) ?? [];
      await Promise.all(deletePromises);
    } catch (e: any) {
      console.error(e);
    } finally {
      setActionChange(!actionChange);
      setIsLoading(false);
    }
  }

  async function handlePublishExercise(
    event: any,
    exerciseId: string,
    isPublished: string
  ) {
    gaEventTracker("Set publish");
    event.preventDefault();
    setIsLoading(true);
    try {
      await mutationUpsertPublish(exerciseId, isPublished);
      setActionChange(!actionChange);
    } catch (error: any) {
      console.log(error);
    }
  }
  // async function handleItemDeleteExercise(event: any, exerciseID: string) {
  //   gaEventTracker("delete exercise", "delete");
  //   event.preventDefault();
  //   setIsLoading(true);
  //   try {
  //     await mutationDeleteExerciseUser(exerciseID);
  //     setActionChange(!actionChange);
  //     setSelectModeShown(true);
  //   } catch (e: any) {
  //     console.error(e);
  //     setIsLoading(false);
  //   }
  // }
  const handleSortBy = (sortType: string) => {
    gaEventTracker("sort exercise by", sortType);
    if (listOfExercise) {
      if (sortType === "Name") {
        const sortedData = [...listOfExercise].sort((a, b) => {
          if (a.exerciseName < b.exerciseName) return -1;
          if (a.exerciseName > b.exerciseName) return 1;
          return 0;
        });
        setListOfExercise(sortedData);
      } else if (sortType === "Date Created") {
        const sortedData = [...listOfExercise].sort((a, b) => {
          return a.createdDate.localeCompare(b.createdDate);
        });
        setListOfExercise(sortedData);
      } else {
        const sortedData = [...listOfExercise].sort((a, b) => {
          return a.lastAccessedDate.localeCompare(b.lastAccessedDate);
        });
        setListOfExercise(sortedData.reverse());
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);

    if (pageContent === "dashboard") {
      setlinkToExercise("/passage/");
      queryAllExerciseForUser()
        .then((res) => {
          try {
            if (res.data?.allExercisesByUser) {
              const exercises = res.data.allExercisesByUser.exercises;
              ListOfExerciseSetup(exercises);
              setIsLoading(false);
            }
          } catch (e: any) {
            console.error(e);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    } else if (pageContent === "paperbank") {
      setlinkToExercise("/paperbank/");
      queryPublishedExercises()
        .then((res) => {
          try {
            if (res.data?.getPublishedExercises) {
              const exercises = res.data.getPublishedExercises.exercises;
              ListOfExerciseSetup(exercises);
              setIsLoading(false);
            }
          } catch (e: any) {
            console.error(e);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  }, [actionChange]);

  async function handleLogout() {
    gaEventTracker("logout", "user");
    setIsLoading(true);
    await Auth.signOut();
    userHasAuthenticated(false);
    setEmailError(null);
    setPwError(null);
    setIsLoading(false);
    nav("/login");
  }

  const ArticleSnippet = ({
    exerciseID,
    name,
    lastModifiedDate,
    onCheck,
    check,
    isPublished,
    setIsPublished,
  }: {
    exerciseID: string;
    name: string;
    lastModifiedDate: string;
    check: boolean;
    onCheck: (check: boolean) => void;
    isPublished: string | undefined | null;
    setIsPublished: (
      event: any,
      exerciseId: string,
      IsPublished: string
    ) => void;
  }) => {
    const [moreActionsShown, setMoreActionsShown] = useState(false);
    const [selectExercise, setSelectExercise] = useState(false);

    async function handleDuplicateExercise(event: any) {
      gaEventTracker("duplicate exercise", "duplicate");
      event.preventDefault();
      setIsLoading(true);
      try {
        if (listOfExercise) {
          const originExercise = listOfExercise.filter(
            (exercises) => exercises.id === exerciseID
          )[0];
          if (originExercise.articleName) {
            if (originExercise.questions.length === 0) {
              //no questions
              await mutationCreateExerciseUser(
                originExercise.exerciseName,
                originExercise.article,
                originExercise.articleName
              );
            } else {
              //all fields
              await mutationCreateExerciseUser(
                originExercise.exerciseName,
                originExercise.article,
                originExercise.articleName,
                originExercise.questions
              );
            }
          } else {
            if (originExercise.questions.length === 0) {
              await mutationCreateExerciseUser(
                originExercise.exerciseName,
                originExercise.article
              );
            } else {
              await mutationCreateExerciseUser(
                originExercise.exerciseName,
                originExercise.article,
                "",
                originExercise.questions
              );
            }
          }
        }
      } catch (e: any) {
        console.error(e);
      }
      setActionChange(!actionChange);
    }
    const ref = useRef(null);
    useOnClickOutside(ref, () => setMoreActionsShown(false));

    function three_dots(isIntern: boolean) {
      if (isIntern == false && title == "Paper Bank") {
      } else {
        return (
          <button
            onClick={() => {
              setMoreActionsShown((prev) => !prev);
            }}
          >
            <div
              className={
                "absolute w-5 h-5 flex justify-center z-10" +
                (moreActionsShown
                  ? " bg-blue1/[.2] rounded-full top-[15px] right-[19px]"
                  : selectExercise
                  ? " top-[15px] right-[19px]"
                  : " top-4 right-5")
              }
            >
              <img src="/more.svg" alt="" />
            </div>
          </button>
        );
      }
    }

    function choicesInThreeDots() {
      if (isIntern == true && title == "Paper Bank") {
        return (
          <div
            className="absolute z-20 top-[43px] left-[85px] bg-white border-solid border-Purple w-32  rounded-11xl border flex items-center"
            style={{ height: `${isIntern ? 4 : 8}rem` }}
          >
            <ul className="text-sm font-medium mx-auto my-auto z-20">
              {isIntern && (
                <div className="flex flex-row mx-auto">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setIsPublished(e, exerciseID, "false");
                    }}
                    type="button"
                    className="inline-flex flex-row align-middle items-center hover:bg-Purple hover:text-blue1 w-[7.5rem] h-11 rounded-11xl pl-4"
                  >
                    <IconShareOff className="hover:text-blue1 w-4 h-4" />
                    <h1 className="pl-2.5">Unshare</h1>
                  </button>
                </div>
              )}
            </ul>
          </div>
        );
      } else {
        return (
          <div
            className="absolute z-20 top-[43px] left-[85px] bg-white border-solid border-Purple w-32  rounded-11xl border flex items-center"
            style={{ height: `${isIntern ? 15 : 9}rem` }}
          >
            <ul className="text-sm font-medium mx-auto my-auto z-20">
              <div className="flex flex-row mx-auto">
                <button
                  onClick={() => {
                    setExportExercise(
                      listOfExercise &&
                        listOfExercise.filter(
                          (exercise) => exercise.id === exerciseID
                        )[0]
                    );
                    setExportPopUpShown(true);
                  }}
                  type="button"
                  className="flex-row align-middle inline-flex items-center hover:bg-Purple hover:text-blue1 w-[7.5rem] h-11 rounded-11xl pl-4"
                >
                  <IconExport className="hover:text-blue1 w-4 h-4" />
                  <h1 className="pl-2.5">Export</h1>
                </button>
              </div>
              <div className="flex flex-row mx-auto">
                <button
                  onClick={(e) => {
                    handleDuplicateExercise(e);
                  }}
                  type="button"
                  className="inline-flex flex-row align-middle items-center hover:bg-Purple hover:text-blue1 w-[7.5rem] h-11 rounded-11xl pl-4"
                >
                  <IconDuplicate className="hover:text-blue1 w-4 h-4" />
                  <h1 className="pl-2.5">Duplicate</h1>
                </button>
              </div>
              {/* EDIT HERE... */}
              {isIntern && (
                <div className="flex flex-row mx-auto">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setIsPublished(e, exerciseID, "true");
                    }}
                    type="button"
                    className="inline-flex flex-row align-middle items-center hover:bg-Purple hover:text-blue1 w-[7.5rem] h-11 rounded-11xl pl-4"
                  >
                    <IconShare className="hover:text-blue1 w-4 h-4" />
                    <h1 className="pl-2.5">Share</h1>
                  </button>
                </div>
              )}
              {/* EDIT HERE */}
              {isIntern && (
                <div className="flex flex-row mx-auto">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setIsPublished(e, exerciseID, "false");
                    }}
                    type="button"
                    className="inline-flex flex-row align-middle items-center hover:bg-Purple hover:text-blue1 w-[7.5rem] h-11 rounded-11xl pl-4"
                  >
                    <IconShareOff className="hover:text-blue1 w-4 h-4" />
                    <h1 className="pl-2.5">Unshare</h1>
                  </button>
                </div>
              )}
              <div className="flex flex-row mx-auto">
                <button
                  type="button"
                  onClick={(e) => {
                    setListOfExercise(
                      listOfExercise?.map((exercise, ind) => {
                        if (exercise.id === exerciseID) {
                          return { ...exercise, checked: true };
                        }
                        return exercise;
                      }) ?? null
                    );
                    setWarningShown(true);
                    setMoreActionsShown(false);
                  }}
                  className="inline-flex flex-row align-middle items-center hover:bg-Purple hover:text-blue1 w-[7.5rem] h-11 rounded-11xl pl-4"
                >
                  <IconDelete className="hover:text-blue1 w-4 h-4" />
                  <h1 className="pl-2.5">Delete</h1>
                </button>
              </div>
            </ul>
          </div>
        );
      }
    }

    return (
      <div className="w-[15.375rem] h-[18.75rem]">
        <div
          className={
            "relative w-[15.375rem] h-[14.25rem] z-10 rounded-t-11xl align-middle inline-flex items-center" +
            (moreActionsShown || selectExercise
              ? " border-2 border-blue1 bg-blue3"
              : " border border-grey4 bg-white")
          }
        >
          <div
            className={
              moreActionsShown || selectExercise ? "pl-[19px]" : "pl-5"
            }
          >
            <div className="flex flex-row">
              {selectModeShown && (
                <input
                  type="checkbox"
                  checked={check}
                  onChange={(event) => {
                    onCheck(event.target.checked);
                  }}
                  className="absolute top-4 form-checkbox border rounded text-blue1 w-5 h-5"
                />
              )}
              <div ref={ref}>
                <>
                  {three_dots(isIntern)}

                  {moreActionsShown && (
                    // EDIT HERE: The height must be var
                    <div className="z-20">{choicesInThreeDots()}</div>
                  )}
                </>
              </div>
            </div>
            {selectModeShown ? (
              <>
                <img
                  onClick={() => {
                    onCheck(!check);
                  }}
                  src="/exercise.svg"
                  className={
                    "absolute cursor-pointer" +
                    (moreActionsShown || selectExercise
                      ? " top-[52px]"
                      : " top-[53px]")
                  }
                  alt=""
                />
              </>
            ) : (
              <Link to={linkToExercise + exerciseID}>
                <img
                  src="/exercise.svg"
                  className={
                    "absolute" +
                    (moreActionsShown || selectExercise
                      ? " top-[52px]"
                      : " top-[53px]")
                  }
                  alt=""
                />
              </Link>
            )}
          </div>
        </div>
        {selectModeShown ? (
          <div
            onClick={() => {
              onCheck(!check);
            }}
            className={
              "cursor-pointer relative text-sm font-medium w-[15.375rem] h-[4.5rem] rounded-b-11xl" +
              (moreActionsShown || selectExercise
                ? " bg-blue1"
                : " bg-innerBorder")
            }
          >
            <div className="pl-6 pr-6">
              <p
                className={
                  "absolute top-[15px] left-[24px] text-sm pb-0.5 w-[167px] truncate ..." +
                  (moreActionsShown || selectExercise
                    ? " text-white"
                    : " text-Fill/Dark/MainDark")
                }
              >
                {name}
              </p>
              <p
                className={
                  "absolute top-[37px] left-[24px] text-[10px] pt-0.5" +
                  (moreActionsShown || selectExercise
                    ? " text-lightblue"
                    : "  text-Fill/Dark/Dark2")
                }
              >
                LAST ACCESS{" "}
                {new Date(lastModifiedDate)?.toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })}
              </p>
            </div>
          </div>
        ) : (
          <Link to={linkToExercise + exerciseID}>
            <div
              className={
                "relative text-sm font-medium w-[15.375rem] h-[4.5rem] z-1 rounded-b-11xl" +
                (moreActionsShown || selectExercise
                  ? " bg-blue1"
                  : " bg-innerBorder")
              }
            >
              <div className="pl-6 pr-6">
                <p
                  className={
                    "absolute top-[15px] left-[24px] text-sm pb-0.5 w-[167px] truncate ..." +
                    (moreActionsShown || selectExercise
                      ? " text-white"
                      : " text-Fill/Dark/MainDark")
                  }
                >
                  {name}
                </p>
                <p
                  className={
                    "absolute top-[37px] left-[24px] text-[10px] pt-0.5" +
                    (moreActionsShown || selectExercise
                      ? " text-lightblue"
                      : "  text-Fill/Dark/Dark2")
                  }
                >
                  LAST ACCESS{" "}
                  {new Date(lastModifiedDate)?.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </p>
              </div>
            </div>
          </Link>
        )}
      </div>
    );
  };

  const ref1 = useRef(null);

  useOnClickOutside(ref1, () => {
    setSortByShown(false);
  });

  return (
    <div>
      <ToastContainer />
      {isLoading && <LoadingPopup action={"Loading"} />}
      {warningShown && (
        <>
          <div className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto mx-auto max-w-3xl">
              <div className="e rounded-20xl shadow-lg relative w-[33.75rem] h-[16.375rem] bg-white outline-none focus:outline-none">
                <button
                  className="absolute right-5 top-5"
                  onClick={() => {
                    setWarningShown(false);
                  }}
                >
                  <img src="close.svg" alt="" />
                </button>
                <div className="flex flex-row rounded-t p-5 mb-2">
                  <img src="deleteWarning.svg" alt="" className="mt-12 mr-9" />
                  <div className="mt-14">
                    <h1 className="text-xl font-semibold mb-3.5">
                      {numOfSelected <= 1
                        ? "Delete Exercise"
                        : "Delete " + numOfSelected + " Exercises"}
                    </h1>
                    <p className="text-CTA/Disabled font-normal text-sm">
                      {numOfSelected <= 1
                        ? "All the questions inside the exercise will be lost. Are you sure you want to delete these exercise?"
                        : "All the questions inside the exercises will be lost. Are you sure you want to delete these exercises?"}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row">
                  <button
                    onClick={() => {
                      setWarningShown(false);
                    }}
                    className="h-11 w-[14.375rem] border border-innerBorder text-black rounded-11xl font-medium mr-4 ml-10"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={(e) => {
                      handleBulkDelete(e);
                      setWarningShown(false);
                      setNumOfSelected(0);
                      setSelectModeShown(false);
                    }}
                    className="h-11 w-[14.375rem] bg-CTA/Error text-white rounded-11xl font-semibold"
                  >
                    {numOfSelected <= 1 ? "Delete" : "Delete All"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
      {newExerciseShown && (
        <>
          <PopUpNewExercise
            discardWarningShown={discardWarningShown}
            setNewExerciseShown={setNewExerciseShown}
            setExerciseName={setExerciseName}
            setArticleName={setArticleName}
            handlePasteFromClipboard={handlePasteFromClipboard}
            handleTextAreaFieldChange={handleTextAreaFieldChange}
            textAreaField={textAreaField}
            setDiscardWarningShown={setDiscardWarningShown}
            exerciseName={exerciseName}
            articleName={articleName}
            handleCreateExercise={handleCreateExercise}
            setTextAreaField={setTextAreaField}
          />
        </>
      )}
      {discardWarningShown && (
        <>
          <div className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto mx-auto max-w-3xl">
              <div className="divide-y divide-innerBorder rounded-20xl shadow-lg relative w-[33.75rem] h-[32rem] bg-white outline-none focus:outline-none">
                <div className=" flex-row w-[33.75rem] h-[4.25rem] align-middle inline-flex items-center ml-[42px]">
                  <h1 className="font-semibold text-sm">Discard Changes</h1>
                  <button
                    className="absolute right-5 top-5"
                    onClick={() => {
                      setDiscardWarningShown(false);
                    }}
                  >
                    <img src="close.svg" alt="" />
                  </button>
                </div>
                <div className="w-[33.75rem] h-[27.75rem]">
                  <img
                    src="deleteWarning.svg"
                    className="ml-[157px] mt-[50px] mb-[35px] w-[12.25rem] h-[10rem]"
                    alt=""
                  />
                  <h1 className="text-[28px] font-medium mb-[6px] ml-[92px]">
                    Abort and discard changes
                  </h1>
                  <div className="text-CTA/Disabled font-normal text-sm w-full ml-[110px] mb-[46px]">
                    Are you sure you want to discard this exercise?
                  </div>
                  <div className="flex flex-row">
                    <button
                      onClick={() => {
                        setDiscardWarningShown(false);
                      }}
                      className="h-11 w-[14.375rem] border border-innerBorder text-black rounded-11xl font-medium mr-4 ml-10 hover:bg-white2"
                    >
                      Continue Editing
                    </button>
                    <button
                      onClick={() => {
                        setDiscardWarningShown(false);
                        // setNewExerciseShown(false);
                        setTextAreaField("");
                        setArticleName("");
                        setExerciseName("");
                      }}
                      className="h-11 w-[14.375rem] bg-CTA/Warning text-white rounded-11xl font-semibold hover:bg-CTA/Warning/[.95]"
                    >
                      Discard
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {exportPopUpShown && (
        <ExportandGeneratePopUp
          onClose={() => {
            setExportPopUpShown(false);
          }}
          exercise={exportExercise!}
          isExport={true}
        />
      )}
      <Header
        onLogout={() => {
          handleLogout();
        }}
      ></Header>
      <div className="h-44 w-full bg-Dark px-32">
        <div className="flex flex-row">
          <p className="pt-4 text-white text-4xl font-medium">
            Good Afternoon, {name}
          </p>
          <img
            alt=""
            src="/dashboardBackground.svg"
            className="absolute right-32 top-[80px] opacity-[.25]"
          />

          {addNewExButtonShown ? (
            <button
              onClick={() => {
                setNewExerciseShown(true);
              }}
              className="text-white font-semibold text-base"
            >
              <div className="rounded-11xl bg-blue1 hover:bg-blue2 h-11 w-44 absolute right-32 flex flex-row items-center justify-center">
                <img src="/add.svg" alt="" className="pr-2.5" />
                New Exercise
              </div>
            </button>
          ) : null}
        </div>
        <div className="flex flex-row">
          <p className="pt-2 pr-8 text-white">
            {new Intl.DateTimeFormat("en-US", {
              weekday: "long",
            }).format(new Date())}
            ,{" "}
            {new Date().toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
        </div>
      </div>
      <div className="document-page divide-y divide-innerBorder bg-white mx-auto absolute z-0 top-56 right-32 left-32 pb-5 mb-5 rounded-21xl drop-shadow-xl overflow-y-auto">
        <div className="h-20 flex-row align-middle inline-flex items-center">
          <h1 className="text-black pl-8 pr-[415px] text-base font-medium">
            {title}
          </h1>
          <div className="flex-row align-middle inline-flex items-center absolute right-8">
            {selectModeShown ? (
              <>
                <button
                  type="button"
                  onClick={() => {
                    toggleSelectAll();
                  }}
                >
                  <div className="pr-2">
                    <div className="text-sm font-medium w-24 h-[2.375rem] bg-white hover:bg-white2 drop-shadow rounded-11xl align-middle inline-flex items-center">
                      <div className="mx-auto flex flex-row">Select All</div>
                    </div>
                  </div>
                </button>
                <button
                  onClick={() => {
                    // EDIT HERE PLEASE
                    const selectedList = listOfExercise?.filter((exercise) => {
                      return exercise.checked;
                    });
                    setNumOfSelected(selectedList?.length || 0);

                    if (selectedList && selectedList?.length !== 0) {
                      setWarningShown(true);
                    }
                  }}
                  type="button"
                >
                  <div
                    className="text-sm font-medium w-24 h-[2.375rem] bg-white hover:bg-white2 drop-shadow rounded-11xl align-middle inline-flex items-center"
                    // {
                    //   numOfSelected
                    //     ? "text-sm font-medium w-24 h-[2.375rem] bg-white hover:bg-white2 drop-shadow rounded-11xl align-middle inline-flex items-center"
                    //     : "text-sm font-medium w-24 h-[2.375rem] bg-white2 drop-shadow rounded-11xl align-middle inline-flex items-center"
                    // }
                  >
                    <div className="mx-auto inline-flex flex-row align-middle items-center">
                      <img src="/deleteDark.svg" alt="" className="pr-2" />
                      Delete
                    </div>
                  </div>
                </button>
              </>
            ) : (
              <div ref={ref1}>
                {sortingButtonShown ? (
                  <button
                    onClick={() => setSortByShown(!sortByShown)}
                    className="inline-flex flex-row align-middle items-center"
                  >
                    <div className="align-middle inline-flex items-center w-[14.5rem] h-[2.375rem] bg-white hover:bg-white2 drop-shadow rounded-11xl">
                      <img
                        src="/sortBy.svg"
                        alt=""
                        className="absolute left-4 pr-2"
                      />
                      <h1 className="text-sm font-normal text-grey2 absolute left-9 align-middle inline-flex items-center">
                        Sort by :
                      </h1>
                      <p className="absolute left-24 font-medium text-Fill/Dark/MainDark text-sm">
                        {sortBy}
                      </p>
                      <img
                        alt=""
                        src="/drop.svg"
                        className="absolute right-5 pointer-events-none align-middle inline-flex items-center"
                      />
                    </div>
                  </button>
                ) : null}

                {sortByShown && (
                  <div className="absolute z-20 right-[104px] top-11 bg-white border-solid border-Purple w-[14.5rem] h-36 rounded-11xl border flex items-center">
                    <ul className="text-sm font-medium">
                      <div className="text-sm font-normal">
                        <SortByButton sortType={"Name"} />
                        <SortByButton sortType={"Date Created"} />
                        <SortByButton sortType={"Last Modified"} />
                      </div>
                    </ul>
                  </div>
                )}
              </div>
            )}
            <div className="pr-2"></div>

            {selectionButtonShown ? (
              <button
                type="button"
                className="button flex-row align-middle inline-flex items-center"
                onClick={() => {
                  toggleSelect();
                  handleResetSelect();
                  setSelectModeShown(!selectModeShown);
                }}
              >
                <div className="text-sm font-medium w-24 h-[2.375rem] bg-white hover:bg-white2 drop-shadow rounded-11xl align-middle inline-flex items-center">
                  <div className="mx-auto flex flex-row">
                    {selectModeShown ? (
                      <h1 className="mx-auto">Done</h1>
                    ) : (
                      <h1 className="mx-auto">Select</h1>
                    )}
                  </div>
                </div>
              </button>
            ) : null}
          </div>
        </div>
        <div className="min-h-full">
          <div className="grid grid-cols-dashboard gap-x-5 gap-y-5 pt-8 px-7">
            {listOfExercise &&
              listOfExercise.map((exercise, index) => {
                return (
                  <ArticleSnippet
                    check={exercise.checked}
                    onCheck={(checked) => {
                      setListOfExercise(
                        listOfExercise.map((exercise, ind) => {
                          if (ind === index) {
                            return { ...exercise, checked: checked };
                          }
                          return exercise;
                        })
                      );
                    }}
                    key={exercise.id}
                    exerciseID={exercise.id}
                    name={exercise.exerciseName}
                    lastModifiedDate={exercise.lastAccessedDate}
                    isPublished={exercise.published}
                    setIsPublished={(event, exerciseId, isPublished) => {
                      handlePublishExercise(event, exerciseId, isPublished);
                    }}
                  />
                );
              })}
            {addNewExButtonShown ? (
              <button
                onClick={() => {
                  setNewExerciseShown(true);
                }}
              >
                <div className="w-[15.375rem] h-[18.75rem] bg-white hover:bg-white2 rounded-t-11xl border border-grey4">
                  <img
                    src="newExercise.svg"
                    alt=""
                    className="mx-auto pt-20 pb-7"
                  />
                  <p className="font-normal text-[10px] text-grey3 flex justify-center">
                    Add New Exercise
                  </p>
                </div>
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export const PopUpNewExercise = ({
  discardWarningShown,
  setNewExerciseShown,
  setExerciseName,
  setArticleName,
  handlePasteFromClipboard,
  handleTextAreaFieldChange,
  textAreaField,
  setDiscardWarningShown,
  exerciseName,
  articleName,
  handleCreateExercise,
  setTextAreaField,
}: {
  discardWarningShown: boolean;
  setNewExerciseShown: React.Dispatch<React.SetStateAction<boolean>>;
  setExerciseName: React.Dispatch<React.SetStateAction<string>>;
  setArticleName: React.Dispatch<React.SetStateAction<string>>;
  handlePasteFromClipboard: () => Promise<void>;
  handleTextAreaFieldChange: (event: any) => void;
  textAreaField: string;
  setDiscardWarningShown: React.Dispatch<React.SetStateAction<boolean>>;
  exerciseName: string;
  articleName: string;
  handleCreateExercise: (e: any) => Promise<void>;
  setTextAreaField: (e: any) => void;
}) => {
  return (
    <>
      <div
        className={
          "fixed z-50 top-[80px] w-full outline-none justify-center items-center h-full" +
          (discardWarningShown ? " invisible" : " visible")
        }
      >
        <div className="relative w-auto mx-auto max-w-3xl h-full">
          <div className="rounded-20xl border-none shadow-lg flex flex-col w-full h-[90%] overflow-x-hidden bg-white outline-none focus:outline-none overflow-y-auto">
            <div className="relative bg-white outline-none focus:outline-none w-full h-full">
              <div className=" flex-row w-full h-[4.25rem] border-b border-innerBorder align-middle inline-flex items-center">
                <h1 className="ml-10 font-semibold font-sm">Create Exercise</h1>
                <button
                  className="absolute right-6"
                  onClick={() => {
                    setNewExerciseShown(false);
                  }}
                >
                  <button
                    onClick={() => {
                      setTextAreaField("");
                      setArticleName("");
                      setExerciseName("");
                    }}
                  >
                    {" "}
                    <img src="close.svg" alt="" />
                  </button>
                </button>
              </div>
              <div className="w-full h-[9.9rem] border-b border-innerBorder align-middle inline-flex items-center flex-row">
                <img src="copyright.svg" alt="" className="ml-11 mr-10" />
                <div>
                  <h1 className="font-medium text-[28px] mb-1.5">
                    Create an exercise
                  </h1>
                  <p className="font-normal text-xs text-Fill/Dark/Dark2">
                    Organize your exercise and make easily accessible by saving
                    in a folder. <br></br>Exercise may contain mcq, long
                    question, short question etc
                  </p>
                </div>
              </div>
              <div className="w-full h-[26.85%] font-normal text-sm ml-[34px]">
                <h1 className="mt-[25px] pb-2">
                  Exercise Name <span className="text-red">*</span>
                </h1>
                <input
                  value={exerciseName}
                  onChange={(e) => {
                    setExerciseName(e.target.value);
                  }}
                  placeholder="Enter exercise name"
                  className="h-12 w-[43rem] pl-4 border border-border2 rounded-16xl outline-none focus:pl-[15px] focus:border-blue1 focus:border-2"
                />
                <h1 className="mt-[25px] pb-2">Article Name</h1>
                <input
                  onChange={(e) => {
                    setArticleName(e.target.value);
                  }}
                  value={articleName}
                  placeholder="Enter article name"
                  className="h-12 w-[43rem] pl-4 border border-border2 rounded-16xl outline-none focus:pl-[15px] focus:border-blue1 focus:border-2"
                />
                <div className="flex flex-row mt-8 mb-2">
                  <h1 className="pr-[435px]">
                    Enter Article <span className="text-red">*</span>
                  </h1>

                  <button
                    onClick={handlePasteFromClipboard}
                    className="text-blue1 font-medium inline-flex flex-row align-middle items-center hover:text-blue2"
                  >
                    <img src="/paste.svg" alt="" className="pr-[7.67px]" />
                    Paste from clipboard
                  </button>
                </div>
                <textarea
                  onChange={(e) => {
                    handleTextAreaFieldChange(e);
                  }}
                  value={textAreaField}
                  placeholder="Paste the article here"
                  className="h-[21rem] w-[43rem] px-4 py-3 border border-border2 rounded-16xl outline-none focus:pl-[15px] focus:pt-[11px] focus:border-blue1 focus:border-2"
                ></textarea>
                <div className="flex flex-row">
                  <p className="mt-[30px] text-Fill/Dark/Dark2">
                    Word count :{" "}
                  </p>
                  <div className="flex justify-center mt-[30px] pt-[1.5px] ml-[5px] w-[2.1875rem] h-[1.5rem] rounded-22xl bg-blue1/[.1] text-blue1">
                    {countWord(textAreaField)}
                  </div>
                  <div className="flex flex-row mt-5 font-semibold text-base absolute right-[42px]">
                    <button
                      onClick={() => {
                        setDiscardWarningShown(true);
                      }}
                      className="w-[9.25rem] h-11 border border-border2 rounded-11xl text-blue1 mr-[17px] hover:bg-grey4"
                    >
                      Discard
                    </button>
                    <button
                      onClick={(e) => {
                        handleCreateExercise(e);
                      }}
                      disabled={textAreaField === "" || exerciseName === ""}
                      className={
                        "w-[13.875rem] h-11 rounded-11xl text-white" +
                        (exerciseName
                          ? textAreaField
                            ? " bg-blue1 hover:bg-blue2"
                            : " bg-grey3"
                          : " bg-grey3")
                      }
                    >
                      Save &amp; Continue
                    </button>
                  </div>
                </div>
                <div className="w-[13.854rem] h-8 bg-white"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default DocumentsPage;
