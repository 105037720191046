import { LoadingIcon } from "../LoadingIcon";

function LoadingPopup({ action }: { action: string }) {
  return (
    <div className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative mx-auto max-w-3xl">
        <div className="rounded-20xl shadow-lg relative w-max max-w-[400px] py-8 px-4 bg-blue1 outline-none focus:outline-none">
          <div className="flex items-center justify-center">
            <LoadingIcon />
            <h1 className="text-white font-medium">{action} In Progress...</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export { LoadingPopup };
