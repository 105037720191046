interface SLQAnswerProps {
  placeholder: string;
  modelAnswer: string | null;
  setModelAnswer: (value: string) => void;
  isEditing: boolean;
}

function SLQContent({
  placeholder,
  modelAnswer,
  setModelAnswer,
  isEditing,
}: SLQAnswerProps) {
  return (
    <>
      {isEditing ? (
        <div className="flex flex-col gap-2 px-4">
          <h1 className="font-normal text-[14px] text-grey2">Answer</h1>
          <textarea
            className="w-full h-fit py-[12px] px-4 border-2 border-Gray focus:outline-none focus:border-sky-400 rounded-md text-sm leading-6"
            placeholder={placeholder}
            value={modelAnswer ?? ""}
            onChange={(e) => setModelAnswer(e.target.value)}
          />
        </div>
      ) : (
        <div className="flex flex-col pl-11 text-sm leading-6">
          <h1 className="font-normal  text-grey2">Answer</h1>
          <div className="text-[14px] pr-11 text-CTA/Success font-semibold">
            {modelAnswer}
          </div>
        </div>
      )}
    </>
  );
}

export default SLQContent;
