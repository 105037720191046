import { MTAnswerWithId, StatementWithId } from "./QuestionTypes";

import { ReactComponent as IconTrashCan } from "../../icons/IconTrashCan.svg";
import cx from "classnames";
import { useState } from "react";
import { MatchQNAwithId } from ".";

interface MatchAnswerProps {
  mtType: string | undefined;
  setMtType: (value: string) => void;
  mtStatement: StatementWithId;
  setMtStatement: (value: StatementWithId) => void;
  mtAnswer: MTAnswerWithId;
  setMtAnswer: (value: MTAnswerWithId) => void;
  mtQuestion: MatchQNAwithId;
  setMtQuestion: (value: MatchQNAwithId) => void;
  isEditing: boolean;
}
export default function MatchContent({
  mtType,
  setMtType,
  mtAnswer,
  setMtAnswer,
  mtQuestion,
  setMtQuestion,
  mtStatement,
  setMtStatement,
  isEditing,
}: MatchAnswerProps) {
  const handleStatementDelete = (id: number | string) => {
    if (mtStatement.length <= 1) return;
    const newArr = mtStatement.filter((item) => item.id !== id);
    setMtStatement(newArr);
    setMtAnswer(mtAnswer);
    setMtQuestion(mtQuestion);
  };

  const handleQuestionDelete = (id: number | string) => {
    if (mtQuestion.length <= 1) return;
    const newArrr = mtQuestion.filter((item) => item.id !== id);
    setMtQuestion(newArrr);
    setMtAnswer(newArrr);
  };

  const handleAnswerChange = (id: number | string, newValue: string) => {
    const newArr = [...mtQuestion];

    const idxToChange = newArr.findIndex((item) => item.id === id);

    if (idxToChange === -1) {
      return;
    }

    newArr[idxToChange].answers = newValue;
    setMtQuestion(newArr);
    setMtAnswer(newArr);
  };

  const handleQuestionChange = (id: number | string, newValue: string) => {
    const newArr = [...mtQuestion];

    const idxToChange = newArr.findIndex((item) => item.id === id);

    if (idxToChange === -1) return;
    newArr[idxToChange].question = newValue;
    setMtQuestion(newArr);
    setMtAnswer(newArr);
  };

  const handleStatementChange = (id: number | string, newValue: string) => {
    const newArr = [...mtStatement];
    const idxToChange = newArr.findIndex((item) => item.id === id);
    if (idxToChange === -1) return;

    newArr[idxToChange].statement = newValue;
    setMtStatement(newArr);
    setMtAnswer(mtAnswer);
    setMtQuestion(mtQuestion);
  };

  const [stringArray, setStringArray] = useState(["", 0]);
  function addString(element: string | number) {
    const newArr = stringArray.concat(element);
    setStringArray(newArr);
  }
  const completeMatchingArray: {
    question: string | number;
    answer: string | number;
    statement: string | number;
    questionId: string | number;
    statementId: string | number;
  }[] = [];

  for (
    let i = 0;
    i <
    (mtQuestion.length < mtStatement.length
      ? mtStatement.length
      : mtAnswer.length);
    i++
  ) {
    if (i >= mtQuestion.length) {
      completeMatchingArray.push({
        question: "",
        answer: "",
        statement: mtStatement[i].statement,
        questionId: "",
        statementId: mtStatement[i].id,
      });
    } else if (i >= mtStatement.length) {
      completeMatchingArray.push({
        question: mtQuestion[i].question,
        answer: mtQuestion[i].answers,
        statement: "",
        questionId: mtQuestion[i].id,
        statementId: "",
      });
    } else {
      completeMatchingArray.push({
        question: mtQuestion[i].question,
        answer: mtQuestion[i].answers,
        statement: mtStatement[i].statement,
        statementId: mtStatement[i].id,
        questionId: mtQuestion[i].id,
      });
    }
  }

  return (
    <table className="table-auto border-separate border-spacing-2 block overflow-x-auto w-full">
      <div className="flex flex-col gap-2 px-4">
        {!isEditing && (
          <thead>
            <tr className="flex flex-row pl-2 gap-32 text-grey2 font-normal w-3/4 text-sm leading-6">
              <td className="w-1/4">{mtType}</td>
              <td className="text-start"> Statements</td>
            </tr>
          </thead>
        )}
        {isEditing && (
          <thead className="font-medium text-sm w-full">
            <tr className="w-full">
              <td className="w-[5%]"></td>
              <td className="w-full">
                <input
                  className="pl-[10px] h-[3rem] border-2 border-Gray focus:outline-none focus:border-sky-400 rounded-md w-full"
                  placeholder="   Type"
                  value={mtType}
                  onChange={(e) => setMtType(e.target.value)}
                />
              </td>
              <td></td>
            </tr>
          </thead>
        )}

        <div className="w-[98%]">
          {mtQuestion.map(({ id, question, answers }, index) => {
            if (isEditing) {
              return (
                <tr key={id}>
                  <td>
                    <div className="h-[22px] w-[22px] rounded-full bg-purple-50 text-[8.5px] grid place-content-center font-semibold">
                      {index + 1}
                    </div>
                  </td>
                  <td className="w-[30%]">
                    <input
                      //type="number"
                      pattern="[A-Z]"
                      className="pl-[10px] w-full h-[3rem] border-2 border-Gray focus:outline-none focus:border-sky-400 rounded-md "
                      placeholder={` Answer ${index + 1}`}
                      value={answers}
                      onChange={(e) => {
                        if (
                          !e.target.value ||
                          (!'1234567890-=!@#$%^&*()_+: "\n{}<>?/\\'.includes(
                            e.target.value[0]
                          ) &&
                            e.target.value.length <= 1)
                        ) {
                          return handleAnswerChange(
                            id,
                            e.target.value.toUpperCase()
                          );
                        }
                      }}
                    />
                  </td>
                  <td className="w-[80%]">
                    <input
                      className="pl-[10px] w-full h-[3rem] border-2 border-Gray focus:outline-none focus:border-sky-400 rounded-md "
                      placeholder={`  Question Statement ${index + 1}`}
                      value={question}
                      onChange={(e) => handleQuestionChange(id, e.target.value)}
                    />
                  </td>
                  <div
                    key={`${id}${index}`}
                    className="flex flex-row gap-x-1 w-full h-[60px] py-[12px]  text-[14px]"
                  >
                    <div className="flex justify-evenly items-center gap-2">
                      <IconTrashCan
                        className={cx(
                          "cursor-pointer w-[1.2em] h-[1.2em] text-[#FF6628] hover:text-[#DE6628]",
                          {
                            "!text-grey cursor-not-allowed":
                              mtQuestion.length <= 1,
                          }
                        )}
                        title={
                          mtQuestion.length <= 1
                            ? "At least 2 choices are required"
                            : "Delete"
                        }
                        onClick={() => {
                          handleQuestionDelete(id);
                        }}
                      />
                    </div>
                  </div>
                </tr>
              );
            }
          })}
          <table className="table-auto border-separate border-spacing-2 block overflow-x-auto w-full">
            {isEditing && (
              <thead className="font-medium text-sm w-full">
                <tr className="w-full">
                  <td></td>
                  <td className="w-full font-normal text-grey2 text-base">
                    Statements
                  </td>
                  <td></td>
                </tr>
              </thead>
            )}
            {mtStatement.map(({ id, statement }, index) => {
              if (isEditing) {
                return (
                  <tr key={id}>
                    <td>
                      <div className="h-[22px] w-[22px] rounded-full bg-purple-50 text-[8.5px] grid place-content-center font-semibold">
                        {String.fromCharCode(index + 97)}
                      </div>
                    </td>
                    <td className="w-full">
                      <input
                        className=" h-[3rem] pl-[10px] w-full border-2 border-Gray focus:outline-none focus:border-sky-400 rounded-md "
                        placeholder={`  Statement ${index + 1}`}
                        value={statement}
                        onChange={(e) =>
                          handleStatementChange(id, e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <IconTrashCan
                        className={cx(
                          "cursor-pointer w-[1.2em] h-[1.2em] text-[#FF6628] hover:text-[#DE6628]",
                          {
                            "!text-grey cursor-not-allowed":
                              mtStatement.length <= 1,
                          }
                        )}
                        title={
                          mtAnswer.length <= 1
                            ? "At least 2 choices are required"
                            : "Delete"
                        }
                        onClick={() => {
                          handleStatementDelete(id);
                        }}
                      />
                    </td>
                  </tr>
                );
              }
            })}
          </table>
        </div>
        {!isEditing &&
          completeMatchingArray.map(
            (
              { question, questionId, answer, statement, statementId },
              index
            ) => {
              return (
                <tr className="w-full flex flex-row" key={questionId}>
                  <td className="flex align-middle w-[40%]">
                    {answer === "" ? (
                      <div></div>
                    ) : (
                      <div
                        key={questionId}
                        className="flex flex-row w-full gap-x-1 text-[14px] pl-1 pr-2"
                      >
                        <div className="flex-[1]">{`${index + 1}.`}</div>
                        <div className="flex-[1] text-decoration: underline text-CTA/Success font-semibold text-sm">
                          &nbsp;&nbsp;&nbsp;{answer}&nbsp;&nbsp;&nbsp;
                        </div>
                        <div className="flex-[7]">{question}</div>
                      </div>
                    )}
                  </td>
                  <td className="flex align-middle w-[60%]">
                    <div
                      key={statementId}
                      className="flex flex-row w-full gap-x-1 text-[14px] pl-4 pr-4 "
                    >
                      <tr className="flex gap-x-2">
                        <span>{`${String.fromCharCode(index + 65)}.`}</span>
                        <span>
                          {statement
                            .toString()
                            .replace("[", "")
                            .replace("]", "")}
                        </span>
                      </tr>
                    </div>
                  </td>
                </tr>
              );
            }
          )}
      </div>
    </table>
  );
}
