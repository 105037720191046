import React from "react";
import cx from "classnames";

export interface ButtonProps {
  text: string;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disable?: boolean;
}

function Button({
  text,
  icon: Icon,
  className,
  onClick = () => {},
  disable,
}: ButtonProps) {
  return (
    <button
      disabled={disable}
      className={cx(
        "py-[10px] px-11 flex items-center gap-2 rounded-[4px] font-bold",
        className
      )}
      onClick={onClick}
    >
      {Icon && <Icon className="w-[1.2em] h-[1.2em]" />}
      {text}
    </button>
  );
}

export default Button;
