import { useState, useLayoutEffect, useEffect, useRef } from "react";

import { ReactComponent as IconQuestionType } from "../../icons/IconQuestionType.svg";
import QuestionTypeCard from "./QuestionTypeCard";
import { QuestionType, QuestionAbility } from "./QuestionTypes";
import classNames from "classnames";
import { Media } from "docx";

interface SelectQuestionTypeProps {
  onSelectType: (
    type: QuestionType,
    ability: QuestionAbility | undefined
  ) => void;
}

function SelectQuestionType({ onSelectType }: SelectQuestionTypeProps) {
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [selectedAbility, setSelectedAbility] = useState<QuestionAbility>();

  const questionTypes = [
    // {
    //   type: QuestionType.MULTIPLE_CHOICE,
    //   ability: undefined,
    //   description: "Question with multiple choices",
    //   example: (
    //     <p  className="max-w-[470px] text-white/70 font-medium">
    //       <span className="text-white font-medium">What is the purpose of the video? </span>
    //       <br />
    //       A. to entertain <br />
    //       B. to inform <br />
    //       C. to record an event <br />
    //       D. to spread a message
    //     </p>
    //   ),
    // },
    // {
    //   type: QuestionType.TRUE_FALSE,
    //   ability: undefined,
    //   description: "Question with only True, False, and Not Given answer",
    //   example: (
    //     <p  className="max-w-[470px] text-white/70 font-medium">
    //       <span className="text-white font-medium">Question: </span>
    //       According to this paragraph, are the following statements <br />
    //       True (T)
    //       <br />
    //       False (F)
    //       <br />
    //       Not Given (NG)?
    //     </p>
    //   ),
    // },
    // {
    //   type: QuestionType.SHORT_QUESTION,
    //   ability: undefined,
    //   description: "Question with a few words",
    //   example: (
    //     <p  className="max-w-[470px] text-white/70 font-medium">
    //       <span className="text-white font-medium">Who or what
    //       does ‘the arrest’ refer to?</span>
    //       <br />
    //       <br />
    //       __________________
    //     </p>
    //   ),
    // },
    // {
    //   type: QuestionType.LONG_QUESTION,
    //   ability: undefined,
    //   description: "Question with more than two lines",
    //   example: (
    //     <p  className="max-w-[470px] text-/70 font-medium">
    //       <span className="text-white font-medium">What is used
    //       to play back sound recordings in one of the {"\n"}many formats
    //       available for computers today?</span>
    //       {"\n"}
    //       {"\n"}___________________________________
    //       {"\n"}
    //       {"\n"}___________________________________
    //     </p>
    //   ),
    // },
    // {
    //   type: QuestionType.MULTIPLE_CHOICE,
    //   ability: QuestionAbility.THEMATIC,
    //   description:
    //     "1. Identifying the main theme / purpose (eg: to inform) of the text\n2. Finding the central idea or main topic of the passage or text",
    //   example: (
    //     <p  className="max-w-[470px] text-white/70 font-medium">
    //       <span className="text-white font-medium">What is the purpose of the writer? He is writing ____ the {"\n"}readers
    //       about the comic book industry. </span>
    //       <br />
    //       A. to warn <br />
    //       B. to advise <br />
    //       C. to inform <br />
    //       D. to reassure
    //     </p>
    //   ),
    // },
    //here also
    {
      type: QuestionType.MULTIPLE_CHOICE,
      ability: QuestionAbility.THEMATIC_PURPOSE,
      description: "Identifying the purpose of the text.",
      example: (
        <p className="leading-5 md:w-[300px] md:text-[10px] lg:max-w-[360px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 text-white font-medium md:text-[10px] lg:text-[12px] xl:text-[14px]">
            The main purpose of Text 7 is to explain...
          </span>
          <br />
          A. how to make Mars similar to Earth. <br />
          B. how dangerous the atmosphere of Mars is. <br />
          C. how human begins can travel to Mars in future. <br />
          D. how human begins should adapt to live on Mars.
        </p>
      ),
    },
    {
      type: QuestionType.MULTIPLE_CHOICE,
      ability: QuestionAbility.THEMATIC_TEXT_TAKEN_FROM,
      description: "Identifying the source from which the text is taken.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[360px] lg:text-[12px] xl:max-w-[470px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 text-white font-medium md:text-[10px] lg:text-[12px] xl:text-[14px]">
            The text is taken from...
          </span>
          <br />
          A. a letter. <br />
          B. a report. <br />
          C. a manual. <br />
          D. a newspaper.
        </p>
      ),
    },
    {
      type: QuestionType.SUMMARY_CLOZE,
      ability: QuestionAbility.MAIN_IDEA_ONE_WORD_PHRASE,
      description: "Filling in the blanks with the correct word or phrase.",
      example: (
        <p className="leading-5 md:max-w-[300px] xl:max-w-[470px] ">
          <table className="leading-5 md:max-w-[300px] lg:max-w-[360px] xl:max-w-[470px] flex flex-col gap-2 border border-white p-2  ">
            <tr>
              <span className=" leading-5 text-white font-medium md:text-[10px] lg:text-[12px] xl:text-[14px]">
                Below are summaries of two possible plants for the housing
                estate. Fill in the blanks with one word that best fits the
                paragraph. Your answers must be grammatically correct.{" "}
              </span>
            </tr>
            <tr className="leading-5 text-white/70 font-medium md:text-[10px] lg:text-[12px] xl:text-[14px]">
              Moon Orchids are beautiful plants to have but it is difficult to{" "}
              take care of them, especially if you want them to have
              (i)___________. You have to (ii)______________ them often because
              they need to be kept moist. you also have to make sure that the
              soil is (iii)_____________ so that the roots get a lot of
              (iv)_____________.
            </tr>
          </table>
        </p>
      ),
    },
    // {
    //   type: QuestionType.MULTIPLE_CHOICE,
    //   ability: QuestionAbility.FACTUAL,
    //   description:
    //     "1. Understanding stated information or identifying a sequence of events\n2. Identifying specific pieces of information mentioned in the passage or understanding the order in which events occur",
    //   example: (
    //            <p  className="max-w-[470px]">

    //       <span className="text-white font-medium">Question: </span>
    //       Which is the following is NOT a factor making Mars currently an
    //       uninhabitable place. <br />
    //       A. atmosphere to thin <br />
    //       B. temperture to cold <br />
    //       C. no ice for water creation <br />
    //       D. limited source for carbon dioxide
    //     </p>
    //   ),
    // },
    {
      type: QuestionType.MULTIPLE_CHOICE,
      ability: QuestionAbility.FACTUAL_INCORRECT_STATEMENT,
      description:
        "Identifying the statement that is not mentioned in the text.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:text-[14px] xl:max-w-[470px] text-white/70 font-medium">
          <span className="leading-5 text-white font-medium md:text-[10px] lg:text-[12px] xl:text-[14px]">
            Which of the following is NOT a factor making Mars currently an
            uninhabitable place?
          </span>
          <br />
          A. atmosphere to thin <br />
          B. temperature to cold <br />
          C. no ice for water creation <br />
          D. limited source for carbon dioxide
        </p>
      ),
    },
    {
      type: QuestionType.MULTIPLE_CHOICE,
      ability: QuestionAbility.FACTUAL_RECOMMENDATION,
      description:
        "Applying information from the text and making recommendations accordingly.",
      warning:
        "WARNING: Please make sure the text is an informative article about choosing something based on some specific characteristics",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:text-[12px] lg:max-w-[350px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="text-white font-medium">
            Miss Lee, 24, lives with her parents adn needs to decorate the
            living room with some plants that do not require too much care but
            produce bright flowers. Which plant suits her?
          </span>
          <br />
          A. Kumquat <br />
          B. English Ivy <br />
          C. Moon Orchid <br />
          D. Red Crown Cactus
        </p>
      ),
    },
    {
      type: QuestionType.MULTIPLE_CHOICE,
      ability: QuestionAbility.FACTUAL_SPECIFIC_INFORMATION,
      description:
        "Identifying specific information to understand why a certain event occurs.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:text-[12px] lg:max-w-[350px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 text-white font-medium md:text-[10px] md:max-w-[300px] lg:max-w-[350px] lg:text-[12px] xl:text-[14px]  xl:max-w-[400px]">
            According to paragraph 10, why are there fewer news stands in Hong
            Kong?
          </span>
          <br />
          A. The newsstands cannot charge higher fees. <br />
          B. The newsstands cannot stock many comic books. <br />
          C. The government stopped issuing licenses to newsstands. <br />
          D. The publishers do not use the newsstands to promote their comics.
        </p>
      ),
    },
    {
      type: QuestionType.MULTIPLE_CHOICE,
      ability: QuestionAbility.FACTUAL_WRITER_THOUGHTS,
      description: "Identifying the writer’s thoughts according to the text.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[380px] lg:text-[12px] xl:max-w-[470px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px] text-white font-medium">
            The writer takes the teenagers to the
            <span className="italic"> Food Garage</span> because {"\n"}he
            thinks...
          </span>
          <br />
          A. they don't like the local food. <br />
          B. they should work for their treat. <br />
          C. they may miss being in America. <br />
          D. they have recently moved to America.
        </p>
      ),
    },
    {
      type: QuestionType.MULTIPLE_CHOICE,
      ability: QuestionAbility.FACTUAL_NUMBER,
      description: "Identifying specific key number information in the text.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 text-white font-medium md:text-[10px] lg:text-[12px] xl:text-[14px]">
            How many posts are there on 22 April, 2021?
          </span>
          <br />
          A. 2<br />
          B. 3<br />
          C. 4<br />
          D. 5
        </p>
      ),
    },
    {
      type: QuestionType.MULTIPLE_CHOICE,
      ability: QuestionAbility.FACTUAL_TOPIC,
      description: "Identifying the topic of the text.",
      example: (
        <p className="leading-5 max-w-[470px] text-white/70 font-medium md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px]">
          <span className="leading-5 text-white font-medium md:text-[10px] lg:text-[12px] xl:text-[14px]">
            What is the topic of the forum?
          </span>
          <br />
          A. Looking at the entrance area <br />
          B. Improving our housing estate
          <br />
          C. Throwing rubbish into plants pots
          <br />
          D. Putting plant holders at the entrance
        </p>
      ),
    },
    {
      type: QuestionType.TRUE_FALSE,
      ability: QuestionAbility.FACTUAL,
      description:
        "Deciding if the statement is correct, incorrect, or is not stated based on the text.",
      example: (
        <div className="leading-5 text-white/70 font-medium">
          <div className="leading-5 grid grid-cols-12 gap-2 gap-x-4 md:max-w-[300px] lg:max-w-[350px] xl:max-w-[400px]">
            <div className="leading-5 font-bold col-span-9 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              Statement
            </div>
            <div className="leading-5 font-bold md:text-[10px] lg:text-[12px] xl:text-[14px]">
              T
            </div>
            <div className="leading-5 font-bold md:text-[10px] lg:text-[12px] xl:text-[14px]">
              F
            </div>
            <div className="leading-5 font-bold md:text-[10px] lg:text-[12px] xl:text-[14px]">
              NG
            </div>
            <div className="leading-5 col-span-9 text-white font-medium md:text-[10px] lg:text-[12px] xl:text-[14px]">
              (i) Artsypatsy has already put climbing plants {"\n"}around the
              centre.
            </div>
            <div className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              O
            </div>{" "}
            <div className="md:text-[10px] lg:text-[12px] xl:text-[14px]">
              O
            </div>{" "}
            <div className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              O
            </div>
            <div className="leading-5 col-span-9 text-white font-medium md:text-[10px] lg:text-[12px] xl:text-[14px]">
              (ii) Girlgeorgy thinks the entrance looks boring.
            </div>
            <div className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              O
            </div>{" "}
            <div className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              O
            </div>{" "}
            <div className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              O
            </div>
            <div className="leading-5 col-span-9 text-white font-medium md:text-[10px] lg:text-[12px] xl:text-[14px]">
              (iii) The left side of entrance receive a lot of light.
            </div>
            <div className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              O
            </div>{" "}
            <div className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              O
            </div>{" "}
            <div className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              O
            </div>
            <div className="leading-5 col-span-9 text-white font-medium md:text-[10px] lg:text-[12px] xl:text-[14px]">
              (iv) Girlgeorgy has all the information on plaut price.
            </div>
            <div className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              O
            </div>{" "}
            <div className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              O
            </div>{" "}
            <div className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              O
            </div>
          </div>
        </div>
      ),
    },
    // {
    //   type: QuestionType.SHORT_QUESTION,
    //   ability: QuestionAbility.FACTUAL,
    //   description:
    //     "1. Understanding stated information or identifying a sequence of events\n2. Identifying specific pieces of information mentioned in the passage or understanding the order in which events occur",
    //   example: (
    //    <p className="w-[470px]"
    //       <span className="text-white font-medium">Question: </span>Who or what
    //       Give two pieces of edvidence the writer {"\n"}provided in paragraph 3
    //       to support the claim that 'the traditional {"\n"}comic industry in Hong Kong
    //       is dying'?
    //       <br />
    //       (i)___________
    //       <br />
    //       (ii)___________
    //     </p>
    //   ),
    // },
    {
      type: QuestionType.SHORT_QUESTION,
      ability: QuestionAbility.FACTUAL_EVIDENCE,
      description:
        "Giving textual evidence that supports the statement in the question.",
      example: (
        <p className="leading-5 max-w-[470px] text-white/70 font-medium md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px]">
          <span className="leading-5 text-white font-medium md:text-[10px] lg:text-[12px] xl:text-[14px]">
            Give two pieces of evidence the writer provides in paragraph 3 to
            support the claim that 'the traditional comic industry in Hong Kong
            is dying'?
          </span>
          <br />
          (i)______________
          <br />
          (ii)______________
        </p>
      ),
    },
    {
      type: QuestionType.SHORT_QUESTION,
      ability: QuestionAbility.FACTUAL_TERM_IDENFITICATION,
      description: "Identify specific information located in the text.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 text-white font-medium md:text-[10px] lg:text-[12px] xl:text-[14px]">
            What are the terms listed on lines 4-7 examples for?
          </span>
          <br />
          <br />
          ___________
        </p>
      ),
    },
    {
      type: QuestionType.SHORT_QUESTION,
      ability: QuestionAbility.FACTUAL_NUMBER,
      description: "Identifying specific key number information in the text.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] max-w-[470px] text-white/70 font-medium">
          <span className="leading-5 text-white font-medium md:text-[10px] lg:text-[12px] xl:text-[14px]">
            How many people posted in the forum?
          </span>
          <br />
          <br />
          ___________
        </p>
      ),
    },
    {
      type: QuestionType.MULTIPLE_CHOICE,
      ability: QuestionAbility.REFERENCE_MC,
      description:
        "Understanding the meaning of pronouns or other words that refer to something previously mentioned in the passage.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:text-[12px] lg:max-w-[350px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 text-white font-medium md:text-[10px] lg:text-[12px] xl:text-[14px]">
            Who does "that" refer to?
          </span>
          {"\n"}
          A.Children playing around the estate{"\n"}
          B.Children going into sensitive areas{"\n"}
          C. Children pulling leaves from the plants{"\n"}
          D. Children sticking things in their mouths
        </p>
      ),
    },
    {
      type: QuestionType.SHORT_QUESTION,
      ability: QuestionAbility.REFERENCE_SQ,
      description: "Understanding reference words and phrases.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:text-[12px] lg:max-w-[350px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 text-white font-medium md:text-[10px] lg:text-[12px] xl:text-[14px]">
            Who or what does "we"(line 24) refer to?
          </span>
          <br />
          <br />
          ___________
        </p>
      ),
    },
    {
      type: QuestionType.FILL_THE_BLANK,
      ability: QuestionAbility.REFERENCE_FILLING,
      description:
        "Students should find what does the word/phrase in the paragraph refers\n to the paragraph in which the word/phrase is from.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:text-[12px] lg:max-w-[350px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 text-white font-medium md:text-[10px] lg:text-[12px] xl:text-[14px]">
            According to paragraph 12, what is the 'education intervention'
            {" (line 70)"} required?
          </span>
          {"\n"}
          {"\n"}
          <span className="leading-5 text-white font-medium md:text-[10px] lg:text-[12px] xl:text-[14px] break-all">
          Education institutions must address
          ___________________________________________________________.
          </span>
        </p>
      ),
    },
    {
      type: QuestionType.MULTIPLE_CHOICE,
      ability: QuestionAbility.VOCABULARY_MEAN,
      description: "Identifying the meaning of words and phrases.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:text-[12px] lg:max-w-[350px] xl:max-w-[400px] xl:text-[14px]  text-white/70 font-medium">
          <span className="leading-5 text-white font-medium md:text-[10px] lg:text-[12px] xl:text-[14px]">
            Do you think it is beneficial that 'The threshold to enter the{" "}
            {"\n"}
            scene has become low'? Give evidence from the test to support your answer.
          </span>
          <br />
          <br />
          A. to stop Al systems from self-policing {"\n"}
          B. to form a consensus on how to use AI {"\n"}
          C. to ensure businesses use Al responsibly {"\n"}
          D. to meet the demand for AI technical talent
        </p>
      ),
    },
    {
      type: QuestionType.MULTIPLE_CHOICE,
      ability: QuestionAbility.VOCABULARY_PURPOSE,
      description: "Understanding the purpose of the text.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:text-[12px] lg:max-w-[350px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="text-white font-medium leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px]">
            What is the purpose of the pharse 'In truth' (line 10)?{" "}
          </span>
          <br />
          A. to concule an idea <br />
          B. to show cause and effect <br />
          C. to show a contrast in ideas
          <br />
          D. to support the previous point
        </p>
      ),
    },
    {
      type: QuestionType.SHORT_QUESTION,
      ability: QuestionAbility.VOCABULARY_SYNONYM,
      description:
        "Locating a word that has a similar meaning to another word.",
      example: (
        <p className="max-w-[470px] text-white/70 font-medium">
          <span className="text-white font-medium">
            Find a word in paragraph 3 that has similar meaning to 'dislike'.
          </span>
          <br />
          <br />
          ___________________
        </p>
      ),
    },
    {
      type: QuestionType.SHORT_QUESTION,
      ability: QuestionAbility.VOCABULARY_PHRASE_SYNONYM,
      description:
        "Locating a word or phrase that has a similar meaning to another phrase.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:text-[12px] lg:max-w-[350px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="text-white font-medium leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px]">
            Find a word or phrase in paragraph 6 which has similar meaning to
            'facing difficulties'.
          </span>
          <br />
          <br />
          ___________________
        </p>
      ),
    },
    {
      type: QuestionType.SHORT_QUESTION,
      ability: QuestionAbility.VOCABULARY_SUPPORT_STATEMENT,
      description:
        "Locating a word or phrase that supports the questions’ statement.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:text-[12px] lg:max-w-[350px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="text-white font-medium leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px]">
            Which a word in paragraph 1 suggests that the writer thought the
            moon landing was an amazing achievement?
          </span>
          <br />
          <br />
          ___________________
        </p>
      ),
    },
    {
      type: QuestionType.SHORT_QUESTION,
      ability: QuestionAbility.VOCABULARY_PHRASE_MEANING,
      description:
        "Identifying the meaning of phrases in context to the sentence it belongs.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:text-[12px] lg:max-w-[350px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="text-white font-medium leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px]">
            What does 'portfolio careerist' (line 1) mean?
          </span>
          <br />
          <br />
          ___________________
        </p>
      ),
    },
    // {
    //   type: QuestionType.MULTIPLE_CHOICE,
    //   ability: QuestionAbility.FIGURATIVE_LANG,
    //   description:
    //     "Interpreting metaphors, similes, or other figures of speech used in the passage",
    //   example: (
    //            <p  className="max-w-[470px] text-white/70 font-medium">
    //       <span className="text-white font-medium">What does the phrases 'the pendulum has definitely shifted' mean?</span>
    //
    //       <br />
    //       A. people now only want food if it is convenient <br />
    //       B. Modern artificial preservatives now make us sick
    //       <br />
    //       C. The existence of processed food has been challenged
    //       <br />
    //       D. Shoppers place less value on convenience and worry more about
    //       health
    //     </p>
    //   ),
    // },
    {
      type: QuestionType.MULTIPLE_CHOICE,
      ability: QuestionAbility.FIGURATIVE_LANG_PHRASE_MEANING,
      description:
        "Interpreting the meaning of the metaphor based on the context in the text.",
      warning:
        "WARNING: Please make sure there are metaphors in selected passage",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px] xl:leading-6 text-white font-medium">
            What does the phrase 'the pendulum has definitely shifted'(line 18)
            mean?
          </span>
          <br />
          A. People now only want food if it is convenient <br />
          B. Modern artificial preservatives now make us sick
          <br />
          C. The existence of processed food has been challenged
          <br />
          D. Shoppers place less value on convenience and worry more about
          health
        </p>
      ),
    },
    {
      type: QuestionType.SHORT_QUESTION,
      ability: QuestionAbility.FIGURATIVE_LANG_METAPHOR,
      description:
        "1. Finding the metaphor that is used to describe things\n2. Interpreting metaphors, similes, or other figures of speech used in the passage",
      warning:
        "WARNING: Please make sure there are metaphors in selected passage",
      example: (
        <p className="max-w-[470px] text-white/70 font-medium">
          <span className="text-white font-medium">
            In paragraph 1, what metaphor is used to describe AI?
          </span>
          <br />
          <br />
          ______________
        </p>
      ),
    },
    {
      type: QuestionType.LONG_QUESTION,
      ability: QuestionAbility.FIGURATIVE_LANG_INFER_LINES,
      description:
        "1. Inferring the phrase in a certain paragraph\n2. Interpreting metaphors, similes, or other figures of speech used in the passage",
      warning:
        "WARNING: Please make sure there are metaphors in selected passage",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="text-white font-medium leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px]">
            What does 'it may one day become a relic' (line 14) suggest about
            the comic industry in Hong Kong?
          </span>
          <br />
          <br />
          ________________________________________
          <br />
          <br />
          ________________________________________
        </p>
      ),
    },
    // {
    //   type: QuestionType.MULTIPLE_CHOICE,
    //   ability: QuestionAbility.VIEW_ATTITUDE,
    //   description:
    //     "Interpreting views & attutude, tone and mood of the passage or a speaker Identifying the tone or mood of the passage or interpreting the attitudes or viewpoints expressed by the author or speaker",
    //   example: (
    //            <p  className="max-w-[470px] text-white/70 font-medium">

    //       <span className="text-white font-medium">What does the writer mean when using the phrase 'stay in tune with'?</span>
    //       <br />
    //       A. Comic artists adjusting to their audiences' perferences
    //       <br />
    //       B. The growth of lifestyle comics about girlfriends and boyfriends
    //       <br />
    //       C. Old-school Hong Kong comic artists working with japanese artists
    //       <br />
    //       D. The cooperation between local Hong Kong and international comic
    //       artists
    //     </p>
    //   ),
    // },
    {
      type: QuestionType.MULTIPLE_CHOICE,
      ability: QuestionAbility.VIEW_ATTITUDE_TONE,
      description:
        "Identifying the appropriate tone of the writer or the speaker based on a text passage.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px] text-white font-medium">
            What is the tone of Prof.Lee in paragraph 2?
          </span>
          <br />
          A. critical
          <br />
          B. sarcastic
          <br />
          C. aggressive
          <br />
          D. indifferent
        </p>
      ),
    },
    {
      type: QuestionType.MULTIPLE_CHOICE,
      ability: QuestionAbility.VIEW_ATTITUDE_VIEW,
      description:
        "Inferring the view of the writer or the speaker based on a text passage.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px] text-white font-medium">
            What is the author's view towards the use of AI in this text?
          </span>
          <br />
          A. ironic
          <br />
          B. neutral
          <br />
          C. critical
          <br />
          D. supportive
        </p>
      ),
    },
    {
      type: QuestionType.MULTIPLE_CHOICE,
      ability: QuestionAbility.VIEW_ATTITUDE_ATTITUDE,
      description:
        "Inferring the speaker's attitude toward a situation based on a text passage.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px] text-white font-medium">
            According to paragraph 1-10, what is the writer's attitude {"\n"}
            towards the trend of the slasher?
          </span>
          <br />
          A. critical
          <br />
          B. neutral
          <br />
          C. doubtful
          <br />
          D. supportive
        </p>
      ),
    },
    {
      type: QuestionType.MULTIPLE_CHOICE,
      ability: QuestionAbility.VIEW_ATTITUDE_MEAN,
      description:
        "Inferring the meaning of the phrase expressed by the speaker.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px] text-white font-medium">
            What does Mills mean when she says 'a huge head start' (line 14)?
          </span>
          <br />
          A. Small businesses are hampered by AI.
          <br />
          B. Big Business has been using AI for a long time.
          <br />
          C. AI can benefit both Big Business and small businesses.
          <br />
          D. Big Business has more resources than small businesses.
        </p>
      ),
    },
    {
      type: QuestionType.MULTIPLE_CHOICE,
      ability: QuestionAbility.VIEW_ATTITUDE_PERSON_INVOLVED,
      description:
        "Identifying the appropriate person involved in a certain text passage.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="md:text-[10px] lg:text-[12px] xl:text-[14px] text-white font-medium">
            What is Girlgeorgy thanking for their support in lin 18?
          </span>
          <br />
          A. Bazseedling and Susan_B
          <br />
          B. Susan_B and Crustyclown
          <br />
          C. Artsypatsy and Bazseedling
          <br />
          D. Crustyclown and Artsypatsy
        </p>
      ),
    },
    {
      type: QuestionType.MATCHING,
      ability: QuestionAbility.VIEW_ATTITUDE_LETTER_ONCE,
      description: "Matching the statement with the corresponding speaker.",
      example: (
        <p className="leading-4 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium ">
          <td className="text-white leading-5 font-medium md:text-[10px] lg:text-[12px] xl:text-[14px]">
            Read the statements in the right column and match each with ONE of
            the authors. Write the corresponding letter in the blank on the
            left. Use each letter ONCE only. ONE statement is NOT used.
          </td>
          <table className="leading-4 md:text-[10px] lg:text-[12px] xl:text-[14px]">
            <tr>
              <td>(i) ____Girlgeorgy{"    "}</td>
              <td>A. There are too many bugs on the estate.</td>
            </tr>
            <tr>
              <td>(ii)____Artspatsy{"    "}</td>
              <td>B. What I really want are flowers.</td>
            </tr>
            <tr>
              <td>(iii)____Bazseeding{"    "}</td>
              <td>C. Plants should be able to cope with lots of touching.</td>
            </tr>
            <tr>
              <td>(iv)____Crustyletown{"    "}</td>
              <td>D. I could take charge of the project.</td>
            </tr>
            <tr>
              <td>(v) _____Susan_B{"    "}</td>
              <td>E. Point the entrance with lots of colors.</td>
            </tr>
            <tr>
              <td></td>
              <td>F. Put a range of plants around the entrance.</td>
            </tr>
          </table>
        </p>
      ),
    },
    {
      type: QuestionType.SHORT_QUESTION,
      ability: QuestionAbility.VIEW_ATTITUDE_IMPLY,
      description: "Identifying the speaker’s implications based on the text.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px] text-white font-medium">
            What does the writer imply by saying 'he simply shrugs'(line 75)?
          </span>
          <br />
          <br />
          _____________________
        </p>
      ),
    },
    {
      type: QuestionType.LONG_QUESTION,
      ability: QuestionAbility.HIGHER_ORDER_OPINION_WITH_EVIDENCE,
      description:
        "Analysing information presented in the passage in a critical or creative manner.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px] text-white font-medium">
            Do you think it is beneficial that 'The threshold to enter the scene
            has become low'? Give evidence from the text to support your answer.
          </span>
          <br />
          <br />
          ________________________________________
          <br />
          <br />
          ________________________________________
        </p>
      ),
    },
    {
      type: QuestionType.LONG_QUESTION,
      ability: QuestionAbility.HIGHER_ORDER_INFER_THOUGHT,
      description:
        "Analysing the writer’s line of thought about a certain event.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px] text-white font-medium">
            Why does the writer suggest that the changes taking place are 'a
            strange turn of events'(line 15)?
          </span>
          <br />
          <br />
          ________________________________________
          <br />
          <br />
          ________________________________________
        </p>
      ),
    },
    {
      type: QuestionType.LONG_QUESTION,
      ability: QuestionAbility.HIGHER_ORDER_COMPARISONS,
      description: "Analysing the comparison between two separate events.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px] text-white font-medium">
            How are 'melting icebergs'(line 13-14) a good comparison for what is
            happening in food and beverage industry?
          </span>
          <br />
          <br />
          ________________________________________
          <br />
          <br />
          ________________________________________
        </p>
      ),
    },

    // {
    //   type: QuestionType.MULTIPLE_CHOICE,
    //   ability: QuestionAbility.VOCABULARY,
    //   description:
    //     "1. Identifying meaning of words and phrases\n2. Understanding the meaning of unfamiliar words or phrases used in the passage",
    //   example: (
    //     <p className="max-w-[470px] text-white/70 font-medium">
    //       <span className="text-white font-medium">
    //         What is the purpose of the pharse 'In truth'?{" "}
    //       </span>
    //       <br />
    //       A. to conclude a idea <br />
    //       B. to show a cause and effect <br />
    //       C. to show a contrast in idea
    //       <br />
    //       D. to support the previous point
    //     </p>
    //   ),
    // },
    // {
    //   type: QuestionType.MULTIPLE_CHOICE,
    //   ability: QuestionAbility.VOCABULARY,
    //   description:
    //     "1. Identifying meaning of words and phrases\n2. Understanding the meaning of unfamiliar words or phrases used in the passage",
    //   example: (
    //     <p className="max-w-[470px] text-white/70 font-medium">
    //       <span className="text-white font-medium">
    //         What is the purpose of the pharse 'In truth'?{" "}
    //       </span>
    //       <br />
    //       A. to conclude a idea <br />
    //       B. to show a cause and effect <br />
    //       C. to show a contrast in idea
    //       <br />
    //       D. to support the previous point
    //     </p>
    //   ),
    // },
    // {
    //   type: QuestionType.SHORT_QUESTION,
    //   ability: QuestionAbility.VOCABULARY,
    //   description:
    //     "1. Identifying meaning of words and phrases\n2. Understanding the meaning of unfamiliar words or phrases used in the passage",
    //   example: (
    //     <p  className="max-w-[470px] text-white/70 font-medium">
    //       <span className="text-white font-medium">Find a word or pharse in paragraph 4 which has {"\n"}similar meaning to
    //       'naitve'</span>
    //       <br />
    //       <br />
    //       ___________
    //     </p>
    //   ),
    // },
    // {
    //   type: QuestionType.MULTIPLE_CHOICE,
    //   ability: QuestionAbility.FIGURATIVE_LANG,
    //   description:
    //     "Interpreting metaphors, similes, or other figures of speech used in the passage",
    //   example: (
    //     <p className="max-w-[470px] text-white/70 font-medium">
    //       <span className="text-white font-medium">
    //         What does the phrases 'the pendulum has definitely shifted' mean?
    //       </span>
    //       <br />
    //       A. people now only want food if it is convenient <br />
    //       B. Modern artificial preservatives now make us sick
    //       <br />
    //       C. The existence of processed food has been challenged
    //       <br />
    //       D. Shoppers place less value on convenience and worry more about
    //       health
    //     </p>
    //   ),
    // },
    {
      type: QuestionType.MULTIPLE_CHOICE,
      ability: QuestionAbility.FIGURATIVE_LANG,
      description:
        "Interpreting metaphors, similes, or other figures of speech used in the passage",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:text-[12px] lg:max-w-[350px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="text-white font-medium leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px]">
            What does the phrases 'the pendulum has definitely shifted' mean?
          </span>
          <br />
          A. people now only want food if it is convenient <br />
          B. Modern artificial preservatives now make us sick
          <br />
          C. The existence of processed food has been challenged
          <br />
          D. Shoppers place less value on convenience and worry more about
          health
        </p>
      ),
    },
    {
      type: QuestionType.MULTIPLE_CHOICE,
      ability: QuestionAbility.VIEW_ATTITUDE,
      description:
        "Interpreting views & attitudes, tone and mood of the passage or a speaker Identifying the tone or mood of the passage or interpreting the attitudes or viewpoints expressed by the author or speaker",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:text-[12px] lg:max-w-[350px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="text-white font-medium leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px]">
            What does the writer mean when using the phrase 'stay in tune with'?
          </span>
          <br />
          A. Comic artists adjusting to their audiences' perferences
          <br />
          B. The growth of lifestyle comics about girlfriends and boyfriends
          <br />
          C. Old-school Hong Kong comic artists working with japanese artists
          <br />
          D. The cooperation between local Hong Kong and international comic
          artists
        </p>
      ),
    },
    {
      type: QuestionType.SHORT_QUESTION,
      ability: QuestionAbility.RELATIONSHIP_CAUSES,
      description: "Identifying the cause of a certain phrase or event.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px] text-white font-medium">
            What is the cause of the "Panic"(line 30)?
          </span>
          <br />
          <br />
          People's perception that AI _____________
        </p>
      ),
    },
    {
      type: QuestionType.SHORT_QUESTION,
      ability: QuestionAbility.RELATIONSHIP_REASONING,
      description:
        "Understanding why certain events happened the way they were.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px] text-white font-medium">
            According to paragraph 2, why have people started to take on various
            jobs?
          </span>
          <br />
          <br />
          ____________________
        </p>
      ),
    },
    {
      type: QuestionType.TABLE,
      ability: QuestionAbility.RELATIONSHIP,
      description:
        "Understanding what and how issues are solved as well as their implications based on a text passage.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px]">
          <span className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px] text-white font-medium">
            According to paragraph 7, the use of AI may have benefits as well as
            cause harm. Complete the table below with a word or phrase taken
            from paragraph 7 in each blank. Your answers must be grammatically
            correct.
          </span>
          <br />
          <br />
          <table className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] border border-slate-400 border-collapse">
            <tr>
              <td className="border p-1 border-slate-300">
                <span className="text-white font-medium">Issue</span>{" "}
              </td>
              <td className="border p-1 border-slate-300 text-white/70 font-medium">
                Using AI to screen (i)____________of individuals
              </td>
            </tr>
            <tr>
              <td className="border p-1 border-slate-300">
                <span className="text-white font-medium">
                  Anticipated benefit
                </span>{" "}
              </td>
              <td className="border p-1 border-slate-300 text-white/70 font-medium">
                Banks could give loans to (ii)_______________.
              </td>
            </tr>
            <tr>
              <td className="border p-1 border-slate-300">
                <span className="text-white font-medium">
                  Possible harm caused
                </span>
              </td>
              <td className="border p-1 border-slate-300 text-white/70 font-medium">
                There could be prejudices.
              </td>
            </tr>
          </table>
        </p>
      ),
    },
    {
      type: QuestionType.MULTIPLE_CHOICE,
      ability: QuestionAbility.INFERENCE_PHRASE_SUGGESTION,
      description:
        "Making suggestions based on the phrase in context to the sentence it belongs.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px] text-white font-medium">
            What do the words 'tiny, cramped and fragile collection of metal and
            wiring' (lines 7-8) suggest?
          </span>
          <br />
          A. Space was exciting for a human explorer. <br />
          B. The journey was uncomfortable and dangerous. <br />
          C. The mission was highly sophisticated for its time. <br />
          D. NASA used substandard materials for the mission.
        </p>
      ),
    },
    {
      type: QuestionType.MULTIPLE_CHOICE,
      ability: QuestionAbility.INFERENCE_ACCORDING_TO,
      description:
        "Drawing conclusions based on the information in the passage.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px] text-white font-medium">
            According to paragraph 1, what did the Apollo 11 mission accomplish?
          </span>
          <br />
          A. It avoided an intrepid exploration. <br />
          B. It made the human race immortal. <br />
          C. It fulfilled a dream of humans to land on the Moon. <br />
          D. It went in fits and starts to find our place in the universe.
        </p>
      ),
    },
    {
      type: QuestionType.SHORT_QUESTION,
      ability: QuestionAbility.INFERENCE_PHRASE_RELATIONS,
      description: "Analysing the meaning of a certain phrase.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px] text-white font-medium">
            Why does Crustyclown say 'You are all dreaming' (line 15).
          </span>
          <br />
          <br />
          Because Crustyclown wants to show _______________
        </p>
      ),
    },
    {
      type: QuestionType.SHORT_QUESTION,
      ability: QuestionAbility.INFERENCE_PHRASE_MEANING,
      description:
        "1. Finding the meaning of the phrase that matches its contexts\n2. Drawing conclusions or making predictions based on information presented in the passage",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px] text-white font-medium">
            What does 'Parking up' in the title mean?
          </span>
          <br />
          <br />
          ___________________
        </p>
      ),
    },
    {
      type: QuestionType.SHORT_QUESTION,
      ability: QuestionAbility.INFERENCE_IMPLY,
      description:
        "Understanding the meaning and implication of the phrase and connect them to another thing.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px] text-white font-medium">
            What does the phrase 'gulit-free'(line 18) imply about the writer's
            attitude to fast food in general?
          </span>
          <br />
          <br />
          ______________________________________
        </p>
      ),
    },
    {
      type: QuestionType.SHORT_QUESTION,
      ability: QuestionAbility.INFERENCE_EVIDENCE,
      description: "Giving evidence that supports the question statement.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px]">
          <span className="text-white font-medium leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px]">
            With reference to paragraph 11, state ONE reason why Alan Wan
            prefers working in the Mainland?
          </span>
          <br />
          <br />
          ______________________________________
        </p>
      ),
    },
    {
      type: QuestionType.SHORT_QUESTION,
      ability: QuestionAbility.INFERENCE_SQ,
      description:
        "Drawing conclusions or making predictions based on information presented in the passage.",
      example: (
        <p className="leading-5 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <span className="leading-5 md:text-[10px] lg:text-[12px] xl:text-[14px] text-white font-medium">
            What does Lam want to show with example of 'the Kowloon Walled
            City'(line 27)?
          </span>
          <br />
          <br />
          ______________________________________
        </p>
      ),
    },
    {
      type: QuestionType.TRUE_FALSE,
      ability: QuestionAbility.INFERENCE_TF,
      description:
        "Deciding if the statement is correct, incorrect, or has no information based on the text.",
      example: (
        <p className="leading-4 md:max-w-[300px] md:text-[10px] lg:max-w-[350px] lg:text-[12px] xl:max-w-[400px] xl:text-[14px] text-white/70 font-medium">
          <div className="grid grid-cols-12 gap-2 gap-x-4 md:max-w-[300px] lg:max-w -[350px] xl:max-w-[400px]">
            <div className="leading-4 md:text-[10px] lg:text-[12px] xl:text-[14px] font-bold col-span-9">
              Statement
            </div>
            <div className="leading-4 font-bold  md:text-[10px] lg:text-[12px] xl:text-[14px]">
              T
            </div>
            <div className="leading-4 font-bold  md:text-[10px] lg:text-[12px] xl:text-[14px]">
              F
            </div>
            <div className="leading-4 font-bold  md:text-[10px] lg:text-[12px] xl:text-[14px]">
              NG
            </div>
            <div className="col-span-9 text-white font-medium leading-4 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              (i) Prof. Lee provides evidence of life forms on other planets.
            </div>
            <div className="leading-4 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              O
            </div>{" "}
            <div className="leading-4 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              O
            </div>{" "}
            <div className="leading-4 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              O
            </div>
            <div className="col-span-9 text-white font-medium leading-4 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              (ii) Prof. Cheung believes that the planetary system would solve
              the ethical problems related to exploring other planets.
            </div>
            <div className="leading-4 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              O
            </div>{" "}
            <div className="leading-4 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              O
            </div>{" "}
            <div className="leading-4 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              O
            </div>
            <div className="col-span-9 text-white font-medium leading-4 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              (iii) Prof. Lee believes that the landing of spacecraft could
              cause the spread of microorganisms across the surface of the
              planet.
            </div>
            <div className="leading-4 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              O
            </div>{" "}
            <div className="leading-4 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              O
            </div>{" "}
            <div className="leading-4 md:text-[10px] lg:text-[12px] xl:text-[14px]">
              O
            </div>
          </div>
        </p>
      ),
    },
  ];

  return (
    <section className="overflow-y-auto flex flex-col">
      <header className="flex items-center gap-3 font-semibold mb-3 self-start">
        <IconQuestionType className="w-[1.2em] h-[1.2em]" />
        Choose Question Type
      </header>

      <div className="p-4 bg-greyBackgroundColour flex flex-col gap-4 rounded-[12px] overflow-y-auto flex-grow flex-shrink basis-0">
        {questionTypes.map((questionType, i) => (
          <QuestionTypeCard
            key={i}
            {...questionType}
            ability={questionType.ability}
            index={i + 1}
            onSelect={() => {
              onSelectType(questionType.type, questionType.ability); // pass to parent
              setSelectedAbility(questionType.ability); // change selected ability
              setSelectedType(questionType.type); // change the selected type
            }}
            selected={
              selectedType === questionType.type &&
              selectedAbility === questionType.ability
            }
            example={questionType.example}
          />
        ))}
      </div>
    </section>
  );
}

export default SelectQuestionType;
