import Button, { ButtonProps } from "./Button";
import cx from "classnames";

function ActionButton({
  text,
  icon,
  className,
  onClick = () => {},
  disable,
}: ButtonProps) {
  return (
    <Button
      icon={icon}
      text={text}
      className={cx(
        "text-sm !font-semibold !rounded-22xl border !px-[12px] !py-[6px] border border-pillColour",
        className
      )}
      onClick={onClick}
      disable={disable}
    />
  );
}

export default ActionButton;
