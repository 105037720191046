import { ReactComponent as IconParagraph } from "../../icons/IconParagraph.svg";
import cx from "classnames";

interface CurrentSelectionProps {
  selections: string | string[];
  className?: string;
  NumberOfParagraph: number;
}

function CurrentSelection({
  selections,
  className,
  NumberOfParagraph,
}: CurrentSelectionProps) {
  let text;
  if (!Array.isArray(selections)) {
    text = [selections];
    selections = "[" + NumberOfParagraph.toString + "] " + selections;
  } else {
    text = selections;
    selections = "[" + NumberOfParagraph.toString + "]\t" + selections;
  }

  return (
    <section className={cx("overflow-y-auto flex flex-col", className)}>
      <header className="flex items-center gap-3 font-semibold mb-3 self-start">
        <IconParagraph className="w-[1.2em] h-[1.2em]" />
        Current Selection
      </header>
      <article className="p-[10px] h-[40%] rounded-[8px] shadow-inner overflow-y-auto flex flex-col gap-4 flex-grow flex-shrink basis-0">
        {text
          .filter((str) => str)
          .map((selection, index) => (
            <div className="flex" key={index}>
              <p className="pl-[4px] flex-[1] items-start">
                {"[" + (NumberOfParagraph + index) + "]"}{" "}
              </p>
              <p className="flex-[18] items-start">
                {selection} <br></br>
              </p>
            </div>
          ))}
      </article>
    </section>
  );
}

export default CurrentSelection;
