import {
  AlignmentType,
  BorderStyle,
  Document,
  Footer,
  FrameAnchorType,
  Header,
  HeadingLevel,
  HorizontalPositionAlign,
  HorizontalPositionRelativeFrom,
  ImageRun,
  LevelFormat,
  LevelSuffix,
  NumberFormat,
  Packer,
  PageNumber,
  Paragraph,
  TabStopType,
  Table,
  TableCell,
  TableLayoutType,
  TableRow,
  TextRun,
  VerticalAlign,
  VerticalPositionAlign,
  VerticalPositionRelativeFrom,
  WidthType,
} from "docx";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import * as types from "../api/codegen/API";
import {
  getFtbAnswerFormat,
  getLqAnswerFormat,
  getLqQuestionFormat,
  getMCAnswerFormat,
  getMatchAnswerFormat,
  getMatchQuestionFormat,
  getMultipleChoiceFormat,
  getScAnswerFormat,
  getScQuestionFormat,
  getSqAnswerFormat,
  getSqQuestionFormat,
  getTableAnswerFormat,
  getTableQuestionFormat,
  getTfAnswerFormat,
  getTfQuestionFormat,
} from "./exportQuestionFormats";
import * as exportUtils from "./exportUtils";
import verticalText from "../assets/text.png";
import cornerLine from "../assets/line.png";
import nextPageText from "../assets/nextPageAnswerBook.png";
export async function exportExerciseHandler(
  exercise: types.Exercise,
  isPdfType: boolean,
  levelOfDiff: string,
  setNumber: string
) {
  try {
    if (!isPdfType) {
      await exportQuestionDoc(exercise, levelOfDiff, setNumber);
      exportPassageDoc(exercise, levelOfDiff, setNumber);
      exportAnswerKey(exercise, levelOfDiff, setNumber);
    } else
      alert(
        "Please export as docx instead or ask someone to implement this feature 👋"
      );

    return true;
  } catch (e) {
    console.error(e);
    toast.error(
      "Sorry, but we are unable to export the document. Please try again later.",
      {
        position: "bottom-right",
      }
    );
    return false;
  }
}

export async function exportQuestionDoc(
  exercise: types.Exercise,
  levelOfDiff: string,
  setNumber: string
) {
  let children: Paragraph[] = [
    new Paragraph({
      text: "Write your Candidate Number and stick a barcode label in the space provided on this page. ",
      style: "question-text",
    }),
    new Paragraph({
      text: `Read Text 6 and answer questions 1-${exercise.exerciseName.length}.`,
      style: "question-text",
    }),
    exportUtils.newLine(),
    exportUtils.newLine(),
  ];
  let levelOfDiffStatement: string;
  if (levelOfDiff === "A") levelOfDiffStatement = "COMPULSORY";
  else if (levelOfDiff === "B1") levelOfDiffStatement = "EASY";
  else levelOfDiffStatement = "DIFFICULT";

  if (!exercise.questions?.length) {
    children.push(
      new Paragraph({
        text: "[No questions in this exercise]",
        style: HeadingLevel.HEADING_3,
      })
    );
  } else {
    let questionPara: Paragraph[][] = exercise.questions.map((question) => {
      return questionToDocParagraph(question);
    });
    for (let abomination of questionPara!) {
      children = [...children, ...abomination];
    }
  }

  const docTable: Table[] = [
    new Table({
      borders: exportUtils.noTableBorder.borders,
      rows: [
        new TableRow({
          children: [
            new TableCell({
              // header
              children: [
                new Table({
                  width: {
                    size: exportUtils.exportSettings.TABLE_WIDTH,
                  },
                  borders: exportUtils.noTableBorder.borders,
                  rows: [
                    new TableRow({
                      // MAIN ROW
                      children: [
                        new TableCell({
                          // left side the header
                          children: [
                            new Paragraph(""),
                            new Table({
                              rows: [
                                new TableRow({
                                  // first row of the left side
                                  children: [
                                    new TableCell({
                                      verticalAlign: VerticalAlign.CENTER,
                                      margins: {
                                        bottom: 150,
                                      },
                                      children: [exportUtils.candidateNumber()],
                                      borders:
                                        exportUtils.noTableBorder.borders,
                                    }),
                                    new TableCell({
                                      borders:
                                        exportUtils.noTableBorder.borders,
                                      children: [
                                        exportUtils.setTable(setNumber),
                                      ],
                                    }),
                                  ],
                                }),
                                //second row of the left side
                                new TableRow({
                                  children: [
                                    new TableCell({
                                      borders:
                                        exportUtils.noTableBorder.borders,
                                      children: [
                                        new Table({
                                          margins: {
                                            // top: 200,
                                          },
                                          borders:
                                            exportUtils.noTableBorder.borders,
                                          rows: [
                                            new TableRow({
                                              children: [
                                                exportUtils.headerDescription(
                                                  levelOfDiff
                                                ),

                                                new TableCell({
                                                  borders: {
                                                    top: {
                                                      size: 10,
                                                      style: BorderStyle.OUTSET,
                                                    },
                                                    bottom: {
                                                      size: 10,
                                                      style: BorderStyle.OUTSET,
                                                    },
                                                    right: {
                                                      size: 10,
                                                      style: BorderStyle.OUTSET,
                                                    },
                                                    left: {
                                                      size: 10,
                                                      style: BorderStyle.OUTSET,
                                                    },
                                                  },
                                                  children: [
                                                    exportUtils.sectionTitle(
                                                      90,
                                                      levelOfDiff === "A"
                                                        ? 15
                                                        : 18,
                                                      levelOfDiff,
                                                      levelOfDiffStatement
                                                    ),
                                                  ],
                                                }),
                                              ],
                                            }),
                                          ],
                                        }),
                                      ],
                                    }),
                                    new TableCell({
                                      borders:
                                        exportUtils.noTableBorder.borders,
                                      margins: {
                                        top: 100,
                                        bottom: 100,
                                        right: 100,
                                        left: 350,
                                      },
                                      children: [exportUtils.barcodeTable()],
                                    }),
                                  ],
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],

                  layout: TableLayoutType.AUTOFIT,
                }),
              ],
              borders: exportUtils.noTableBorder.borders,
            }),
          ],
        }),
        new TableRow({
          // cantSplit: true,
          children: [
            new TableCell({
              children: [
                ...children,
                ...exportUtils.trailingLines,

                exportUtils.answerLine(),
                new Paragraph(""),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `END OF PART ${levelOfDiff}`,
                      bold: true,
                    }),
                  ],
                  style: "question-text",
                  alignment: AlignmentType.CENTER,
                }),
              ],
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },

              borders: {
                top: exportUtils.pageBorderSetting,
                left: exportUtils.pageBorderSetting,
                right: exportUtils.pageBorderSetting,
                bottom: exportUtils.pageBorderSetting,
              },
            }),
          ],
        }),
      ],
    }),
  ];

  const fetchImage = async (imageUrl: string) => {
    const res = await fetch(imageUrl);
    const imageBlob = await res.arrayBuffer();
    return imageBlob;
  };
  const doc = new Document({
    styles: {
      paragraphStyles: [
        exportUtils.questionTextStyle,
        exportUtils.circleStyle,
        exportUtils.boldStyle,
      ],
    },
    numbering: {
      config: [
        {
          reference: "my-numbering",
          levels: [
            {
              level: 0,
              format: LevelFormat.DECIMAL,
              text: "%1.",
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  rightTabStop: 140,
                  leftTabStop: 300,
                },
              },
            },
          ],
        },
        {
          reference: "lower-roman",
          levels: [
            {
              level: 0,
              format: LevelFormat.LOWER_ROMAN,
              text: "%1)",
              start: 1,
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  rightTabStop: 140,
                  leftTabStop: 300,
                },
              },
              suffix: LevelSuffix.NOTHING,
            },
          ],
        },
      ],
    },
    sections: [
      {
        children: docTable,
        properties: {
          page: {
            margin: {
              top: exportUtils.cmToDocDXA(1.27),
              bottom: 100,
              left: exportUtils.cmToDocDXA(1.9),
              right: exportUtils.cmToDocDXA(1.95),
            },
          },
        },
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                text: "Answers written in the margins will not be marked.",
              }),
              new Paragraph({
                text: `READily-DSE-ENG LANG 1-${levelOfDiff}–5`,
              }),
              new Paragraph({
                frame: {
                  position: {
                    x: -650,
                    y: 6000,
                  },
                  width: 500,
                  height: 288,
                  anchor: {
                    horizontal: FrameAnchorType.MARGIN,
                    vertical: FrameAnchorType.MARGIN,
                  },
                  alignment: {
                    x: HorizontalPositionAlign.LEFT,
                    y: VerticalPositionAlign.OUTSIDE,
                  },
                },

                children: [
                  new ImageRun({
                    //This IMAGE
                    data: await fetchImage(verticalText),
                    transformation: {
                      width: 41,
                      height: 288,
                    },
                  }),
                ],
              }),
              new Paragraph({
                frame: {
                  position: {
                    x: 9800,
                    y: 6000,
                  },
                  width: 500,
                  height: 288,
                  anchor: {
                    horizontal: FrameAnchorType.MARGIN,
                    vertical: FrameAnchorType.MARGIN,
                  },
                  alignment: {
                    x: HorizontalPositionAlign.RIGHT,
                    y: VerticalPositionAlign.OUTSIDE,
                  },
                },

                children: [
                  new ImageRun({
                    //This IMAGE
                    data: await fetchImage(verticalText),
                    transformation: {
                      width: 41,
                      height: 288,
                    },
                  }),
                ],
              }),
              new Paragraph({
                frame: {
                  position: {
                    x: 7800,
                    y: 15000,
                  },
                  width: 3000,
                  height: 41,
                  anchor: {
                    horizontal: FrameAnchorType.MARGIN,
                    vertical: FrameAnchorType.MARGIN,
                  },
                  alignment: {
                    x: HorizontalPositionAlign.RIGHT,
                    y: VerticalPositionAlign.OUTSIDE,
                  },
                },

                children: [
                  new ImageRun({
                    data: await fetchImage(nextPageText),
                    transformation: {
                      width: 159.87,
                      height: 41,
                    },
                  }),
                ],
              }),
            ],
          }),
        },
      },
    ],
  });
  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, "answer-book.docx");
  });
}

function questionToDocParagraph(question: types.Question): Paragraph[] {
  let result: any[] = [
    new Paragraph({
      text: `   ${question.questionStatement}`,
      style: "question-text",
      numbering: {
        reference: "my-numbering",
        level: 0,
      },
    }),
  ];

  switch (question.questionType) {
    case types.QuestionType.MULTIPLE_CHOICE: {
      result = getMultipleChoiceFormat(question, result);
      break;
    }

    case types.QuestionType.TRUE_FALSE: {
      result = getTfQuestionFormat(question, result);
      break;
    }

    case types.QuestionType.LONG_QUESTION: {
      result = getLqQuestionFormat(result);
      break;
    }
    case types.QuestionType.FILL_THE_BLANK: {
      break;
    }
    case types.QuestionType.SUMMARY_CLOZE: {
      result = getScQuestionFormat(question, result);
      break;
    }
    case types.QuestionType.MATCHING: {
      result = getMatchQuestionFormat(question, result);
      break;
    }
    case types.QuestionType.TABLE: {
      result = getTableQuestionFormat(question, result);
      break;
    }

    default: {
      result = getSqQuestionFormat(result);
    }
  }
  return result;
}

export async function exportPassageDoc(
  exercise: types.Exercise,
  levelOfDiff: string,
  setNumber: string
) {
  let levelOfDiffStatement: string;
  if (levelOfDiff === "A") levelOfDiffStatement = "COMPULSORY";
  else if (levelOfDiff === "B1") levelOfDiffStatement = "EASY";
  else levelOfDiffStatement = "DIFFICULT";
  const fetchImage = async (imageUrl: string) => {
    const res = await fetch(imageUrl);
    const imageBlob = await res.arrayBuffer();
    return imageBlob;
  };

  const doc = new Document({
    styles: {
      paragraphStyles: [exportUtils.questionTextStyle],
    },

    numbering: {
      config: [
        {
          reference: "para-numbering",
          levels: [
            {
              level: 0,
              format: LevelFormat.DECIMAL,
              text: "[%1]",
              alignment: AlignmentType.START,
              suffix: LevelSuffix.NOTHING,
            },
          ],
        },
      ],
    },
    sections: [
      {
        properties: {
          page: {
            pageNumbers: {
              start: 1,
              formatType: NumberFormat.DECIMAL,
            },
            margin: {
              top: exportUtils.cmToDocDXA(1.27),
              bottom: exportUtils.cmToDocDXA(1.75),
              left: exportUtils.cmToDocDXA(1),
              right: exportUtils.cmToDocDXA(1.4), //1.95
            },
          },
        },
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    children: [
                      `READily-DSE-ENG LANG 1-${levelOfDiff}-RP-`,
                      PageNumber.CURRENT,
                      "                                        ",
                      PageNumber.CURRENT,
                    ],
                  }),
                ],
              }),
            ],
          }),
        },
        children: [
          new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    columnSpan: 3,
                    borders: exportUtils.noTableBorder.borders,
                    children: [
                      exportUtils.leftTopContent(
                        levelOfDiff,
                        levelOfDiffStatement
                      ),
                      new Paragraph({
                        frame: {
                          position: {
                            x: 0,
                            y: 5000,
                          },
                          width: 10,
                          height: 600,
                          anchor: {
                            horizontal: FrameAnchorType.MARGIN,
                            vertical: FrameAnchorType.MARGIN,
                          },
                          alignment: {
                            x: HorizontalPositionAlign.OUTSIDE,
                            y: VerticalPositionAlign.OUTSIDE,
                          },
                        },

                        children: [
                          new ImageRun({
                            data: await fetchImage(cornerLine),
                            transformation: {
                              width: 700,
                              height: 7,
                              rotation: -50,
                            },
                            floating: {
                              horizontalPosition: {
                                relative:
                                  HorizontalPositionRelativeFrom.LEFT_MARGIN,
                                align: HorizontalPositionAlign.CENTER,
                              },
                              verticalPosition: {
                                relative:
                                  VerticalPositionRelativeFrom.TOP_MARGIN,
                                offset: 1500000,
                              },
                            },
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    columnSpan: 3,
                    borders: exportUtils.noTableBorder.borders,

                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "READily EXAMINATIONS AND ASSESSMENT AUTHORITY",
                            size: 20,
                            font: {
                              name: "Arial",
                            },
                          }),
                        ],
                      }),
                      exportUtils.newLine(),
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "HONG KONG DIPLOMA OF SECONDARY EDUCATION EXAMINATION 2022 (MOCK)",
                            size: 20,
                            font: {
                              name: "Arial",
                            },
                          }),
                        ],
                      }),
                      exportUtils.newLine(),
                      exportUtils.newLine(),
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "ENGLISH LANGUAGE PAPER 1",
                            bold: true,
                            size: 34,
                            font: {
                              name: "Arial",
                            },
                          }),
                        ],
                      }),
                      exportUtils.newLine(),
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: `PART ${levelOfDiff}`,
                            bold: true,
                            size: 34,
                            font: {
                              name: "Arial",
                            },
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              exportUtils.readingPassagesRow(setNumber),
            ],
          }),
          exportUtils.newLine(),
          exportUtils.newLine(),
          new Table({
            margins: {
              left: exportUtils.cmToDocDXA(0.9),
            },
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    columnSpan: 2,
                    borders: exportUtils.noTableBorder.borders,
                    children: exportUtils.generalInstrcutions(levelOfDiff),
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    borders: exportUtils.noTableBorder.borders,
                    width: {
                      size: 70,
                      type: WidthType.PERCENTAGE,
                    },
                    children: [new Paragraph(" ")],
                  }),
                  exportUtils.notTakenAwayField(),
                ],
              }),
            ],
          }),
          exportUtils.newLine(),
          exportUtils.newLine(),
          exportUtils.newLine(),

          new Table({
            margins: {
              left: exportUtils.cmToDocDXA(0.9),
            },
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    columnSpan: 3,
                    borders: exportUtils.noTableBorder.borders,
                    children: [
                      new Paragraph({
                        spacing: {
                          after: 100,
                        },
                        children: [
                          new TextRun({
                            text: `PART ${levelOfDiff}`,
                            font: "Arial",
                            size: 24,
                            bold: true,
                          }),
                        ],
                      }),

                      new Paragraph({
                        spacing: {
                          after: 100,
                        },
                        children: [
                          new TextRun({
                            text: `Read Text 6 and answer questions 1-${exercise.questions.length} in the Question-Answer Book for Part ${levelOfDiff}.`,
                            size: 20,
                            font: "Arial",
                          }),
                        ],
                      }),

                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Text 6",
                            font: "Arial",
                            size: 24,
                            bold: true,
                          }),
                        ],
                      }),

                      exportUtils.newLine(),
                      exportUtils.newLine(),
                      exportUtils.newLine(),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: exercise.articleName
                              ? exercise.articleName
                              : "[Untitled article]",
                            font: "Arial",
                            size: 24,
                            bold: true,
                          }),
                        ],
                      }),

                      exportUtils.newLine(),
                      exportUtils.newLine(),
                      new Table({
                        rows: [
                          new TableRow({
                            children: [
                              new TableCell({
                                borders: exportUtils.noTableBorder.borders,
                                width: {
                                  size: 4,
                                  type: WidthType.PERCENTAGE,
                                },
                                children: exportUtils.lineNumbers(
                                  exercise.article
                                ),
                              }),
                              new TableCell({
                                borders: exportUtils.noTableBorder.borders,
                                children: [
                                  ...articleToParagraphs(exercise.article),
                                ],
                              }),
                            ],
                          }),
                        ],
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "END OF READING PASSAGES",
                            bold: true,
                            font: "Arial",
                            size: 20,
                          }),
                        ],
                        style: "question-text",
                        alignment: AlignmentType.CENTER,
                      }),

                      exportUtils.newLine(),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Source of materials used in this paper will be acknowledged in the booklet ",
                            size: 20,
                          }),
                          new TextRun({
                            text: "HKDSE Mock Exam Question Papers ",
                            italics: true,
                            size: 20,
                          }),
                        ],
                        style: "question-text",
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "published by READily Examination and Assessment Authority at a later stage.",
                            size: 20,
                          }),
                        ],
                        style: "question-text",
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
        ],
      },
    ],
  });
  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, "passage-book.docx");
  });
}

export function articleToParagraphs(article: string): Paragraph[] {
  const trimmed: string[] = article.split(/\n+/).map((para) => para.trim());
  const paragraphs: Paragraph[] = trimmed
    .filter((str) => str)
    .map(
      (not_empty_str) =>
        new Paragraph({
          //style: "question-text",
          numbering: { reference: "para-numbering", level: 0 },
          children: [
            new TextRun({
              text: " " + not_empty_str,
            }),
          ],
        })
    );
  const result: Paragraph[] = paragraphs.reduce((prev, cur) => {
    prev.push(cur);
    prev.push(new Paragraph({}));
    return prev;
  }, [] as Array<Paragraph>);

  return result;
}

export function exportAnswerKey(
  exercise: types.Exercise,
  levelOfDiff: string,
  setNumber: string
) {
  const headerNameDoc: Header = new Header({
    children: [
      new Paragraph({
        text: `Answer Key for Part ${levelOfDiff} Set ${setNumber}`,
      }),
    ],
  });
  // successfully created the header of the table
  const tableHeader = new TableRow({
    children: [
      new TableCell({
        children: [
          new Paragraph({
            children: [
              new TextRun({ text: "Question  Number", bold: true, size: 22 }),
            ],
            alignment: AlignmentType.CENTER,
          }),
        ],
        width: { size: 11, type: WidthType.PERCENTAGE },
        verticalAlign: VerticalAlign.TOP,
        margins: { bottom: 300 },
      }),
      new TableCell({
        children: [
          new Paragraph({
            children: [new TextRun({ text: "Answer", bold: true, size: 22 })],
            alignment: AlignmentType.CENTER,
          }),
        ],
        width: { size: 47, type: WidthType.PERCENTAGE },
        verticalAlign: VerticalAlign.TOP,
        margins: { bottom: 300 },
      }),
      new TableCell({
        children: [
          new Paragraph({
            children: [
              new TextRun({ text: "Mark awarded", bold: true, size: 22 }),
            ],
            alignment: AlignmentType.CENTER,
          }),
        ],
        width: { size: 17, type: WidthType.PERCENTAGE },
        verticalAlign: VerticalAlign.TOP,
        margins: { bottom: 300, left: 100, right: 200 },
      }),
      new TableCell({
        children: [
          new Paragraph({
            children: [new TextRun({ text: "Remarks", bold: true, size: 22 })],
            alignment: AlignmentType.CENTER,
          }),
        ],

        width: { size: 25, type: WidthType.PERCENTAGE },
        verticalAlign: VerticalAlign.TOP,
        margins: { bottom: 300 },
      }),
    ],
  });
  const tableElement = new Table({ rows: [tableHeader] });

  let children: (Paragraph | Table)[] = [exportUtils.newLine(), tableElement];

  if (!exercise.questions?.length) {
    children.push(
      new Paragraph({
        text: "[No questions in this exercise]",
        style: HeadingLevel.HEADING_3,
      })
    );
  } else {
    // answerPara is the array of array of the answer of each question
    let answerPara: Paragraph[][] = exercise.questions.map((question) => {
      return answerToDocParagraph(question);
    });
    let count = 1;
    for (let abomination of answerPara!) {
      // 1. A
      // abomination.forEach((p) => {p.});
      tableElement.addChildElement(
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: String(count++),
                  alignment: AlignmentType.CENTER,
                }),
              ],
              width: { size: 11, type: WidthType.PERCENTAGE },
              verticalAlign: VerticalAlign.TOP,
              margins: { bottom: 150 },
            }),
            new TableCell({
              children: abomination,
              width: { size: 47, type: WidthType.PERCENTAGE },
              verticalAlign: VerticalAlign.TOP,
              margins: { left: 100, bottom: 150, right: 200 },
            }),
            new TableCell({
              children: [new Paragraph("")],
              width: { size: 17, type: WidthType.PERCENTAGE },
              verticalAlign: VerticalAlign.TOP,
              margins: { left: 100, bottom: 150, right: 200 },
            }),
            new TableCell({
              children: [new Paragraph("")],
              width: { size: 25, type: WidthType.PERCENTAGE },
              verticalAlign: VerticalAlign.TOP,
              margins: { left: 100, bottom: 150, right: 200 },
            }),
          ],
        })
      );
    }
  }

  // Document styles and settings
  const doc = new Document({
    styles: {
      paragraphStyles: [exportUtils.questionTextStyle],
    },
    numbering: {
      config: [
        {
          reference: "my-numbering",
          levels: [
            {
              level: 0,
            },
          ],
        },
      ],
    },
    sections: [
      {
        headers: { default: headerNameDoc },
        children: children,
        properties: {
          page: {
            margin: {
              top: exportUtils.cmToDocDXA(1.27),
              bottom: exportUtils.cmToDocDXA(1.75),
              left: exportUtils.cmToDocDXA(1.9),
              right: exportUtils.cmToDocDXA(1.95),
            },
          },
        },
      },
    ],
  });
  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, "answer-key.docx");
  });
}

function answerToDocParagraph(question: types.Question): Paragraph[] {
  let answerText: string | string[] = "";
  const generateParagraph = (answer: string, index: number): Paragraph => {
    if (index === 0) {
      return new Paragraph({
        children: [new TextRun({ text: answer, size: 22 })],

        tabStops: [
          {
            type: TabStopType.LEFT,
            position: exportUtils.exportSettings.LEFT_MARGIN,
          },
        ],
        spacing: {
          after: 130,
        },
        numbering: { reference: "my-numbering", level: 0 },
      });
    }
    return new Paragraph({
      children: [new TextRun({ text: answer, size: 22 })],

      tabStops: [
        {
          type: TabStopType.LEFT,
          position: exportUtils.exportSettings.LEFT_MARGIN,
        },
      ],
      spacing: {
        after: 130,
      },
    });
  };
  switch (question.questionType) {
    case types.QuestionType.MULTIPLE_CHOICE: {
      answerText = getMCAnswerFormat(question);
      break;
    }

    case types.QuestionType.LONG_QUESTION: {
      answerText = getLqAnswerFormat(question);
      break;
    }

    case types.QuestionType.SHORT_QUESTION: {
      answerText = getSqAnswerFormat(question);
      break;
    }

    case types.QuestionType.FILL_THE_BLANK: {
      answerText = getFtbAnswerFormat(question);
      break;
    }
    case types.QuestionType.MATCHING: {
      answerText = getMatchAnswerFormat(question);
      return answerText.map((answer, index) => {
        return generateParagraph(answer, index);
      });
    }
    case types.QuestionType.SUMMARY_CLOZE: {
      answerText = getScAnswerFormat(question);
      return answerText.map((answer, index) => {
        return generateParagraph(answer, index);
      });
    }
    case types.QuestionType.TABLE: {
      answerText = getTableAnswerFormat(question);
      return answerText.map((answer, index) => {
        return generateParagraph(answer, index);
      });
    }

    default: {
      answerText = getTfAnswerFormat(question);
      return answerText.map((answer, index) => {
        return generateParagraph(answer, index);
      });
    }
  }

  return [
    new Paragraph({
      children: [new TextRun({ text: `${answerText}`, size: 22 })],
      tabStops: [
        {
          type: TabStopType.LEFT,
          position: exportUtils.exportSettings.LEFT_MARGIN,
        },
      ],
      numbering: { reference: "my-numbering", level: 0 },
      spacing: {
        after: 130,
      },
    }),
  ];
}
