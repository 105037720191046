import { useState, useEffect } from "react";
import { AppContext, appContextDefaultValues } from "./context/context";
import Routes from "./Routes";
import { Auth } from "aws-amplify";

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(
    appContextDefaultValues.isAuthenticated
  );
  const [emailError, setEmailError] = useState(
    appContextDefaultValues.emailError
  );
  const [pwError, setPwError] = useState(appContextDefaultValues.pwError);
  const [email, setEmail] = useState(appContextDefaultValues.email);
  const [name, setName] = useState(appContextDefaultValues.name);

  const [exerciseArticleInput, setExerciseArticleInput] = useState(
    appContextDefaultValues.exerciseArticleInput
  );
  const [exerciseNameInput, setExerciseNameInput] = useState(
    appContextDefaultValues.exerciseNameInput
  );
  const [exerciseIdInput, setExerciseIdInput] = useState(
    appContextDefaultValues.exerciseIdInput
  );
  const [userId, setUserId] = useState(appContextDefaultValues.userId);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (e) {
      console.warn("User haven't signed in");
    }
  }

  return (
    <>
      <AppContext.Provider
        value={{
          isAuthenticated,
          userHasAuthenticated,
          emailError,
          setEmailError,
          pwError,
          setPwError,
          email,
          setEmail,
          name,
          setName,
          exerciseArticleInput,
          setExerciseArticleInput,
          exerciseNameInput,
          setExerciseNameInput,
          exerciseIdInput,
          setExerciseIdInput,
          userId,
          setUserId,
        }}
      >
        <Routes />
      </AppContext.Provider>
    </>
  );
}

export default App;
