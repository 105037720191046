import React from "react";
import { ReactComponent as IconCross } from "../../icons/IconCross.svg";
import { ReactComponent as WarningCat } from "../../assets/warningCat.svg";

interface WarningPopUpTextProps {
  title: string;
  proceedText: string;
}

export const WarningPopUp: React.FC<{
  text: WarningPopUpTextProps;
  onClose: Function;
  proceedAction: Function;
}> = (props) => {
  return (
    <div className="justify-center items-center flex fixed inset-0 bg-black bg-opacity-50 z-10 outline-none focus:outline-none">
      <div className="rounded-20xl shadow-lg relative w-[33.75rem] bg-white outline-none focus:outline-none pb-7">
        <IconCross
          className="cursor-pointer w-[24px] h-[24px] text-grey hover:text-black absolute right-5 top-5"
          onClick={() => {
            props.onClose();
          }}
        />

        <div className="flex flex-row rounded-t p-5 mb-2">
          <WarningCat className="mt-12 mr-9 w-[300px] h-[121px]" />
          <h1 className="mt-14 text-[24px] font-bold mb-3.5">
            {props.text.title}
          </h1>
        </div>
        <div className="flex flex-row">
          <button
            onClick={() => {
              props.onClose();
            }}
            className="h-11 w-[14.375rem] border border-innerBorder text-black rounded-11xl font-medium mr-4 ml-10 hover:bg-grey4"
          >
            Cancel
          </button>
          <button
            onClick={(e) => {
              props.onClose();
              props.proceedAction();
            }}
            className="h-11 w-[14.375rem] bg-CTA/Error hover:bg-[#ff5728] text-white rounded-11xl font-semibold"
          >
            {props.text.proceedText}
          </button>
        </div>
      </div>
    </div>
  );
};
