import { MatchQuestion } from "./../../../../amplify/src/API";

import {
  TfQuestionAnswerListInput,
  ChoicesWithId,
  TFQAListWithId,
  Question,
  McQuestion,
  TfQuestion,
  LqQuestion,
  SqQuestion,
  ScQuestion,
  QuestionType,
  QuestionState,
  McQuestionInput,
  TfQuestionInput,
  SqQuestionInput,
  LqQuestionInput,
  ScQuestionInput,
  MatchQuestionInput,
  AnswerWithId,
  StatementWithId,
  MTAnswerWithId,
  MatchQNAwithId,
  TableQuestion,
  TBAnswerWithId,
  TBQuestionTitleAndStatementWithId,
  FtbQuestionInput,
} from "./QuestionTypes";
import { TfAnswer } from "./QuestionTypes";
import { v4 as uuid } from "uuid";
import {
  FtbQuestion,
  QuestionAbility,
  TableQuestionInput,
  WeirdQuestionInput,
} from "../../api/codegen/API";

export const transformMCWithId = (choices: Array<string | null>) => {
  if (!choices) return [];

  return choices.reduce((withId, choice) => {
    withId.push({ id: uuid(), choice: choice ?? "null" });
    return withId;
  }, [] as { id: number | string; choice: string }[]);
};
export const transformSCWithId = (answers: Array<string | undefined>) => {
  if (!answers) return [];

  return answers.reduce((withId, answer) => {
    withId.push({
      id: uuid(),
      answer: answer ?? "null",
    });
    return withId;
  }, [] as { id: number | string; answer: string }[]);
};

//new function MT trans and detrans
//import the question type
export const transformMTQuestionType = (questionItemType: string) => {
  if (!questionItemType) return "";
  return questionItemType;
};

export const transformMTAnswerWithId = (answers: Array<string | number>) => {
  if (!answers) return [];
  return answers.reduce((thisId, answers) => {
    thisId.push({ id: uuid(), answers: answers ?? "" });
    return thisId;
  }, [] as { id: number | string; answers: string | number }[]);
};
//get the id from statement

export const transFormMTstatementWithId = (statement: Array<string>) => {
  if (!statement) return [];
  return statement.reduce((thisId, statement) => {
    thisId.push({ id: uuid(), statement: statement ?? "" });
    return thisId;
  }, [] as { id: number | string; statement: string | number }[]);
};

export const transformTBQuestionTitleAndStatementWithId = (
  StatementAndTitle: { statement: string; title: string }[]
) => {
  if (!StatementAndTitle) {
    return [];
  }
  const newArr = [...StatementAndTitle];

  return newArr.reduce((thisId, member) => {
    thisId.push({
      id: uuid(),
      statement: member.statement ?? "",
      title: member.title ?? "",
    });
    return thisId;
  }, [] as { id: number | string; statement: string; title: string }[]);
};

export const transformTBAnswerWithId = (answers: Array<string>) => {
  if (!answers) return [];
  return answers.reduce((thisId, answers) => {
    thisId.push({ id: uuid(), answers: answers ?? "" });
    return thisId;
  }, [] as { id: number | string; answers: string }[]);
};

export const transformFTBAnswerStatement = (answerStatement: string) => {
  if (!answerStatement) return "";
  const longUnderscore = "_".repeat(
    answerStatement.lastIndexOf("_") - answerStatement.indexOf("_") + 1
  );
  return answerStatement
    .replace("\n", "")
    .replace(".", "")
    .replace(longUnderscore, "");
};
// export const transFormMTQuestionWithId=(question:Array<string>)=>{
//   if(!question) return [];
//   return question.reduce((thisId,question)=>{
//     thisId.push({id:uuid(),question: question??""});
//     return thisId
//   }, [] as { id: number | string; question: string |number }[]);
// }
export const transFormMTQNAWithId = (
  question: Array<string>,
  answers: Array<number>
) => {
  if (!question || !answers.length) return [];

  return question.reduce((thisId, question, idx) => {
    thisId.push({
      id: uuid(),
      question: question ?? "",
      answers: String.fromCharCode(answers[idx] + "A".charCodeAt(0)),
    });

    return thisId;
  }, [] as { id: number | string; question: string; answers: string }[]);
};

export const transFormMTQNAAWithId = (
  question: Array<string>,
  answer: Array<number>
) => {
  if (!question || !answer) return [];
  return answer.reduce((thisId, answers) => {
    thisId.push({ id: uuid(), answers: answers ?? "" });
    return thisId;
  }, [] as { id: number | string; answers: number }[]);
};

export const transFormMTQuestionitemWithId = (question: Array<string>) => {
  if (!question) return [];
  return question.reduce((thisId, question) => {
    thisId.push({ id: uuid(), question: question ?? "" });
    return thisId;
  }, [] as { id: number | string; question: string | number }[]);
};

export const detransformMCWithId = (withId: ChoicesWithId) => {
  return withId.reduce((noId, choice) => {
    noId.push(choice.choice);
    return noId;
  }, [] as Array<string | null>);
};

export const detransformMTWithId = (withId: MTAnswerWithId) => {
  return withId.reduce((noId, answers) => {
    noId.push(String(answers.answers).charCodeAt(0) - "A".charCodeAt(0));

    return noId;
  }, [] as Array<number | string>);
};

// This will return only the question
export const detransformMTQuestionWithId = (withId: MatchQNAwithId) => {
  return withId.reduce((noId, question) => {
    noId.push(question.question);
    return noId;
  }, [] as Array<number | string>);
};

export const detransformMTStatenentWithId = (withId: StatementWithId) => {
  return withId.reduce((noId, statement) => {
    noId.push(statement.statement);
    return noId;
  }, [] as Array<number | string>);
};

export const detransformSCWithId = (withId: AnswerWithId) => {
  return withId.reduce((noId, answer) => {
    noId.push(answer.answer);
    return noId;
  }, [] as Array<string | null>);
};

export const detransformMTWithIdNUM = (withId: MTAnswerWithId) => {
  return withId.reduce((noId, answer) => {
    noId.push(String(answer.answers).charCodeAt(0) - "A".charCodeAt(0));
    return noId;
  }, [] as Array<number | string | null>);
};

export const detransformTBQuestionTitleWithId = (
  withId: TBQuestionTitleAndStatementWithId
) => {
  return withId.reduce((noId, member) => {
    noId.push(member.title);
    return noId;
  }, [] as Array<string>);
};

export const detransformTBAnswerStatementWithId = (
  withId: TBQuestionTitleAndStatementWithId
) => {
  return withId.reduce((noId, member) => {
    noId.push(member.statement);
    return noId;
  }, [] as Array<string>);
};
export const detransformTBAnswerWithId = (withId: TBAnswerWithId) => {
  return withId.reduce((noId, answers) => {
    noId.push(answers.answers);
    return noId;
  }, [] as Array<string>);
};
export const newMCChoice = () => {
  return { id: uuid(), choice: "" };
};

export const newTMCChoice = () => {
  return { id: uuid(), choice: "" };
};

export const transformTFWithId = (
  tfQuestionAnswerList: TfQuestionAnswerListInput[]
) => {
  return tfQuestionAnswerList.reduce((list, tfPair) => {
    list.push({ id: uuid(), ...tfPair });
    return list;
  }, [] as (TfQuestionAnswerListInput & { id: number | string })[]);
};

export const detransformTFWithId = (QAListWithId: TFQAListWithId) => {
  return QAListWithId.reduce((noId, item) => {
    noId.push({ tfQuestion: item.tfQuestion, tfAnswer: item.tfAnswer });
    return noId;
  }, [] as TfQuestionAnswerListInput[]);
};

export const detransformFTBAnswerStatement = (answerStatement: string) => {
  return answerStatement.replace("\n", "").concat("____ .");
};

export const newTFAnswer = () => {
  return {
    id: uuid(),
    tfQuestion: "",
    tfAnswer: TfAnswer.TRUE,
  };
};

export const newScAnswer = () => {
  return {
    id: uuid(),
    answer: "",
  };
};

export const newMTAnswer = () => {
  return {
    id: uuid(),
    answer: "",
  };
};

export const newMTStatement = () => {
  return {
    id: uuid(),
    statement: "",
  };
};

export const newMTQuestion = () => {
  return {
    id: uuid(),
    question: "",
    answers: "A",
  };
};

export const newTBQuestionTitleAndStatement = () => {
  return {
    id: uuid(),
    statement: "",
    title: "",
  };
};

export const newTBAnswer = () => {
  return {
    id: uuid(),
    answers: "",
  };
};

export const createNewQuestion = (
  questionID: string | null,
  questionType: QuestionType,
  questionAbility: QuestionAbility | null,
  questionState: QuestionState | null,
  questionStatement: string,
  mcQuestionContent: McQuestionInput | null,
  tfQuestionContent: TfQuestionInput | null,
  sqQuestionContent: SqQuestionInput | null,
  lqQuestionContent: LqQuestionInput | null,
  scQuestionContent: ScQuestionInput | null,
  matchQuestionContent: MatchQuestionInput | null,
  tableQuestionContent: TableQuestionInput | null,
  ftbQuestionContent: FtbQuestionInput | null,
  weirdQuestionContent: WeirdQuestionInput | null,
  articleContext: string,
  highlightStart: string,
  highlightEnd: string
) => {
  return {
    questionID: questionID ?? uuid(),
    questionType,
    questionAbility,
    questionState,
    questionStatement,
    mcQuestionContent,
    tfQuestionContent,
    sqQuestionContent,
    lqQuestionContent,
    scQuestionContent,
    tableQuestionContent,
    matchQuestionContent,
    ftbQuestionContent,
    weirdQuestionContent,
    articleContext,
    highlightStart,
    highlightEnd,
    startGenerationTime: new Date().toISOString(),
  } as Question;
};

export const createQuestion = (
  original: Question,
  statement: string,
  mcChoices: ChoicesWithId,
  mcAnswerId: string | number,
  mtAnswerId: string | number,
  slqAnswer: string | null,
  tfAnsList: TFQAListWithId,
  scAnswer: AnswerWithId,
  scSummary: string | undefined,
  mtAnswer: MTAnswerWithId,
  mtStatement: StatementWithId,
  mtQuestion: MatchQNAwithId,
  TBQuestionTitleAndStatement: TBQuestionTitleAndStatementWithId,
  TBAnswer: TBAnswerWithId,
  FTBAnswerStatement: string,
  FTBAnswer: string,
  questionItemType: string,
  newQuestion = false
) => {
  const {
    questionID,
    questionType,
    questionAbility,
    questionState,
    weirdQuestionContent,
    articleContext,
    highlightStart,
    highlightEnd,
    startGenerationTime,
  } = original;

  const mcContent = {
    choices: detransformMCWithId(mcChoices),
    answer: mcChoices.findIndex((choice) => choice.id === mcAnswerId),
  } as McQuestion;

  const sqContent = {
    answer: slqAnswer,
  } as SqQuestion;

  const ftbContent = {
    answer: FTBAnswer,
    answerStatement: detransformFTBAnswerStatement(FTBAnswerStatement),
  } as FtbQuestion;

  const lqContent = {
    answer: slqAnswer,
  } as LqQuestion;

  const tfContent = {
    tfQuestionAnswerList: detransformTFWithId(tfAnsList),
  } as TfQuestion;

  const scContent = {
    summary: scSummary,
    numBlanks: detransformSCWithId(scAnswer).length,
    answer: detransformSCWithId(scAnswer),
  } as ScQuestion;

  const matchContent = {
    questionItemType: questionItemType,
    questionItem: detransformMTQuestionWithId(mtQuestion),
    numItems: detransformMTWithId(mtQuestion).length,
    statement: detransformMTStatenentWithId(mtStatement),
    answers: detransformMTWithIdNUM(mtQuestion),
  } as MatchQuestion;

  const tbContent = {
    answers: detransformTBAnswerWithId(TBAnswer),
    numItems: detransformTBAnswerWithId(TBAnswer).length,
    questionTitle: detransformTBQuestionTitleWithId(
      TBQuestionTitleAndStatement
    ),
    answerStatement: detransformTBAnswerStatementWithId(
      TBQuestionTitleAndStatement
    ),
  } as TableQuestion;

  return createNewQuestion(
    newQuestion ? null : questionID,
    questionType,
    questionAbility ?? null,
    questionState!,
    statement,
    questionType === QuestionType.MULTIPLE_CHOICE ? mcContent : null,
    questionType === QuestionType.TRUE_FALSE ? tfContent : null,
    questionType === QuestionType.SHORT_QUESTION ? sqContent : null,
    questionType === QuestionType.LONG_QUESTION ? lqContent : null,
    questionType === QuestionType.SUMMARY_CLOZE ? scContent : null,
    questionType === QuestionType.MATCHING ? matchContent : null,
    questionType === QuestionType.TABLE ? tbContent : null,
    questionType === QuestionType.FILL_THE_BLANK ? ftbContent : null,
    weirdQuestionContent!,
    articleContext,
    highlightStart,
    highlightEnd
  );
};
