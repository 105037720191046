/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const swapQuestion = /* GraphQL */ `
  mutation SwapQuestion(
    $exerciseID: String!
    $questionIdx1: Int!
    $questionIdx2: Int!
  ) {
    swapQuestion(
      exerciseID: $exerciseID
      questionIdx1: $questionIdx1
      questionIdx2: $questionIdx2
    ) {
      id
      userId
      exerciseName
      questions {
        questionID
        questionType
        questionState
        questionAbility
        questionStatement
        mcQuestionContent {
          choices
          answer
        }
        tfQuestionContent {
          tfQuestionAnswerList {
            tfQuestion
            tfAnswer
          }
        }
        sqQuestionContent {
          answer
        }
        lqQuestionContent {
          answer
        }
        scQuestionContent {
          summary
          numBlanks
          answer
        }
        ftbQuestionContent {
          answerStatement
          answer
        }
        matchQuestionContent {
          questionItemType
          questionItem
          numItems
          statement
          answers
        }
        tableQuestionContent {
          questionTitle
          numItems
          answerStatement
          answers
        }
        weirdQuestionContent {
          weirdQuestionAnswerList {
            weirdQuestion
            weirdAnswer
          }
        }
        articleContext
        highlightStart
        highlightEnd
        startGenerationTime
      }
      article
      articleName
      createdDate
      lastAccessedDate
      version
      published
    }
  }
`;
export const createExercise = /* GraphQL */ `
  mutation CreateExercise(
    $exerciseName: String!
    $article: String!
    $articleName: String
    $questions: [QuestionInput!]
    $published: String
  ) {
    createExercise(
      exerciseName: $exerciseName
      article: $article
      articleName: $articleName
      questions: $questions
      published: $published
    ) {
      id
      userId
      exerciseName
      questions {
        questionID
        questionType
        questionState
        questionAbility
        questionStatement
        mcQuestionContent {
          choices
          answer
        }
        tfQuestionContent {
          tfQuestionAnswerList {
            tfQuestion
            tfAnswer
          }
        }
        sqQuestionContent {
          answer
        }
        lqQuestionContent {
          answer
        }
        scQuestionContent {
          summary
          numBlanks
          answer
        }
        ftbQuestionContent {
          answerStatement
          answer
        }
        matchQuestionContent {
          questionItemType
          questionItem
          numItems
          statement
          answers
        }
        tableQuestionContent {
          questionTitle
          numItems
          answerStatement
          answers
        }
        weirdQuestionContent {
          weirdQuestionAnswerList {
            weirdQuestion
            weirdAnswer
          }
        }
        articleContext
        highlightStart
        highlightEnd
        startGenerationTime
      }
      article
      articleName
      createdDate
      lastAccessedDate
      version
      published
    }
  }
`;
export const generateQuestion = /* GraphQL */ `
  mutation GenerateQuestion(
    $exerciseID: String!
    $questionType: QuestionType!
    $questionAbility: QuestionAbility
    $articleContext: String!
    $highlightStart: String!
    $highlightEnd: String!
  ) {
    generateQuestion(
      exerciseID: $exerciseID
      questionType: $questionType
      questionAbility: $questionAbility
      articleContext: $articleContext
      highlightStart: $highlightStart
      highlightEnd: $highlightEnd
    )
  }
`;
export const generatePaper = /* GraphQL */ `
  mutation GeneratePaper(
    $exerciseID: String!
    $articleContext: String!
    $highlightStart: String!
    $highlightEnd: String!
    $levelOfDifficulty: String!
  ) {
    generatePaper(
      exerciseID: $exerciseID
      articleContext: $articleContext
      highlightStart: $highlightStart
      highlightEnd: $highlightEnd
      levelOfDifficulty: $levelOfDifficulty
    )
  }
`;
export const updateExercise = /* GraphQL */ `
  mutation UpdateExercise(
    $exerciseID: String!
    $article: String
    $questions: [QuestionInput!]
    $articleName: String
    $exerciseName: String
  ) {
    updateExercise(
      exerciseID: $exerciseID
      article: $article
      questions: $questions
      articleName: $articleName
      exerciseName: $exerciseName
    ) {
      id
      userId
      exerciseName
      questions {
        questionID
        questionType
        questionState
        questionAbility
        questionStatement
        mcQuestionContent {
          choices
          answer
        }
        tfQuestionContent {
          tfQuestionAnswerList {
            tfQuestion
            tfAnswer
          }
        }
        sqQuestionContent {
          answer
        }
        lqQuestionContent {
          answer
        }
        scQuestionContent {
          summary
          numBlanks
          answer
        }
        ftbQuestionContent {
          answerStatement
          answer
        }
        matchQuestionContent {
          questionItemType
          questionItem
          numItems
          statement
          answers
        }
        tableQuestionContent {
          questionTitle
          numItems
          answerStatement
          answers
        }
        weirdQuestionContent {
          weirdQuestionAnswerList {
            weirdQuestion
            weirdAnswer
          }
        }
        articleContext
        highlightStart
        highlightEnd
        startGenerationTime
      }
      article
      articleName
      createdDate
      lastAccessedDate
      version
      published
    }
  }
`;
export const updateExerciseLastAccess = /* GraphQL */ `
  mutation UpdateExerciseLastAccess($exerciseID: String!) {
    updateExerciseLastAccess(exerciseID: $exerciseID) {
      id
      userId
      exerciseName
      questions {
        questionID
        questionType
        questionState
        questionAbility
        questionStatement
        mcQuestionContent {
          choices
          answer
        }
        tfQuestionContent {
          tfQuestionAnswerList {
            tfQuestion
            tfAnswer
          }
        }
        sqQuestionContent {
          answer
        }
        lqQuestionContent {
          answer
        }
        scQuestionContent {
          summary
          numBlanks
          answer
        }
        ftbQuestionContent {
          answerStatement
          answer
        }
        matchQuestionContent {
          questionItemType
          questionItem
          numItems
          statement
          answers
        }
        tableQuestionContent {
          questionTitle
          numItems
          answerStatement
          answers
        }
        weirdQuestionContent {
          weirdQuestionAnswerList {
            weirdQuestion
            weirdAnswer
          }
        }
        articleContext
        highlightStart
        highlightEnd
        startGenerationTime
      }
      article
      articleName
      createdDate
      lastAccessedDate
      version
      published
    }
  }
`;
export const updateExerciseLambda = /* GraphQL */ `
  mutation UpdateExerciseLambda(
    $userID: String!
    $exerciseID: String!
    $fetchedQuestions: [QuestionInput!]
    $uploadQuestions: [QuestionInput!]
    $version: Int!
  ) {
    updateExerciseLambda(
      userID: $userID
      exerciseID: $exerciseID
      fetchedQuestions: $fetchedQuestions
      uploadQuestions: $uploadQuestions
      version: $version
    ) {
      id
      userId
      exerciseName
      questions {
        questionID
        questionType
        questionState
        questionAbility
        questionStatement
        mcQuestionContent {
          choices
          answer
        }
        tfQuestionContent {
          tfQuestionAnswerList {
            tfQuestion
            tfAnswer
          }
        }
        sqQuestionContent {
          answer
        }
        lqQuestionContent {
          answer
        }
        scQuestionContent {
          summary
          numBlanks
          answer
        }
        ftbQuestionContent {
          answerStatement
          answer
        }
        matchQuestionContent {
          questionItemType
          questionItem
          numItems
          statement
          answers
        }
        tableQuestionContent {
          questionTitle
          numItems
          answerStatement
          answers
        }
        weirdQuestionContent {
          weirdQuestionAnswerList {
            weirdQuestion
            weirdAnswer
          }
        }
        articleContext
        highlightStart
        highlightEnd
        startGenerationTime
      }
      article
      articleName
      createdDate
      lastAccessedDate
      version
      published
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $exerciseID: String!
    $questionIdx: Int!
    $question: QuestionInput!
  ) {
    updateQuestion(
      exerciseID: $exerciseID
      questionIdx: $questionIdx
      question: $question
    ) {
      id
      userId
      exerciseName
      questions {
        questionID
        questionType
        questionState
        questionAbility
        questionStatement
        mcQuestionContent {
          choices
          answer
        }
        tfQuestionContent {
          tfQuestionAnswerList {
            tfQuestion
            tfAnswer
          }
        }
        sqQuestionContent {
          answer
        }
        lqQuestionContent {
          answer
        }
        scQuestionContent {
          summary
          numBlanks
          answer
        }
        ftbQuestionContent {
          answerStatement
          answer
        }
        matchQuestionContent {
          questionItemType
          questionItem
          numItems
          statement
          answers
        }
        tableQuestionContent {
          questionTitle
          numItems
          answerStatement
          answers
        }
        weirdQuestionContent {
          weirdQuestionAnswerList {
            weirdQuestion
            weirdAnswer
          }
        }
        articleContext
        highlightStart
        highlightEnd
        startGenerationTime
      }
      article
      articleName
      createdDate
      lastAccessedDate
      version
      published
    }
  }
`;
export const addQuestionLambda = /* GraphQL */ `
  mutation AddQuestionLambda(
    $userID: String!
    $exerciseID: String!
    $questionID: String!
    $questionType: QuestionType!
    $questionAbility: QuestionAbility
    $questionState: QuestionState
    $questionStatement: String!
    $articleContext: String!
    $mcQuestionContent: McQuestionInput
    $tfQuestionContent: TfQuestionInput
    $sqQuestionContent: SqQuestionInput
    $lqQuestionContent: LqQuestionInput
    $scQuestionContent: ScQuestionInput
    $ftbQuestionContent: FtbQuestionInput
    $matchQuestionContent: MatchQuestionInput
    $tableQuestionContent: TableQuestionInput
    $weirdQuestionContent: WeirdQuestionInput
    $highlightStart: String!
    $highlightEnd: String!
    $startGenerationTime: String
  ) {
    addQuestionLambda(
      userID: $userID
      exerciseID: $exerciseID
      questionID: $questionID
      questionType: $questionType
      questionAbility: $questionAbility
      questionState: $questionState
      questionStatement: $questionStatement
      articleContext: $articleContext
      mcQuestionContent: $mcQuestionContent
      tfQuestionContent: $tfQuestionContent
      sqQuestionContent: $sqQuestionContent
      lqQuestionContent: $lqQuestionContent
      scQuestionContent: $scQuestionContent
      ftbQuestionContent: $ftbQuestionContent
      matchQuestionContent: $matchQuestionContent
      tableQuestionContent: $tableQuestionContent
      weirdQuestionContent: $weirdQuestionContent
      highlightStart: $highlightStart
      highlightEnd: $highlightEnd
      startGenerationTime: $startGenerationTime
    ) {
      id
      userId
      exerciseName
      questions {
        questionID
        questionType
        questionState
        questionAbility
        questionStatement
        mcQuestionContent {
          choices
          answer
        }
        tfQuestionContent {
          tfQuestionAnswerList {
            tfQuestion
            tfAnswer
          }
        }
        sqQuestionContent {
          answer
        }
        lqQuestionContent {
          answer
        }
        scQuestionContent {
          summary
          numBlanks
          answer
        }
        ftbQuestionContent {
          answerStatement
          answer
        }
        matchQuestionContent {
          questionItemType
          questionItem
          numItems
          statement
          answers
        }
        tableQuestionContent {
          questionTitle
          numItems
          answerStatement
          answers
        }
        weirdQuestionContent {
          weirdQuestionAnswerList {
            weirdQuestion
            weirdAnswer
          }
        }
        articleContext
        highlightStart
        highlightEnd
        startGenerationTime
      }
      article
      articleName
      createdDate
      lastAccessedDate
      version
      published
    }
  }
`;
export const addBatchQuestions = /* GraphQL */ `
  mutation AddBatchQuestions(
    $userID: String!
    $exerciseID: String!
    $questions: [BatchQuestionsInput!]!
  ) {
    addBatchQuestions(
      userID: $userID
      exerciseID: $exerciseID
      questions: $questions
    ) {
      id
      userId
      exerciseName
      questions {
        questionID
        questionType
        questionState
        questionAbility
        questionStatement
        mcQuestionContent {
          choices
          answer
        }
        tfQuestionContent {
          tfQuestionAnswerList {
            tfQuestion
            tfAnswer
          }
        }
        sqQuestionContent {
          answer
        }
        lqQuestionContent {
          answer
        }
        scQuestionContent {
          summary
          numBlanks
          answer
        }
        ftbQuestionContent {
          answerStatement
          answer
        }
        matchQuestionContent {
          questionItemType
          questionItem
          numItems
          statement
          answers
        }
        tableQuestionContent {
          questionTitle
          numItems
          answerStatement
          answers
        }
        weirdQuestionContent {
          weirdQuestionAnswerList {
            weirdQuestion
            weirdAnswer
          }
        }
        articleContext
        highlightStart
        highlightEnd
        startGenerationTime
      }
      article
      articleName
      createdDate
      lastAccessedDate
      version
      published
    }
  }
`;
export const updateArticle = /* GraphQL */ `
  mutation UpdateArticle(
    $exerciseID: String!
    $article: String!
    $articleName: String!
  ) {
    updateArticle(
      exerciseID: $exerciseID
      article: $article
      articleName: $articleName
    ) {
      id
      userId
      exerciseName
      questions {
        questionID
        questionType
        questionState
        questionAbility
        questionStatement
        mcQuestionContent {
          choices
          answer
        }
        tfQuestionContent {
          tfQuestionAnswerList {
            tfQuestion
            tfAnswer
          }
        }
        sqQuestionContent {
          answer
        }
        lqQuestionContent {
          answer
        }
        scQuestionContent {
          summary
          numBlanks
          answer
        }
        ftbQuestionContent {
          answerStatement
          answer
        }
        matchQuestionContent {
          questionItemType
          questionItem
          numItems
          statement
          answers
        }
        tableQuestionContent {
          questionTitle
          numItems
          answerStatement
          answers
        }
        weirdQuestionContent {
          weirdQuestionAnswerList {
            weirdQuestion
            weirdAnswer
          }
        }
        articleContext
        highlightStart
        highlightEnd
        startGenerationTime
      }
      article
      articleName
      createdDate
      lastAccessedDate
      version
      published
    }
  }
`;
export const updateExerciseName = /* GraphQL */ `
  mutation UpdateExerciseName($exerciseID: String!, $exerciseName: String!) {
    updateExerciseName(exerciseID: $exerciseID, exerciseName: $exerciseName) {
      id
      userId
      exerciseName
      questions {
        questionID
        questionType
        questionState
        questionAbility
        questionStatement
        mcQuestionContent {
          choices
          answer
        }
        tfQuestionContent {
          tfQuestionAnswerList {
            tfQuestion
            tfAnswer
          }
        }
        sqQuestionContent {
          answer
        }
        lqQuestionContent {
          answer
        }
        scQuestionContent {
          summary
          numBlanks
          answer
        }
        ftbQuestionContent {
          answerStatement
          answer
        }
        matchQuestionContent {
          questionItemType
          questionItem
          numItems
          statement
          answers
        }
        tableQuestionContent {
          questionTitle
          numItems
          answerStatement
          answers
        }
        weirdQuestionContent {
          weirdQuestionAnswerList {
            weirdQuestion
            weirdAnswer
          }
        }
        articleContext
        highlightStart
        highlightEnd
        startGenerationTime
      }
      article
      articleName
      createdDate
      lastAccessedDate
      version
      published
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion($exerciseID: String!, $questionIdx: Int!) {
    deleteQuestion(exerciseID: $exerciseID, questionIdx: $questionIdx) {
      id
      userId
      exerciseName
      questions {
        questionID
        questionType
        questionState
        questionAbility
        questionStatement
        mcQuestionContent {
          choices
          answer
        }
        tfQuestionContent {
          tfQuestionAnswerList {
            tfQuestion
            tfAnswer
          }
        }
        sqQuestionContent {
          answer
        }
        lqQuestionContent {
          answer
        }
        scQuestionContent {
          summary
          numBlanks
          answer
        }
        ftbQuestionContent {
          answerStatement
          answer
        }
        matchQuestionContent {
          questionItemType
          questionItem
          numItems
          statement
          answers
        }
        tableQuestionContent {
          questionTitle
          numItems
          answerStatement
          answers
        }
        weirdQuestionContent {
          weirdQuestionAnswerList {
            weirdQuestion
            weirdAnswer
          }
        }
        articleContext
        highlightStart
        highlightEnd
        startGenerationTime
      }
      article
      articleName
      createdDate
      lastAccessedDate
      version
      published
    }
  }
`;
export const deleteExercise = /* GraphQL */ `
  mutation DeleteExercise($exerciseID: String!) {
    deleteExercise(exerciseID: $exerciseID) {
      exercises {
        id
        userId
        exerciseName
        questions {
          questionID
          questionType
          questionState
          questionAbility
          questionStatement
          mcQuestionContent {
            choices
            answer
          }
          tfQuestionContent {
            tfQuestionAnswerList {
              tfQuestion
              tfAnswer
            }
          }
          sqQuestionContent {
            answer
          }
          lqQuestionContent {
            answer
          }
          scQuestionContent {
            summary
            numBlanks
            answer
          }
          ftbQuestionContent {
            answerStatement
            answer
          }
          matchQuestionContent {
            questionItemType
            questionItem
            numItems
            statement
            answers
          }
          tableQuestionContent {
            questionTitle
            numItems
            answerStatement
            answers
          }
          weirdQuestionContent {
            weirdQuestionAnswerList {
              weirdQuestion
              weirdAnswer
            }
          }
          articleContext
          highlightStart
          highlightEnd
          startGenerationTime
        }
        article
        articleName
        createdDate
        lastAccessedDate
        version
        published
      }
      nextToken
    }
  }
`;
export const upsertPublish = /* GraphQL */ `
  mutation UpsertPublish($exerciseID: String!, $published: String) {
    upsertPublish(exerciseID: $exerciseID, published: $published) {
      id
      userId
      exerciseName
      questions {
        questionID
        questionType
        questionState
        questionAbility
        questionStatement
        mcQuestionContent {
          choices
          answer
        }
        tfQuestionContent {
          tfQuestionAnswerList {
            tfQuestion
            tfAnswer
          }
        }
        sqQuestionContent {
          answer
        }
        lqQuestionContent {
          answer
        }
        scQuestionContent {
          summary
          numBlanks
          answer
        }
        ftbQuestionContent {
          answerStatement
          answer
        }
        matchQuestionContent {
          questionItemType
          questionItem
          numItems
          statement
          answers
        }
        tableQuestionContent {
          questionTitle
          numItems
          answerStatement
          answers
        }
        weirdQuestionContent {
          weirdQuestionAnswerList {
            weirdQuestion
            weirdAnswer
          }
        }
        articleContext
        highlightStart
        highlightEnd
        startGenerationTime
      }
      article
      articleName
      createdDate
      lastAccessedDate
      version
      published
    }
  }
`;
