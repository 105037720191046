import { useEffect, useState } from "react";
import { mutationCreateExerciseUser } from "../api/api";
import Highlight from "../components/Highlight";
import { useAppContext } from "../context/context";
import {
  useNavigate,
  useParams,
  useLocation,
  Navigate,
} from "react-router-dom";
import { Auth } from "aws-amplify";
import { QuestionSummary } from "../components/QuestionSummary";
import * as api from "../api/api";
import * as apiType from "../api/codegen/API";
import Dropdown from "../components/Dropdown";
import SelectQuestionPopup from "../pages/SelectQuestionPopup";
import { QuestionCard, ReorderDirection } from "../components/QuestionType";
import "./ExercisePage.css";
import { ReactComponent as IconFlashCircle } from "../icons/IconFlashCircle.svg";
import { ReactComponent as IconGeneratePaper } from "../icons/IconGeneratePaper.svg";
import { ReactComponent as IconCommentPlus } from "../icons/IconCommentPlus.svg";
import { ReactComponent as IconExport } from "../icons/IconExport.svg";
import { ReactComponent as IconPenEdit } from "../icons/IconPenEdit.svg";
import { ReactComponent as IconSave } from "../icons/IconSave.svg";
import { ReactComponent as IconBack } from "../icons/IconBack.svg";
import { ReactComponent as IconPencilEdit } from "../icons/IconPencilEdit.svg";
import { ReactComponent as IconClear } from "../icons/IconClear.svg";
import { ReactComponent as IconSelectAll } from "../icons/IconSelectAll.svg";
import { ReactComponent as WarningCat } from "../assets/warningCat.svg";
import SelectionAbsence from "../components/SelectionAbsence";
import Button, { ActionButton } from "../components/Button";
import { createNewQuestion } from "../components/QuestionType/helper";
import cx from "classnames";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as IconCreateCopy } from "../icons/IconCreateCopy.svg";
import useAnalyticsEventTracker from "../useAnalyticsEventTracker";
import Header from "../components/Header";
import { LoadingPopup, WarningPopUp } from "../components/PopupWindow";
import { UnSubscriptionPopUp } from "../components/PopupWindow/UnSubscriptionPopUp";
import { ExportandGeneratePopUp } from "./PopupWindow/ExportandGeneratePopup";
interface ExercisePageProps {
  isPaperBank: boolean;
}
export default function ExercisePage({ isPaperBank }: ExercisePageProps) {
  const nav = useNavigate();
  const location = useLocation();
  const gaEventTracker = useAnalyticsEventTracker("Passage");
  const { exerciseIdParams } = useParams();
  const [wordStartPos, setWordStartPos] = useState(-1);
  const [wordEndPos, setWordEndPos] = useState(-1);
  const [highlightedArticle, setHighlightedArticle] = useState("");
  const [exportPopUpShown, setExportPopUpShown] = useState(false);
  const [exportExercise, setExportExercise] = useState<apiType.Exercise | null>(
    null
  );
  const [showSelectQuestion, setShowSelectQuestion] = useState<string | null>(
    null
  );
  const [part, setPart] = useState<string>("")

  const {
    userHasAuthenticated,
    setEmailError,
    setPwError,

    userId,
  } = useAppContext();

  const [editExerciseNamePressed, setEditExerciseNamePressed] = useState(false);
  const [editArticlePressed, setEditArticlePressed] = useState(false);
  const [questionSummaryShown, setQuestionSummaryShown] = useState(false);
  const [selectAllPressed, setSelectAllPressed] = useState(false);

  const [exercise, setExercise] = useState<apiType.Exercise | null>(null);
  const [questionsWithId, setQuestionsWithId] = useState<
    Array<{ id: string } & apiType.Question>
  >([]);
  const [exerciseNameShown, setExerciseNameShown] = useState("");
  const [articleShown, setArticleShown] = useState("");
  const [articleName, setArticleName] = useState("");
  const [action, setAction] = useState("");
  const [warningPopUpShown, setWarningPopUpShown] = useState(false);
  const [generatePaperPopUpShown, setGeneratePaperPopUpShown] = useState(false);
  //const [paragraphs, setParagraphs] = useState<string | string[]>("");

  useEffect(() => {
    if (part !== "") {
      gaEventTracker("generating paper")
      setAction("Generation");
      const genExResult: string | null = generatePaper(
        highlightedArticle, wordStartPos, wordEndPos
      );

      if (genExResult === "Generating") {
        setAction("Loading")
      } else if (genExResult === "Failed") {
        console.error("Generate exercise failed");
        gaEventTracker(
          `generate paper failed`
        );
        setAction("");
      }
      setPart("");
    }
  }, [part])
  const transformQuestionWithId = (questions: apiType.Question[]) => {
    if (!questions) return [];
    return questions.reduce((withId, question) => {
      withId.push({ id: question.questionID, ...question });
      return withId;
    }, [] as Array<{ id: string } & apiType.Question>);
  };

  const detransformQuestionsWithId = (
    questionsWithId: Array<{ id: string } & apiType.Question>
  ) => {
    if (!questionsWithId) return [];

    const temp = questionsWithId.reduce(
      (
        noId,
        {
          id,
          questionID,
          questionType,
          questionState,
          questionStatement,
          mcQuestionContent,
          matchQuestionContent,
          tableQuestionContent,
          tfQuestionContent,
          sqQuestionContent,
          lqQuestionContent,
          scQuestionContent,
          ftbQuestionContent,
          weirdQuestionContent,
          articleContext,
          highlightStart,
          highlightEnd,
          startGenerationTime,
        }
      ) => {
        noId.push({
          questionID,
          questionType,
          questionState,
          questionStatement,
          mcQuestionContent,
          matchQuestionContent,
          tableQuestionContent,
          tfQuestionContent,
          sqQuestionContent,
          lqQuestionContent,
          scQuestionContent,
          ftbQuestionContent,
          weirdQuestionContent,
          articleContext,
          highlightStart,
          highlightEnd,
          startGenerationTime,
        } as apiType.Question);
        return noId;
      },
      [] as apiType.Question[]
    );
    return temp;
  };
  const dropDownArrayWithGeneratePaper = [
    {
      id: "1",
      name: "Generate Question",
      value: "by machine",
      icon: IconFlashCircle,
    },
    {
      id: "2",
      name: "Generate Paper",
      value: "by machine",
      icon: IconGeneratePaper,
    },
    {
      id: "3",
      name: "Add Manually",
      value: "manually",
      icon: IconCommentPlus,
    },
  ];
  const swapQuestion = async (questionIdx1: number, questionIdx2: number) => {
    const isWithinRange = (idx: number) =>
      idx >= 0 && idx < questionsWithId.length;
    // don't swap if out of bound
    if (!isWithinRange(questionIdx1) || !isWithinRange(questionIdx2)) return;
    setAction("Reordering");

    api
      .mutationSwapQuestion(exerciseIdParams!, questionIdx1, questionIdx2)
      .then((res) => {
        const questions = res.data.swapQuestion?.questions;
        setQuestionsWithId(transformQuestionWithId(questions));
      })
      .catch((error) =>
        toast.error(error.message, {
          position: "bottom-right",
        })
      )
      .finally(() => {
        setAction("");
      });
  };

  const generatePaper = (
    highlightedArticle: string,
    wordStartPos: number,
    wordEndPos: number,
  ) => {
    gaEventTracker("generate paper");
    if (
      exerciseIdParams !== undefined &&
      exerciseIdParams !== null) {
      try {
        const selectedTxt: string[] = highlightedArticle
          .split(/\n+/)
          .filter((str) => str)
          .map((str) => str.trim())
          .filter((str) => str);

        const formattedSelectedArticle = selectedTxt.reduce(
          (strResult, para, index) => {
            return strResult
              .concat(para)
              .concat("\n");
          },
          ""
        );
        setAction("Generation");
        api
          .mutationGeneratePaper(
            exerciseIdParams,
            formattedSelectedArticle,
            wordStartPos.toString(),
            wordEndPos.toString(),
            part
          )
          .then((res: any) => {
            // Save for warning
            return res.data.generatePaper;
          })
          .catch((error) => {
            if (error.errors[0].message === "NoSubscriptionException") {
              setNoSubscriptionPopupOpen(true);
            }
            setAction("");
          });
      } catch (e: any) {
        console.error(e.message);
        setAction("");
      }
    }

    return "";
  };

  const updateQuestion = async (
    questionIdx: number,
    question: apiType.Question
  ) => {
    if (
      (questionIdx < 0 || questionIdx >= questionsWithId.length) &&
      questionIdx !== 999
    )
      return;
    setAction(questionIdx === 999 ? "Adding question" : "Saving changes");

    api
      .mutationUpdateQuestion(exerciseIdParams!, questionIdx, question)
      .then((res) => {
        const questions = res.data.updateQuestion?.questions;
        setQuestionsWithId(transformQuestionWithId(questions));
      })
      .catch((error) =>
        toast.error(error.message, {
          position: "bottom-right",
        })
      )
      .finally(() => setAction(""));
  };

  const selectArticleByQuestion = (questionIdInput: string) => {
    gaEventTracker("select article by question", "article");
    let originQuestionIdArray = questionsWithId.reduce((curArray, question) => {
      curArray.push(question.questionID);
      return curArray;
    }, [] as string[]);
    const currentQuestion =
      questionsWithId[originQuestionIdArray.indexOf(questionIdInput)];
    const hightlightStartPos = Number(currentQuestion.highlightStart);
    const highlightEndPos = Number(currentQuestion.highlightEnd);

    let articleSelected = articleShown
      .replace(/[\n]/g, " \n")
      .trim()
      .split(" ");

    let finalArticle = "";
    articleSelected.forEach((word, index) => {
      if (
        index >= Number(hightlightStartPos) &&
        index <= Number(highlightEndPos)
      ) {
        finalArticle = finalArticle + word;
        if (index !== word.length - 1 || word.length > 1) {
          finalArticle = finalArticle + " ";
        }
      }
    });

    if (finalArticle.trim() === currentQuestion.articleContext.trim()) {
      setWordStartPos(hightlightStartPos);
      setWordEndPos(highlightEndPos);
      setHighlightedArticle(finalArticle);
    }
  };

  const toggleExerciseNameEdit = () => {
    gaEventTracker("edit exercise name", "edit");
    if (editExerciseNamePressed && exercise) {
      setAction("Modifying exercise name");

      api
        .mutationUpdateExerciseName(exerciseIdParams!, exerciseNameShown)
        .then((res) => {
          setExerciseNameShown(res.data.updateExerciseName.exerciseName);
        })
        .catch((error) =>
          toast.error(error.message, {
            position: "bottom-right",
          })
        )
        .finally(() => {
          setAction("");
        });
    }
    setEditExerciseNamePressed(!editExerciseNamePressed);
  };

  const showExerciseNameError = () => {
    toast.error("Exercise name cannot be empty!", {
      position: "top-left",
    });
  };

  const toggleArticleEdit = () => {
    gaEventTracker("edit article", "edit");
    if (editArticlePressed) {
      setAction("Modifying article");
      api
        .mutationUpdateArticle(exerciseIdParams!, articleShown, articleName)
        .then((res) => {
          setArticleShown(res.data.updateArticle.article);
          setArticleName(res.data.updateArticle.articleName!);
        })
        .catch((error) =>
          toast.error(error.message, {
            position: "bottom-right",
          })
        )
        .finally(() => {
          setAction("");
        });
    }
    setEditArticlePressed(!editArticlePressed);
  };

  async function handleCreateExercise(event: any) {
    event.preventDefault();
    let idForPassagePage = "";
    try {
      await mutationCreateExerciseUser(
        isPaperBank ? exerciseNameShown + " - Copy" : exerciseNameShown,
        articleShown,
        articleName,
        exercise?.questions,
        "false"
      ).then((res) => {
        if (res.data?.createExercise?.id !== undefined) {
          idForPassagePage = res.data?.createExercise?.id;
        }
      });
      nav("/passage/" + idForPassagePage);
    } catch (e: any) {
      console.error(e);
    }
  }

  const handleSelectQuestionPopupOnSave = (
    questionType: apiType.QuestionType | null,
    questionAbility: apiType.QuestionAbility | null,
    numberOfParagraph: string
  ) => {
    gaEventTracker(
      `generate question ${showSelectQuestion}`,
      String(`${questionType} ${questionAbility}`)
    );
    if (showSelectQuestion === "by machine") {
      setAction("Generation");
      const genExResult: string | null = generateExercise(
        questionType,
        questionAbility,
        numberOfParagraph
      );

      if (genExResult === "Generating") {
        return "Generating";
      } else if (genExResult === "Failed") {
        console.error("Generate exercise failed");
        gaEventTracker(
          `generate question ${showSelectQuestion} failed`,
          String(`${questionType} ${questionAbility}`)
        );
        setAction("");
        return "";
      }
    } else if (showSelectQuestion === "manually" && questionType !== null) {
      const mcContent =
        questionType === apiType.QuestionType.MULTIPLE_CHOICE
          ? ({
            choices: ["", ""],
            answer: 0,
          } as apiType.McQuestion)
          : null;

      const sqContent =
        questionType === apiType.QuestionType.SHORT_QUESTION
          ? ({
            answer: "",
          } as apiType.SqQuestion)
          : null;

      const lqContent =
        questionType === apiType.QuestionType.LONG_QUESTION
          ? ({
            answer: "",
          } as apiType.LqQuestion)
          : null;

      const tfContent =
        questionType === apiType.QuestionType.TRUE_FALSE
          ? ({
            tfQuestionAnswerList: [
              { tfQuestion: "", tfAnswer: apiType.TfAnswer.TRUE },
            ],
          } as apiType.TfQuestion)
          : null;

      const scContent =
        questionType === apiType.QuestionType.SUMMARY_CLOZE
          ? ({
            summary: "Input Summary Here",
            numBlanks: 1,
            answer: [""],
          } as apiType.ScQuestion)
          : null;
      const matchContent =
        questionType === apiType.QuestionType.MATCHING
          ? ({
            questionItemType: "",
            questionItem: [""],
            numItems: 0,
            statement: [""],
            answers: [0],
          } as apiType.MatchQuestion)
          : null;
      const tbContent =
        questionType === apiType.QuestionType.TABLE
          ? ({
            questionTitle: [""],
            answers: [""],
            answerStatement: [""],
            numItems: 0,
          } as apiType.TableQuestion)
          : null;

      const ftbContent =
        questionType === apiType.QuestionType.FILL_THE_BLANK
          ? ({
            answer: "",
            answerStatement: "",
          } as apiType.FtbQuestion)
          : null;

      const newQuestion = createNewQuestion(
        null,
        questionType,
        questionAbility,
        apiType.QuestionState.SUCCESS,
        "Write statement...",
        mcContent,
        tfContent,
        sqContent,
        lqContent,
        scContent,
        matchContent,
        tbContent,
        ftbContent,
        null,
        highlightedArticle,
        wordStartPos.toString(),
        wordEndPos.toString()
      );
      updateQuestion(999, newQuestion);
    }
    setShowSelectQuestion(null);
  };

  function deleteQuestion(questionIdx: number) {
    gaEventTracker("delete generate failed question", "delete");
    setAction("Deleting");

    api
      .mutationDeleteQuestion(exerciseIdParams!, questionIdx)
      .then((res) => {
        const questions = res.data.deleteQuestion.questions;
        setQuestionsWithId(transformQuestionWithId(questions));
      })
      .catch((error) =>
        toast.error(error.message, {
          position: "bottom-right",
        })
      )
      .finally(() => {
        setAction("");
      });
  }

  const handleSelectAllArticle = () => {
    gaEventTracker("select all article", "article");

    if (!selectAllPressed) {
      const beforeSplitWords = articleShown.replace(/[\n]/g, " \n").trim();
      const splitWords = beforeSplitWords
        .split(" ")
        .filter((word) => word != "");
      setWordEndPos(beforeSplitWords.length - 1);
      setWordStartPos(0);
      const toBeHighlighted = splitWords.reduce((acc, curr) => {
        return acc + curr + " ";
      }, "");
      setHighlightedArticle(
        // splitWords.reduce((acc, curr) => acc + curr + " ", "")
        toBeHighlighted
      );
    } else {
      setWordEndPos(-1);
      setWordStartPos(-1);
      setHighlightedArticle("");
    }
    setSelectAllPressed(!selectAllPressed);
  };

  const toggleDiscardButton = () => {
    gaEventTracker("reset article", "article");
    setEditArticlePressed(false);
    setArticleShown(exercise?.article ?? "");
  };

  const [isNoSubscriptionPopupOpen, setNoSubscriptionPopupOpen] =
    useState(false);
  // EDIT HERE
  const generateExercise = (
    questionTypeSelected: apiType.QuestionType | null,
    questionAbilitySelected: apiType.QuestionAbility | null,
    numberOfParagraph: string
  ) => {
    gaEventTracker("generate exercise", String(questionTypeSelected));
    if (
      exerciseIdParams !== undefined &&
      exerciseIdParams !== null &&
      questionTypeSelected !== null
    ) {
      try {
        const selectedTxt: string[] = highlightedArticle
          .split(/\n+/)
          .filter((str) => str)
          .map((str) => str.trim())
          .filter((str) => str);

        const formattedSelectedArticle = selectedTxt.reduce(
          (strResult, para, index) => {
            return strResult
              .concat(
                "[" +
                String(
                  Number(numberOfParagraph[numberOfParagraph.length - 1]) +
                  index
                ) +
                "] "
              )
              .concat(para)
              .concat("\n");
          },
          ""
        );
        setAction("Generation");
        api
          .mutationGenerateExerciseUser(
            exerciseIdParams,
            questionTypeSelected,
            questionAbilitySelected,
            formattedSelectedArticle,
            wordStartPos.toString(),
            wordEndPos.toString()
          )
          .then((res: any) => {
            // Save for warning
            return res.data.generateQuestion;
          })
          .catch((error) => {
            if (error.errors[0].message === "NoSubscriptionException") {
              setNoSubscriptionPopupOpen(true);
            }
            setAction("");
          });
      } catch (e: any) {
        console.error(e.message);
        setAction("");
      }
    }

    return "";
  };
  async function handleLogout() {
    gaEventTracker("logout", "user");
    await Auth.signOut();
    userHasAuthenticated(false);
    setEmailError(null);
    setPwError(null);
    nav("/login");
  }

  const getExercise = async (exerciseIdParams: string) => {
    const res = isPaperBank
      ? await api.queryParticularPublishedExercise(exerciseIdParams)
      : await api.queryParticularExercise(exerciseIdParams);

    if (!res) {
      nav("/");
    }
    if (!isPaperBank && res.data?.getParticularExercise?.exercises?.[0]) {
      const exercise: apiType.Exercise =
        res.data.getParticularExercise.exercises[0];

      if (exercise?.article) {
        setExercise(exercise);
        const q = transformQuestionWithId(exercise.questions);
        setQuestionsWithId(q);
        setExerciseNameShown(exercise?.exerciseName);
        setArticleShown(exercise?.article);
        if (exercise?.articleName) setArticleName(exercise.articleName);
      }
    } else if (
      isPaperBank &&
      res.data?.getParticularPublishedExercise?.exercises?.[0]
    ) {
      const exercise: apiType.Exercise =
        res.data.getParticularPublishedExercise.exercises[0];

      if (exercise?.article) {
        setExercise(exercise);
        const q = transformQuestionWithId(exercise.questions);
        setQuestionsWithId(q);
        setExerciseNameShown(exercise?.exerciseName);
        setArticleShown(exercise?.article);
        if (exercise?.articleName) setArticleName(exercise.articleName);
      }
    }
  };

  const checkQuestionValidity = (
    questions: apiType.Question[],
    timeout = 100 * 1000
  ) => {
    questions.forEach(({ questionState, questionID, startGenerationTime }) => {
      if (
        questionState !== apiType.QuestionState.GENERATING ||
        !startGenerationTime
      )
        return;
      const expireAfter =
        new Date(startGenerationTime).valueOf() + timeout - Date.now();

      if (expireAfter <= 0) {
        console.error(`Question ${questionID} failed because of timeout`);
        setQuestionsWithId((prevQ) => {
          const newArr = [...prevQ];
          const idx = newArr.findIndex(
            (item) => item.questionID === questionID
          );
          if (idx === -1) return prevQ; // no change
          newArr[idx].questionState = apiType.QuestionState.FAILED;
          return newArr;
        });
      }
    });
  };
  useEffect(() => {
    const oneMin = 60 * 1000;
    // will not do checking if the array is changed within one second
    const debounceId = setTimeout(
      () => checkQuestionValidity(questionsWithId),
      1000
    );
    const intervalId = setInterval(() => {
      checkQuestionValidity(questionsWithId);
    }, oneMin);

    return () => {
      clearInterval(intervalId);
      clearTimeout(debounceId);
    };
  }, [questionsWithId]);

  useEffect(() => {
    //Get the particular exercise info by query
    async function updateExerciseinApi(exerciseIdParams: string) {
      try {
        if (exerciseIdParams) {
          await api.mutationUpdateExerciseAccessDateUser(exerciseIdParams);
        }
      } catch (e: any) {
        console.error(e);
        setAction("");
      }
    }
    exerciseIdParams && updateExerciseinApi(exerciseIdParams);
    async function getExerciseMutation() {
      try {
        if (exerciseIdParams) {
          await getExercise(exerciseIdParams);
          // handle the result here
        }
      } catch (e: any) {
        console.error(e.message);
        setAction("");
      }
    }

    getExerciseMutation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //Subscribe Exercise DONE
    if (!exerciseIdParams || !userId || !exercise) return;

    let subscription: ZenObservable.Subscription | null = null;

    try {
      subscription = api
        .subscriptionUser(exerciseIdParams, userId)
        .subscribe((res: any) => {
          const subscriptionQuestions =
            res.value.data.subscribeExercise?.questions;

          if (exercise != null && subscriptionQuestions) {
            const originQuestionIdArray = questionsWithId.reduce(
              (curArray, question) => {
                curArray.push(question.questionID);
                return curArray;
              },
              [] as string[]
            );

            const hasQuestionExisted = (id: string) =>
              originQuestionIdArray.indexOf(id) !== -1;
            // setup timers for questions that is generating
            subscriptionQuestions.forEach((questionInMap: apiType.Question) => {
              if (!hasQuestionExisted(questionInMap.questionID)) {
                setQuestionsWithId((prev) => {
                  return prev.concat({
                    id: questionInMap.questionID,
                    ...questionInMap,
                  })
                });

                setAction("");
              } else if (
                // questionState now turn from GENERATING to SUCCESS or FAILED
                hasQuestionExisted(questionInMap.questionID) &&
                (questionInMap.questionState ===
                  apiType.QuestionState.SUCCESS ||
                  questionInMap.questionState ===
                  apiType.QuestionState.FAILED) &&
                questionsWithId[
                  originQuestionIdArray.indexOf(questionInMap.questionID)
                ].questionState === apiType.QuestionState.GENERATING
              ) {
                setQuestionsWithId((prevQuestions) => {
                  const questionListToChange = [...prevQuestions];

                  questionListToChange[
                    originQuestionIdArray.indexOf(questionInMap.questionID)
                  ] = { id: questionInMap.questionID, ...questionInMap };
                  return questionListToChange;
                });
              }
            });
          }
        });
    } catch (e: any) {
      console.error(e);
      setAction("");
    }

    return () => {
      subscription?.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsWithId]);

  useEffect(() => {
    if (wordStartPos === -1 && wordEndPos === -1) {
      setSelectAllPressed(false);
    } else if (
      wordStartPos === 0 &&
      wordEndPos ===
      articleShown.replace(/[\n]/g, " \n").trim().split(" ").length - 1
    ) {
      setSelectAllPressed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wordStartPos, wordEndPos]);

  if (!exerciseIdParams) {
    return <Navigate to={`/404`} />;
  }
  if (!exercise) {
    return <LoadingPopup action={"Loading"} />;
  }

  return (
    <div>
      <ToastContainer />
      {action !== "" && <LoadingPopup action={action} />}
      {generatePaperPopUpShown && (
        <ExportandGeneratePopUp
          onClose={() => {
            setGeneratePaperPopUpShown(false);
          }}
          exercise={exportExercise!}
          isExport={false}
          onConfirm={(e) => { setPart(e) }} />
      )}
      {exportPopUpShown && (
        <ExportandGeneratePopUp
          onClose={() => {
            setExportPopUpShown(false);
          }}
          exercise={exportExercise!}
          isExport={true}
        />
      )}
      {questionSummaryShown && (
        <QuestionSummary
          onClose={() => {
            gaEventTracker("check question summary", "question");
            setQuestionSummaryShown(false);
          }}
          // exercise={exercise ? exercise : null}
          questions={questionsWithId ? questionsWithId : null}
        />
      )}
      <Header
        onLogout={() => {
          setAction("Logout");
          handleLogout();
        }}
      ></Header>

      <div className="h-44 w-full bg-Dark"></div>
      {showSelectQuestion && (
        <SelectQuestionPopup
          article={exercise.article}
          selectedText={highlightedArticle}
          onClose={() => setShowSelectQuestion(null)}
          onSave={(questionType, questionAbility, numberOfParagraph) =>
            handleSelectQuestionPopupOnSave(
              questionType,
              questionAbility,
              numberOfParagraph
            )
          }
          mode={showSelectQuestion}
          selectAllPressed={selectAllPressed}
        />
      )}
      <div
        className="h-44 w-full bg-Dark"
        onMouseDown={() => {
          setSelectAllPressed(false);
        }}
      ></div>
      <div className="flex flex-auto flex-col divide-y divide-innerBorder bg-white mx-auto absolute z-0 top-24 inset-x-16 lg:inset-x-32 pb-5 rounded-21xl shadow-xl  passage-page">
        <div className="relative flex-auto grid grid-cols-2 gap-4">
          <div
            className="text-area block"
            onMouseDown={() => {
              setWordEndPos(-1);
              setWordStartPos(-1);
              setHighlightedArticle("");
            }}
          >
            <div
              className={`row-span-1 relative mb-[${isPaperBank ? 30 : 0}px]`}
            >
              <div className="flex-row align-middle inline-flex items-center max-h-[58px]">
                {editExerciseNamePressed ? (
                  <input
                    className={cx(
                      "mt-[22px] ml-[40px] text-clip text-black text-base font-semibold border rounded-lg max-h-[24px] overflow-auto max-w-[250px] outline-none focus:outline-none",
                      editExerciseNamePressed
                        ? " border-blue1"
                        : " border-white1",
                      { " border-CTA/Error": exerciseNameShown === "" }
                    )}
                    value={exerciseNameShown ? exerciseNameShown : ""}
                    onChange={(e) => {
                      setExerciseNameShown(e.target.value);
                    }}
                  ></input>
                ) : (
                  <h1
                    className={`max-w-[250px] mt-[${isPaperBank ? 40 : 22}px] ${isPaperBank ? "mb-[15px] pt-8" : ""
                      } ml-[40px] text-left text-black text-base font-semibold whitespace-nowrap text-ellipsis overflow-hidden`}
                  >
                    {exerciseNameShown}
                  </h1>
                )}

                {!isPaperBank && (
                  <IconPenEdit
                    onClick={() => {
                      if (exerciseNameShown === "") {
                        showExerciseNameError();
                      } else {
                        toggleExerciseNameEdit();
                      }
                    }}
                    className="ml-[9px] mt-[22px] cursor-pointer w-[1.2em] h-[1.2em] text-grey hover:text-black"
                  />
                )}
              </div>
              <div className="flex flex-row ml-[94px] mt-[22px] absolute top-0 right-0">
                {!editArticlePressed && !selectAllPressed ? (
                  <>
                    {!isPaperBank && (
                      <ActionButton
                        text="Edit"
                        icon={IconPencilEdit}
                        onClick={() => {
                          if (questionsWithId.length) {
                            setWarningPopUpShown(true);
                          } else {
                            toggleArticleEdit();
                          }
                        }}
                        className="text-blue1 ml-[8px] hover:bg-white2"
                      />
                    )}
                    {!isPaperBank && (
                      <ActionButton
                        text="Select All"
                        icon={IconSelectAll}
                        onClick={handleSelectAllArticle}
                        className={cx("text-blue1 ml-[8px] hover:bg-white2", {
                          "text-white1 bg-blue1 hover:!bg-blue2":
                            selectAllPressed,
                        })}
                      />
                    )}
                  </>
                ) : !editArticlePressed && selectAllPressed ? (
                  <>
                    <ClearButtonComponent
                      toggleSelectAllArticle={handleSelectAllArticle}
                    />{" "}
                    <ActionButton
                      text="Select All"
                      icon={IconSelectAll}
                      onClick={handleSelectAllArticle}
                      className={cx("text-blue1 ml-[8px] hover:bg-white2", {
                        "text-white1 bg-blue1 hover:!bg-blue2":
                          selectAllPressed,
                      })}
                    />
                  </>
                ) : (
                  <>
                    <ActionButton
                      text="Done"
                      icon={IconSave}
                      onClick={toggleArticleEdit}
                      className="text-white1 bg-blue1 hover:bg-blue2"
                    //disable
                    />
                    <ActionButton
                      text="Discard"
                      icon={IconBack}
                      onClick={toggleDiscardButton}
                      className="text-blue1 ml-[8px] hover:bg-white2"
                    />
                  </>
                )}
              </div>
            </div>
            {!isPaperBank && (
              <div className="text-black pl-[41px] text-xs font-medium">
                {/* Last modified 12 June 2022, at 3:26 PM{" "} */}
                {(() => {
                  const dateOfExercise = new Date(exercise?.lastAccessedDate);

                  return (
                    "Last accessed: " +
                    dateOfExercise.toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      timeZone: "Asia/Hong_Kong",
                    })
                  );
                })()}
              </div>
            )}
            {editArticlePressed ? (
              <div
                className={`relative row-span-5 h-full font-normal text-sm break-words ml-[41px] pb-[50px] paragraph`}
              >
                {isPaperBank ? (
                  <textarea
                    className={
                      "w-full h-[90%]  overflow-y-auto pb-[10px]" +
                      (editArticlePressed
                        ? "  border rounded-lg border-blue1"
                        : " border rounded-lg border-white1")
                    }
                    value={articleShown ? articleShown : ""}
                  ></textarea>
                ) : (
                  <textarea
                    className={
                      "w-full h-[90%] overflow-y-auto pb-[10px] " +
                      (editArticlePressed
                        ? "  border rounded-lg border-blue1"
                        : " border rounded-lg border-white1")
                    }
                    value={articleShown ? articleShown : ""}
                    onChange={(e) => {
                      setArticleShown(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
            ) : (
              <Highlight
                wordStartPos={wordStartPos}
                wordEndPos={wordEndPos}
                setWordEndPos={setWordEndPos}
                setWordStartPos={setWordStartPos}
                setHighlightedArticle={setHighlightedArticle}
                splitedWords={articleShown
                  .replace(/[\n]/g, " \n")
                  .trim()
                  .split(" ")}
                editArticlePressed={editArticlePressed}
              ></Highlight>
            )}
          </div>
          <div className="question-section grid grid-cols-1 overflow-y-auto">
            {isPaperBank && (
              <div className="flex flex-row w-full items-center align-middle">
                <div className="h-[80px] flex-[30]"></div>
                <div className="flex flex-[16] items-center h-[80px] w-full mt-[5px] rounded-b-21xl">
                  <button>
                    <img />
                    <button
                      onClick={(e) => {
                        handleCreateExercise(e);
                      }}
                      type="button"
                      className="inline-flex flex-row align-middle items-center bg-blue1 hover:bg-blue2 w-[11.5rem] h-11 rounded-11xl pl-5"
                    >
                      <IconCreateCopy className="flex items-center hover:text-blue1 w-8 h-8" />
                      <h1 className="text-center w-full pl-2 pr-5 text-white text-[16px] font-bold">
                        Create a Copy
                      </h1>
                    </button>
                  </button>
                </div>
              </div>
            )}
            <div
              className={`mr-[24px] bg-greyBackgroundColour mb-[28px] mt-[${isPaperBank ? 5 : 25
                }px] pb-[35px] flex flex-col  gap-4 rounded-18xl`}
            >
              <div className="flex rounded-18xl ml-[36px] mt-[34px] justify-between items-center">
                <div className="text-2xl font-medium">Questions</div>
                {!isPaperBank && questionsWithId.length === 0 ? (
                  <Dropdown
                    placeholder="Create Question"
                    options={[
                      {
                        id: "1",
                        name: "Generate Question",
                        value: "by machine",
                        icon: IconFlashCircle,
                      },
                      {
                        id: "2",
                        name: "Generate Paper",
                        value: "by machine",
                        icon: IconGeneratePaper,
                      },
                      {
                        id: "3",
                        name: "Add Manually",
                        value: "manually",
                        icon: IconCommentPlus,
                      },
                    ]}
                    defaultOption={{
                      id: "1",
                      name: "Generate Question",
                      value: "generate-question",
                      icon: IconCommentPlus,
                    }}
                    disabled={highlightedArticle === ""}
                    onSelect={(item) => {
                      if (item?.name === "Generate Paper") {
                        setGeneratePaperPopUpShown(true);
                      } else {
                        setShowSelectQuestion(item?.value ? item?.value : "");
                      }
                    }}
                  />
                ) : (
                  questionsWithId.length != 0 && (
                    <button
                      onClick={() => setQuestionSummaryShown(true)}
                      className="text-blue1 hover:text-blue2 text-[16px] font-semibold mr-[28px]"
                    >
                      Summary
                    </button>
                  )
                )}
              </div>

              {questionsWithId.map(({ id, ...question }, index) => {
                if (question.questionState === apiType.QuestionState.SUCCESS) {
                  return (
                    <QuestionCard
                      key={id}
                      isPaperBank={isPaperBank}
                      question={question}
                      pos={{ index, total: questionsWithId.length }}
                      onUpdateQuestion={(question) => {
                        gaEventTracker("update question content", "question");
                        const idxToUpdate = questionsWithId.findIndex(
                          (item) => item.id === id
                        );
                        if (idxToUpdate === -1) return; // don't update things that doesn't exist
                        updateQuestion(index, question);
                      }}
                      onDiscardQuestion={(questionId) => {
                        gaEventTracker("discard question", "question");
                        const idxToDiscard = questionsWithId.findIndex(
                          (item) => item.questionID === questionId
                        );
                        if (idxToDiscard === -1) return; // don't discard things that doesn't exist
                        deleteQuestion(idxToDiscard);
                      }}
                      onDuplicateQuestion={(newQuestion) => {
                        gaEventTracker("duplicate question", "question");
                        const idxToDuplicate = questionsWithId.findIndex(
                          (item) => item.id === id
                        );
                        if (idxToDuplicate === -1) return; // don't duplicate things that doesn't exist
                        updateQuestion(999, newQuestion);
                      }}
                      onReorderQuestion={(dir) => {
                        if (dir === ReorderDirection.UP && index > 0) {
                          swapQuestion(index - 1, index);
                        } else if (
                          dir === ReorderDirection.DOWN &&
                          index < questionsWithId.length - 1
                        ) {
                          swapQuestion(index, index + 1);
                        }
                      }}
                      selectArticle={() => {
                        selectArticleByQuestion(id);
                      }}
                    />
                  );
                } else if (
                  question.questionState === apiType.QuestionState.GENERATING
                ) {
                  return <GeneratingQuestionCard key={id} />;
                } else if (
                  question.questionState === apiType.QuestionState.FAILED
                ) {
                  return (
                    <FailedQuestionCard
                      key={id}
                      isPaperbank={isPaperBank}
                      onDelete={() => deleteQuestion(index)}
                    />
                  );
                }
                return null;
              })}

              {highlightedArticle && !isPaperBank ? (
                <>
                  <div className="flex flex-row items-center">
                    <h1 className="text-[20px] text-grey5 font-medium ml-[36px] mr-auto">
                      Currently Selected
                    </h1>
                    {questionsWithId.length !== 0 && selectAllPressed && (
                      <div className="ml-auto mr-[12px]">
                        <Dropdown
                          placeholder="Create Question"
                          options={dropDownArrayWithGeneratePaper}
                          defaultOption={{
                            id: "1",
                            name: "Generate Question",
                            value: "generate-question",
                            icon: IconCommentPlus,
                          }}
                          disabled={highlightedArticle === ""}
                          onSelect={(item) => {
                            if (item?.name === "Generate Paper") {
                              setGeneratePaperPopUpShown(true);
                            } else {
                              setShowSelectQuestion(
                                item?.value ? item?.value : ""
                              );
                            }
                          }}
                        />
                      </div>
                    )}
                    {questionsWithId.length !== 0 && !selectAllPressed && (
                      <div className="ml-auto mr-[12px]">
                        <Dropdown
                          placeholder="Create Question"
                          options={[
                            {
                              id: "1",
                              name: "Generate Question",
                              value: "by machine",
                              icon: IconFlashCircle,
                            },
                            {
                              id: "3",
                              name: "Add Manually",
                              value: "manually",
                              icon: IconCommentPlus,
                            },
                          ]}
                          defaultOption={{
                            id: "1",
                            name: "Generate Question",
                            value: "generate-question",
                            icon: IconCommentPlus,
                          }}
                          disabled={highlightedArticle === ""}
                          onSelect={(item) => {
                            if (item?.name === "Generate Paper") {
                              setGeneratePaperPopUpShown(true);
                            } else {
                              setShowSelectQuestion(
                                item?.value ? item?.value : ""
                              );
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="border border-border2 w-[90%] h-[170px] rounded-[8px] pt-[17px] pb-[13px] pl-[18px] pr-[16px] overflow-y-auto mx-auto">
                    <p className="text-Fill/Dark/Dark2 font-light text-[14px]">
                      {selectAllPressed ? "Whole article" : highlightedArticle}
                    </p>
                  </div>
                </>
              ) : (
                !isPaperBank && <SelectionAbsence />
              )}

              {isNoSubscriptionPopupOpen && (
                <UnSubscriptionPopUp
                  onClose={() => {
                    setNoSubscriptionPopupOpen(false);
                  }}
                  proceedAction={async () => {
                    setNoSubscriptionPopupOpen(false);
                    const data = await api.queryGetCheckOutSession(
                      window.location.href
                    );
                    const url = data.data?.getCheckOutSession;
                    if (url != null) {
                      window.location.href = url;
                      // window.open(url, '_blank');
                    }
                  }}
                />
              )}
              {warningPopUpShown && (
                <WarningPopUp
                  text={{
                    title:
                      "Editing the article when you already have questions will disable the highlight feature.",
                    proceedText: "Continue",
                  }}
                  onClose={() => {
                    setWarningPopUpShown(false);
                  }}
                  proceedAction={toggleArticleEdit}
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center h-[80px] w-full absolute bottom-[0px] bg-white rounded-b-21xl">
          <Button
            text="Export"
            icon={IconExport}
            onClick={() => {
              setExportPopUpShown(true);
              setExportExercise({
                id: exercise.id,
                userId: exercise.userId,
                exerciseName: exerciseNameShown,
                questions: detransformQuestionsWithId(questionsWithId),
                article: articleShown,
                articleName: exercise.exerciseName,
                createdDate: exercise.createdDate,
                lastAccessedDate: exercise.lastAccessedDate,
                version: exercise.version,
              } as apiType.Exercise);
            }}
            className="items-center border border-grey11 ml-auto mr-[25px] rounded-[4px] text-blue1 text-[16px] font-semibold px-[25px] hover:text-white hover:bg-blue1 hover:border-transparent"
          />
        </div>
      </div>
    </div>
  );
}

export const ClearButtonComponent = ({
  toggleSelectAllArticle,
}: {
  toggleSelectAllArticle: () => void;
}) => {
  return (
    <ActionButton
      text="Clear"
      icon={IconClear}
      onClick={toggleSelectAllArticle}
      className="text-blue1 ml-[8px] hover:bg-white2"
    />
  );
};

export const GeneratingQuestionCard = () => {
  return (
    <>
      <div className="cardbar flex flex-row items-center w-[90%] h-[111px] bg-grey9 rounded-[4px] mx-auto mb-[9px]">
        <img
          src="/rocket.svg"
          className="mt-[15px] ml-[20px] mr-[14px]"
          alt=""
        />
        <div>
          <h1 className="text-grey5 text-[20px] font-semibold mb-[4px]">
            Question is being generated
          </h1>
          <p className="text-grey6 text-[12px] font-normal">
            We are generating the question based on your selection.
          </p>
        </div>
      </div>
    </>
  );
};

export const FailedQuestionCard = ({
  onDelete,
  isPaperbank,
}: {
  onDelete: () => void;
  isPaperbank: boolean;
}) => {
  return (
    <>
      <button
        onClick={
          isPaperbank
            ? () => {
              return null;
            }
            : onDelete
        }
      >
        <div className="flex flex-row items-center w-[90%] h-[111px] bg-grey9 rounded-[4px] mx-auto mb-[9px]">
          <WarningCat className=" ml-[20px] mr-[14px] w-[75px] h-[75px]" />
          <div>
            <h1 className="text-grey5 text-[20px] font-semibold mb-[4px]">
              Question generation failed
            </h1>
            <p className="text-grey6 text-[12px] font-normal">
              Please click on this card to delete this question.
            </p>
          </div>
        </div>
      </button>
    </>
  );
};
