import { sign } from "crypto";
import { TableQuestion } from "./../../../amplify/src/API";
import * as types from "../api/codegen/API";
import {
  AlignmentType,
  Paragraph,
  Table,
  TableRow,
  TableCell,
  WidthType,
  TabStopType,
  BorderStyle,
  VerticalAlign,
  TextRun,
} from "docx";
import * as exportUtils from "./exportUtils";
import { exportSettings } from "./exportUtils";
import { match } from "assert";
import { SCContent } from "./QuestionType";

export function getMultipleChoiceFormat(
  question: types.Question,
  template: any[]
): any[] {
  if (question.mcQuestionContent) {
    if (question.mcQuestionContent.choices.length) {
      const choices: (string | null)[] = question.mcQuestionContent.choices;
      const children: Paragraph[] = choices.map(
        (choice, index) =>
          new Paragraph({
            text: `${String.fromCharCode(index + 65)}. ${choice}`,
            style: "question-text",
          })
      );

      const mcTable = new Table({
        width: {
          size: exportSettings.TABLE_WIDTH,
          type: WidthType.DXA,
        },
        margins: {
          left: exportSettings.LEFT_MARGIN,
        },
        borders: exportUtils.noTableBorder.borders,
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: children,
                borders: exportUtils.noTableBorder.borders,
              }),
              new TableCell({
                borders: exportUtils.noTableBorder.borders,
                children: [
                  new Table({
                    borders: exportUtils.noTableBorder.borders,
                    alignment: AlignmentType.RIGHT,
                    margins: {
                      right: exportSettings.RIGHT_MARGIN,
                    },
                    rows: [
                      new TableRow({
                        children: [
                          exportUtils.mcChoiceLabelInCell("A"),
                          exportUtils.mcChoiceLabelInCell("B"),
                          exportUtils.mcChoiceLabelInCell("C"),
                          exportUtils.mcChoiceLabelInCell("D"),
                        ],
                      }),
                      new TableRow({
                        children: [
                          exportUtils.circleInCell(),
                          exportUtils.circleInCell(),
                          exportUtils.circleInCell(),
                          exportUtils.circleInCell(),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
        ],
      });
      template = [
        ...template,
        exportUtils.newLine(),
        mcTable,
        ...exportUtils.trailingLines,
      ];
    } else {
      template = [
        ...template,
        exportUtils.newLine(),
        new Paragraph({
          text: "\t[No choices are found in this question]",
          tabStops: [
            { type: TabStopType.LEFT, position: exportSettings.LEFT_MARGIN },
          ],
        }),
        ...exportUtils.trailingLines,
      ];
    }
  }
  return template;
}

export function getTfQuestionFormat(
  question: types.Question,
  template: any[]
): any[] {
  if (question.tfQuestionContent) {
    const statements: types.TfQuestionAnswerList[] =
      question.tfQuestionContent.tfQuestionAnswerList;
    const children: Paragraph[] = statements.map(
      (statement, index) =>
        new Paragraph({
          text: `${String.fromCharCode(index + 97)}) ${statement.tfQuestion}`,
          style: "question-text",
        })
    );

    const tfRows: TableRow[] = children.map((tfStatement) => {
      return new TableRow({
        children: [
          new TableCell({
            children: [tfStatement],
            borders: exportUtils.noTableBorder.borders,
          }),
          exportUtils.circleInCell(),
          exportUtils.circleInCell(),
          exportUtils.circleInCell(),
        ],
      });
    });
    const tfTable = new Table({
      width: {
        size: exportSettings.TABLE_WIDTH,
        type: WidthType.DXA,
      },
      margins: {
        left: exportSettings.LEFT_MARGIN,
        right: exportSettings.RIGHT_MARGIN,
      },
      borders: exportUtils.noTableBorder.borders,
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: "\tStatements",
                  style: "bold",
                  tabStops: [
                    {
                      type: TabStopType.LEFT,
                      position: exportSettings.TF_STATEMENT_TAB,
                    },
                  ],
                }),
              ],
              width: {
                size: exportSettings.TF_STATEMENT_WIDTH,
                type: WidthType.DXA,
              },
              borders: exportUtils.noTableBorder.borders,
            }),
            exportUtils.tfChoiceLabelInCell("    T"),
            exportUtils.tfChoiceLabelInCell("    F"),
            exportUtils.tfChoiceLabelInCell("  NG"),
          ],
        }),
        ...tfRows,
      ],
    });
    template = [
      ...template,
      exportUtils.newLine(),
      tfTable,
      ...exportUtils.trailingLines,
    ];
  } else {
    template = [
      ...template,
      exportUtils.newLine(),
      new Paragraph({
        text: "\t[No question statements are found]",
        tabStops: [
          { type: TabStopType.LEFT, position: exportSettings.LEFT_MARGIN },
        ],
      }),
      ...exportUtils.trailingLines,
    ];
  }
  return template;
}

export function getLqQuestionFormat(template: any[]): any[] {
  return [
    ...template,
    exportUtils.newLine(),
    exportUtils.answerLine(),
    exportUtils.newLine(),
    exportUtils.answerLine(),
    ...exportUtils.trailingLines,
  ];
}

export function getMatchQuestionFormat(
  question: types.Question,
  template: any[]
): any[] {
  const questionItemArray: string[] =
    question.matchQuestionContent?.questionItem || [];
  const statementArray: string[] =
    question.matchQuestionContent?.statement || [];
  const questionAndAnswerArray: {
    questionItem: string | number;
    statement: string | number;
  }[] = [];

  for (
    let i = 0;
    i <
    (questionItemArray.length < statementArray.length
      ? statementArray.length
      : questionItemArray.length);
    i++
  ) {
    if (i >= questionItemArray.length) {
      questionAndAnswerArray.push({
        questionItem: "",
        statement: statementArray[i],
      });
    } else if (i >= statementArray.length) {
      questionAndAnswerArray.push({
        questionItem: questionItemArray[i],
        statement: "",
      });
    } else {
      questionAndAnswerArray.push({
        questionItem: questionItemArray[i],

        statement: statementArray[i],
      });
    }
  }

  // successfully constructed.
  if (question.matchQuestionContent) {
    let count: number = 0;
    const children: Paragraph[] =
      question.matchQuestionContent.questionItem.map((item, index) => {
        count++;
        if (count === 1) {
          return new Paragraph({
            text: `\t\t____ ${item}`,
            style: "question-text",
            numbering: {
              reference: "lower-roman",
              level: 0,
            },
            spacing: {
              after: 160,
            },
          });
        } else {
          return new Paragraph({
            text: `\t____ ${item}`,
            style: "question-text",
            numbering: {
              reference: "lower-roman",
              level: 0,
            },
            spacing: {
              after: 160,
            },
          });
        }
      });

    const matchTable = new Table({
      width: {
        size: exportSettings.TABLE_WIDTH,
        type: WidthType.DXA,
      },
      margins: {
        left: exportSettings.LEFT_MARGIN,
      },
      borders: exportUtils.noTableBorder.borders,
      rows: [
        new TableRow({
          // Fist row. The each column title/statement
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: ``,
                  style: "bold",
                  spacing: {
                    after: 0,
                    before: 20,
                  },
                  alignment: AlignmentType.START,
                }),
              ],
              width: {
                size: (exportSettings.TABLE_WIDTH * 2) / 20,
                type: WidthType.DXA,
              },
              borders: exportUtils.noTableBorder.borders,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: ``,
                  style: "bold",
                  spacing: {
                    before: 20,
                    after: 0,
                  },
                  alignment: AlignmentType.START,
                }),
              ],
              width: {
                size: exportSettings.TABLE_WIDTH / 20,
                type: WidthType.DXA,
              },
              borders: exportUtils.noTableBorder.borders,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: `${question.matchQuestionContent.questionItemType}`,
                  style: "bold",
                  spacing: {
                    before: 0,
                    after: 0,
                  },
                  alignment: AlignmentType.START,
                }),
              ],
              width: {
                size: (exportSettings.TABLE_WIDTH * 4) / 20,
                type: WidthType.DXA,
              },
              margins: {
                left: 0,
                right: 0,
              },
              borders: exportUtils.noTableBorder.borders,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: ``,
                  style: "bold",
                  spacing: {
                    after: 100,
                  },
                }),
              ],
              width: {
                size: (exportSettings.TABLE_WIDTH * 2) / 20,
                type: WidthType.DXA,
              },
              borders: exportUtils.noTableBorder.borders,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: `Statements`,
                  style: "bold",
                  spacing: {
                    before: 0,
                    after: 100,
                  },
                }),
              ],
              margins: {
                left: 0,
              },
              width: {
                size: (exportSettings.TABLE_WIDTH * 8) / 20,
                type: WidthType.DXA,
              },
              borders: exportUtils.noTableBorder.borders,
            }),
          ],
        }),
      ],
    });
    questionAndAnswerArray.forEach((member, index) => {
      matchTable.addChildElement(
        new TableRow({
          children: [
            new TableCell({
              borders: exportUtils.noTableBorder.borders,
              children: [
                // This one will map all the elements
                member.questionItem
                  ? new Paragraph({
                      children: [
                        new TextRun({
                          text: `${exportUtils.numberToRomanNumeral(index)})`,
                          bold: false,
                          size: 20,
                        }),
                      ],
                      style: "question-text",
                      spacing: {
                        before: 20,
                        after: 0,
                      },
                      alignment: AlignmentType.START,
                    })
                  : new Paragraph({}),
              ],
              width: {
                size: (exportSettings.TABLE_WIDTH * 2) / 20,
                type: WidthType.DXA,
              },
              margins: {
                left: 900,
                right: 0,
              },
            }),
            new TableCell({
              borders: exportUtils.noTableBorder.borders,
              children: [
                // This one will map all the elements
                member.questionItem
                  ? new Paragraph({
                      children: [
                        new TextRun({ text: `____`, bold: false, size: 20 }),
                      ],
                      style: "question-text",
                      spacing: {
                        before: 20,
                        after: 0,
                      },
                      alignment: AlignmentType.START,
                    })
                  : new Paragraph({}),
              ],
              width: {
                size: exportSettings.TABLE_WIDTH / 20,
                type: WidthType.DXA,
              },
              margins: {
                left: 20,
                right: 0,
              },
            }),
            new TableCell({
              borders: exportUtils.noTableBorder.borders,
              children: [
                // This one will map all the elements
                member.questionItem
                  ? new Paragraph({
                      children: [
                        new TextRun({
                          text: `${member.questionItem}`,
                          bold: false,
                          size: 20,
                        }),
                      ],
                      style: "question-text",
                      spacing: {
                        after: 160,
                      },
                      alignment: AlignmentType.START,
                    })
                  : new Paragraph({}),
              ],
              width: {
                size: (exportSettings.TABLE_WIDTH * 4) / 20,
                type: WidthType.DXA,
              },
              margins: {
                left: 0,
                right: 0,
              },
            }),
            new TableCell({
              borders: exportUtils.noTableBorder.borders,
              children: [
                // This one will map all the elements
                member.statement
                  ? new Paragraph({
                      children: [
                        new TextRun({
                          text: `${String.fromCharCode(
                            index + "A".charCodeAt(0)
                          )}.`,
                          bold: false,
                          size: 20,
                        }),
                      ],
                      style: "question-text",
                      spacing: {
                        after: 160,
                      },

                      alignment: AlignmentType.END,
                    })
                  : new Paragraph({}),
              ],
              width: {
                size: (exportSettings.TABLE_WIDTH * 2) / 20,
                type: WidthType.DXA,
              },
              margins: {
                left: 0,
                right: 200,
              },
            }),
            new TableCell({
              borders: exportUtils.noTableBorder.borders,
              children: [
                // This one will map all the elements
                member.statement
                  ? new Paragraph({
                      children: [
                        new TextRun({
                          text: String(member.statement),
                          bold: false,
                          size: 20,
                        }),
                      ],
                      style: "question-text",
                      spacing: {
                        after: 160,
                      },
                      alignment: AlignmentType.START,
                    })
                  : new Paragraph({}),
              ],
              width: {
                size: (exportSettings.TABLE_WIDTH * 8) / 20,
                type: WidthType.DXA,
              },
              margins: {
                left: 0,
                right: 0,
              },
            }),
          ],
        })
      );
    });
    template = [
      ...template,
      exportUtils.newLine(),
      matchTable,
      ...exportUtils.trailingLines,
    ];
  }

  return template;
}
export function getScQuestionFormat(
  question: types.Question,
  template: any[]
): any[] {
  if (question.scQuestionContent) {
    let count = 0;
    const children: Paragraph[] = [
      new Paragraph({
        style: "question-text",
        children: [
          new TextRun({
            text: question.scQuestionContent.summary
              .replace(/\(\d+\) ____/g, () => {
                count++;
                return `(${count}) ________________`;
              })
              .replace(/____ \(\d+\) ________________/g, () => {
                count++;
                return ` ________(${count})________`;
              }),
            size: 20,
            font: {
              name: "Garamond",
            },
          }),
        ],

        spacing: {
          line: 380,
        },
      }),
    ];

    const scTable = new Table({
      width: {
        size: exportSettings.TABLE_WIDTH,
        type: WidthType.DXA,
      },
      borders: exportUtils.noTableBorder.borders,
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph(" ")],
              margins: {
                right: 450,
              },
            }),
            new TableCell({
              children: children,
              borders: {
                top: { style: BorderStyle.SINGLE },
                bottom: { style: BorderStyle.SINGLE },
                right: { style: BorderStyle.SINGLE },
                left: { style: BorderStyle.SINGLE },
              },
              margins: {
                top: 250,
                bottom: 100,
                right: 100,
                left: 100,
              },
            }),
            new TableCell({
              children: [new Paragraph(" ")],
              margins: {
                left: 450,
              },
            }),
          ],
        }),
      ],
    });
    template = [
      ...template,
      exportUtils.newLine(),
      scTable,
      ...exportUtils.trailingLines,
    ];
  }
  return template;
}
export function getTableQuestionFormat(
  question: types.Question,
  template: any[]
): any[] {
  if (question.tableQuestionContent?.questionTitle.length) {
    const matchTable = new Table({
      width: {
        size: exportSettings.TABLE_WIDTH,
        type: WidthType.DXA,
      },
      borders: exportUtils.noTableBorder.borders,
      margins: {
        left: exportSettings.LEFT_MARGIN,
      },

      rows: question.tableQuestionContent.questionTitle.map((item, index) => {
        return new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph(" ")],
              margins: {
                right: 425,
                left: 0,
              },
              // width: { size: 225 },
              borders: exportUtils.noTableBorder.borders,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: item,
                  style: "bold",
                  spacing: {
                    after: 70,
                    before: 70,
                  },
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              borders: {
                top: { style: BorderStyle.SINGLE, size: 5 },
                bottom: { style: BorderStyle.SINGLE, size: 5 },
                left: { style: BorderStyle.SINGLE, size: 5 },
                right: { style: BorderStyle.SINGLE, size: 5 },
              },
              width: {
                size: exportSettings.TABLE_WIDTH / 7,
                type: WidthType.DXA,
              },
              margins: {
                left: 100,
                right: 100,
                top: 150,
                bottom: 150,
              },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: question.tableQuestionContent?.answerStatement[index]
                    .replace(/\(\d+\) ____/g, () => {
                      return `(${
                        index + 1
                      }) ___________________________________`;
                    })
                    .replace(/____ \(\d+\) ________________/g, () => {
                      return ` ________(${index + 1})________`;
                    }),
                  spacing: {
                    after: 70,
                    before: 70,
                  },
                }),
              ],

              borders: {
                top: { style: BorderStyle.SINGLE, size: 5 },
                bottom: { style: BorderStyle.SINGLE, size: 5 },
                left: { style: BorderStyle.SINGLE, size: 5 },
                right: { style: BorderStyle.SINGLE, size: 5 },
              },
              verticalAlign: VerticalAlign.CENTER,
              margins: {
                left: 100,
                right: 100,
                top: 150,
                bottom: 150,
              },
            }),
            new TableCell({
              children: [new Paragraph(" ")],
              margins: {
                right: 425,
                left: 0,
              },
              // width: { size: 225 },
              borders: exportUtils.noTableBorder.borders,
            }),
          ],
        });
      }),
    });
    template = [
      ...template,
      exportUtils.newLine(),
      matchTable,
      ...exportUtils.trailingLines,
    ];
  }
  return template;
}

export function getSqQuestionFormat(template: any[]): any[] {
  return [
    ...template,
    exportUtils.newLine(),
    exportUtils.answerLine(),
    ...exportUtils.trailingLines,
  ];
}
export function getScAnswerFormat(question: types.Question): string[] {
  if (question.scQuestionContent) {
    const scList: string[] = question.scQuestionContent.answer;
    return scList.map((value, index) => {
      return `(${exportUtils.numberToRomanNumeral(index)}) ${value}`;
    });
  }
  return [""];
}
export function getTableAnswerFormat(question: types.Question): string[] {
  if (question.tableQuestionContent) {
    const tableList: string[] = question.tableQuestionContent.answers;
    return tableList.map((value, index) => {
      return `(${exportUtils.numberToRomanNumeral(index)}) ${value}`;
    });
  }
  return [""];
}
export function getFtbAnswerFormat(question: types.Question): string {
  if (question.ftbQuestionContent) return question.ftbQuestionContent.answer;
  return "";
}
export function getSqAnswerFormat(question: types.Question): string {
  if (question.sqQuestionContent) return question.sqQuestionContent.answer;
  return "";
}

export function getLqAnswerFormat(question: types.Question): string {
  if (question.lqQuestionContent) return question.lqQuestionContent.answer;
  return "";
}

export function getMCAnswerFormat(question: types.Question): string {
  if (question.mcQuestionContent)
    return String.fromCharCode(question.mcQuestionContent.answer + 65);
  return "";
}
export function getMatchAnswerFormat(question: types.Question): string[] {
  if (question.matchQuestionContent) {
    const matchList: number[] = question.matchQuestionContent.answers;
    return matchList.map((value, index) => {
      return `(${exportUtils.numberToRomanNumeral(
        index
      )}) ${String.fromCharCode(65 + value)}`;
    });
  }
  return [""];
}

export function getTfAnswerFormat(question: types.Question): string[] {
  if (question.tfQuestionContent) {
    const tfList: types.TfQuestionAnswerList[] =
      question.tfQuestionContent.tfQuestionAnswerList;
    return tfList.map((tfPair, index) => {
      const answer =
        tfPair.tfAnswer === types.TfAnswer.TRUE
          ? "T"
          : tfPair.tfAnswer === types.TfAnswer.FALSE
          ? "F"
          : "NG";
      return `${exportUtils.numberToRomanNumeral(index)}) ${answer}`;
    });
  }
  return [""];
}
