import React, { useState } from "react";
//import Form from "react-bootstrap/Form";
//import Button from "react-bootstrap/Button";
import { Auth } from "aws-amplify";
import { useAppContext } from "../context/context";
import { Link } from "react-router-dom";
import { LoadingIcon } from "../components/LoadingIcon";
import { ReactComponent as Eye } from "./eye.svg";
export default function LoginPage() {
  const {
    userHasAuthenticated,
    setEmailError,
    emailError,
    setPwError,
    pwError,
  } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  

  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

   async function handleSubmit(event: any) {
    event.preventDefault();
    setEmailError(null);
    setPwError(null);
    setIsLoading(true);
    if (!email || !password) {
      !email && setEmailError("Email cannot be empty.");
      !password && setPwError("Password cannot be empty.");
      setIsLoading(false);
      return;
    }

    try {
      await Auth.signIn(email, password);

      userHasAuthenticated(true);
      setIsLoading(false);
    } catch (e: any) {
      setEmailError(e.message);
      setIsLoading(false);
    }
  }
  return (
    <form className="flex flex-row" onSubmit={handleSubmit}>
      <div className="relative lg:w-4/5">
        <img
          src="/READilyLogoBlack.svg"
          alt="logo"
          className="w-40 h-12 absolute left-16 top-10"
        />
        <img
          src="/Section.png"
          alt="section"
          className=" h-screen w-0 lg:w-screen"
        />
      </div>
      <div className="h-screen w-fill container mx-auto flex justify-center">
        <div className="my-auto w-full mx-10 lg:max-w-md">
          <h1 className="text-4xl text-Fill/Dark/MainDark font-Manrope font-semibold py-2">
            Let's Sign you in
          </h1>
          <p className="text-sm text-Fill/Dark/Dark2 font-normal pb-8">
            Create your exercise now!{" "}
          </p>
          <div className="pb-2 text-sm text-MainColor/Black">
            <EmailTextInputComponent
              onChange={(e) => {
                setEmail(e.target.value);
                const validRegex =
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

                if (e.target.value.match(validRegex)) {
                  setEmailError(null);
                } else {
                  setEmailError("Please enter a valid Email.");
                }
              }}
              label="Email"
              error={emailError}
              value={email ?? ""}
            />

            <div>
              <p className="pt-3 pb-2 text-sm font-normal">Password</p>
              <div className="relative">
                <input
                  className="peer outline-none border border-Purple rounded focus:border-blue1 w-full h-12 pl-4 py-3"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  type={passwordShown ? "text" : "password"}
                  id="password"
                  name="password"
                ></input>
                <Eye onClick={togglePassword} />
              </div>
              <p className="pt-1.5 invisible peer-invalid:visible text-xs text-CTA/Error">
                Password is not matching
              </p>
              <p
                className={
                  pwError != null
                    ? "pt-1.5 text-xs text-CTA/Error"
                    : "pt-1.5 invisible"
                }
              >
                Wrong password. Try again or click Forget password to reset it.
              </p>
            </div>
            <div className=" pt-3 grid grid-rows-1 grid-cols-2">
              <div className="justify-self-start">
                <div className="m-1 align-middle inline-flex items-center">
                  <input
                    className="form-checkbox mr-3.5 border rounded text-blue1 w-5 h-5"
                    type="checkbox"
                  />
                  <span className="text-xs font-medium text-Secondary/Black">
                    Remember Me
                  </span>
                </div>
              </div>
              <div className="text-sm text-blue1 font-semibold justify-self-end hover:underline">
                <Link to="/passwordreset">Forget Password</Link>
              </div>
            </div>
            <div className="flex justify-center items-center"></div>
            <div className="pt-7">
             
              <Button
                type="submit"
                isLoading={isLoading}
                onClick={handleSubmit}
                text="Sign In"
              />
            </div>
            
            <div className="justify-center pt-8">
              <p className="text-center">
                Don't have any account? &nbsp;
                <Link
                  to="/register"
                  className="text-blue1 hover:underline font-semibold"
                >
                  Register
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export const EmailTextInputComponent = ({
  onChange,
  error,
  label,
  placeholder = "example@email.com",
  value = "",
}: {
  onChange: (e: any) => void;
  error: string | null;
  label: string;
  placeholder?: string;
  value?: string;
}) => {
  return (
    <>
      <p className="pb-2 text-sm font-normal">{label}</p>
      <div>
        <input
          className={
            "peer outline-none border  rounded  w-full h-12 pl-4 py-3 " +
            (error
              ? " border-CTA/Error text-CTA/Error focus:border-CTA/Error focus:ring-CTA/Error"
              : "border-Purple focus:border-blue1")
          }
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          autoFocus
        ></input>
        {error && (
          <p
            className={
              error != null
                ? "pt-1.5 text-xs text-CTA/Error"
                : "pt-1.5 invisible"
            }
          >
            {error}
          </p>
        )}
      </div>
    </>
  );
};

export const Button = ({ isLoading, onClick, text }: any) => {
  return (
    <button
      className={
        "flex flex-row items-center justify-center text-white bg-blue1 border rounded w-full h-12 font-medium hover:bg-blue2 " +
        (isLoading ? "cursor-not-allowed" : "")
      }
      disabled={isLoading}
      onClick={onClick}
    >
     
      {isLoading && <LoadingIcon />}
      {text}
    </button>
  );
};
