import { useState, useRef, useLayoutEffect } from "react";
import { RefObject } from "react";

import cx from "classnames";
import {
  QuestionAbility,
  questionCategoryMap,
  QuestionType,
  questionTypeNameMap,
} from "./QuestionTypes";
interface QuestionTypeCardProps {
  ability: QuestionAbility | undefined;
  index: number;
  type: QuestionType;
  description: string;
  warning?: string;
  selected?: boolean;
  onSelect: () => void;
  example: React.ReactNode;
}

function QuestionTypeCard({
  ability,
  index,
  type,
  description,
  warning,
  selected,
  onSelect,
  example,
}: QuestionTypeCardProps) {
  const [viewExampleShown, setViewExampleShown] = useState(false);

  const [yPosition, setyPosition] = useState(0);
  document.addEventListener("mousemove", (event) => {
    setyPosition(event.clientY);
  });
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  useLayoutEffect(() => {
    setHeight(ref.current ? ref.current.clientHeight : 0);
  });
  return (
    <>
      <div
        className={cx(
          "cursor-pointer rounded-[8px] px-4 py-[12px] flex items-start gap-4 bg-white shadow-md border-2 border-transparent",
          { "!border-blue1 bg-lightblue1": selected }
        )}
        onClick={onSelect}
      >
        {/* the index number */}
        <div
          className={cx(
            "w-[28px] h-[28px] shrink-0 grid place-items-center bg-purple2 rounded-full text-[12px] font-bold",
            { "!bg-white !text-darkblue1": selected }
          )}
        >
          {/* This is the number of the question */}
          {index}
        </div>
        <div className="flex flex-col items-start gap-4">
          <h6 className="font-semibold">
            {(ability === undefined
              ? ""
              : questionCategoryMap[ability].Ability + " ") +
              questionTypeNameMap[type] +
              (ability === undefined
                ? ""
                : questionCategoryMap[ability].Category + " ")}
          </h6>
          <p className="text-[12px] text-Fill/Dark/Dark2 whitespace-pre-wrap">
            {description}
          </p>
          <p className="text-[12px] text-rose-600 whitespace-pre-wrap">
            {warning}
          </p>
          <div className="h-px bg-Fill/Dark/Dark2 bg-opacity-20 w-full" />
          <div className="relative">
            <div
              className="text-blue1 font-semibold hover:text-blue2 w-[109px]"
              onMouseEnter={() => {
                setViewExampleShown(true);
              }}
              onMouseLeave={() => {
                setViewExampleShown(false);
              }}
            >
              View Example
            </div>
            {viewExampleShown && (
              <>
                <div
                  style={{
                    position: "absolute",
                    top: `${yPosition >= 500 ? -height - 25 : 25}px`,
                    zIndex: "30",
                    width: "244px",
                    height: "max",
                    left: "-3em",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {yPosition < 500 && (
                    <img
                      src="/ViewExample.svg"
                      alt=""
                      className="w-[40px] h-[8px] mx-auto"
                    />
                  )}
                  <div
                    className={cx(
                      "flex w-max bg-black rounded-[8px] items-center"
                    )}
                  >
                    <div
                      className="text-CTA/Disabled text-[14px] font-normal leading-[14px] m-[10px] whitespace-pre-wrap"
                      ref={ref}
                    >
                      {example}
                    </div>
                  </div>
                  {yPosition >= 500 && (
                    <img
                      src="/ViewExample.svg"
                      alt=""
                      className="top-[-25px] w-[40px] h-[8px] mx-auto rotate-180"
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default QuestionTypeCard;
