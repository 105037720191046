import {
  QuestionType,
  QuestionState,
  TfAnswer,
  QuestionAbility,
} from "../../api/codegen/API";

export type QuestionTypeKeys = keyof typeof QuestionType;
export type QuestionAbilityKeys = keyof typeof QuestionAbility;
export type AnswerWithId = Array<{ id: number | string; answer: string }>;
export type MTAnswerWithId = Array<{
  id: number | string;
  answers: number | string;
}>;
export type TBQuestionTitleAndStatementWithId = Array<{
  id: string | number;
  statement: string;
  title: string;
}>;
export type TBAnswerWithId = Array<{
  id: string | number;
  answers: string;
}>;

export type StatementWithId = Array<{
  id: number | string;
  statement: string | number;
}>;
export type QuestionWithId = Array<{ id: number | string; question: string }>;
export type MatchQNAwithId = Array<{
  id: number | string;
  question: string;
  answers: string;
}>;
export type ChoicesWithId = Array<{ id: number | string; choice: string }>;

export type TFQAListWithId = Array<{
  id: number | string;
  tfQuestion: string;
  tfAnswer: TfAnswer;
}>;

export interface questionCategoryMapProps {
  Ability: string;
  Category: string;
}

export { QuestionType, QuestionState, TfAnswer, QuestionAbility };
export type {
  Question,
  McQuestionInput,
  TfQuestionInput,
  TfQuestionAnswerListInput,
  SqQuestionInput,
  LqQuestionInput,
  ScQuestionInput,
  TableQuestionInput,
  McQuestion,
  TfQuestion,
  TfQuestionAnswerList,
  SqQuestion,
  LqQuestion,
  ScQuestion,
  MatchQuestionInput,
  TableQuestion,
  FtbQuestionInput,
} from "../../api/codegen/API";

export const questionTypeNameMap = {
  [QuestionType.LONG_QUESTION]: "Long Question (LQ)",
  [QuestionType.SHORT_QUESTION]: "Short Question (SQ)",
  [QuestionType.TRUE_FALSE]: "True False (T/F)",
  [QuestionType.MULTIPLE_CHOICE]: "MCQ ",
  [QuestionType.SUMMARY_CLOZE]: "Summary Cloze (SC)",
  [QuestionType.FILL_THE_BLANK]: "Fill in The Blank (FTB)",
  [QuestionType.MATCHING]: "Matching (MT)",
  [QuestionType.TABLE]: "Table (TB)",
};

export const questionCategoryMap = {
  [QuestionAbility.THEMATIC]: { Ability: "Thematic", Category: "" },
  [QuestionAbility.THEMATIC_PURPOSE]: {
    Ability: "Thematic",
    Category: " - Purpose",
  },
  [QuestionAbility.THEMATIC_TEXT_TAKEN_FROM]: {
    Ability: "Thematic",
    Category: " - Text Taken From",
  },
  [QuestionAbility.MAIN_IDEA]: { Ability: "Main Idea", Category: "" },
  [QuestionAbility.MAIN_IDEA_ONE_WORD_PHRASE]: {
    Ability: "Main Idea",
    Category: "- One Word & Phrase",
  },
  [QuestionAbility.FACTUAL]: { Ability: "Specific Factual", Category: "" },
  [QuestionAbility.FACTUAL_INCORRECT_STATEMENT]: {
    Ability: "Specific Factual",
    Category: " - Incorrect Exception",
  },
  [QuestionAbility.FACTUAL_EVIDENCE]: {
    Ability: "Specific Factual",
    Category: " - Evidence",
  },
  [QuestionAbility.FACTUAL_RECOMMENDATION]: {
    Ability: "Specific Factual",
    Category: " - Recommendation",
  },
  [QuestionAbility.FACTUAL_SPECIFIC_INFORMATION]: {
    Ability: "Specific Factual",
    Category: " - Specific Information",
  },
  [QuestionAbility.FACTUAL_WRITER_THOUGHTS]: {
    Ability: "Specific Factual",
    Category: " - Writer's Thoughts",
  },
  [QuestionAbility.FACTUAL_TOPIC]: {
    Ability: "Specific Factual",
    Category: " - Topic",
  },
  [QuestionAbility.FACTUAL_NUMBER]: {
    Ability: "Specific Factual",
    Category: " - Number",
  },
  [QuestionAbility.FACTUAL_TERM_IDENFITICATION]: {
    Ability: "Specific Factual",
    Category: " - Term Identification",
  },
  [QuestionAbility.VOCABULARY]: { Ability: "Vocabulary", Category: "" },
  [QuestionAbility.VOCABULARY_MEAN]: {
    Ability: "Vocabulary",
    Category: " - Meaning",
  },
  [QuestionAbility.VOCABULARY_PURPOSE]: {
    Ability: "Vocabulary",
    Category: " - Purpose of the Phrase",
  },
  [QuestionAbility.VOCABULARY_SYNONYM]: {
    Ability: "Vocabulary",
    Category: " - Synonym",
  },
  [QuestionAbility.VOCABULARY_PHRASE_SYNONYM]: {
    Ability: "Vocabulary",
    Category: " - Phrase Synonym",
  },
  [QuestionAbility.VOCABULARY_SUPPORT_STATEMENT]: {
    Ability: "Vocabulary",
    Category: " - Supporting Statement",
  },
  [QuestionAbility.VOCABULARY_PHRASE_MEANING]: {
    Ability: "Vocabulary",
    Category: " - Phrase Meaning",
  },
  [QuestionAbility.REFERENCE]: { Ability: "Reference", Category: "" },
  [QuestionAbility.REFERENCE_MC]: { Ability: "Reference", Category: "" },
  [QuestionAbility.REFERENCE_SQ]: { Ability: "Reference", Category: "" },
  [QuestionAbility.REFERENCE_FILLING]: {
    Ability: "Reference",
    Category: " - Filling the Reference",
  },
  [QuestionAbility.INFERENCE]: { Ability: "Inference", Category: "" },
  [QuestionAbility.INFERENCE_SQ]: { Ability: "Inference", Category: "" },
  [QuestionAbility.INFERENCE_TF]: { Ability: "Inference", Category: "" },
  [QuestionAbility.INFERENCE_PHRASE_SUGGESTION]: {
    Ability: "Inference",
    Category: " - Phrase suggestion",
  },
  [QuestionAbility.INFERENCE_ACCORDING_TO]: {
    Ability: "Inference",
    Category: " - According to Paragraph",
  },
  [QuestionAbility.INFERENCE_PHRASE_MEANING]: {
    Ability: "Inference",
    Category: " - Phrase Meaning",
  },
  [QuestionAbility.INFERENCE_PHRASE_RELATIONS]: {
    Ability: "Inference",
    Category: " - Phrase Relations",
  },
  [QuestionAbility.INFERENCE_IMPLY]: {
    Ability: "Inference",
    Category: " - Implication",
  },
  [QuestionAbility.INFERENCE_EVIDENCE]: {
    Ability: "Inference",
    Category: " - Evidence",
  },
  [QuestionAbility.HIGHER_ORDER]: { Ability: "Higher Order", Category: "" },
  [QuestionAbility.HIGHER_ORDER_OPINION_WITH_EVIDENCE]: {
    Ability: "Higher Order",
    Category: " - Opinion with Evidence",
  },
  [QuestionAbility.HIGHER_ORDER_INFER_THOUGHT]: {
    Ability: "Higher Order",
    Category: " - Infer Writer's Thought",
  },
  [QuestionAbility.HIGHER_ORDER_COMPARISONS]: {
    Ability: "Higher Order",
    Category: " - Comparisons",
  },
  [QuestionAbility.FIGURATIVE_LANG]: {
    Ability: "Figurative Language",
    Category: "",
  },
  [QuestionAbility.FIGURATIVE_LANG_PHRASE_MEANING]: {
    Ability: "Figurative Language",
    Category: " - Phrase Meaning",
  },
  [QuestionAbility.FIGURATIVE_LANG_METAPHOR]: {
    Ability: "Figurative Language",
    Category: " - Metaphor",
  },
  [QuestionAbility.FIGURATIVE_LANG_INFER_LINES]: {
    Ability: "Figurative Language",
    Category: " - Inference",
  },
  [QuestionAbility.VIEW_ATTITUDE]: {
    Ability: "Views and Attitudes",
    Category: "",
  },
  [QuestionAbility.VIEW_ATTITUDE_TONE]: {
    Ability: "Views and Attitudes",
    Category: " - Tone",
  },
  [QuestionAbility.VIEW_ATTITUDE_VIEW]: {
    Ability: "Views and Attitudes",
    Category: " - View",
  },
  [QuestionAbility.VIEW_ATTITUDE_ATTITUDE]: {
    Ability: "Views and Attitudes",
    Category: " - Attitude",
  },
  [QuestionAbility.VIEW_ATTITUDE_IMPLY]: {
    Ability: "Views and Attitudes",
    Category: " - Implication",
  },
  [QuestionAbility.VIEW_ATTITUDE_PERSON_INVOLVED]: {
    Ability: "Views and Attitudes",
    Category: " - Person Involved",
  },
  [QuestionAbility.VIEW_ATTITUDE_MEAN]: {
    Ability: "Views and Attitudes",
    Category: " - Mean",
  },
  [QuestionAbility.VIEW_ATTITUDE_LETTER_ONCE]: {
    Ability: "Views and Attitudes",
    Category: " - Letter Once",
  },
  [QuestionAbility.RELATIONSHIP]: { Ability: "Relationship", Category: "" },
  [QuestionAbility.RELATIONSHIP_PROBLEM_AND_SOLUTION]: {
    Ability: "Relationship",
    Category: " - Problem and Solution",
  },
  [QuestionAbility.RELATIONSHIP_CAUSES]: {
    Ability: "Relationship",
    Category: " - Causes",
  },
  [QuestionAbility.RELATIONSHIP_REASONING]: {
    Ability: "Relationship",
    Category: " - Reasoning",
  },
};
