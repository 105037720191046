import React, { cloneElement } from "react";
import { Navigate } from "react-router-dom";
import { useAppContext } from "../context/context";

function querystring(name: any, url = window.location.href) {
  const parsedName = name.replace(/[[]]/g, "\\$&");
  // Safari doesnt support the line below because of regex look behind https://exerror.com/invalid-regular-expression-invalid-group-specifier-name-in-safari/
  const regex = new RegExp(`[?&]${parsedName}(=([^&#]*)|&|#|$)`, "i");
  const results = regex.exec(url);

  if (!results || !results[2]) {
    return null;
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export default function UnauthenticatedRoute(props: any) {
  const { isAuthenticated } = useAppContext();
  const { children } = props;
  const redirect = querystring("redirect");

  if (isAuthenticated) {
    return <Navigate to={redirect || "/"} />;
  }

  return cloneElement(children, props);
}
