import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import NotFoundPage from "./pages/NotFoundPage";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import DashboardPage from "./pages/DashboardPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import PassagePage from "./pages/PassagePage";

import ReactGA from "react-ga";
import PapersPage from "./pages/PapersPage";
import PaperBankExercisePage from "./pages/PaperBankExercisePage";

const TRACKING_ID = "UA-234238070-1";
ReactGA.initialize(TRACKING_ID); // also look for index.tsx

export default function Links() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });
  return (
    <Routes>
      <Route
        path="/passage/:exerciseIdParams"
        element={
          <AuthenticatedRoute>
            <PassagePage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/"
        element={
          <AuthenticatedRoute>
            <DashboardPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/login"
        element={
          <UnauthenticatedRoute>
            <LoginPage />
          </UnauthenticatedRoute>
        }
      />
      {/* <Route path="/paperbank" element={<PapersPage />} /> */}
      <Route path="/paperbank">
        <Route index element={<PapersPage />} />
        <Route
          path=":exerciseIdParams"
          element={
            <AuthenticatedRoute>
              <PaperBankExercisePage />
            </AuthenticatedRoute>
          }
        />
      </Route>
      <Route
        path="/register"
        element={
          <UnauthenticatedRoute>
            <RegisterPage />
          </UnauthenticatedRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <AuthenticatedRoute>
            <DashboardPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/passwordreset"
        element={
          <UnauthenticatedRoute>
            <ForgotPasswordPage />
          </UnauthenticatedRoute>
        }
      />
      {/* Finally, catch all unmatched routes */}
      <Route path="*" element={<NotFoundPage />} />;
    </Routes>
  );
}
