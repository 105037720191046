import React from "react";
import cx from "classnames";
import { ReactComponent as IconCross } from "../../icons/IconCross.svg";

interface PopupWindowProps extends React.HTMLAttributes<HTMLDivElement> {
  onClose?: () => void;
  children: React.ReactNode;
}

function PopupWindow({ children, className }: PopupWindowProps) {
  return (
    <div className="fixed z-[100] inset-0 bg-black bg-opacity-50">
      <div
        className={cx(
          "absolute inset-x-0 mx-auto bg-white rounded-[16px]",
          className
        )}
      >
        {children}
      </div>
    </div>
  );
}

interface PopupWindowWithHeaderProps extends PopupWindowProps {
  title: string;
  onClose: () => void;
}

export function PopupWindowWithHeader({
  title,
  children,
  className,
  onClose,
}: PopupWindowWithHeaderProps) {
  return (
    <PopupWindow className={className}>
      <header className="px-[30px] flex items-center justify-between py-6 shadow-sm">
        <h3 className="font-bold font-sm">{title}</h3>
        <IconCross
          onClick={onClose}
          className="cursor-pointer w-[1.2em] h-[1.2em] text-grey hover:text-black"
        />
      </header>
      {children}
    </PopupWindow>
  );
}

export default PopupWindow;
